import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { timecardProps } from 'components/props/timecard';
import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DayButton from 'components/Shared/Timecard/DayButton';
import { formatDate } from 'utils/weekUtils';

const style = () => ({
  root: {},
  days: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    alignContent: 'stretch',
    marginBottom: 5,
  },
  note: {
    padding: 20,
  },
  divider: {
    marginBottom: 10,
    marginTop: 10,
  },
});

const CalendarRow = ({
  classes,
  activeDays,
  blockedDays,
  onChangeActive,
  timecard,
  crewPartialDays,
}) => {
  const days = _.map(timecard.days, (day, index) => (
    <DayButton
      key={`Day-${index}`}
      day={day}
      index={index}
      active={day.isActive && !!activeDays[formatDate(day.date)]}
      blocked={blockedDays[formatDate(day.date)]}
      crewPartialDays={crewPartialDays}
      onChangeActive={(date, active) => onChangeActive(date, active, index)}
    />
  ));

  let blockedMessage = null;

  if (_.some(blockedDays, blocked => blocked)) {
    blockedMessage = (
      <div className={classes.note}>
        <Typography color="primary">
          {
            '*The selected deal memo is not valid for the marked days. You cannot input hours for days marked with *'
          }
        </Typography>
      </div>
    );
  }

  return (
    <Paper square elevation={0} className={classes.root}>
      <div className={classes.days}>{days}</div>
      {blockedMessage}
    </Paper>
  );
};

CalendarRow.propTypes = {
  classes: PropTypes.object.isRequired,
  activeDays: PropTypes.object,
  blockedDays: PropTypes.object,
  onChangeActive: PropTypes.func,
  timecard: timecardProps,
};

export default withStyles(style)(CalendarRow);
