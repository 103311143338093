import React, { useEffect } from 'react';
import _ from 'lodash';

import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { TableListWTC as TableList } from 'components/Shared/TableList';
import WeekDay from './WeekDay';

import MasterRow from './MasterRow';

import { FieldArray } from 'redux-form';
import { formatDate } from 'utils';
import { formatDateUTC } from 'utils/formatDate';
import { dayHoursCalc } from 'utils/wtcWeekUtils';
import { useDispatch } from 'react-redux';
import {
  isRegionCanada,
  isArrayItemEmptyOrNull,
  canadianLabel,
} from 'utils/helperFunctions';
import { toggleTableFields, toggleAllowanceField } from 'actions/wtc';

const style = ({ palette }) => ({
  tableContainer: {
    display: 'flex',
    '& > div:nth-child(n+1)': {
      marginLeft: 6,
    },
  },

  total: {
    float: 'right',
    marginRight: 5,
    paddingTop: 15,
  },
  totalLabel: {
    fontSize: 18,
    verticalAlign: 'middle',
    display: 'inline',
  },
  totalHoursCell: {
    padding: '0 5px',
    margin: '0px 10px',
    borderRadius: 5,
    verticalAlign: 'middle',
    fontWeight: 500,
    fontSize: 24,
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    display: 'inline-flex',
    minWidth: 56,
    minHeight: 32,
  },

  tabluea: {
    width: '99%',
  },
  tableSizer: {
    flexGrow: 1,
    overflowX: 'auto',
  },

  wrLink: {
    color: palette.primary.main,
    cursor: 'pointer',
    marginLeft: 10,
    marginBottom: 12,
    width: 135,
  },

  fadeToWhiteRight: {
    position: 'absolute',
    right: 20,
    backgroundImage: 'linear-gradient(to right, transparent,25%, white)',
    width: 35,
    //height set dynamiclly to that of the parent
    zIndex: 2,
    pointerEvents: 'none',
    transition: 'opacity 0.5s',
  },
});

const TimecardDays = props => {
  const {
    change,
    blur,
    classes,
    paidHours,
    dayTypes,
    workSchedules,
    loadWorkSchedules,
    loadDayTypes,
    occupationCodes,
    loadOccupationCodes,
    workLocations,
    loadWorkLocations,
    dealMemoId,
    dealMemos,
    onShowMemoModal,
    episodes,
    loadEpisodes,
    tableFields,
    details = [],
    splitHourTypes,
    employeeId,
    comments,
    error,
    allowancesCount,
    wtcDisabled,
    upmEnabled,
    settings,
    maskingFunc,
    accountCodeMask,
    masterRowData,
    onFetchAndSetScaleRate,
    scaleLoading,
    //
    countries,
    workingWorkState,
    stateOptions,
    countyOptions,
    cityOptions,
    subdivisions,
    locationFuncs,
    timecardDealMemo,
    project,
  } = props;
  const dispatch = useDispatch();

  const caRegion = isRegionCanada(project.region);
  let headerArray = Object.values(tableFields).map(item => ({
    ...item,
    visible:
      caRegion &&
      (item.columnId === 'locationType' || item.columnId === 'schedule')
        ? false
        : item.visible,
    label: canadianLabel(item.label),
  }));
  const paidHoursHeader = _.cloneDeep(paidHours.headerObjArray);
  //insert paidHours headers before 'accountCode'
  const accountCodeIndex = _.findIndex(
    headerArray,
    h => h.columnId === 'accountCode',
  );

  if (Array.isArray(paidHoursHeader)) {
    headerArray.splice(accountCodeIndex, 0, ...paidHoursHeader);
  }

  const calcHoursColumns = tableFields => {
    const hoursPosition = tableFields.hoursWorked.position;
    let count = 0;

    Object.values(tableFields).forEach(field => {
      if (field.visible && field.position <= hoursPosition) {
        count++;
      }
    });
    return count;
  };

  //if no hours worked on timecard, calculate it from existing details
  let hoursWorked = details
    .reduce((total, day) => {
      return total + dayHoursCalc(day);
    }, 0)
    .toFixed(2);

  useEffect(() => {
    // react-router will set the scroll lower if it was there before navigation
    window.scrollTo(0, 0);
  }, [timecardDealMemo]);

  useEffect(() => {
    const workCities = details.map(item => item.workCity);
    if (
      isRegionCanada(localStorage.getItem('region')) &&
      isArrayItemEmptyOrNull(workCities)
    ) {
      dispatch(toggleTableFields({ columnId: 'workCity', visible: false }));
      dispatch(toggleAllowanceField({ columnId: 'workCity', visible: false }));
    }
  }, [dispatch, details]);

  return (
    <div className={`${classes.tabluea} tcTable`}>
      <div className={classes.tableContainer}>
        <TableList
          className={classes.tableSizer}
          headers={headerArray}
          id="wtcTable"
          onShowMemoModal={onShowMemoModal}
          dealMemoId={dealMemoId}
          wtcDisabled={wtcDisabled}
        >
          <MasterRow
            headers={headerArray}
            enableWorkLocation
            workSchedules={workSchedules}
            loadWorkSchedules={loadWorkSchedules}
            loadDayTypes={loadDayTypes}
            dealMemos={dealMemos}
            occupationCodes={occupationCodes}
            loadOccupationCodes={loadOccupationCodes}
            workLocations={workLocations}
            loadWorkLocations={loadWorkLocations}
            episodes={episodes}
            loadEpisodes={loadEpisodes}
            dayTypes={dayTypes}
            change={change}
            blur={blur}
            allowancesCount={allowancesCount}
            accountCodesEnabled
            onCopyToAll={false}
            viewOnly
            editableFields
            dayError
            tableFields={tableFields}
            wtcDisabled={wtcDisabled}
            details={details}
            upmEnabled={upmEnabled}
            settings={settings}
            maskingFunc={maskingFunc}
            accountCodeMask={accountCodeMask}
            onFetchAndSetScaleRate={onFetchAndSetScaleRate}
            scaleLoading={scaleLoading}
            //
            masterRowData={masterRowData}
            workingWorkState={workingWorkState}
            countries={countries}
            stateOptions={stateOptions}
            countyOptions={countyOptions}
            cityOptions={cityOptions}
            subdivisions={subdivisions}
            locationFuncs={locationFuncs}
            caRegion={caRegion}
            project={project}
          />
          <FieldArray
            name="details"
            component={renderDays}
            workSchedules={workSchedules}
            loadWorkSchedules={loadWorkSchedules}
            masterRowData={masterRowData}
            occupationCodes={occupationCodes}
            loadOccupationCodes={loadOccupationCodes}
            workLocations={workLocations}
            loadWorkLocations={loadWorkLocations}
            dealMemos={dealMemos}
            loadDayTypes={loadDayTypes}
            employeeId={employeeId}
            episodes={episodes}
            loadEpisodes={loadEpisodes}
            dayTypes={dayTypes}
            rerenderOnEveryChange
            isViewOnly
            change={change}
            blur={blur}
            tableFields={tableFields}
            headerArray={headerArray}
            paidHours={paidHours}
            details={details}
            splitHourTypes={splitHourTypes}
            comments={comments}
            error={error}
            wtcDisabled={wtcDisabled}
            onShowMemoModal={onShowMemoModal}
            upmEnabled={upmEnabled}
            settings={settings}
            maskingFunc={maskingFunc}
            accountCodeMask={accountCodeMask}
            scaleLoading={scaleLoading}
            onFetchAndSetScaleRate={onFetchAndSetScaleRate}
            //
            countries={props.countries}
            stateOptions={stateOptions}
            cityOptions={cityOptions}
            countyOptions={countyOptions}
            subdivisions={subdivisions}
            locationFuncs={locationFuncs}
            timecardDealMemo={timecardDealMemo}
            caRegion={caRegion}
            project={project}
          />
          <tr>
            <td colSpan={calcHoursColumns(tableFields)}>
              <div className={classes.total}>
                <Typography className={classes.totalLabel}>Total:</Typography>
                <Typography className={classes.totalHoursCell}>
                  {hoursWorked}
                </Typography>
              </div>
            </td>
          </tr>
        </TableList>
      </div>
    </div>
  );
};

const renderDays = ({
  fields,
  workSchedules,
  loadWorkSchedules,
  loadDayTypes,
  occupationCodes,
  loadOccupationCodes,
  workLocations,
  loadWorkLocations,
  dealMemos,
  employeeId,
  episodes,
  loadEpisodes,
  dayTypes,
  masterRowData,
  change,
  blur,
  tableFields,
  paidHours,
  headerArray,
  details,
  splitHourTypes,
  comments,
  //
  countries,
  stateOptions,
  cityOptions,
  subdivisions,
  timecardDealMemo,
  caRegion,
  project,
  ...props
}) => {
  const daysError = (props.error && props.error.details) || [];
  const formFields = fields.getAll();
  const weekdays = fields.map((member, index) => {
    const detail = formFields[index];
    const dayError = daysError[index];
    const visibleComment =
      comments &&
      _.find(
        comments,
        comment =>
          formatDateUTC(comment.referenceDate, 'dddd') ===
          formatDate(detail.effectiveDate, 'dddd'),
      );

    //const visibleComment = comments.map

    return (
      <WeekDay
        key={detail.effectiveDate}
        timecardDealMemo={timecardDealMemo}
        member={member}
        tcDates={details?.map(d => d.effectiveDate) || []}
        dayIndex={index}
        enableWorkLocation
        workSchedules={workSchedules}
        loadWorkSchedules={loadWorkSchedules}
        loadDayTypes={loadDayTypes}
        occupationCodes={occupationCodes}
        loadOccupationCodes={loadOccupationCodes}
        episodes={episodes}
        loadEpisodes={loadEpisodes}
        workLocations={workLocations}
        loadWorkLocations={loadWorkLocations}
        dealMemos={dealMemos}
        employeeId={employeeId}
        dayTypes={dayTypes}
        change={change}
        blur={blur}
        even={index % 2 === 0}
        masterRowData={masterRowData}
        accountCodesEnabled
        onCopyToAll={false}
        viewOnly
        editableFields
        dayError={dayError}
        tableFields={tableFields}
        paidHours={paidHours}
        headerArray={headerArray}
        detail={detail}
        splitHourTypes={splitHourTypes}
        visibleComment={visibleComment}
        //
        countries={countries}
        stateOptions={stateOptions}
        cityOptions={cityOptions}
        subdivisions={subdivisions}
        caRegion={caRegion}
        project={project}
        details={details}
        {...props}
      />
    );
  });
  return weekdays;
};

export default withStyles(style)(TimecardDays);
