import moment from './moment';
import * as _find from 'lodash/find';
import _ from 'lodash';
import { isRegionCanada } from 'utils/helperFunctions';

/**
 * Base class for timecard validation for WTC and DTS
 */
class TimecardValidatorBase {
  constructor(detail, tableFields, episodes) {
    this._detail = detail;
    this._tableFields = tableFields;
    this._episodes = episodes;
    this.TIME_FIELD_ORDER = Object.keys(tableFields).filter(
      f =>
        this._tableFields[f].group === 'time' &&
        !this._tableFields[f].skipValidation,
    );

    this._errors = {};
    this._warnings = {};
  }

  getNumValue(fieldName) {
    return parseFloat(this._detail[fieldName]);
  }

  isAfterPrevExistingTime(fieldName) {
    const fIndex = this.TIME_FIELD_ORDER.findIndex(
      timeField => timeField === fieldName,
    );

    let i;
    let prevField;
    for (i = fIndex - 1; i >= 0; i--) {
      prevField = this.TIME_FIELD_ORDER[i];
      if (this._detail[prevField]) break;
    }

    if (i < 0) return;

    const dealMemoHtgUnion =
      this._detail.dealMemo && this._detail.dealMemo.htgUnion;
    const canBeEqualTimes =
      dealMemoHtgUnion &&
      (dealMemoHtgUnion.code === 'SAG' || dealMemoHtgUnion.code === 'AFTRA');

    if (canBeEqualTimes) {
      this.isAfterOrSame(fieldName, prevField);
    } else {
      this.isAfter(fieldName, prevField);
    }
  }

  validateTimeFields() {
    //flag for warnings for DTS instead of hard errors
    const isDTS = this._variant === 'dts';

    Object.keys(this._tableFields)
      .filter(f => {
        return (
          (this._detail[f] || this._detail[f] === 0) &&
          this._tableFields[f].group === 'time' &&
          !this._tableFields[f].skipValidation
        );
      })
      .forEach(fieldName => {
        switch (fieldName) {
          case 'callTime':
            this.isBeforeHours(fieldName, 24);
            break;
          // Note: we DO NOT need bothRequired Validation for NDB
          // eslint-disable-next-line no-fallthrough
          case 'lastMan1In':
          case 'LastMan1In':
            this.requiredIfPresent('meal1In', fieldName, isDTS);
            break;
          case 'meal1Out':
            this.bothRequired('meal1In', fieldName, isDTS);
            break;
          case 'meal1In':
            this.bothRequired('meal1Out', fieldName, isDTS);
            break;
          case 'ndmOut':
            this.bothRequired('ndmIn', fieldName, isDTS);
            break;
          case 'ndmIn':
            this.bothRequired('ndmOut', fieldName, isDTS);
            break;
          case 'meal2Out':
            this.bothRequired('meal2In', fieldName, isDTS);
            break;
          case 'meal2In':
            this.bothRequired('meal2Out', fieldName, isDTS);
            break;
          case 'meal3Out':
            this.bothRequired('meal3In', fieldName, isDTS);
            break;
          case 'meal3In':
            this.bothRequired('meal3Out', fieldName, isDTS);
            break;

          default:
            break;
        }

        if (fieldName !== 'callTime') {
          this.isNotZero(fieldName);
          this.isBeforeHours(fieldName, 48);
        }
        this.isAfterPrevExistingTime(fieldName);
      });

    //handle special call/wrap time instances
    if (this._variant === 'dts') {
      this.warnRequiredIfOtherTimes('callTime');
      this.warnRequiredIfOtherTimes('wrapTime');
    } else if (this._variant === 'wtc') {
      if (this._isExempt) {
        this.requiredIfPresent('wrapTime', 'callTime');
        this.requiredIfPresent('callTime', 'wrapTime');
      } else {
        const dayTypeCode = this._detail?.dayType?.code;
        //Day Type is WORK (code:'1') or Travel Only (code:'6')
        const dayTypeRequiresCall = dayTypeCode === '1' || dayTypeCode === '6';

        if (dayTypeRequiresCall) {
          this.required('callTime');
        }
        this.requiredIfPresent('wrapTime', 'callTime');
      }

      this.validateDayOverlap();
    }
  }

  validateDayOverlap() {
    const wrapTime = this._detail?.wrapTime;
    const nextCall = this._nextDay?.callTime;

    const nextCalendarDay = moment(this._detail?.effectiveDate)
      .add(1, 'days')
      .format('MM-DD-YYYY');
    const nextDay = moment(this._nextDay?.effectiveDate)?.format('MM-DD-YYYY');

    if (
      nextCalendarDay === nextDay &&
      (nextCall || nextCall === 0) &&
      wrapTime &&
      wrapTime >= 24
    ) {
      if (nextCall <= wrapTime - 24) {
        this.addError(
          'wrapTime',
          "Wrap time overlaps with next day's call time.",
          false,
        );
      }
    }
  }

  validateProjectFields() {
    if (!this._project) return;

    if (this._project.accountCodeRequired) {
      //this comes in as a bool, the rest are "Y" or "N"
      this.required('accountCode');
    }

    if (this._project.episodeRequired === 'Y') {
      this.required('episode');
    }

    if (this._project.seriesRequired === 'Y') {
      this.required('series');
    }

    if (this._project.setRequired === 'Y') {
      this.required('set');
    }

    if (this._project.insuranceRequired === 'Y') {
      this.required('insurance');
    }

    if (this._project.locationRequired === 'Y') {
      this.required('location');
    }

    for (let i = 1; i <= 4; i++) {
      const f = `customField${i}`;
      if (this._project[`${f}Required`] === 'Y') {
        this.required(`freeField${i}`);
      }
    }
  }

  validateCCK() {
    const cck = this._detail.combineCheck;

    if (!cck) {
      this.addError(
        `combineCheck`,
        `${this.getLabel('combineCheck')} is required`,
      );
    } else if (!/[A-Za-z0-9!]{1}/.test(cck)) {
      this.addError(
        `combineCheck`,
        `${this.getLabel('combineCheck')} has an invalid value`,
      );
    }
  }

  validateState() {
    this.doesStateRequireCity(); // see below for comments
    this.doesStateRequireSubdivision();

    const state = this._detail.workState;

    const country = this._detail.workCountry;

    if (!state || !state.id) {
      if (
        !country ||
        country.code === 'US' ||
        country.code === 'CA' ||
        _.isEmpty(country)
      ) {
        this.addError(`workState`, `${this.getLabel('workState')} is required`);
        this.required('workCity'); //HOUR-1425 - remove when doing proper validation
        return;
      }
    }
  }

  doesStateRequireCity() {
    // the state.specialOptions object doesn't get passed through by HTG.
    // as part of the short-term fix for HOUR-1425, adding city requirement wherever state is required.
    const state = this._detail.workState;
    if (state?.specialOptions?.includes('T')) {
      this.required('workCity');
    } else {
      this.clearError('workCity');
    }
    if (state?.specialOptions?.includes('C')) {
      this.required('workCounty');
    } else {
      this.clearError('workCounty');
    }
  }

  doesStateRequireSubdivision() {
    const state = this._detail.workState;

    if (state?.specialOptions?.includes('U')) {
      if (!(this._detail.workSubdivision && this._detail.workSubdivision.id)) {
        this.addError(
          'workSubdivision',
          `${this.getLabel('workSubdivision')} is required for work state ${
            state.code
          }`,
        );
      }
    }
  }

  getLabel(fieldName) {
    const tableField = this._tableFields[fieldName];

    if (!tableField || !tableField.label) {
      return fieldName;
    }

    return tableField.label;
  }

  numberZeroOrGreater(fieldName) {
    if (!fieldName || this._detail[fieldName] === undefined) {
      return;
    }
    // Allowance Hours are 'Units' in the WTC allowances table.
    const label = fieldName === 'hours' ? 'Units' : this.getLabel(fieldName);
    let value = this._detail[fieldName];
    if (value || value === 0) value = value.toString().trim();

    if (value === '') return;

    const numValue = parseFloat(value);

    // parseFloat will remove parts of a string if it can
    // it will return 5.5 when given '5.5abc'
    if (isNaN(numValue)) {
      this.addError(fieldName, `${label} must be a number`);
      return;
    }

    if (numValue < 0) {
      this.addError(fieldName, `${label} cannot be less than 0`);
    }
  }

  //time comparisons

  isNotZero(fieldName) {
    if (!fieldName) {
      return;
    }
    const value = this.getNumValue(fieldName);

    if (value === 0) {
      this.addError(fieldName, `${this.getLabel(fieldName)} cannot be 0`);
    }
  }

  isBeforeHours(fieldName, num) {
    if (!fieldName) {
      return;
    }

    const value1 = this.getNumValue(fieldName);

    if (value1 && value1 >= num) {
      this.addError(
        fieldName,
        `${this.getLabel(fieldName)} must be less than ${num} hours`,
      );
    }
  }

  isBefore(fieldName1, fieldName2) {
    if (!fieldName1 || !fieldName2) {
      return;
    }

    const value1 = this.getNumValue(fieldName1);
    const value2 = this.getNumValue(fieldName2);

    if (value1 && value2 && value1 >= value2) {
      this.addError(
        fieldName1,
        `${this.getLabel(fieldName1)} must be before ${this.getLabel(
          fieldName2,
        )}`,
      );
    }
  }

  isAfter(fieldName1, fieldName2) {
    if (!fieldName1 || !fieldName2) {
      return;
    }

    const value1 = this.getNumValue(fieldName1);
    const value2 = this.getNumValue(fieldName2);

    if (value1 && value2 && value1 <= value2) {
      this.addError(
        fieldName1,
        `${this.getLabel(fieldName1)} must be after ${this.getLabel(
          fieldName2,
        )}`,
      );
    }
  }

  isAfterOrSame(fieldName1, fieldName2) {
    if (!fieldName1 || !fieldName2) {
      return;
    }

    const value1 = this.getNumValue(fieldName1);
    const value2 = this.getNumValue(fieldName2);

    if (value1 && value2 && value1 < value2) {
      this.addError(
        fieldName1,
        `${this.getLabel(fieldName1)} must be after or equal to ${this.getLabel(
          fieldName2,
        )}`,
      );
    }
  }
  //end time comparisons

  between(fieldName, min, max) {
    if (!fieldName) {
      return;
    }

    const value = this._detail[fieldName];

    if (value && (value < min || value > max)) {
      this.addError(
        fieldName,
        `${this.getLabel(fieldName)} must be between ${min} and ${max}`,
      );
    }
  }

  required(fieldName, warn = false) {
    if (!fieldName) return;

    const value = this._detail[fieldName];

    if (fieldName === 'callTime' && value === 0) return;

    //if field is just a value, then !value will be false => error is added.
    //if field is an object (like for a dropdown), then it should have an id, and !value.id will be false => error is added
    if (!value || (typeof value === 'object' && !value.id)) {
      this.addError(fieldName, `${this.getLabel(fieldName)} is required`, warn);
    }
  }

  bothRequired(fieldName1, fieldName2, warn = false) {
    if (!fieldName1 || !fieldName2) return;

    const value1 = this.getNumValue(fieldName1);
    const value2 = this.getNumValue(fieldName2);

    if (value1 && !value2 && value2 !== 0) {
      this.addError(
        fieldName2,
        `${this.getLabel(fieldName1)} and ${this.getLabel(
          fieldName2,
        )} are both required`,
        warn,
      );
    }

    if (value2 && !value1 && value1 !== 0) {
      this.addError(
        fieldName1,
        `${this.getLabel(fieldName1)} and ${this.getLabel(
          fieldName2,
        )} are both required`,
        warn,
      );
    }
  }

  requiredIfPresent(fieldName1, fieldName2, warn = false) {
    if (!fieldName1 || !fieldName2) return;

    const value1 = this.getNumValue(fieldName1);
    const value2 = this.getNumValue(fieldName2);

    if ((value2 || value2 === 0) && !value1 && value1 !== 0) {
      const field1Label = this.getLabel(fieldName1);
      const field2Label = this.getLabel(fieldName2);
      this.addError(
        fieldName1,
        `${field1Label} required if ${field2Label} is present`,
        warn,
      );
    }
  }

  warnRequiredIfOtherTimes(fieldName) {
    const value = this.getNumValue(fieldName);
    if ((!value && value !== 0) || Number.isNaN(value)) {
      for (let i = 0; i < this.TIME_FIELD_ORDER.length; i++) {
        const field = this.TIME_FIELD_ORDER[i];
        if (this.getNumValue(field)) {
          const fieldLabel = this.getLabel(fieldName);
          //other time cell has value, call/wrap is required. add warning for dts

          this.addError(fieldName, `${fieldLabel} is required`, true);
          break;
        }
      }
    }
  }

  getErrors() {
    return this._errors;
  }
  getWarnings() {
    return this._warnings;
  }

  hasErrors() {
    return Object.keys(this._errors).length > 0;
  }

  addError(fieldName, error, warn) {
    if (!fieldName) return;

    const errors = warn ? this._warnings : this._errors;

    if (this._variant === 'wtcAllowance') {
      error += ' - Allowance';
    }
    if (this._variant === 'wtc') {
      error += ' - Labor';
    }

    const prevErrors = errors[fieldName];
    if (prevErrors && error) {
      errors[fieldName] += '. ' + error;
      return;
    }

    if (error) {
      errors[fieldName] = error;
      return;
    }

    if (prevErrors === undefined) {
      errors[fieldName] = null;
    }
  }

  clearError(fieldName) {
    delete this._errors[fieldName];
  }
}

const dealMemoErr =
  'No changes to the timecard can be saved because the deal memo is no longer valid. Please select a valid deal memo before making any changes.';

const validateDealMemo = ({
  values,
  dealMemosOptions,
  dealMemoId,
  timecard,
  errors,
}) => {
  const dealMemo = _find(dealMemosOptions, dm => dm.id === dealMemoId);

  // Dealmemo not in options
  if (dealMemoId && !dealMemo) {
    errors.valid = false;
    errors.timecard.push({ dealMemo: dealMemoErr });
    return;
  }

  // Dealmemo invalid or out of date range.
  if (dealMemoId && dealMemo) {
    const dealInfo = dealMemo;
    const dealStart = moment.utc(dealInfo.start);
    const dealEnd = moment.utc(dealInfo.end);
    const tcStart = moment.utc(timecard.weekStartingDate);
    const tcEnd = moment.utc(timecard.weekEndingDate);

    if (
      dealInfo.valid === false ||
      dealStart.isAfter(tcEnd) ||
      dealEnd.isBefore(tcStart)
    ) {
      errors.valid = false;
      errors.timecard.push({ dealMemo: dealMemoErr });
      return;
    }
  }
};

class TimecardWtcDetailValidator extends TimecardValidatorBase {
  constructor({
    detail,
    tableFields,
    tcDealMemo,
    dealMemos,
    project,
    weekStartingDate,
    weekEndingDate,
    episodes,
    nextDay,
  }) {
    super(detail, tableFields, episodes);
    this._variant = 'wtc';
    this._dealMemos = dealMemos;
    this._project = project;
    this._nextDay = nextDay;
    this._weekStartingDate = weekStartingDate;
    this._weekEndingDate = weekEndingDate;

    const fullTCDealMemo = dealMemos.find(dm => dm.id === tcDealMemo.id);

    this._isExempt = fullTCDealMemo?.exempt === true;
  }

  validate() {
    const dmObj = _.find(
      this._dealMemos,
      dealmemo => dealmemo.id === this._detail?.dealMemo?.id,
    );

    if (dmObj === undefined) {
      return;
    }

    const isPartialDealMemo =
      moment(this._detail?.effectiveDate).isBefore(dmObj?.start) ||
      moment(this._detail?.effectiveDate).isAfter(dmObj?.end);

    const isUnusedDay = this._detail?.unusedDay === true;

    if (!isPartialDealMemo && !isUnusedDay) {
      this.validateProjectFields();
      this.validateDealMemo();
      this.validateTimeFields();
      const isCanada = isRegionCanada(this._project.region);
      if (!isCanada) this.required('locationType');
      this.required('dayType');
      this.required('schedule');
      this.validateState();
      this.validateCCK(); // Do we keep this one?????
      this.validateSplits(); // I'm guessing yest keep splits validation
    }
  }

  validateSplits() {
    const daySplits = this._detail.percentBreakdown;
    if (!daySplits) return;
    daySplits.forEach((split, index) => {
      const value1 = this._detail.percentBreakdown[index].timeOutSplit;
      const value2 = this._detail.percentBreakdown[index].timeInSplit;
      const regex = /^\d+(\.\d+)?$|^\*$/;
      if (split.percentSplit) {
        if (
          parseFloat(split.percentSplit) >= 101 ||
          parseFloat(split.percentSplit) <= 0 ||
          !regex.test(split.percentSplit)
        ) {
          this.addError(
            `percentBreakdown[${index}].percentSplit`,
            `The value for a split is invalid`,
          );
        }
      }
      if (split.unitsSplit) {
        if (!regex.test(split.unitsSplit)) {
          this.addError(
            `percentBreakdown[${index}].unitsSplit`,
            `The value for a split is invalid`,
          );
        }
      }
      if (split.timeInSplit) {
        if (value2 && !value1 && value1 !== 0) {
          this.addError(
            `percentBreakdown[${index}].timeOutSplit`,
            `Start and End are both required`,
          );
        }
      }
      if (split.timeOutSplit) {
        if (value1 && !value2 && value2 !== 0) {
          this.addError(
            `percentBreakdown[${index}].timeInSplit`,
            `Start and End are both required`,
          );
        }
      }
      if (split.timeInSplit && split.timeOutSplit) {
        if (value1 && value2 && parseFloat(value2) <= parseFloat(value1)) {
          this.addError(
            `percentBreakdown[${index}].timeInSplit`,
            `The value for a split is invalid`,
          );
        }
      }
      if (
        !split.percentSplit &&
        !split.unitsSplit &&
        !split.timeOutSplit &&
        !split.timeInSplit
      ) {
        this.addError(
          `percentBreakdown[${index}].splitType`,
          `A value for the split is required`,
        );
      }

      if (
        !split.locationType &&
        !split.workCountry &&
        !split.onProduction &&
        !split.accountCode &&
        !split.episode &&
        !split.series &&
        !split.location &&
        !split.set &&
        !split.insurance &&
        !split.freeField1 &&
        !split.freeField1 &&
        !split.freeField1 &&
        !split.freeField1 &&
        !split.workState
      ) {
        this.addError(
          `percentBreakdown[${index}]`,
          `Please fill out at least one splittable field`,
        );
      }
      if (!split.hoursTypeSplit) {
        this.addError(
          `percentBreakdown[${index}].hoursTypeSplit.id`,
          `Please select a hour type for the split`,
        );
      }
    });
  }

  validateDealMemo() {
    const dealMemo = this._detail.dealMemo;
    if (!dealMemo || !dealMemo.id) {
      return;
    }

    if (this.isDealMemoExpired()) {
      this.addError('dealMemo', `${this.getLabel('dealMemo')} is expired`);
      return;
    }

    if (!dealMemo.workSchedule) {
      this.addError('dealMemo', `${this.getLabel('dealMemo')} has no Schedule`);
      return;
    }
  }

  isDealMemoExpired() {
    const dayDeal = this._detail.dealMemo;

    if (!dayDeal) {
      return true;
    }

    const dealMemoId = dayDeal.id;

    // deal memos can be stale, _dealMemos has most up to date deals.
    const dealMemo = _find(this._dealMemos, dm => dm.id === dealMemoId);
    if (!dealMemo) return true;
    const date = moment.utc(this._detail.effectiveDate, 'YYYY-MM-DD');

    const dmStart = moment.utc(dealMemo.start);

    const dmEnd = moment.utc(dealMemo.end);

    if (date.isBefore(dmStart)) return true;

    if (date.isAfter(dmEnd)) return true;
  }
}

class TimecardAllowanceValidator extends TimecardValidatorBase {
  constructor({
    allowance,
    tableFields,
    project,
    weekStartingDate,
    weekEndingDate,
    episodes,
  }) {
    super(allowance, tableFields, episodes);
    this._project = project;
    this._weekStartingDate = weekStartingDate;
    this._weekEndingDate = weekEndingDate;
    this._variant = 'wtcAllowance';
  }

  validate() {
    this.validateDate();

    this.numberZeroOrGreater('hours');
    this.numberZeroOrGreater('rate');

    this.validateState();
    this.validateCCK();
    this.validateProjectFields();

    const isCanada = isRegionCanada(this._project.region);
    if (!isCanada) this.required('locationType');
    this.required('reason'); //'reason' is the columnId for 'Pay Type'
  }

  validateDate() {
    const dt = this._detail.date;

    if (!dt) {
      return;
    }

    const dtMoment = moment(dt, 'YYYY-MM-DD');

    if (
      dtMoment.isValid() &&
      this._weekStartingDate &&
      this._weekEndingDate &&
      (dtMoment < this._weekStartingDate || dtMoment > this._weekEndingDate)
    ) {
      this.addError('date', 'Date must be within the week ending date range');
    }
  }

  isComplete() {
    if (!this._detail.reason) {
      return false;
    }

    if (this._detail.grossAmount || this._detail.amount || this._detail.hours) {
      return true;
    }

    return false;
  }
}

class DTSTimecardValidator extends TimecardValidatorBase {
  constructor({ timecard, columns }) {
    const tableFields = {};
    columns.forEach(
      c =>
        (tableFields[c.id] = {
          columnId: c.id,
          label: c.Header,
          group: c.type,
          skipValidation: false,
        }),
    );
    const detail = timecard;
    super(detail, tableFields);
    this._variant = 'dts';
  }

  requireBatchTemplateId() {
    const value = this._detail['batchTemplateId'];

    if (!value) {
      this.addError(
        'batchTemplateId',
        `Some departments have not yet been assigned a batch template and cannot be saved. Please contact support.`,
      );
    }
  }
}

export const wtcTimecardValidate = (values, formProps) => {
  const errors = { valid: true, details: [], allowances: [], timecard: [] };
  //no need to run validation if timecard is not in ready state
  if (formProps.loadings || formProps.processing || formProps.wtcUpdating) {
    return errors;
  }

  // Expects errors.valid to be true at this point
  validateDealMemo({
    values,
    dealMemosOptions: formProps.dealMemos,
    dealMemoId: formProps.dealMemoId,
    timecard: formProps.timecard,
    errors,
  });

  if (values.details) {
    for (let i = 0; i < values.details.length; i++) {
      const detail = values.details[i];
      if (detail === undefined) continue;
      const nextDay = values.details[i + 1];
      const detailValidator = new TimecardWtcDetailValidator({
        detail,
        nextDay,
        tableFields: formProps.tableFields,
        tcDealMemo: values.dealMemo,
        dealMemos: formProps.dealMemos,
        project: formProps.project,
        weekStartingDate: values.weekStartingDate,
        weekEndingDate: values.weekEndingDate,
        episodes: formProps.episodes,
      });

      detailValidator.validate(formProps.episodes);

      errors.valid = errors.valid && !detailValidator.hasErrors();
      errors.details.push(detailValidator.getErrors());
    }
  }

  if (values.allowances) {
    for (let i = 0; i < values.allowances.length; i++) {
      const allowance = values.allowances[i];

      const allowanceValidator = new TimecardAllowanceValidator({
        allowance,
        tableFields: formProps.allowancesOrder,
        project: formProps.project,
        weekStartingDate: values.weekStartingDate,
        weekEndingDate: values.weekEndingDate,
        episodes: formProps.episodes,
      });

      allowanceValidator.validate(formProps.episodes);

      errors.valid = errors.valid && !allowanceValidator.hasErrors();
      errors.allowances.push(allowanceValidator.getErrors());
    }
  }

  return errors;
};

export const wtcTimecardWarn = (values, formProps) => {
  const warnings = {};

  const anyPartialDays = values?.details?.some(
    d => d.isPartialDealMemo === true,
  );

  if (anyPartialDays) {
    warnings.dealMemo =
      'Some day(s) on this timecard are not valid on the current deal memo. These days will be removed on save.';
  }

  return warnings;
};

/**
 * Validate DTS fields
 * @param {obj} timecard - tableRow of timecard
 * @param {array} columns - all columns for DTS
 * @param {bool} isSave - is this save validation? AKA do we need to highlight all required fields
 * @returns {obj} errors {columnId: 'error message string' }
 */
export const dtsTimecardValidate = ({ timecard, columns }) => {
  const dayValidator = new DTSTimecardValidator({ timecard, columns });

  dayValidator.validateTimeFields();

  dayValidator.required('dealMemo');

  dayValidator.requireBatchTemplateId();

  const errors = dayValidator.getErrors();
  const warnings = dayValidator.getWarnings();
  return { errors, warnings };
};
