import { buildUrl } from '.';
import { OPTIONS_URLS } from 'feature/DTS/dtsUtils';

const URL_DTS = '/projects/:projectId/dts/:dtsDate';
const URL_DTS_SEARCH = '/projects/:projectId/dts/:dtsDate/search';

const URL_DEPT_FILTER = '/projects/:projectId/dts/departments';
const URL_FILTERS =
  '/projects/:projectId/dts/filter/:dtsDate?filter=:filterName';

const URL_TEMPLATES = '/timecardTemplates';
const URL_USER_COLUMNS = '/userDefinedColumns';

const URL_ASSIGN_BATCHES = '/projects/:projectId/dts/:dtsDate/assignBatches';
const URL_ASSIGN_RATES = '/projects/:projectId/dts/:dtsDate/assignRates';

const DELETE_TIMECARDS = '/projects/:projectId/dts/timecards';

// handy sleep function for mock APIs
// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// use:   await sleep(300);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async getData({ projectId, dtsDate, filters }) {
    const url = buildUrl(URL_DTS_SEARCH, { projectId, dtsDate });
    const rsp = await clientV2.post(url, { filters });
    return rsp.data;
  },

  async save({ projectId, dtsDate, timecards }) {
    const url = buildUrl(URL_DTS, { projectId, dtsDate });
    const rsp = await clientV2.post(url, { timecards });
    return rsp;
  },

  async fetchTemplates() {
    const rsp = await clientV2.get(URL_TEMPLATES);
    return rsp.data;
  },
  async fetchTemplateColumns({ name }) {
    const url = URL_USER_COLUMNS + '/name/' + encodeURIComponent(name);
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async fetchDropdownOption({ columnId, parentValue, projectId }) {
    const URL = OPTIONS_URLS[columnId].url;
    const payload = {
      page: OPTIONS_URLS[columnId].page,
      pageSize: OPTIONS_URLS[columnId].pageSize,
    };
    if (parentValue) payload.parentValue = parentValue;
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.post(url, payload);
    return rsp.data;
  },
  async fetchSoloOption({ columnId, params }) {
    try {
      const URL = OPTIONS_URLS[columnId].url;
      const payload = {
        page: OPTIONS_URLS[columnId].page,
        pageSize: OPTIONS_URLS[columnId].pageSize,
      };
      if (params?.parentValue) payload.parentValue = params.parentValue;
      if (params?.options) payload.options = params.options;
      if (params?.search) payload.search = params.search;
      const rsp = await clientV2.post(URL, payload);
      return rsp.data;
    } catch (error) {
      console.error(`Invalid Query params for: ${columnId}`);
      return [];
    }

    // queryString += '&options=' + encodeURIComponent(JSON.stringify(options));
  },

  async fetchDeptFilter({ projectId }) {
    const url = buildUrl(URL_DEPT_FILTER, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async fetchFilters({ projectId, dtsDate, filterName }) {
    const url = buildUrl(URL_FILTERS, { projectId, dtsDate, filterName });
    const rsp = await clientV2.post(url, { type: filterName });
    return rsp.data;
  },
  async assignBatches({ projectId, dtsDate, timecards }) {
    const url = buildUrl(URL_ASSIGN_BATCHES, { projectId, dtsDate });
    const rsp = await clientV2.post(url, { timecards });
    return rsp.data;
  },

  async assignRates({ projectId, dtsDate, timecards }) {
    const url = buildUrl(URL_ASSIGN_RATES, { projectId, dtsDate });
    const rsp = await clientV2.post(url, { timecards });
    return rsp.data;
  },

  async deleteTimecards({ projectId, body }) {
    const url = buildUrl(DELETE_TIMECARDS, { projectId });
    const rsp = await clientV2.delete(url, { data: body });
    return rsp.data;
  },
});
