import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import DialogContent from '@mui/material/DialogContent';
// import Divider from '@mui/material/Divider';
import clsx from 'clsx';

export const style = ({ breakpoints }) => ({
  root: {
    marginTop: 10,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    minWidth: 400,
    [breakpoints.down('sm')]: {
      minWidth: 200,
    },
    fontFamily: 'inherit',
    overflow: 'visible',
  },
  shrinkTop: {
    marginTop: 4,
    paddingTop: 0,
  },
  [breakpoints.down('md')]: {
    root: {
      padding: '0 12px 0 12px',
    },
  },
});

class ModalContent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const { classes, children, shrinkTop } = this.props;

    return [
      <DialogContent
        key={`Dialog-content`}
        className={clsx(classes.root, {
          [classes.shrinkTop]: shrinkTop,
        })}
      >
        {children}
      </DialogContent>,
    ];
  }
}

export default withStyleSheet('ModalContent', style)(ModalContent);
