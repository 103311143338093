import React from 'react';
import { compose } from 'utils/helperFunctions';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// components
import NotificationsComp from 'components/Employees/Profiles/Preferences/Notifications';
import VerificationModal from 'containers/Employees/Profiles/Preferences/VerificationModal';

//actions
import { show as showModal } from 'actions/modalDialog';
import {
  fetchNotifications,
  fetchPhoneNumber,
  verifyPhoneNumber,
  editPreferences,
  updateNotifications,
  setUpdateStatus,
} from 'actions/profile';

//selectors
import {
  getNotificationsLoading,
  getPhoneNumberLoading,
  getAllNotifications,
  getAllPhoneNumbers,
  getNotificationSelectedFlag,
  getUpdateStatus,
  getPhoneNumberVerifiedStatus,
} from 'selectors/profileDetails';

//container
import DeletePhoneNumberModal from './DeletePhoneNumber';

const mapStateToProps = state => {
  const notifications = getAllNotifications(state);
  const phoneNumbers = getAllPhoneNumbers(state);
  const selectedAll = getNotificationSelectedFlag(state);
  const updateStatus = getUpdateStatus(state);
  const phoneNumberVerified = getPhoneNumberVerifiedStatus(state);
  return {
    loadNotification: getNotificationsLoading(state),
    loadPhoneNumber: getPhoneNumberLoading(state),
    initialValues: {
      ...phoneNumbers,
      notifications,
      selectedAll,
      updateStatus,
      phoneNumberVerified,
    },
    notifications,
    phoneNumbers,
    selectedAll,
    updateStatus,
    phoneNumberVerified,
  };
};

const mapDispatch = dispatch => ({
  onFetchData: () => {
    dispatch(fetchNotifications());
    dispatch(fetchPhoneNumber());
    dispatch(setUpdateStatus({ status: '' }));
  },
  onVerifyPhoneNumber: () => {
    dispatch(verifyPhoneNumber());
  },
  onSelectAll: (name, currentValue) => (_, value) => {
    dispatch(editPreferences({ name, currentValue, value }));
  },
  onSelectIndividual: (id, name, currentValue) => (_, value) => {
    dispatch(editPreferences({ id, name, currentValue, value }));
  },
  onDeletePhoneNumber: () => {
    dispatch(showModal({ dialog: 'deletePhoneNumber' }));
  },
});

const onSubmit = (values, dispatch) => {
  dispatch(updateNotifications());
};

class Notifications extends React.Component {
  componentDidMount() {
    const { onFetchData } = this.props;
    onFetchData();
  }
  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <NotificationsComp {...this.props} />
        </form>
        <VerificationModal />
        <DeletePhoneNumberModal />
      </div>
    );
  }
}
export default compose(
  connect(mapStateToProps, mapDispatch),
  reduxForm({
    form: 'Notifications',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    onSubmit,
  }),
)(Notifications);
