import produce from 'immer';
import * as actions from 'actions/location';

const initialState = {
  countriesV1: [],
  citiesV1: [],
  statesV1: [],
  countiesV1: [],
  subdivisionsV1: [],
  cacheStates: {},
  cacheCities: {},
  loading: {
    countries: false,
    cities: false,
    states: false,
    counties: false,
    subdivisions: false,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.storeCountriesV1}`:
        draft.countriesV1 = action.countries;
        break;
      case `${actions.storeCitiesV1}`:
        draft.citiesV1 = action.cities;
        break;
      case `${actions.storeStatesV1}`:
        draft.statesV1 = action.states;
        break;
      case `${actions.storeCountiesV1}`:
        draft.countiesV1 = action.counties;
        break;
      case `${actions.storeSubdivisionsV1}`:
        draft.subdivisionsV1 = action.subdivisions;
        break;

      case `${actions.storeCacheStates}`:
        draft.cacheStates[action.projectId] = action.states;
        break;
      case `${actions.storeCacheCities}`:
        draft.cacheCities[action.stateId] = action.cities;
        break;

      case `${actions.clearCache}`:
        draft.cacheCities = initialState.cacheCities;
        draft.cacheStates = initialState.cacheStates;
        break;
      case `${actions.setLoading}`:
        const variant = action.variant;
        draft.loading[variant] = action.loading;
        break;
      default:
    }
  });
