/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import clsx from 'clsx';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CallSplit from '@mui/icons-material/CallSplit';
import makeStyles from '@mui/styles/makeStyles';

//components
import Splits from './Splits';
import DealTextField from './DealTextField';
import TextCell from './WTCGridCells/TextCell';
import CheckboxCell from './WTCGridCells/CheckboxCell';
import AutocompleteCell from './WTCGridCells/AutocompleteCell';
//utils
import {
  formatDate,
  doesDayAllowTimes,
  getIsValidDealmemo,
} from 'utils/weekUtils';
import {
  dayHoursCalc,
  handleChangeWorkLocationsDay,
  fieldHasValue,
  onBlurNumber,
} from 'utils/wtcWeekUtils';
import TimeValidator from 'utils/TimeValidator';
// actions
import {
  toggleTableFields,
  populateUnusedDay,
  populateTimeFields,
} from 'actions/wtc';

//selectors
import { getCanEditWorkTimes, getIsDraft } from 'selectors/wtc';

export const DEL_BTN_PAD_SIZE = 34;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    overflow: 'visible',
  },
  tableCell: {
    verticalAlign: 'middle',
    padding: 4,
  },
  firstCell: {
    verticalAlign: 'top',
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 11,
  },
  timeCell: {
    borderRadius: 3,
    width: 45,
    height: 30,
    backgroundColor: `${palette.primary.main}10`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  limitCellWidth: {
    maxWidth: 100,
  },
  dealMemoCell: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    bottom: 4,
    '&:hover': {
      borderBottom: '2px solid black',
    },
  },
  dealMemoOuter: {
    position: 'relative',
  },
  leftBorder: {
    borderLeft: `solid 5px ${palette.background.paper} `,
  },
  timeLabel: {
    borderRadius: 3,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    color: palette.button.primary.disabled,
  },
  noPad: {
    padding: 0,
  },
  noBorder: {
    border: 0,
  },
  row: {
    height: 45,
  },
  oddRow: { backgroundColor: palette.primary.table },
  evenRow: { backgroundColor: palette.background.paper },
  btnCopy: {
    fontWeight: 700,
    fontSize: 10,
    padding: '2px 8px',
    '&:hover': {
      backgroundColor: palette.secondary.light,
      color: palette.common.white,
    },
  },
  iconCopy: {
    fontSize: 14,
    paddingRight: 2,
  },
  dayOfWeek: {
    fontSize: '0.75rem',
    color: palette.primary.main,
    width: 80,
  },
  dayDate: {
    padding: '0 15px 0 0',
  },
  commentSignal: {
    float: 'right',
    width: 10,
    height: 10,
    color: palette.primary.main,
    paddingTop: 5,
    WebkitTransform: 'scaleX(-1)',
    transform: 'scaleX(-1)',
  },
  stickyCell: {
    zIndex: 1,
    position: 'sticky',
  },
  input: {
    '& div': {
      width: 'auto',
      height: 'auto',
    },
  },
  inputProp: {
    width: 56,
    padding: '8px 4px',
    fontSize: 14,
  },
  inputPropAC: {
    width: 71,
    padding: '8px 4px',
    fontSize: 14,
  },
  inputError: {
    backgroundColor: palette.button.yellow.background,
    borderRadius: 5,
  },
  splitBorder: {
    border: `1px solid ${palette.primary.main}`,
  },
  splitClass: {
    color: palette.primary.main,
    backgroundColor: palette.background.default,
  },
  splitSelected: {
    backgroundColor: palette.primary.main,
    color: palette.background.default,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  disableCell: {
    backgroundColor: `${palette.gray['+8']} !important`,
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'default',
  },
  tooltipTitle: {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
}));

const mapState = state => ({
  canEditWorkTimes: getCanEditWorkTimes(state),
  isDraft: getIsDraft(state),
});

const mapDispatch = {
  onToggleTableFields: toggleTableFields,
  onPopulateUnusedDay: populateUnusedDay,
  onPopulateTimeFields: populateTimeFields,
};

function WeekDay(props) {
  const {
    even,
    dayIndex,
    member,
    workSchedules,
    loadWorkSchedules,
    dayTypes,
    loadDayTypes,
    occupationCodes,
    loadOccupationCodes,
    workLocations,
    loadWorkLocations,
    dealMemos,
    onShowMemoModal,
    episodes,
    loadEpisodes,
    tableFields,
    headerArray,
    paidHours = {},
    detail = {},
    splitHourTypes,
    visibleComment,
    blur,
    change,
    tcDates,
    dayError,
    // timecard,
    masterRowData,
    wtcDisabled,
    upmEnabled,
    settings,
    maskingFunc,
    accountCodeMask,
    scaleLoading,
    onFetchAndSetScaleRate,

    //
    countries,
    stateOptions,
    cityOptions,
    countyOptions,

    subdivisions,
    locationFuncs,
    locationFuncs: {
      onFetchCountries,
      onFetchStates,
      onFetchCities,
      onFetchCounties,
      onFetchSubdivisions,
      onResetLoc,
    },
    caRegion,

    //mapped Props
    canEditWorkTimes,
    isDraft,

    //dispatchActions
    onToggleTableFields,
    onPopulateUnusedDay,
    onPopulateTimeFields,
    project,
    details,
  } = props;

  const classes = useStyles();
  let rowSpan = 1;
  const [splits, toggleSplits] = useState(false);
  const splitPresentInit = _.isEmpty(detail.percentBreakdown)
    ? false
    : detail.percentBreakdown.length !== 0;

  const splitPresentForm = _.isEmpty(detail.percentBreakdown)
    ? false
    : detail.percentBreakdown.length !== 0;

  const splitPresent = splitPresentInit || splitPresentForm;

  const [isNoTimeDayType, setIsNoTimeDayType] = useState(false);

  const isUnusedDay = detail.unusedDay ? true : false;
  const dayType = detail.dayType;

  //check valid deal memo
  const isValidDealMemo = getIsValidDealmemo(dealMemos, details);

  // PartialDealMemo check
  // is this date valid on the current deal memo?

  const isPartialDealMemo = useMemo(() => {
    let dealMemo = _.find(dealMemos, dm => dm.id === detail?.dealMemo?.id);
    if (!dealMemo) return true;

    return (
      (dealMemo.start &&
        detail?.effectiveDate &&
        moment(detail.effectiveDate).isBefore(dealMemo.start)) ||
      (dealMemo.end &&
        detail?.effectiveDate &&
        moment(detail.effectiveDate).isAfter(dealMemo.end))
    );
  }, [detail?.dealMemo?.id, detail?.effectiveDate, dealMemos]);

  useEffect(() => {
    //clear day if valid dealmemo and patial dealmemo and day contains dealmemo
    //for invalid dealmemo no need to clear day
    if (isValidDealMemo && isPartialDealMemo && detail.dealMemo) {
      const partialDealMemoDay = {
        effectiveDate: detail.effectiveDate, // to check tc validation
        dealMemo: null,
        isPartialDealMemo: true,
      };
      change(member, partialDealMemoDay);
    }
  }, [isPartialDealMemo, change, member, detail, isValidDealMemo]);

  //if no hours worked on day details, calculate it from existing details
  let hoursWorked =
    detail.hoursWorked === undefined
      ? dayHoursCalc(detail).toFixed(2)
      : detail.hoursWorked;

  const currentState = !_.isEmpty(detail) && detail.workState;

  const showWorkSubdivision = useCallback(() => {
    onToggleTableFields({ columnId: 'workSubdivision', visible: true });
  }, [onToggleTableFields]);

  useEffect(() => {
    const specialOptions =
      (currentState && currentState.id && currentState?.specialOptions) || null;
    if (specialOptions && specialOptions?.includes('U')) {
      showWorkSubdivision();
    }
  }, [currentState, showWorkSubdivision]);

  useEffect(() => {
    if (
      detail &&
      detail.workSubdivision &&
      fieldHasValue(detail.workSubdivision)
    ) {
      showWorkSubdivision();
    }
  }, [detail, showWorkSubdivision]);

  const applyRounding = value => {
    const tv = new TimeValidator(detail?.dealMemo?.roundTo ?? 0.1);
    return tv.parse(value);
  };

  const timeFieldBlur = (e, newValue, prev, name) => {
    e.preventDefault();
    const rounded = applyRounding(newValue);
    const roundedVal = !!rounded || rounded === 0 ? rounded : '';
    blur(name, roundedVal);
  };

  const rateFieldBlur = (e, newValue, prev, name) => {
    e.preventDefault();
    const newVal = onBlurNumber(newValue);
    blur(name, newVal);
  };

  const loadStatesOnOpen = useCallback(() => {
    const countryId = detail?.workCountry?.id || '';
    onFetchStates({ countryId });
  }, [detail?.workCountry?.id, onFetchStates]);

  const loadCountiesOnOpen = useCallback(() => {
    const stateId = detail?.workState?.id || '';
    onFetchCounties({ stateId });
  }, [detail?.workState?.id, onFetchCounties]);

  const loadCitiesOnOpen = useCallback(() => {
    const stateId = detail?.workState?.id || '';
    if (stateId) onFetchCities({ stateId });
  }, [detail?.workState?.id, onFetchCities]);

  const loadSubdivisions = useCallback(
    arg => {
      if (typeof arg === 'string') {
        onFetchSubdivisions({ search: arg });
      } else {
        //onOpen calls with an event obj
        onFetchSubdivisions();
      }
    },
    [onFetchSubdivisions],
  );

  //
  // cascading change functions
  //
  const handleChangeWorkLocations = newValue => {
    handleChangeWorkLocationsDay(newValue, {
      detail,
      dealMemos,
      change,
      member,
      onFetchAndSetScaleRate,
    });
  };

  function handleChangeEpisode(newValue) {
    const newSeries = newValue?.series || '';
    const newLocation = newValue?.location || '';
    change(`${member}.series`, newSeries);
    change(`${member}.location`, newLocation);
  }

  function handleChangeCountry() {
    change(`${member}.workState`, null);
    change(`${member}.workCounty`, null);
    change(`${member}.workCity`, null);
  }

  function handleChangeState(newValue) {
    change(`${member}.workCounty`, null);
    change(`${member}.workCity`, null);

    if (
      newValue?.specialOptions?.includes('C') &&
      tableFields.workCounty &&
      !tableFields.workCounty.visible
    ) {
      onToggleTableFields({
        columnId: tableFields.workCounty.columnId,
        visible: true,
      });
    }
    if (
      newValue?.specialOptions &&
      !newValue.specialOptions.includes('U')
      // U is the special option that denotes if that state can use workSubdivision
    ) {
      change(`${member}.workSubdivision`, null);
    }
  }

  //handleChangeDayType
  const dayTypeChangedRef = React.useRef(false);
  useEffect(() => {
    dayTypeChangedRef.current = true;
  }, [dayType]);

  useEffect(() => {
    if (dayTypeChangedRef.current) {
      dayTypeChangedRef.current = false;
      const prevIsNotTimeDayType = isNoTimeDayType;

      const allowsTimes = doesDayAllowTimes(dayType?.code);
      setIsNoTimeDayType(!allowsTimes);

      if (canEditWorkTimes) {
        if (prevIsNotTimeDayType && allowsTimes) {
          onPopulateTimeFields({ detail, member });
        }

        if (isUnusedDay && dayType) {
          if (caRegion) {
            if (!detail?.locationType) {
              detail.locationType =
                workLocations?.find(d => d.code === 'S') || null;
            }
          }
          onPopulateUnusedDay({
            detail,
            isNoTimeDayType: !allowsTimes,
            member,
          });
        } else if (dayType === null) {
          change(`${member}.unusedDay`, true);
        }
      }
    }
  }, [
    canEditWorkTimes,
    change,
    dayType,
    detail,
    isNoTimeDayType,
    isUnusedDay,
    member,
    onPopulateTimeFields,
    onPopulateUnusedDay,
    caRegion,
    workLocations,
  ]);

  useEffect(() => {
    // close splits on edit times toggle
    // splits open/closed is stored in local state, not redux so need to reset
    // when we're changing the length of details array
    toggleSplits(false);
  }, [canEditWorkTimes]);

  const loadWithDealMemo = callback => {
    // parentValue need to be dbCode ID
    const dbCodeId = _.get(masterRowData, 'project.dbCode.id', '');
    const htgContractId = _.get(detail, 'dealMemo.contract.id', '');
    const pensionUnionId = _.get(detail, 'dealMemo.pensionUnion.id', '');

    return searchText => {
      const params = {
        search: searchText,
        htgContractId,
        pensionUnionId,
        dbCodeId,
      };
      return callback(params);
    };
  };

  const launchDealMemoModal = e => {
    const momentDate = moment(detail.effectiveDate);
    const formattedDate = momentDate.format('YYYY-MM-DDT00:00:00');

    // e.stopPropagation();
    onShowMemoModal({
      source: 'day',
      startDate: formattedDate,
      endDate: formattedDate,
      currentDeal: detail.dealMemo.id,
    });
  };

  // Generic onChange for autocomplete and checkbox fields
  function weekDayOnChange(e, newValue, prev, name) {
    const newValId = newValue?.id || newValue;
    const prevId = prev?.id || prev;
    if (prevId === newValId) return;

    // eslint-disable-next-line no-unused-vars
    const [memberName, fieldName] = name.split('.');

    switch (fieldName) {
      //Cascading changes
      case 'locationType':
        handleChangeWorkLocations(e, newValue, prev, name);
        return;
      case 'episode':
        handleChangeEpisode(e, newValue, prev, name);
        return;
      case 'workCountry':
        handleChangeCountry(e, newValue, prev, name);
        return;
      case 'workState':
        handleChangeState(e, newValue, prev, name);
        return;

      default:
        break;
    }
  }

  const dropdownProps = {
    workLocations,

    dayTypes,
    loadDayTypes,

    episodes,
    loadEpisodes,
    //locations
    onResetLoc,

    countries,
    onFetchCountries,

    stateOptions,
    loadStatesOnOpen,

    countyOptions,
    loadCountiesOnOpen,

    cityOptions,
    loadCitiesOnOpen,

    subdivisions,
    loadSubdivisions,
    //meta-codes

    occupationCodes,
    loadOccWithDeal: loadWithDealMemo(loadOccupationCodes),

    workSchedules,
    loadWorkSchedules,

    onChange: weekDayOnChange,
  };

  const renderTableCell = field => {
    const cellProps = {
      key: field.columnId,
      field,
      member,
      wtcDisabled,
      caRegion,
      isUnusedDay,
      isPartialDealMemo: isValidDealMemo && isPartialDealMemo,
      disableCellClass: classes.disableCell,
    };
    switch (field.type) {
      case 'buttons':
        if (field.columnId === 'splitButton') {
          return (
            <TableCell
              key={field?.columnId}
              className={clsx(
                classes.tableCell,
                classes.stickyCell,
                oddOrEvenRowClass,
                {
                  [classes.disableCell]:
                    (isValidDealMemo && isPartialDealMemo) || isUnusedDay,
                },
              )}
              sx={{ left: delBtnPad + 0 }}
              align="center"
            >
              <IconButton
                aria-label="Show Splits"
                size="small"
                className={clsx(
                  'PENDO_wtc_splitButton',
                  classes.splitClass,
                  {
                    [classes.splitBorder]: splitPresent,
                  },
                  {
                    [classes.splitSelected]: splits,
                  },
                )}
                onClick={() => toggleSplits(!splits)}
              >
                <CallSplit />
              </IconButton>
            </TableCell>
          );
        }
        return (
          <TableCell
            key={field?.columnId}
            className={clsx(classes.tableCell, {
              [classes.disableCell]:
                (isValidDealMemo && isPartialDealMemo) || isUnusedDay,
            })}
            sx={{ left: delBtnPad + 42 }}
            rowSpan={rowSpan}
          >
            <Field
              component="input"
              name={`${member}.id`}
              type="hidden"
              value={detail.id}
            />
            <Typography className={classes.dayOfWeek}>
              {formatDate(detail.effectiveDate, 'dddd')}
              {visibleComment && (
                <ChatBubbleIcon className={classes.commentSignal} />
              )}
            </Typography>
            <Tooltip
              className={classes.tooltipTitle}
              title={tooltipTitle}
              placement="bottom-start"
            >
              <Typography className={classes.dayDate} color="inherit">
                {formatDate(detail.effectiveDate, 'DD MMM').toUpperCase()}
              </Typography>
            </Tooltip>
          </TableCell>
        );
      case 'text':
        if (field.columnId === 'hoursWorked') {
          return (
            <TableCell
              key={field?.columnId}
              className={clsx(classes.tableCell, {
                [classes.disableCell]:
                  (isValidDealMemo && isPartialDealMemo) || isUnusedDay,
              })}
              style={
                !tableFields.hoursWorked.visible ? { display: 'none' } : null
              }
            >
              <div className={classes.timeCell}>
                <Typography className={classes.timeLabel}>
                  {hoursWorked}
                </Typography>
              </div>
            </TableCell>
          );
        }
        return (
          <TextCell
            {...cellProps}
            timeFieldBlur={timeFieldBlur}
            scaleLoading={scaleLoading}
            rateFieldBlur={rateFieldBlur}
            accountCodeMask={accountCodeMask}
            maskingFunc={maskingFunc}
            upmEnabled={upmEnabled}
            canEditWorkTimes={canEditWorkTimes}
            isDraft={isDraft}
            isNoTimeDayType={isNoTimeDayType}
            wtcEditable={settings?.wtcEditable}
            project={project}
          />
        );
      case 'checkbox':
        return <CheckboxCell {...cellProps} />;
      case 'auto-complete':
        if (field.columnId === 'dealMemo') {
          return (
            <TableCell
              key={field?.columnId}
              className={clsx(classes.tableCell, {
                [classes.disableCell]:
                  (isValidDealMemo && isPartialDealMemo) || isUnusedDay,
              })}
              style={!tableFields.dealMemo.visible ? { display: 'none' } : null}
            >
              <div className={classes.dealMemoOuter}>
                <div
                  className={classes.dealMemoCell}
                  onClick={launchDealMemoModal}
                />

                <Field
                  component={DealTextField}
                  name={`${member}.dealMemo`}
                  disabled={
                    wtcDisabled ||
                    isUnusedDay ||
                    (isValidDealMemo && isPartialDealMemo)
                  }
                  launchDealMemoModal={launchDealMemoModal}
                />
              </div>
            </TableCell>
          );
        }
        return (
          <AutocompleteCell
            {...cellProps}
            {...dropdownProps}
            upmEnabled={upmEnabled}
          />
        );
      case 'read-only':
        const label = field.columnId;
        const data =
          paidHours?.tableData?.length &&
          paidHours.tableData[dayIndex] &&
          paidHours.tableData[dayIndex].hasOwnProperty(label)
            ? paidHours.tableData[dayIndex][label]
            : null;
        return (
          <TableCell
            className={clsx(classes.tableCell, {
              [classes.leftBorder]: label === '1x',
              [classes.disableCell]: isPartialDealMemo || isUnusedDay,
            })}
            key={`paidHourCell-${label}`}
          >
            <div className={classes.timeCell}>
              <Typography className={classes.timeLabel}>{data}</Typography>
            </div>
          </TableCell>
        );
      default:
        //placeholder - shouldn't be hit
        console.warn('Unexpected field:', field?.columnId);
        return <TableCell key={field?.columnId}></TableCell>;
    }
  };

  const oddOrEvenRowClass = even ? classes.oddRow : classes.evenRow;
  const tooltipTitle = isPartialDealMemo
    ? 'This deal memo is not valid for this date'
    : '';

  const delBtnPad = canEditWorkTimes ? 34 : 0;

  return (
    <React.Fragment>
      <TableRow
        className={clsx(
          'weekday',
          classes.root,
          classes.row,
          oddOrEvenRowClass,
        )}
        key={`${detail.date}-1`}
      >
        {canEditWorkTimes && (
          <TableCell
            className={clsx(classes.stickyCell, oddOrEvenRowClass)}
            sx={{ padding: 0, left: 0 }}
          >
            {!isPartialDealMemo && (
              <Tooltip placement="left" title="Remove Day">
                <IconButton
                  sx={{ padding: '5px' }}
                  onClick={() => {
                    change(`${member}.dayType`, null);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )}
        {headerArray.filter(header => header.visible).map(renderTableCell)}
      </TableRow>
      {splits && (
        <Splits
          member={member}
          tableFields={tableFields}
          paidHours={paidHours}
          workLocations={workLocations}
          loadWorkLocations={loadWorkLocations}
          change={change}
          headerArray={headerArray}
          splitHourTypes={splitHourTypes}
          detail={detail}
          tcDates={tcDates}
          dayError={dayError}
          episodes={episodes}
          loadEpisodes={loadEpisodes}
          masterRowData={masterRowData}
          wtcDisabled={wtcDisabled}
          maskingFunc={maskingFunc}
          accountCodeMask={accountCodeMask}
          canEditWorkTimes={canEditWorkTimes}
          //
          countries={countries}
          stateOptions={stateOptions}
          countyOptions={countyOptions}
          cityOptions={cityOptions}
          locationFuncs={locationFuncs}
          project={project}
        />
      )}
    </React.Fragment>
  );
}

export default connect(mapState, mapDispatch)(WeekDay);
