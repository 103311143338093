import React, { useRef, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { connect, batch } from 'react-redux';
import moment from 'moment';
import { push } from 'connected-react-router';

import { compose } from 'utils/helperFunctions';
import {
  LinearProgress,
  Tooltip,
  CircularProgress,
  IconButton,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

// actions
import { setInitBatch, clearFilter, setInitLoad } from 'actions/bulkEdit';
import {
  wtcDownloadTimecardsReport,
  drawerInit,
  reviewUPMTimecards,
} from 'actions/wtc';
import { setBatchLockStatus } from 'actions/reviews';
import { show as showModal } from 'actions/modalDialog';

// selector
import {
  getTextFilter,
  getTitleHoverContent,
  getFilterSortedTimecards,
  getSortBy,
  currentUserLevel,
  getLoadingDrawerTimecards,
  getFilteredCount,
  getDownloadingReport,
  getDownloadIconTooltip,
  getCurrentTimecardHeaderId,
  getMoveTooltip,
  getIsMoveDisabled,
  getBulkEditTooltip,
  getBatchInfo,
} from 'selectors/wtc';
import { isProcessingApproval } from 'selectors/reviews';
import { getIsProjectArchived } from 'selectors/project';
import { getProject as getProjectId } from 'selectors/routeParams';

//components
import ConfirmApproveReject from './Drawer/ConfirmApproveReject';
import ApproveRejectButtons from './Drawer/ApproveRejectButtons';
import LockStatusIcon from './Drawer/LockStatusIcon';
import SearchFilterSort from './FilterSortComps/SearchFilterSort';
import TimecardList from './Drawer/TimecardList';
import MoveTimecardsModal from '../Modal/MoveTimecardsModal';
import WarningModal from './Drawer/Modals/WarningModal';

//Icons
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ViewListIcon from '@mui/icons-material/ViewList';

//utils
import { useDidMount } from 'utils/customHooks';
import { checkTCSelection } from 'containers/Employees/Reviews/Shared/timecardUtils';

const style = theme => ({
  root: {
    display: 'flex',
    transition: 'all 0.3s ease-in-out',
    borderRight: `1px ${theme.palette.gray['+8']} solid`,
    width: 285,
  },
  widdyCollapsed: {
    width: 40,
  },
  drawer: {
    width: 285,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  tabsCollapsed: {
    display: 'flex',
    flexDirection: 'column',
    width: 40,
  },
  collipso: {
    height: 48,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `solid 1px ${theme.palette.gray['+8']}`,
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 15,
  },
  batch: {
    height: 58,
    marginTop: 8,
    marginLeft: 8,
    position: 'relative',
    color: theme.palette.primary.main,
    display: 'flex',
    '& > span': {
      top: 0,
      position: 'relative',
    },
  },
  filter: {},

  footer: {
    position: 'fixed',
    bottom: 0,
    width: 250,
    backgroundColor: theme.palette.background.default,
    padding: '0px 16px',
  },
  footerHeader: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  footerContent: {
    padding: '24px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  icon: {
    height: 24,
    width: 24,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    cursor: 'pointer',
  },

  filerIcons: {
    display: 'flex',
  },
  collapseIcon: {
    paddingLeft: 7,
  },
});

const mapStateToProps = state => ({
  projectId: getProjectId(state),
  timecards: getFilterSortedTimecards(state),
  textFilter: getTextFilter(state),
  filterCount: getFilteredCount(state),
  downloading: getDownloadingReport(state),

  isProjectArchived: getIsProjectArchived(state),
  titleHoverContent: getTitleHoverContent(state),
  processingBulkApproval: isProcessingApproval(state),
  sortBy: getSortBy(state),
  batch: getBatchInfo(state),
  currentTimecard: getCurrentTimecardHeaderId(state),

  userLevel: currentUserLevel(state),
  loadingDrawerTimecards: getLoadingDrawerTimecards(state),

  isMoveDisabled: getIsMoveDisabled(state),
  bulkEditTooltip: getBulkEditTooltip(state),
  moveTooltip: getMoveTooltip(state),
  downloadTooltip: getDownloadIconTooltip(state),
});

const mapDispatchToProps = dispatch => ({
  onInit: () => {
    dispatch(drawerInit());
  },
  onApproveBatch: selected => {
    const status = 'approved';
    dispatch(
      reviewUPMTimecards({
        comment: '',
        status,
        timecardEntryHeaderIds: selected,
      }),
    );
  },
  onRejectBatch: (selected, note) => {
    const status = 'rejected';
    const comment = note;
    dispatch(
      reviewUPMTimecards({
        comment,
        status,
        timecardEntryHeaderIds: selected,
      }),
    );
  },

  onMoveTimecard: sourceBatch => {
    dispatch(
      showModal({
        dialog: 'moveTimecardsToBatch',
        modalParams: { sourceBatch },
      }),
    );
  },
  onDownloadTimecardsReport: ({ timecards }) => {
    dispatch(wtcDownloadTimecardsReport({ timecards }));
  },
  onSetBatchLockStatus: (hoursBatchId, reviewBatchLockStatus) => {
    dispatch(setBatchLockStatus({ hoursBatchId, reviewBatchLockStatus }));
  },
  onBulkEditBatch: ({ projectId, batchWorksight, weekEnding }) => {
    batch(() => {
      dispatch(clearFilter({ filterName: 'departments' }));
      dispatch(clearFilter({ filterName: 'status' }));
      dispatch(clearFilter({ filterName: 'weekDay' }));
      dispatch(clearFilter({ filterName: 'batches' }));
      dispatch(clearFilter({ filterName: 'employees' }));
      dispatch(clearFilter({ filterName: 'accountCodes' }));
      dispatch(clearFilter({ filterName: 'episodes' }));
      dispatch(clearFilter({ filterName: 'sets' }));
    });
    batch(() => {
      dispatch(setInitLoad());
      dispatch(
        setInitBatch({
          batchId: batchWorksight,
          weekEnding: moment(weekEnding).format('YYYY-MM-DD'),
        }),
      );
    });
    const toURL = `/projects/${projectId}/review/bulk-edit`;
    dispatch(push(toURL));
  },
});

function Drawer(props) {
  const {
    classes,
    timecards = [],
    loadTimecard,
    processingBulkApproval,
    currentTimecard,
    downloading,
    sortBy: { sortOrder },
    wtcUpdating: wtcUpdatingUnBounced, //disable navigation if wtc is in updating state
    onSetBatchLockStatus,
    onMoveTimecard,
    batch,
    projectId,
    onBulkEditBatch,
    isPA,
    isUPM,
    onInit,
    userLevel,
    onApproveBatch,
    onRejectBatch,
    onDownloadTimecardsReport,
    pristine,
    isProjectArchived,
    titleHoverContent = '',
    bulkEditTooltip,
    moveTooltip,
    downloadTooltip,
    filterCount,
    isMultiBatch,
    isMoveDisabled,
    loadingDrawerTimecards,
  } = props;

  const [collapsed, setCollapsed] = useState(false);
  const [enableUPMBulkBtns, setEnableUPMBulkBtns] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [selectedTCS, setSelectedTCS] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const toggleDrawer = () => setCollapsed(!collapsed);
  const [wtcUpdating, setWtcUpdating] = useState(false);
  const [lockStatus, setLockStatus] = useState(batch?.lockStatus);

  const enableBulkApproveReject = enableUPMBulkBtns && !bulkAction;
  const batchTitle = isMultiBatch
    ? 'Selected Timecards'
    : batch
    ? `${batch.htgBatchNumber} - ${batch.name}`
    : '';
  const isBulkDisabled = !batch;

  useDidMount(() => {
    onInit();
  });

  const resizeDrawer = useCallback(() => {
    const boundingRec = wtcActionsRef.current?.getBoundingClientRect();
    const bodyHeight = document.body.clientHeight;
    const viewPortHeight = window.innerHeight;
    const maxScroll = bodyHeight - viewPortHeight;
    if (boundingRec) {
      const footerHeight = boundingRec.height;

      const drawerHeight = `calc(100% - ${
        footerHeight + (maxScroll - window.scrollY)
      }px)`;
      drawerRef.current.style.height = drawerHeight;
    }
  }, []);

  //Handle dynamic sizing of the footer / actions section
  const drawerRef = useRef();
  const wtcActionsRef = useRef();
  useEffect(() => {
    resizeDrawer();
    window.addEventListener('scroll', resizeDrawer);
    return () => {
      window.removeEventListener('scroll', resizeDrawer);
    };
  }, [resizeDrawer]);

  useEffect(() => {
    setLockStatus(batch?.lockStatus);
  }, [batch?.lockStatus]);

  // handle enable/disable flashing when wtcUpdating switches from true/false
  // during timecard loading
  const updatingRef = React.useRef(null);
  useEffect(() => {
    if (wtcUpdatingUnBounced) {
      clearTimeout(updatingRef.current);
      updatingRef.current = null;
      setWtcUpdating(true);
    } else if (wtcUpdating) {
      updatingRef.current = setTimeout(() => {
        setWtcUpdating(false);
        updatingRef.current = null;
      }, 350);
    }
  }, [setWtcUpdating, wtcUpdating, wtcUpdatingUnBounced]);

  useEffect(() => {
    if (
      !bulkAction &&
      timecards.some(tc =>
        checkTCSelection(tc, userLevel, bulkAction, isUPM, isPA),
      )
    ) {
      setEnableUPMBulkBtns(true);
    } else {
      setEnableUPMBulkBtns(false);
    }
  }, [timecards, userLevel, bulkAction, isUPM, isPA]);

  const handleBulkAction = useCallback(
    (selected, bulkAction, note) => {
      if (bulkAction === 'approve') {
        onApproveBatch(selected);
      } else if (bulkAction === 'reject') {
        onRejectBatch(selected, note);
      }
    },
    [onApproveBatch, onRejectBatch],
  );

  function setBatchLockStatus(hoursBatchId, batchLockStatus) {
    if (batchLockStatus === 'unlock') {
      setLockStatus('softlock');
      onSetBatchLockStatus(hoursBatchId, 'softlock');
    } else if (batchLockStatus === 'softlock') {
      setLockStatus('unlock');
      onSetBatchLockStatus(hoursBatchId, 'unlock');
    }

    if (batchLockStatus === 'hardlock') return;
  }

  //handle selecting the bulk action, either 'approve' or 'reject'
  const showCheckboxesHandler = str => {
    if (pristine) {
      setBulkAction(str);
      setEnableUPMBulkBtns(false);
      setSelectedTCS([]);
    } else {
      setOpenDialogue(true);
    }
  };

  const resetBulkAction = () => {
    setBulkAction('');
    setSelectedTCS([]);
  };

  useEffect(resetBulkAction, [pristine]);

  const onDownloadTimecards = React.useCallback(() => {
    const list = timecards.map(tc => tc.timecardEntryHeaderId);
    onDownloadTimecardsReport({
      timecards: list,
    });
  }, [timecards, onDownloadTimecardsReport]);

  const buttonsAlignment = isPA ? { display: 'block' } : {};

  const pendoClass = `PENDO_wtc_bulk_approval_${isPA ? 'PA' : 'UPM'}`;
  return (
    <div
      className={clsx(classes.root, {
        [classes.widdyCollapsed]: collapsed,
      })}
    >
      {!collapsed ? (
        <div ref={drawerRef} className={classes.drawer}>
          <div className={classes.collipso} onClick={toggleDrawer}>
            <ViewListIcon />
            <KeyboardDoubleArrowLeftIcon color="primary" />
          </div>
          <div className={classes.batch}>
            <Tooltip arrow placement="top" title={titleHoverContent}>
              <div>{batchTitle}</div>
            </Tooltip>
            {isPA && !isMultiBatch && (
              <span
                className="PENDO_wtcToggleBatchLock"
                onClick={e => {
                  e.stopPropagation();
                  setBatchLockStatus(batch.id, lockStatus);
                }}
              >
                <LockStatusIcon lockStatus={lockStatus} />
              </span>
            )}
          </div>
          <SearchFilterSort resetBulkAction={resetBulkAction} />

          {(loadingDrawerTimecards || processingBulkApproval) && (
            <LinearProgress />
          )}

          <TimecardList
            timecards={timecards}
            currentTimecard={currentTimecard}
            selectedTCS={selectedTCS}
            setSelectedTCS={setSelectedTCS}
            loadTimecard={loadTimecard}
            wtcUpdating={wtcUpdating}
            userLevel={userLevel}
            isPA={isPA}
            isUPM={isUPM}
            bulkAction={bulkAction}
            filterCount={filterCount}
          />

          <footer
            ref={wtcActionsRef}
            className={clsx(classes.footer, pendoClass)}
          >
            {filterCount.total !== filterCount.showing && (
              <span
                className={classes.footerHeader}
              >{`Showing ${filterCount.showing} of ${filterCount.total}`}</span>
            )}
            <section className={classes.footerContent} style={buttonsAlignment}>
              {!!bulkAction ? (
                <ConfirmApproveReject
                  bulkAction={bulkAction}
                  resetBulkAction={resetBulkAction}
                  handleBulkAction={handleBulkAction}
                  selectedTCS={selectedTCS}
                />
              ) : (
                <ApproveRejectButtons
                  enableBulkApproveReject={enableBulkApproveReject}
                  processingBulkApproval={processingBulkApproval}
                  showCheckboxesHandler={showCheckboxesHandler}
                  isPA={isPA}
                  timecards={timecards}
                  pristine={pristine}
                />
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                {isPA && !isProjectArchived && (
                  <Tooltip arrow title={bulkEditTooltip}>
                    <span>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          onBulkEditBatch({
                            projectId,
                            batchWorksight: batch.worksightId,
                            weekEnding: batch.endsOn,
                          });
                        }}
                        key={'bulk-edit'}
                        disabled={isBulkDisabled}
                      >
                        <EditIcon
                          color={isBulkDisabled ? '' : 'primary'}
                          alt="bulkEdit"
                          className={classes.icon}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {isPA && (
                  <Tooltip arrow title={moveTooltip}>
                    <span>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          onMoveTimecard(batch);
                        }}
                        key={'moveBatch'}
                        disabled={isMoveDisabled}
                      >
                        <DriveFileMoveOutlinedIcon
                          color={isMoveDisabled ? '' : 'primary'}
                          alt="move"
                          className={classes.icon}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {(isPA || (isUPM && !bulkAction)) && (
                  <Tooltip arrow title={downloadTooltip}>
                    <div>
                      {downloading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            !downloading && onDownloadTimecards();
                          }}
                          key={'downloadBatch'}
                        >
                          <DownloadIcon
                            color={'primary'}
                            alt="download"
                            className={classes.icon}
                          />
                        </IconButton>
                      )}
                    </div>
                  </Tooltip>
                )}
              </Box>
            </section>
          </footer>
          <MoveTimecardsModal sortOrder={sortOrder} />
        </div>
      ) : (
        <div className={classes.tabsCollapsed} onClick={toggleDrawer}>
          <div className={classes.collipso} onClick={toggleDrawer}>
            <ViewListIcon color="primary" />
          </div>
        </div>
      )}
      <WarningModal
        open={openDialogue}
        title={'Unsaved Changes'}
        content={'Please save the changes before proceeding.'}
        setOpenDialogue={setOpenDialogue}
      />
    </div>
  );
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style),
)(Drawer);
