import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import FieldCheckboxDropDown from 'components/Shared/FieldCheckboxDropDown';
// selector
import { getAllowancesTableOrder, getTableFieldOrder } from 'selectors/wtc';
// actions
import { toggleAllowanceField, toggleTableFields } from 'actions/wtc';

const mapState = state => ({
  allowancesOrder: getAllowancesTableOrder(state),
  tableFieldOrder: getTableFieldOrder(state),
});

const mapDispatch = dispatch => ({
  onToggleTableFields: (columnId, visible) => {
    dispatch(toggleTableFields({ columnId, visible }));
  },
  onToggleAllowanceField: (columnId, visible) => {
    dispatch(toggleAllowanceField({ columnId, visible }));
  },
});

const AdditionalFieldsContainer = props => {
  const {
    allowancesOrder,
    tableFieldOrder,
    onToggleTableFields,
    onToggleAllowanceField,
  } = props;

  const onClickField = field => {
    if (field.sharedField === true) {
      //shared field, make sure they're in sync before blindly toggling
      const columnId = field.columnId;
      const tableVis = tableFieldOrder[columnId].visible;
      const allowanceVis = allowancesOrder[columnId].visible;

      if (tableVis !== allowanceVis) {
        onToggleAllowanceField(field.columnId, true);
        onToggleTableFields(field.columnId, true);
      } else {
        onToggleAllowanceField(field.columnId);
        onToggleTableFields(field.columnId);
      }
    } else {
      onToggleTableFields(field.columnId);
    }
  };

  //Create sub-field obj for additional fields container
  const additionalSubFields = React.useMemo(() => {
    const obj = {};
    for (const field in tableFieldOrder) {
      if (Object.hasOwnProperty.call(tableFieldOrder, field)) {
        const item = tableFieldOrder[field];
        if (item.additionalField) {
          obj[field] = item;
        }
      }
    }
    return obj;

    // Don't need to refresh this as it just controls which fields are shown
    // which ones are visible or not are covered in the table fields obj
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showingFields, setShowFields] = useState(0);
  const additionalFieldsRef = useRef(_.cloneDeep(additionalSubFields));

  useEffect(() => {
    let fieldsCount = 0;
    let additionalFields = additionalFieldsRef.current;

    //Ensure table/allowance/additional fields all line up

    for (let field in additionalFields) {
      if (field in tableFieldOrder && field in allowancesOrder) {
        additionalFields[field].sharedField = true;
      }

      if (tableFieldOrder[field]?.visible || allowancesOrder[field]?.visible) {
        additionalFields[field].visible = true;

        const columnId = additionalFields[field].columnId;
        onToggleAllowanceField(columnId, true);
        onToggleTableFields(columnId, true);
        fieldsCount++;
      } else {
        additionalFields[field].visible = false;
      }
    }
    setShowFields(fieldsCount);
    return () => {};
  }, [
    allowancesOrder,
    tableFieldOrder,
    onToggleAllowanceField,
    onToggleTableFields,
  ]);

  return (
    <FieldCheckboxDropDown
      fieldsList={additionalFieldsRef.current}
      selectedCount={showingFields}
      onClickField={onClickField}
      label="Additional Fields"
      pendoClass="wtc"
    />
  );
};

export default connect(mapState, mapDispatch)(AdditionalFieldsContainer);
