import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

//actions
import {
  fetchReviewBatches,
  reviewTimecard,
  setCurrentApprovalFlow,
  reviewBatch,
  setSelectedFlows,
  fetchComments,
  storeBatchLoadingByID,
  fetchApprovalFlows,
  reviewDownloadTimecardsReport,
} from 'actions/reviews';
import {
  fetchWeekendings,
  setFromURL,
  setCurrentTimecard,
} from 'actions/timecards';
import { show as showModal } from 'actions/modalDialog';

//selector
import {
  reviewBatchesByReviewType,
  getLoading,
  getTimecardLoadingByBatchID,
} from 'selectors/reviews';
import { getWeekendings } from 'selectors/timecards';
import { currentUser } from 'selectors/session';
import { getProjectDetails } from 'selectors/project';

// components
import { Listing } from 'components/Employees/Reviews/Reviewer';
import RejectApprovalFlow from './RejectApprovalFlow';
import AddCommentModal from './Modals/AddComment';

import { BATCH_REVIEW_DEFAULT_PAGE_SIZE } from 'components/Shared/constants';
const pageSize = BATCH_REVIEW_DEFAULT_PAGE_SIZE;

const reviewType = 'ready';

const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  batches: reviewBatchesByReviewType(reviewType)(state),
  activeUser: currentUser(state),
  weekendings: getWeekendings(state),
  isLoading: getLoading(state),
  project: getProjectDetails(state),
  reviewType,
  timecardLoadingByBatch: getTimecardLoadingByBatchID(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onFetchData: () => {
    dispatch(setFromURL({ fromURI: location.pathname }));

    dispatch(
      fetchReviewBatches({
        reviewType,
        page: 1,
        size: pageSize,
      }),
    );
    dispatch(fetchWeekendings());
  },
  onApproveBatch: (_batch, selected) => {
    dispatch(
      reviewBatch({
        comment: '',
        status: 'approved',
        timecardEntryHeaderIds: selected,
      }),
    );
    dispatch(
      storeBatchLoadingByID({
        batchId: _batch.id,
        loading: true,
      }),
    );
  },
  onRejectBatch: (_batch, selected, submitRejected) => {
    dispatch(setSelectedFlows({ selectedApprovalFlows: selected }));
    dispatch(showModal({ dialog: 'RejectApprovalFlow', maxWidth: 'md' }));
  },
  onViewApprovalFlow: (batch, record) => {
    dispatch(setFromURL({ fromURI: location.pathname }));
    dispatch(setCurrentApprovalFlow({ approvalFlow: record }));
    dispatch(
      reviewTimecard({
        batchId: batch.id,
        approvalFlowId: record.id,
        role: record.role,
      }),
    );
  },
  onViewTimecard: (batch, record) => {
    dispatch(setFromURL({ fromURI: location.pathname }));
    dispatch(setCurrentTimecard({ timecardId: record.timecardId }));
    dispatch(reviewTimecard({ timecardId: record.timecardId, reviewType }));
  },
  refreshData: (page, size) => {
    dispatch(fetchReviewBatches({ reviewType, page, size }));
  },
  onDownloadTimecardsReport: ({ timecards, batchId, htgId }) => {
    dispatch(
      reviewDownloadTimecardsReport({ timecards, batchId, htgId, reviewType }),
    );
  },
  displayComment: record => {
    dispatch(fetchComments({ timecardEntryHeaderId: record.entryHeaderId }));
    dispatch(setCurrentTimecard({ timecardId: record.entryHeaderId }));
    dispatch(showModal({ dialog: 'AddComment' }));
  },
  onFetchBatchTimecards: batchId => {
    const reviewBatches = [{ id: batchId }];
    dispatch(fetchApprovalFlows({ reviewType, reviewBatches }));
  },
});

// DH - Ready for Review
class Ready extends Component {
  state = {
    page: 0,
    pageSize: pageSize,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  onRefreshPage(page, pageSize) {
    this.setState({
      page,
      pageSize,
    });

    this.props.refreshData(page, pageSize);
  }

  render() {
    const { activeUser } = this.props;
    return (
      <React.Fragment>
        {this.props.isLoading && <LinearProgress />}
        <Listing
          {...this.props}
          role={activeUser.role}
          page={this.state.page}
          pageSize={this.state.pageSize}
          onRefreshPage={this.onRefreshPage.bind(this)}
        />
        <RejectApprovalFlow />
        <AddCommentModal />
      </React.Fragment>
    );
  }
}
export default withRouter(connect(mapState, mapDispatch)(Ready));
