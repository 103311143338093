import _ from 'lodash';
import { makeDropDownDisplay } from 'feature/DTS/dtsUtils';

export const handleSortBy = (column, setSortBy, defaultSort) => {
  //set sort desc, asc or none?
  let newSort;
  switch (column.isSortedDesc) {
    case true:
      newSort = [...defaultSort];
      break;
    case false:
      newSort = [{ id: column.id, desc: true }, ...defaultSort];
      break;

    default:
      newSort = [{ id: column.id, desc: false }, ...defaultSort];
      break;
  }

  setSortBy(newSort);
};

export const handleSort = ({ data, sortBy, tableColumns }) => {
  const sorted = _.cloneDeep(data); //slow copy, could use something better

  sorted.sort((a, b) => compareRecursive(a, b, sortBy, 0, tableColumns));

  return sorted;
};

const compareRecursive = (a, b, sortBy, idx, tableColumns) => {
  //end of list
  if (idx === sortBy.length) return 0;

  const sortId = sortBy[idx].id;

  const column = _.find(tableColumns, tc => tc.id === sortId);
  const accessor = column.accessor;
  const sortType = column.sortType;

  let aVal = accessor === 'day' ? getDayLabel(a) : _.get(a, accessor);
  let bVal = accessor === 'day' ? getDayLabel(b) : _.get(b, accessor);

  if (column.type === 'auto-complete') {
    aVal = makeDropDownDisplay(aVal, column.accessor);
    bVal = makeDropDownDisplay(bVal, column.accessor);
  }

  //Ensure undefined values are at the end of the list
  if (sortType === 'num') {
    aVal = aVal ? parseFloat(aVal) : aVal === 0 ? 0 : Number.POSITIVE_INFINITY;
    bVal = bVal ? parseFloat(bVal) : bVal === 0 ? 0 : Number.POSITIVE_INFINITY;
  } else if (sortType === 'date') {
    aVal = aVal ? new Date(aVal).getTime() : Number.POSITIVE_INFINITY;
    bVal = bVal ? new Date(bVal).getTime() : Number.POSITIVE_INFINITY;
  } else if (sortType === 'bool') {
    aVal = aVal ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    bVal = bVal ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  } else if (sortType === 'alpha') {
    // ~ is the last character in alpha sorting, this should put empty/undefined values at the rear
    // during a normal sort, but the front during a desc sort
    aVal = aVal ? aVal : '~~~~~~~~~~~~~';
    bVal = bVal ? bVal : '~~~~~~~~~~~~~';
  } else {
    console.warn(
      `Unknown Sort Type for ${column.id}, expected 'alpha','bool','num',or 'date' received: ${sortType}`,
    );
    //unknown sortType
  }

  const desc = sortBy[idx].desc;
  if (aVal > bVal) return desc ? -1 : 1;
  if (aVal < bVal) return desc ? 1 : -1;
  return compareRecursive(a, b, sortBy, idx + 1, tableColumns);
};

const getDayLabel = row => {
  const DAYS = { sun: 1, mon: 2, tue: 3, wed: 4, thu: 5, fri: 6, sat: 7 };

  const dayName = row['day'] && row['day'].toLowerCase();
  return DAYS[dayName] || 0;
};

//takes workedDaysDetails obj and returns true if any are invalid
export const anyInvalidTimeEntry = workedDaysDetails => {
  for (const day in workedDaysDetails) {
    if (Object.hasOwnProperty.call(workedDaysDetails, day)) {
      const detail = workedDaysDetails[day];
      if (detail.hasValidTimeEntry === false) return true;
    }
  }
  return false;
};

export function findNextValidInput({
  rowIndex,
  columnId,
  direction = 'down',
  circulating = false,
  rowsCount = null,
}) {
  let index = rowIndex;
  let nextElement;
  let tagName = '';
  do {
    if (direction === 'down') {
      index++;
      nextElement = document.getElementById(`${index}.${columnId}`);
      if (!nextElement && circulating && index >= rowsCount) index = -1;
    } else if (direction === 'up') {
      index--;
      nextElement = document.getElementById(`${index}.${columnId}`);
      if (!nextElement && circulating && index <= 0) index = rowsCount;
    }

    tagName =
      nextElement instanceof HTMLElement
        ? nextElement.tagName
        : circulating
        ? ''
        : null;
  } while (
    (!circulating && tagName !== 'INPUT' && tagName !== null) ||
    (circulating && tagName !== 'INPUT') ||
    (nextElement && nextElement.disabled)
  );

  return nextElement;
}
