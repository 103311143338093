import React, { Component } from 'react';
import { customFormatDate } from 'utils/formatDate';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import EditScheduledReportComp from 'components/Employees/Reports/EditScheduledReport';
//actions
import * as actions from 'actions/reports';
//selector
import {
  getEditingScheduledReport,
  getUserReports,
  getScheduledReportFormValues,
} from 'selectors/reports';
import { getProjects } from 'selectors/project';
//Utils
import { formatMessage, REQUIRED } from 'utils/messages';
import validator from 'utils/validator';
import { INVALID_EMAIL } from 'utils/messages';

const formName = 'editScheduledReport';

const mapStateToProps = state => ({
  initialValues: getEditingScheduledReport(state),
  report: getScheduledReportFormValues(formName)(state),
  reportNames: getUserReports(state),
  projects: getProjects(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
});

export const validate = values => {
  const errors = {
    valid: true,
  };

  const {
    reportType,
    recipientEmails,
    projectIds,
    startDate,
    endDate,
    frequency,
    endOccurences,
    end,
  } = values;

  if (!reportType) {
    errors.reportType = formatMessage(REQUIRED);
    errors.valid = false;
  }

  if (!recipientEmails) {
    errors.recipientEmails = formatMessage(REQUIRED);
    errors.valid = false;
  } else {
    const emails = recipientEmails.split(/[ ,]+/);
    let isValidEmail = true;
    emails.map(email => {
      if (!!email && !validator.isEmail(email.trim())) isValidEmail = false;
      return isValidEmail;
    });
    if (!isValidEmail) {
      errors.recipientEmails = formatMessage(INVALID_EMAIL);
      errors.valid = false;
    }
  }

  if (!projectIds || !projectIds.length) {
    errors.projectIds = formatMessage(REQUIRED);
    errors.valid = false;
  }

  if (!startDate) {
    errors.startDate = formatMessage(REQUIRED);
    errors.valid = false;
  }
  if (frequency === 'hourly' && !endDate) {
    errors.endDate = formatMessage(REQUIRED);
    errors.valid = false;
  }

  if (frequency === 'daily' && end === 'endDate' && !endDate) {
    errors.endDate = formatMessage(REQUIRED);
    errors.valid = false;
  }

  if (frequency === 'daily' && end === 'endOccurences' && !endOccurences) {
    errors.endOccurences = formatMessage(REQUIRED);
    errors.valid = false;
  }

  const startDateFormatted = Date.parse(startDate);
  const endDateFormatted = Date.parse(endDate);

  if (endDateFormatted < startDateFormatted) {
    errors.endDate = formatMessage('End Date cannot be less then Start Date');
    errors.valid = false;
  }

  return errors;
};

const onSubmit = (values, dispatch) => {
  const report = values;
  const inputFormat = 'MM-DD-YYYY';
  const outputFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  if (report.frequency === 'daily') {
    if (report.end === 'endDate') {
      report.endOccurences = null;
      report.endNever = null;
    } else if (report.end === 'endNever') {
      report.endOccurences = null;
      report.endDate = null;
      report.endNever = true;
    } else {
      report.endNever = null;
      report.endDate = null;
    }
  }
  if (!report.id) {
    report.startDate = customFormatDate(
      report.startDate,
      inputFormat,
      outputFormat,
    );
  }

  if (!!report.endDate) {
    report.endDate = customFormatDate(
      report.endDate,
      inputFormat,
      outputFormat,
    );
  }

  if (report.recipientEmails) {
    report.recipientEmails = report.recipientEmails.split(/[ ,]+/).join(',');
  }
  dispatch(actions.saveScheduleReport({ report }));
};

export const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class EditScheduledReport extends Component {
  renderContent() {
    const { reportNames, report, projects, initialValues, ...others } =
      this.props;
    return (
      <EditScheduledReportComp
        key="editSchedule"
        reportNames={reportNames}
        report={report}
        projects={projects}
        initialValues={initialValues}
        {...others}
      />
    );
  }

  render() {
    const { classes, report, handleSubmit, ...others } = this.props;
    const title = (
      <Typography variant="h6" color="inherit" className={classes.title}>
        {report.id ? `Edit ` : `Add `}
        Scheduled Report
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={this.renderContent()}
          title={title}
          buttonText="Save"
          raisedButton
          {...others}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('EditScheduledReport', style),
  withModalDialog({ dialog: 'editScheduledReport', maxWidth: 'md' }),
  connect(mapStateToProps, null),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(EditScheduledReport);
