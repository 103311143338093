import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';
import { Button, Typography } from '@mui/material';
import withModalDialog from 'decorators/withModalDialog';
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';

export const style = ({ breakpoints }) => ({
  root: {},
  button: {
    margin: [0, 8],

    '&:last-child': {
      marginRight: 0,
    },
  },

  footer: {},
  [breakpoints.down('md')]: {
    footer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginTop: 2,
    },
    button: {
      margin: [4, 2],
      width: '100%',
    },
  },
});

class DepartmentApproveRemove extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onModalHide: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  renderFooter() {
    const { classes, onModalHide, onToggle } = this.props;

    return (
      <div>
        <Button
          className={classes.button}
          variant="outlined"
          name="cancel"
          onClick={onModalHide}
        >
          {'Cancel'}
        </Button>
        <Button color="secondary" className={classes.button} onClick={onToggle}>
          {'Remove'}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <ModalTitle>
          <Typography variant="h6" color="inherit">
            {'Remove Department Head'}
          </Typography>
        </ModalTitle>
        <ModalContent>
          <Typography color="inherit">
            {`Please be careful, any timecards awaiting review on this
                level will be passed onto the next level. If this is the
                final reviewer level all timecards on this level will be
                submitted to the next level.`}
          </Typography>
        </ModalContent>
        <ModalFooter>{this.renderFooter()}</ModalFooter>
      </div>
    );
  }
}
export default compose(
  withModalDialog({ dialog: 'departmentHeadApproval' }),
  withStyleSheet('DepartmentApproveRemove', style),
)(DepartmentApproveRemove);
