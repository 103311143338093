import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';

import { Box, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//redux
import * as actions from '../actions';
import { hide as hideModal } from 'actions/modalDialog';

import { getModalParams } from 'selectors/modalDialog';
import {
  FORM_NAME,
  getCommentsLoading,
  getLoading,
  getIsMobile,
} from '../selectors';
//local components
import {
  TitleBox,
  ContentBox,
  FooterBox,
  FooterButton,
} from '../Shared/styledComponents';

import withModalDialog from 'decorators/withModalDialog';
import { db, TAB_NAV_MAP, TAB_NAV_MAP_REVERSE } from '../empTimecardUtils';

import { useDidMount } from 'utils/customHooks';

import { UNSAVED_CHANGES_MODAL } from './modalNames';

const mapState = (state, ownProps) => ({
  modalParams: getModalParams(state, UNSAVED_CHANGES_MODAL),
  commentsSaving: getCommentsLoading(state),
  timecardSaving: getLoading(state, 'saving'),
  isMobile: getIsMobile(state),
});

const mapDispatch = dispatch => ({
  onCloseModal: () => dispatch(hideModal({ dialog: UNSAVED_CHANGES_MODAL })),
  onResetForm: () => {
    dispatch(reset(FORM_NAME));
  },
  onClearComment: url => {
    dispatch(actions.setNewComment({ comment: '' }));
  },
  onNav: url => {
    dispatch(push(url));
    dispatch(hideModal({ dialog: UNSAVED_CHANGES_MODAL }));
  },
  onSaveTimecard: url => {
    dispatch(actions.saveTimecard({ url }));
    dispatch(hideModal({ dialog: UNSAVED_CHANGES_MODAL }));
  },
  onSaveComment: () => dispatch(actions.saveComment()),
});

const UnsavedChangesModal = props => {
  const {
    onCloseModal,
    modalParams,
    onResetForm,
    onClearComment,
    onNav,
    onSaveComment,
    onSaveTimecard,
    commentsSaving,
    timecardSaving,
    isMobile,
  } = props;

  const saving = commentsSaving || timecardSaving;

  const { newTabName, oldTabName, queryParams = '' } = modalParams;

  useDidMount(() => {
    if (!newTabName || !oldTabName) {
      console.warn(
        'UnsavedChangesModal: missing tab names',
        oldTabName,
        newTabName,
      );
    }
  });

  const location = useLocation();
  const url = location.pathname + `?${newTabName}${queryParams}`;

  const isTimecard = oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.timecard];

  const isComments = oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.comments];

  const handleRevert = () => {
    if (isTimecard) {
      onResetForm();
      db('resetting form');
    } else if (isComments) {
      onClearComment(url);
    } else {
      throw new Error('Unknown tab');
    }
    onNav(url);
  };
  const handleClose = () => {
    onCloseModal();
  };

  const handleSaveConfirm = () => {
    if (isTimecard) {
      onSaveTimecard(url);
    } else {
      onSaveComment();
      onNav(url);
    }
  };

  return (
    <Box>
      {saving && <LinearProgress />}
      <TitleBox>
        <Box>{`Unsaved Changes`}</Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <ContentBox sx={{ m: 3 }}>
        Do you want to save changes before proceeding?
      </ContentBox>
      <FooterBox
        sx={{
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? 3 : '',
        }}
      >
        <FooterButton sx={{ flex: 5 }} variant="text" onClick={handleRevert}>
          Continue without Saving
        </FooterButton>
        <FooterButton sx={{ flex: 2 }} variant="outlined" onClick={handleClose}>
          Stay Here
        </FooterButton>
        <FooterButton sx={{ flex: 4 }} onClick={handleSaveConfirm}>
          Save and Continue
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

UnsavedChangesModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalParams: PropTypes.shape({
    oldTabName: PropTypes.string,
    newTabName: PropTypes.string,
    queryParams: PropTypes.string,
  }),
  onResetForm: PropTypes.func.isRequired,
  onClearComment: PropTypes.func.isRequired,
  onNav: PropTypes.func.isRequired,
  onSaveTimecard: PropTypes.func.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  commentsSaving: PropTypes.bool.isRequired,
  timecardSaving: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default compose(
  withModalDialog({
    dialog: UNSAVED_CHANGES_MODAL,
    maxWidth: 'lg',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(UnsavedChangesModal);
