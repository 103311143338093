import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';

import { FieldArray } from 'redux-form';
import AllowanceRow from './AllowancesRow';
import { WTCTableCell as TableCell } from 'components/Shared/Table/';
import { canadianLabel, isRegionCanada } from 'utils/helperFunctions';

const style = ({ palette }) => ({
  root: {
    display: 'block !important',
    width: '100%',
    boxShadow: 'none',
  },
  allowanceTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  addRowBtn: {
    float: 'right',
    position: 'absolute',
    top: 16,
    right: 32,
  },
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  table: {
    overflowX: 'auto',
  },
  tableBody: {
    '& > tr:nth-child(even)': {
      backgroundColor: palette.primary['+9'],
    },
  },
  expansionDetails: {
    paddingTop: 0,
  },

  noDisplay: { display: 'none' },
  showDisplay: { display: 'inherit' },
  occupationCodeSelect: {
    width: 184,
  },
  fadeToWhiteRight: {
    position: 'absolute',
    top: 56,
    right: 16,
    backgroundImage:
      ' linear-gradient(to right, rgba(255, 255, 255, 0),25%, white)',
    width: 35,
    //height set dynamically to that of the parent
    zIndex: 2,
    pointerEvents: 'none',
    transition: 'opacity 0.5s',
  },
  supportDocHeader: { lineHeight: 1 },
  supportDocHeaderText: { fontSize: 10, color: 'gray' },
});

const RenderBody = ({ fields, props }) => {
  const {
    classes,
    allowancesOrder,
    masterRowData,
    occupationCodes,
    loadOccupationCodes,
    workLocations,
    dealMemos,
    dealMemoId,
    loadDealMemo,
    episodes,
    loadEpisodes,
    payCodes,
    allowances = [],
    change,
    blur,
    error,
    allowanceDisabled,
    upmEnabled,
    maskingFunc,
    accountCodeMask,
    dateOptions,
    isUPM,
    //
    countries,
    stateOptions,
    countyOptions,
    cityOptions,
    subdivisions,
    locationFuncs,
    onShowAlert,
    project,
  } = props;

  const allowanceErrors = error.allowances || [];
  const filterArray = allowances?.filter(
    item => parseFloat(item?.factor || 1) === 1.0,
  );
  const hasFactorNot1 = filterArray.length === allowances?.length;

  function handleAddRow() {
    const newAllowance = {};
    const countryId = _.get(masterRowData, 'workCountry.id', '');
    const stateId = _.get(masterRowData, 'workState.id', '');
    const cityId = _.get(masterRowData, 'workCity.id', '');
    const countyId = _.get(masterRowData, 'workCounty.id', '');
    const subdivisionId = _.get(masterRowData, 'workSubdivision.id', '');

    if (countryId) {
      newAllowance.workCountry = _.cloneDeep(masterRowData.workCountry);
    }

    if (stateId) {
      newAllowance.workState = _.cloneDeep(masterRowData.workState);
    }
    if (cityId) {
      newAllowance.workCity = _.cloneDeep(masterRowData.workCity);
    }
    if (countyId) {
      newAllowance.workCounty = _.cloneDeep(masterRowData.workCounty);
    }
    if (subdivisionId) {
      newAllowance.workSubdivision = _.cloneDeep(masterRowData.workSubdivision);
    }

    const locationType = _.get(masterRowData, 'locationType.id', '');
    const episode = _.get(masterRowData, 'episode.id', '');
    const cck = masterRowData?.combineCheck;
    const accountCode = masterRowData?.accountCode;
    const series = masterRowData?.series;
    const set = masterRowData?.set;
    const freeField1 = masterRowData?.freeField1;
    const freeField2 = masterRowData?.freeField2;
    const freeField3 = masterRowData?.freeField3;
    const freeField4 = masterRowData?.freeField4;
    const location = masterRowData?.location;
    const insurance = masterRowData?.insurance;

    if (locationType) {
      newAllowance.locationType = _.cloneDeep(masterRowData.locationType);
    }
    if (episode) newAllowance.episode = _.cloneDeep(masterRowData.episode);
    if (cck) newAllowance.combineCheck = masterRowData.combineCheck;
    if (accountCode) newAllowance.accountCode = masterRowData.accountCode;
    if (location) newAllowance.location = masterRowData.location;
    if (insurance) newAllowance.insurance = masterRowData.insurance;
    if (series) newAllowance.series = masterRowData.series;
    if (set) newAllowance.set = masterRowData.set;
    if (freeField1 || freeField1 === 0) {
      newAllowance.freeField1 = masterRowData.freeField1;
    }
    if (freeField2 || freeField2 === 0) {
      newAllowance.freeField2 = masterRowData.freeField2;
    }
    if (freeField3 || freeField3 === 0) {
      newAllowance.freeField3 = masterRowData.freeField3;
    }
    if (freeField4 || freeField4 === 0) {
      newAllowance.freeField4 = masterRowData.freeField4;
    }

    if (masterRowData?.dealMemo?.id) {
      const dealMemoId = masterRowData.dealMemo.id;

      const dealMemo = dealMemos.find(dealMemo => dealMemo.id === dealMemoId);

      if (!_.isEmpty(dealMemo) && dealMemoId) {
        if (!_.isEmpty(dealMemo.occupationCode)) {
          const occupationCode = {
            id: dealMemo.occupationCode.id,
            code: dealMemo.occupationCode.code,
            name: dealMemo.occupationCode.name,
          };
          newAllowance.occupationCode = occupationCode;
        }

        // TODO - cut this down to the tiny dealMemo so there's no difference between
        // a new allowance and an old one dealMemo wise.

        newAllowance.dealMemo = _.cloneDeep(dealMemo);
      }
    }
    newAllowance.allowanceTypeFlag = 'M';
    newAllowance.frequency = 'F';
    newAllowance.hours = 1;
    fields.push(newAllowance);
  }

  function handleCopyRow(currentIndex, selectedRow) {
    selectedRow.copied = true;
    fields.splice(currentIndex + 1, 0, selectedRow);
  }
  const caRegion = isRegionCanada(project.region);

  return (
    <div className={classes.table}>
      <Button
        variant="text"
        color="primary"
        className={classes.addRowBtn}
        onClick={handleAddRow}
        disabled={allowanceDisabled}
      >
        <AddIcon />
        <span>Add Row</span>
      </Button>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {_.map(allowancesOrder, (header, i) => {
              const iscaRegionLocationType =
                caRegion && header.columnId === 'locationType';
              const isFactorVisible =
                hasFactorNot1 && header.columnId === 'factor';
              return (
                <TableCell
                  key={i}
                  display={
                    iscaRegionLocationType || isFactorVisible
                      ? false
                      : header.visible
                  }
                  header
                >
                  {header.label !== 'Supporting Document' ? (
                    <span>{canadianLabel(header.label)}</span>
                  ) : (
                    <div className={classes.supportDocHeader}>
                      <span>{header.label}</span>
                      <br />
                      <span className={classes.supportDocHeaderText}>
                        (max size 10 MB, use PDF, JPG, PNG formats)
                      </span>
                    </div>
                  )}
                </TableCell>
              );
            })}
            <TableCell display sticky={true}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {fields.map((allowanceRow, index) => {
            const allowanceError = allowanceErrors[index];

            const currentStateId = _.get(
              allowances,
              `[${index}].workState.id`,
              '',
            );
            const currentCountryId = _.get(
              allowances,
              `[${index}].workCountry.id`,
              '',
            );

            const supportDocument = _.get(
              allowances,
              `[${index}].comment`,
              '',
            ).replace(/<[^>]+>/g, '');
            // regex for removing some html tag

            return (
              <AllowanceRow
                onShowAlert={onShowAlert}
                removeRow={() => fields.remove(index)}
                // eslint-disable-next-line react/no-array-index-key
                key={`allowance_${index}`} //HOUR-8905
                allowancesOrder={allowancesOrder}
                allowance={allowanceRow}
                allowanceData={allowances[index] || {}}
                workLocations={workLocations}
                dealMemos={dealMemos}
                dealMemoId={dealMemoId}
                loadDealMemo={loadDealMemo}
                episodes={episodes}
                loadEpisodes={loadEpisodes}
                payCodes={payCodes}
                supportDocument={supportDocument}
                index={index}
                occupationCodes={occupationCodes}
                loadOccupationCodes={loadOccupationCodes}
                change={change}
                blur={blur}
                allowanceError={allowanceError}
                masterRowData={masterRowData}
                allowanceDisabled={allowanceDisabled}
                upmEnabled={upmEnabled}
                isUPM={isUPM}
                maskingFunc={maskingFunc}
                accountCodeMask={accountCodeMask}
                dateOptions={dateOptions}
                handleCopyRow={handleCopyRow}
                currentStateId={currentStateId}
                currentCountryId={currentCountryId}
                countries={countries}
                stateOptions={stateOptions}
                countyOptions={countyOptions}
                cityOptions={cityOptions}
                subdivisions={subdivisions}
                locationFuncs={locationFuncs}
                caRegion={caRegion}
                hasFactorNot1={hasFactorNot1}
                project={project}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const Allowances = props => {
  const { classes, allowances } = props;
  const [expand, expanded] = React.useState(true);
  React.useEffect(() => {
    if (allowances && allowances.length) expanded(true);
  }, [allowances]);
  const handleExpand = () => {
    expanded(!expand);
  };
  return (
    <div>
      <Accordion className={classes.root} expanded={expand}>
        <AccordionSummary
          onClick={handleExpand}
          expandIcon={<ExpandMoreIcon />}
          variant="iconOnRight"
        >
          <Typography>
            <span className={classes.allowanceTitle}>Allowances</span>
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.expansionDetails}>
          <FieldArray
            name="allowances"
            component={RenderBody}
            props={{
              props,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(style)(Allowances);
