import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import {
  TableCell as MuiTableCell,
  TableSortLabel as MuiTableSortLabel,
} from '@mui/material';

export const style = ({ palette }) => ({
  root: {},
  tableCell: {
    fontWeight: 700,
    color: palette.getDefaultColor(),
  },
});

export const sortProps = PropTypes.shape({
  applySorting: PropTypes.func,
  columnId: PropTypes.string,
  compact: PropTypes.bool,
  direction: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
});

class TableCellSortable extends Component {
  static propTypes = {
    applySorting: PropTypes.func,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    columnId: PropTypes.string,
    compact: PropTypes.bool,
    direction: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
  };

  static defaultProps = {
    applySorting: undefined,
    columnId: undefined,
    compact: false,
    direction: 'desc',
    orderBy: undefined,
  };

  handleSorting = (property, direction) => () => {
    const { applySorting } = this.props;

    if (applySorting) applySorting(property, direction);
  };

  render() {
    const { children, classes, columnId, compact, direction, orderBy, align } =
      this.props;

    const isActive = columnId === orderBy;
    const sortOrder = isActive && direction === 'desc' ? 'asc' : 'desc';

    return (
      <MuiTableCell
        className={classes.tableCell}
        size={compact ? 'small' : 'medium'}
        align={align}
      >
        <MuiTableSortLabel
          active={isActive}
          direction={direction}
          onClick={this.handleSorting(columnId, sortOrder)}
        >
          {children}
        </MuiTableSortLabel>
      </MuiTableCell>
    );
  }
}

export default withStyleSheet('TableCellSortable', style)(TableCellSortable);
