import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { List, ListItem, IconButton } from '@mui/material';

import UserNav from 'components/Nav/UserNav';

import { MenuDrawer, NavItem } from '../Shared';
import { withStyleSheet } from 'shared/theme';
import ColorLensIcon from '@mui/icons-material/ColorLens';

//selectors
import { getCurrentProject, getIsProjectArchived } from 'selectors/project';
import { currentUser, token } from 'selectors/session';
import {
  hasReviewerRole,
  hasCrewTimeCardRoles,
  roleIsPA,
  roleIsDH,
  roleIsUPM,
} from 'selectors/session';
import { isFlagEnabled } from 'selectors/flags';

//actions
import { logout } from 'actions/authenticate';
import { hideMenu } from 'actions/dropdownMenu';
import { toggleTheme } from 'actions/flag';

//utils
import { myCncUrl } from 'constants/config/apiServer';
import { PA, UPM } from 'components/props/profiles';

export const style = ({ palette }) => ({
  root: {
    width: '100%',
    fontSize: '1.15em',
    textAlign: 'right',
  },
  list: {
    flex: 'initial',
    '& > * ': {
      width: '100%',
      color: palette.common.white,
    },
  },
  anchor: {
    fontSize: 15,
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: 5,
    paddingTop: 2,
    paddingBottom: 2,
  },

  menuToggle: {
    cursor: 'pointer',
    fontWeight: 900,
    fontSize: 15,
  },

  togglerText: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    '& > *': {
      textAlign: 'center',
    },
  },

  titleItem: {
    padding: 0,
    alignItems: 'flex-start',
    width: '100%',
    cursor: 'default',
  },

  listItem: {
    padding: 0,
    alignItems: 'flex-start',
    width: '100%',
    '& > *': {
      padding: 16,
      width: '100%',
      textAlign: 'justify',
      fontSize: 16,
    },
  },
  logout: {
    padding: 0,
    alignItems: 'flex-start',
    width: '100%',
    '& > *': {
      padding: 16,
      width: '100%',
      textAlign: 'justify',
      fontSize: 15,
    },
  },
});
const mapStateToProps = state => ({
  isLoggedIn: !!token(state),
  project: getCurrentProject(state),
  currentUser: currentUser(state),
  isProjectArchived: getIsProjectArchived(state),
  canToggleTheme: isFlagEnabled(state, 'color_toggle'),
});

const mapDispatchToProps = dispatch => ({
  onLogOut: () => {
    dispatch(logout());
  },
  onMenuHide: () => dispatch(hideMenu({ menu: 'menu-drawer' })),
  onToggleTheme: () => dispatch(toggleTheme()),
});

/**
 * Mobile only nav menu in hamburger menu
 */
class AppDrawer extends Component {
  static propTypes = {
    anchor: PropTypes.string,
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    fullName: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    onLogOut: PropTypes.func.isRequired,
    isProjectArchived: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    anchor: 'right',
  };

  getNavModules = () => {
    const {
      project,
      currentUser: loggedInUser,
      isLoggedIn,
      isProjectArchived,
    } = this.props;

    if (!project || !project.id) {
      return [];
    }
    let isAdminUser = false;
    if (isLoggedIn && loggedInUser) {
      isAdminUser = loggedInUser.isAdmin;
    }

    const isReviewer = hasReviewerRole(loggedInUser.role);
    const isCrewTimecardRole = hasCrewTimeCardRoles(loggedInUser.role);
    const seeMyTimecards = !!loggedInUser.workSightId;
    const isPA = roleIsPA(loggedInUser.role);
    const isDH = roleIsDH(loggedInUser.role);
    const isUPM = roleIsUPM(loggedInUser.role);

    const navModules = [];

    if (isAdminUser) {
      navModules.push(
        {
          to: `/admin/projects/${project.id}/settings`,
          caption: 'Project Settings',
        },
        {
          to: `/admin/projects/${project.id}/crew-list`,
          caption: 'Crew list',
        },
        {
          to: `/admin/projects/${project.id}/review-flows`,
          caption: 'Departments & Approvers',
        },
        {
          to: `/admin/projects/${project.id}/templates`,
          caption: 'Templates',
        },
        {
          to: `/admin/projects/${project.id}/audit-logs`,
          caption: 'Audit Log',
        },
      );
    } else {
      if (seeMyTimecards) {
        navModules.push({
          to: `/projects/${project.id}/me/timecards`,
          caption: 'My timecards',
        });
      }
      if (isReviewer) {
        if (isCrewTimecardRole && !isProjectArchived) {
          if (project.dtsSettingEnabled && true) {
            navModules.push({
              to: `/projects/${project.id}/daily-timesheets`,
              caption: 'Daily timesheets',
            });
          }
          navModules.push({
            to: `/projects/${project.id}/timecards`,

            caption: 'Crew timecards',
          });
        }
        navModules.push({
          to: `/projects/${project.id}/review/search-timecards`,
          caption: 'Timecards',
        });
        if (isPA && !isProjectArchived) {
          navModules.push({
            to: `/projects/${project.id}/review/bulk-edit`,
            caption: 'Bulk edit',
          });
        }
        if ((isDH || isPA || isUPM) && !isProjectArchived) {
          navModules.push({
            to: `/projects/${project.id}/me/review`,
            caption: 'Reviews',
          });
        }
        if (!isDH) {
          navModules.push({
            to: `/projects/${project.id}/search-invoices`,
            caption: 'Invoices',
          });
        }
        if (!isProjectArchived) {
          navModules.push({
            to: `/projects/${project.id}/reports/ondemand`,
            caption: 'Reports',
          });
        }
        if (!isProjectArchived) {
          navModules.push({
            to: `/projects/${project.id}/crew-list`,
            caption: 'Crew list',
          });
        }
      }
    }

    return navModules;
  };

  renderNavItems = () => {
    const { classes } = this.props;

    const navItems = this.getNavModules().map(navItem => {
      // if (navItem.roles.indexOf(role) < 0) return null;
      return (
        <ListItem className={classes.listItem} key={navItem.to} divider>
          <NavItem
            linkTo={navItem.to}
            navLabel={navItem.caption}
            showSideActive
            mobileNav
          />
        </ListItem>
      );
    });

    return navItems || [];
  };
  render() {
    const {
      email,
      anchor,
      classes,
      fullName,
      role,
      onMenuHide,
      userRole,
      canToggleTheme,
      onToggleTheme,
    } = this.props;
    const adjustmenturl =
      window.location.origin === 'https://hoursplus.castandcrew.com/'
        ? 'https://my.castandcrew.com/adjustment-request'
        : 'https://mycnc-web-qa1.dev.aws.castandcrew.com/adjustment-request';

    return (
      <div className={classes.root}>
        <MenuDrawer anchor={anchor} menuOpen={this.props.menuOpen}>
          <List component="nav" className={classes.root}>
            <ListItem button divider className={classes.titleItem}>
              <UserNav
                email={email}
                fullName={fullName}
                role={role}
                onMenuHide={onMenuHide}
              />
            </ListItem>
            {canToggleTheme && (
              <ListItem>
                <IconButton
                  onClick={onToggleTheme}
                  key="colorButton"
                  style={{ color: 'white' }}
                >
                  <ColorLensIcon /> Toggle Theme
                </IconButton>
              </ListItem>
            )}
            {this.renderNavItems()}
            <ListItem className={classes.listItem} divider>
              <NavItem url={myCncUrl} navLabel="MyCast&Crew" mobileNav />
            </ListItem>
            <ListItem className={classes.listItem} divider>
              <NavItem
                url="https://edge.castandcrew.com/learning/cc"
                navLabel="C&C Edge Learning"
                mobileNav
              />
            </ListItem>
            <ListItem className={classes.listItem} divider>
              <NavItem
                url="https://support.castandcrew.com/"
                navLabel="Help Center"
                mobileNav
              />
            </ListItem>
            {(userRole === PA || userRole === UPM) && (
              <ListItem className={classes.listItem} divider>
                <NavItem
                  url={adjustmenturl}
                  navLabel="Submit Adjustment"
                  mobileNav
                />
              </ListItem>
            )}
            <ListItem className={classes.logout}>
              <NavItem navLabel="Logout" linkTo={`/logout`} />
            </ListItem>
          </List>
        </MenuDrawer>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyleSheet('AppDrawer', style),
)(AppDrawer);
