import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import {
  Field,
  FieldArray,
  reduxForm,
  getFormSyncErrors,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { Typography, Button } from '@mui/material';
//icons
import WarningIcon from '@mui/icons-material/Warning';

import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ROLE_LABELS, PA } from 'components/props/profiles';
import { ConfirmModal } from 'components/Shared/Modals';
import { usersProps } from 'components/props/profiles';
import { EditReviewer } from 'components/Admin/Projects/DepartmentApprovers';
//actions
import { saveEditingStepUser } from 'actions/reviewFlows';
// selectors
import { getUsers } from 'selectors/users';
import {
  getEditingReviewers,
  getSaving,
  getCurrentApprovers,
} from 'selectors/reviewFlows';
//form validation
import { editReviewersValidate, getFilteredUsers } from 'utils/';

const formName = 'EditApprovalFlow';

const selector = formValueSelector(formName);

const mapStateToProps = (state, ownProps) => ({
  initialValues: getEditingReviewers(state, ownProps),
  approvalFlow: getEditingReviewers(state, ownProps),
  users: getUsers(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  approvers: getCurrentApprovers(state),
  isSaving: getSaving(state),
  reviewersInForm: selector(state, 'reviewers'),
});

const mapDispatchToProps = dispatch => ({});

const onSubmit = (values, dispatch) => {
  dispatch(saveEditingStepUser({ values }));
};

export const style = theme => ({
  content: {
    maxWidth: 500,
  },
  error: {
    fontSize: '1.1em',
    color: theme.palette.error.main,
    margin: '10px 0px 0px 0px',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    margin: 12,
  },
  warningText: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 450,
  },
});

//get count of fields with data in them.
const getUserCount = fields => {
  let count = 0;
  for (let i = 0; i < fields.length; i++) {
    const element = fields.get(i);
    if (element && element.id) count++;
  }
  return count;
};

class EditApprovalFlow extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reviewFlowId: PropTypes.string,
    approvalFlow: PropTypes.object,
    users: usersProps.isRequired,
    change: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    reviewFlowId: '',
    stepId: '',
    approvalFlow: {},
  };

  renderFields({ fields, approvalFlow, approvers, users, classes }) {
    if (
      fields.length === 0 &&
      approvalFlow &&
      approvalFlow.reviewers.length === 0
    ) {
      fields.push({});
    }

    const listEmpty = fields && fields.length === 0;
    const userCount = getUserCount(fields);
    const disableRemove = userCount <= 1 && approvalFlow.role === PA;
    const filteredUsers = getFilteredUsers(approvalFlow, approvers, users);

    const list = fields.map((user, index) => (
      <EditReviewer
        // eslint-disable-next-line react/no-array-index-key
        key={`${index}_${fields.length}`} //HOUR-8905
        user={user}
        approvalFlow={approvalFlow}
        users={filteredUsers}
        addNext={() => fields.push({})}
        removeUser={() => fields.remove(index)}
        disableRemove={disableRemove}
        canAddNext={!listEmpty && fields.length === index + 1}
      />
    ));

    return (
      <div>
        {approvalFlow.stepId && listEmpty && (
          <div className={classes.warningText}>
            <div style={{ display: 'flex' }}>
              <WarningIcon className={classes.warningIcon} />
              <p>
                <b>Please be careful, this action can not be undone.</b>
              </p>
            </div>

            <p>
              Any timecards awaiting review on this level will be passed onto
              the next level.
            </p>
            <p>
              If this is the final approver level all timecards on this level
              will be submitted to C&C.
            </p>
            <Button variant="outlined" onClick={() => fields.push({})}>
              Add approver
            </Button>
          </div>
        )}
        {list}
      </div>
    );
  }

  render() {
    const {
      classes,
      approvalFlow,
      approvers,
      handleSubmit,
      reviewFlowId,
      users,
      isSaving,
      reviewersInForm = [],
      ...others
    } = this.props;

    const haveReviewers = reviewersInForm.length > 0;

    const roleLabel = ROLE_LABELS[approvalFlow.role];

    const title = (
      <Typography variant="h6" color="inherit" className={classes.title}>
        {approvalFlow.stepId
          ? haveReviewers
            ? `Edit ${roleLabel} Level`
            : 'Remove Approver Level'
          : `Assign ${roleLabel} Level`}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component="input"
          name="reviewFlowId"
          type="hidden"
          value={reviewFlowId}
        />
        <Field
          component="input"
          name="id"
          type="hidden"
          value={approvalFlow.id}
        />
        <Field component="input" name="role" type="hidden" />
        <ConfirmModal
          content={
            <FieldArray
              name="reviewers"
              component={this.renderFields}
              classes={classes}
              approvalFlow={this.props.approvalFlow}
              users={this.props.users}
              approvers={this.props.approvers}
            />
          }
          title={title}
          buttonText={haveReviewers ? 'Save' : 'Remove'}
          raisedButton
          submitDisabled={isSaving}
          {...others}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('EditApprovalFlow', style),
  withModalDialog({ dialog: 'editApprovalFlow', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    onSubmit,
    validate: editReviewersValidate,
    shouldValidate: () => true,
  }),
)(EditApprovalFlow);
