import React from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import * as actions from 'actions/wtc';
import { hide as hideModal } from 'actions/modalDialog';
//selectors
import { getProspectiveTimecard } from 'selectors/wtc';
import { getProject as project } from 'selectors/routeParams';

const mapState = state => ({
  prospectiveTimecard: getProspectiveTimecard(state),
  projectId: project(state),
});

const mapDispatch = dispatch => ({
  onLeaveTimecard: (prospectiveTimecard, projectId) => {
    dispatch(hideModal({ dialog: 'TakeMeBack' }));
    if (prospectiveTimecard.actionType === 'loadTimecard') {
      const { timecardEntryHeaderId } = prospectiveTimecard;
      dispatch(
        actions.setCurrentTimecardHeaderId({
          currentTimecardHeaderId: timecardEntryHeaderId,
        }),
      );
      dispatch(
        actions.fetchTimecard({
          projectId,
          timecardEntryHeaderId,
          initialLoad: true,
        }),
      );
      dispatch(actions.fetchEpisodes({ initialLoad: true }));
      dispatch(actions.fetchBreakdown());
      dispatch(actions.fetchComments());
      dispatch(actions.fetchPaidHours({ timecardEntryHeaderId }));
      dispatch(actions.fetchSplitHourTypes({ initialLoad: true }));
    } else if (prospectiveTimecard.actionType === 'loadSingleBatch') {
      prospectiveTimecard.actionOnLeave();
    } else {
      const defaultURL = `/projects/${projectId}/review/search-timecards`;
      const fromURI = localStorage.getItem('fromURI') || defaultURL;

      setTimeout(() => {
        dispatch(push(fromURI));
        dispatch(
          actions.setCurrentTimecardHeaderId({
            currentTimecardHeaderId: null,
          }),
        );
        dispatch(actions.storeTimecardsInDrawer({ timecards: [] }));
        dispatch(actions.storeTimecard({ timecard: {} }));
        dispatch(actions.resetAllAdditionalFields());
        dispatch(actions.resetTableFields());
      }, 1000);
    }
  },
  onModalHide: () => {
    dispatch(hideModal({ dialog: 'TakeMeBack' }));
  },
});

const TakeMeBack = props => {
  const { prospectiveTimecard, projectId, onLeaveTimecard, onModalHide } =
    props;

  const title = <div>{'Unsaved Changes'}</div>;

  const content = (
    <div>
      <Typography color="inherit">
        {'Your new changes will be lost.'} <br />
        {'Are you sure you want to leave this page?'}
      </Typography>
    </div>
  );

  return (
    <ConfirmModal
      title={title}
      content={content}
      buttonText="Leave this Page"
      buttonType="default"
      cancelText="Stay on this Page"
      hasSubmitAction={true}
      onModalHide={onModalHide}
      onSubmit={() => onLeaveTimecard(prospectiveTimecard, projectId)}
    />
  );
};

export default withModalDialog({
  dialog: 'TakeMeBack',
})(connect(mapState, mapDispatch)(TakeMeBack));
