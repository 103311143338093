import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
  Button,
} from '@mui/material';

//theme
import { withStyleSheet } from 'shared/theme';
// components
import { TableList } from 'components/Shared/TableList';
import { timecardsProps } from 'components/props/timecard';

//utils
import {
  currentApprovers,
  getDeleteIcon,
  getStatusBadgeV2,
  EmergencyIcon,
  ForceResubmitIcon,
} from 'containers/Employees/Reviews/Shared/timecardUtils';

import { getApproverList } from '../Reviews/ApproverList';
export const style = ({ palette }) => ({
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
  boldFont: {
    fontWeight: 500,
  },
  tableEmpty: {
    textAlign: 'center',
  },
  title: {
    marginLeft: 20,
  },
  tableRow: {
    cursor: 'pointer',
  },
  emergencyIcon: {
    display: 'block',
    margin: 'auto',
    marginTop: 155,
  },

  '@keyframes tc-approved': {
    from: { opacity: 1, backgroundColor: palette.primary.main },
    to: { opacity: 0, backgroundColor: palette.primary.main },
  },
  tcApprovedFlash: {
    animation: '$tc-approved 1s 3',
    animationDuration: '250ms',
    animationDelay: '200ms',
  },
});

//shared functions:
// currentApprovers,
// getComment,
// getDeleteIcon,
// getStatusBadge,
// getEmergencyIcon
// have been moved to containers/Employees/Reviews/Shared/timecardUtils
let currPlace = 0;
const keyCombo = ['t', 'c', 'v', '1'];

class TimecardList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    timecards: timecardsProps.isRequired,
    activeUser: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.state = {
      showV1: false,
    };

    this.listenForCombo = this.listenForCombo.bind(this);
  }

  handleClick(timecard) {
    if (this.props.onClick) {
      this.props.onClick(timecard);
    }
  }

  handleDelete(e, timecardId) {
    e.stopPropagation();
    if (this.props.onDelete) {
      this.props.onDelete(timecardId);
    }
  }
  componentDidMount() {
    const root = document.querySelector('#root');
    root.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    document.addEventListener('keydown', this.listenForCombo);
  }

  listenForCombo = e => {
    const key = e.key.toLowerCase();
    if (key === keyCombo[currPlace]) {
      currPlace++;
    } else {
      currPlace = 0;
    }
    if (currPlace === keyCombo.length) {
      this.setState({ showV1: true });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listenForCombo);
  }

  render() {
    const {
      classes,
      timecards = [],
      activeUser,
      loading,
      isUserDeleted,
      ...others
    } = this.props;
    let { approvedTimecardId } = this.props;
    approvedTimecardId = approvedTimecardId
      ? parseInt(approvedTimecardId, 10)
      : approvedTimecardId;
    const self = this;

    let contentRows = null;

    const headers = [
      { columnId: 'timecard.emergencyType', label: '', width: '3%' },
      {
        columnId: 'endsOn',
        label: 'Week Ending',
        sortable: true,
        compact: true,
      },
      {
        columnId: 'timecard.dealMemo.jobDescription',
        label: 'Occupation Code',
        sortable: true,
        compact: true,
      },
      { columnId: 'timecard.user.fullName', label: 'Current Approver' },
      { columnId: 'status', label: 'Status', sortable: true, compact: true },
      { columnId: 'oldComments', label: '' }, // TODO - remove after my tc redesign is ready
      { columnId: 'actions', label: '', compact: true },
    ];

    if (timecards && timecards.length <= 0) {
      contentRows = (
        <TableRow>
          <TableCell colSpan={7} className={classes.tableEmpty}>
            {loading ? 'Loading Timecards...' : 'No Timecards Found'}
          </TableCell>
        </TableRow>
      );
    } else {
      contentRows = timecards.map(timecard => {
        return (
          <TableRow
            hover
            className={clsx(classes.tableRow, {
              [classes.tcApprovedFlash]:
                timecard.timecardId === approvedTimecardId,
            })}
            key={timecard.timecardId}
            onClick={() => self.handleClick(timecard)}
          >
            <TableCell size="small">
              {timecard.emergencyType ? (
                <EmergencyIcon />
              ) : timecard.workflowAction === 'resubmitToEmployee' ? (
                <ForceResubmitIcon />
              ) : (
                ''
              )}
            </TableCell>
            <TableCell size="small" className={classes.boldFont}>
              {timecard.endsOn.substring(0, 10)}
            </TableCell>
            <TableCell className={classes.boldFont}>
              {timecard.occupationCode || 'Pending'}
            </TableCell>
            <TableCell size="small" className={classes.boldFont}>
              {getApproverList(currentApprovers(timecard.currentApproversStr))}
            </TableCell>
            <TableCell size="small">{getStatusBadgeV2(timecard)}</TableCell>
            <TableCell size="small">
              {this.state.showV1 && (
                <Button
                  onClick={e => {
                    this.props.onClickV1(timecard);
                    e.stopPropagation();
                  }}
                >
                  View V1
                </Button>
              )}
            </TableCell>
            <TableCell size="small">
              {!isUserDeleted && getDeleteIcon(timecard, self.handleDelete)}
            </TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Paper className={classes.paper}>
        {loading && timecards.length <= 0 && <LinearProgress />}

        <TableList headers={headers} {...others}>
          {contentRows}
        </TableList>
      </Paper>
    );
  }
}

export default withStyleSheet('TimecardList', style)(TimecardList);
