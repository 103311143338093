import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Toolbar, Hidden } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import * as _isEmpty from 'lodash/isEmpty';
import * as _find from 'lodash/find';
//selector
import { currentUser, token } from 'selectors/session';
import { getProjectDetails } from 'selectors/project';

//action
import { storeProject } from 'actions/routeParams';
import { show as showModal } from 'actions/modalDialog';
import { fetch as fetchSettings } from 'actions/settings';
import {
  fetchDetails,
  validatingStart,
  // validateStartProject,
  startProjectResult,
} from 'actions/project';
//component
import { AppBar, Title, StartProject } from 'components/Nav';
import { projectProps } from 'components/props/projects';
import { profileProps } from 'components/props/profiles';
import { REVIEW_TABS_BY_ROLE } from 'components/Shared/constants';
import YearPicker from './YearPicker';
//container
import Start from 'containers/Admin/Projects/Start';
import { ProjectNav } from '../Nav';
//selector
import {
  hasReviewerRole,
  hasCrewTimeCardRoles,
  roleIsPA,
  roleIsDH,
  roleIsUPM,
  hasIARole,
} from 'selectors/session';

import { START_PROJ_MODAL } from 'containers/Admin/Projects/Start';

export const style = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: 'inherit',
  },
  titleText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleContainer: {
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  navItems: {
    order: 0,
    flex: '1 1 auto',
    alignSelf: 'auto',
    cursor: 'regular',
  },
  titleGroup: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
});

const mapStateToProps = state => ({
  isLoggedIn: !!token(state),
  currentUser: currentUser(state),
  project: getProjectDetails(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onSetProject: () => {
    dispatch(storeProject({ projectId: match.params.projectId }));
  },
  onFetchData: excludeSearch => {
    dispatch(fetchDetails({ currentPath: match.path }));
  },
  onStartProject: () => {
    dispatch(validatingStart({ validating: true }));
    dispatch(showModal({ dialog: START_PROJ_MODAL }));
    dispatch(startProjectResult({}));
  },
  // onValidateStart: () => dispatch(validateStartProject()),
  onFetchSettings: () => dispatch(fetchSettings()),
});

class ProjectHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    project: projectProps,
    currentUser: profileProps,
    isLoggedIn: PropTypes.bool,
    onFetchData: PropTypes.func.isRequired,
    onStartProject: PropTypes.func.isRequired,
    onFetchSettings: PropTypes.func.isRequired,
    onSetProject: PropTypes.func.isRequired,
    onValidateStart: PropTypes.func.isRequired,
  };

  static defaultProps = {
    project: undefined,
    isLoggedIn: false,
    currentUser: undefined,
  };

  //FYI this runs every time react-router is used
  componentDidMount() {
    const { project, onFetchData, onFetchSettings, onSetProject } = this.props;
    onSetProject();
    //only fetch data if we don't have project info
    if (!project || _isEmpty(project)) onFetchData();
    onFetchSettings();
  }

  onStartProject() {
    if (this.props) {
      const {
        onStartProject,
        // onValidateStart
      } = this.props;
      onStartProject();
      // setTimeout(() => onValidateStart(), 3000);
    }
  }

  render() {
    const {
      classes,
      currentUser: loggedInUser,
      isLoggedIn,
      project,
    } = this.props;
    let isAdminUser = false;

    if (isLoggedIn && loggedInUser) {
      isAdminUser = loggedInUser.isAdmin;
    }
    const isReviewer = hasReviewerRole(loggedInUser.role);
    const isCrewTimecardRole = hasCrewTimeCardRoles(loggedInUser.role);
    const tabs = REVIEW_TABS_BY_ROLE(loggedInUser.role, project.id);
    const activeReviewTab = !_isEmpty(tabs)
      ? _find(tabs, { defaultActive: true }).linkTo
      : null;
    const seeMyTimecards = !!loggedInUser.workSightId;
    const isPA = roleIsPA(loggedInUser.role);
    const isDH = roleIsDH(loggedInUser.role);
    const isUPM = roleIsUPM(loggedInUser.role);
    const isIA = hasIARole(loggedInUser?.projectRoles);

    const isDraft = project?.isDraft;
    const isTimePlusEnabled = project?.timePlusEnabled;
    const isNotPending = project?.status !== 'P';
    const isStartProjectBtnEnabled =
      isAdminUser && isTimePlusEnabled && isDraft && isNotPending;
    return (
      <AppBar secondary>
        <Toolbar>
          <Hidden lgDown>
            <Grid
              container
              className={classes.root}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={isDraft ? 3 : 4} className={classes.titleGroup}>
                <Title
                  className={classes.titleContainer}
                  textClassName={classes.titleText}
                  title={project?.name}
                />
                <YearPicker hide={isAdminUser} />
              </Grid>
              {isDraft && (
                <Grid item xs={1}>
                  <StartProject
                    onStartProject={this.onStartProject.bind(this)}
                    isStartProjectBtnEnabled={isStartProjectBtnEnabled}
                    isAdminUser={isAdminUser}
                  />
                </Grid>
              )}
              <Hidden lgDown>
                <Grid className={classes.navItems} item xs={8}>
                  {isLoggedIn && project && (
                    <ProjectNav
                      isAdminUser={isAdminUser}
                      project={project}
                      currentUser={currentUser}
                      isReviewer={isReviewer}
                      isCrewTimecardRole={isCrewTimecardRole}
                      activeReviewTab={activeReviewTab}
                      seeMyTimecards={seeMyTimecards}
                      isPA={isPA}
                      isDH={isDH}
                      isUPM={isUPM}
                      isIA={isIA}
                    />
                  )}
                </Grid>
              </Hidden>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item className={classes.titleGroup} xs={12}>
              <Title
                className={classes.titleContainer}
                textClassName={classes.titleText}
                title={project?.name}
              />
              <YearPicker hide={isAdminUser} />
            </Grid>
          </Hidden>
        </Toolbar>
        <Start />
      </AppBar>
    );
  }
}

const ProjectHeaderV1 = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyleSheet('SubHeader', style)(ProjectHeader)),
);

ProjectHeaderV1.displayName = 'ProjectHeaderV1';

export default ProjectHeaderV1;
