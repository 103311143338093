import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';

import { getModalParams } from 'selectors/modalDialog';
import { getSubmitting } from 'selectors/searchTimecards';

import { hide as hideModal } from 'actions/modalDialog';
import { deleteTimecards } from 'actions/searchTimecards';

import { Typography, LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import withModalDialog from 'decorators/withModalDialog';
import { ConfirmModal } from 'components/Shared/Modals';
import WarningIcon from '@mui/icons-material/Warning';

export const style = ({ palette }) => ({
  warningIcon: {
    color: palette.warning.main,
    verticalAlign: 'middle',
  },
  warningMessage: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  errorField: {
    extend: 'field',
    '& > label': {
      color: palette.error.main,
    },
    '& > p': {
      color: palette.error.main,
    },
  },
});

const modalDialog = 'DeleteSearchModal';
const mapStateToProps = state => ({
  modalParams: getModalParams(state, modalDialog),
  submitting: getSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  onModalHide: () => dispatch(hideModal({ dialog: modalDialog })),
  onDelete: ({ timecardIds }) => {
    dispatch(deleteTimecards({ timecardIds }));
  },
});

const DeleteSearchModal = props => {
  const {
    classes,
    // modalParams,
    modalParams: { timecardIds = [] },
    onModalHide,
    onDelete,
    submitting,
  } = props;

  const title = (
    <Typography variant="h6" color="inherit">
      {`Delete ${pluralize('timecard', timecardIds.length)}`}
    </Typography>
  );

  const onSubmit = () => {
    onDelete({ timecardIds });
  };

  const content = (
    <div>
      <Typography>
        Are you sure you want to delete{' '}
        {`${pluralize('timecard', timecardIds.length, true)}`}?
      </Typography>
      <div className={classes.warningMessage}>
        <WarningIcon className={classes.warningIcon} />{' '}
        <b>This cannot be undone.</b>
      </div>
      {submitting && <LinearProgress />}
    </div>
  );
  return (
    <div>
      <ConfirmModal
        content={content}
        title={title}
        buttonText={`Yes, delete ${pluralize(
          'timecard',
          timecardIds.length,
          true,
        )}`}
        buttonType="secondary"
        raisedButton
        cancelText="No, Take Me Back"
        submitDisabled={submitting}
        submitting={submitting}
        onModalHide={onModalHide}
        hasSubmitAction
        customSubmit
        customOnSubmit={onSubmit}
      />
    </div>
  );
};

DeleteSearchModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'DeleteSearchModal', maxWidth: 'sm' }),
  connect(mapStateToProps, mapDispatchToProps),
)(DeleteSearchModal);
