import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';

export const style = ({ palette }) => ({
  root: {},

  menuTitle: {
    cursor: 'default',
    display: 'block',
    textAlign: 'left',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    paddingTop: 2,
    paddingBottom: 2,
  },

  titleElement: {
    extend: 'menuTitle',
    fontSize: '1.3333em',
  },

  secondaryElement: {
    extend: 'menuTitle',
    fontSize: '1em',
    fontWeight: 300,
    color: palette.gray['+0'],
  },
});

class MenuTitle extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    primary: PropTypes.bool,
  };

  static defaultProps = { primary: false };

  render() {
    const { classes, children, primary } = this.props;

    return (
      <div
        className={primary ? classes.titleElement : classes.secondaryElement}
      >
        {children}
      </div>
    );
  }
}

export default withStyleSheet('MenuTitle', style)(MenuTitle);
