import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import HeaderActionButtons from './HeaderActionButtons';
import DataCardsControl from './DataCardsControl';

import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  Box,
  Button,
  Skeleton,
  Typography,
  Tooltip,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
//icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { ArrowBack } from '@mui/icons-material';

//Actions
import { navToStudioPlus } from 'actions/digitalEdits';
import { show as openModal } from 'actions/modalDialog';
import { push } from 'connected-react-router';

//selectors
import * as sel from 'selectors/digitalEdits';
import { getProjectDetails } from 'selectors/project';

//Components
import CommentModal from '../DigitalEdits/Comments/CommentModal';
import DataCard from './DataCard';
import HeaderCommentCard from './HeaderCommentCard';
import FadeInScroll from 'components/Shared/FadeInScroll';
import UploadFilesModal from './UploadFilesComps/UploadFilesModal';
import ResubmitInvoiceModal from './Modals/ResubmitInvoiceModal';
import DigitalEditsCommentsLite from '../DigitalEdits/Comments/DigitalEditsCommentsLite';
import ApproveInvoiceModal from './Modals/ApproveInvoiceModal';
import ColorPill from 'components/Shared/ColorPill';
import DownloadEditReports from './DownloadEditReports';
import JumpToTitle from './JumpToTitle';

//utils
import {
  CAN_APPROVE_STATUSES,
  CHARGE_DES_MAX,
  CHARGE_DES_MAX_STICKY,
  calcChargeNoWrap,
  getPillInfo,
} from './digitalEditsUtils';

const useStyles = makeStyles(({ palette }) => ({
  Header: {
    display: 'grid',
    gridTemplateAreas: `
    "header header header actions actions actions"
    "header header header actions actions actions"
    "notifications notifications notifications . . ."
    "info info info info info info"
    `,
    gridTemplateRows: '58px 50px 1fr',
    margin: 8,
    minWidth: 900,
  },
  navTopBar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 8,
  },
  header: {
    gridArea: 'header',
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    color: palette.gray.primary,
  },
  notifications: {
    gridArea: 'notifications',
    padding: 8,
  },
  chargeDescription: {
    gridArea: 'chargeDescription',
    fontSize: 32,
    fontWeight: 800,
    color: palette.primary.main,
  },
  actions: {
    gridArea: 'actions',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
  },
  info: {
    padding: '12px 24px 24px 24px',
    gridArea: 'info',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 12,
  },
  largePayment: {
    fontWeight: 700,
    fontSize: 20,
    color: palette.warning.main,
  },
  stickyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
    color: palette.text.primary,
    fontWeight: 500,
  },
  stickyHeaderText: {
    flexWrap: 'wrap',
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between',
    marginLeft: '40px',
    gap: '5px',
    fontSize: '18px',
  },
  clipText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: `${CHARGE_DES_MAX}ch`,
  },
  clipTextSticky: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: `${CHARGE_DES_MAX_STICKY}ch`,
  },
}));

const mapState = state => ({
  currentInvoiceId: sel.getCurrentInvoiceId(state),
  invoice: sel.getInvoice(state),
  invoiceDetails: sel.getInvoiceDetails(state),
  comments: sel.getExistingComments(state),
  isUpdatingStatus: sel.getIsUpdatingInvoiceStatus(state),
  resubmitLoading: sel.getResubmitLoading(state),
  projectDetails: getProjectDetails(state),
  hasUnsubmitted: sel.getHasUnsubmitted(state),
  uploadedFiles: sel.getUploadedFiles(state),
  acEditRecord: sel.getAcEditRecord(state),
  anyErrors: sel.getHasAnyError(state),
  anyPending: sel.getAnyPendingTimecards(state),
});

const mapDispatch = dispatch => ({
  onOpenModal: params => dispatch(openModal(params)),
  onNavToInvoices: projectId => {
    const toURL = `/projects/${projectId}/search-invoices`;
    dispatch(push(toURL));
  },
  onNavToStudio: () => dispatch(navToStudioPlus()),
});

const Header = props => {
  const classes = useStyles();
  const {
    invoice,
    invoiceDetails,
    onOpenModal,
    onNavToInvoices,
    currentInvoiceId,
    comments,
    isUpdatingStatus,
    resubmitLoading,
    projectDetails,
    onNavToStudio,
    hasUnsubmitted,
    uploadedFiles,
    acEditRecord,
    anyErrors,
    anyPending,
  } = props;

  //Handle Sticky Header Width
  const HeaderRef = React.useRef();
  const [stickyHeaderWidth, setStickyHeaderWidth] = React.useState(0);

  const updateWidth = React.useCallback(() => {
    const headerWidth = (HeaderRef?.current?.offsetWidth || 0) - 25;
    const stickyWidth = headerWidth > 1000 ? headerWidth : undefined;
    setStickyHeaderWidth(stickyWidth);
  }, []);

  const initWidth = React.useCallback(() => {
    if (HeaderRef.current) {
      updateWidth();
    } else {
      setTimeout(initWidth, 250);
    }
  }, [updateWidth]);

  React.useEffect(() => {
    initWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [initWidth, updateWidth]);

  let approveDisabled;
  let approveTooltip = '';
  if (CAN_APPROVE_STATUSES.includes(invoice.status) === false) {
    approveDisabled = true;
    approveTooltip = 'Invoice cannot be approved in the current status';
  }

  if (hasUnsubmitted && uploadedFiles.length > 0) {
    approveDisabled = true;
    approveTooltip =
      'Unsubmitted comments and files must be removed in order to approve this invoice';
  } else if (uploadedFiles.length > 0) {
    approveDisabled = true;
    approveTooltip =
      'Unsubmitted files must be removed in order to approve this invoice';
  } else if (hasUnsubmitted) {
    approveDisabled = true;
    approveTooltip =
      'Unsubmitted comments must be removed in order to approve this invoice';
  } else if (anyErrors) {
    approveDisabled = true;
    approveTooltip = 'Resolve errors or Resubmit instead';
  } else if (anyPending) {
    approveDisabled = true;
    approveTooltip = 'Pending timecard calculation';
  }

  if (acEditRecord) {
    approveDisabled = true;
    approveTooltip = (
      <JumpToTitle acEditRecord={acEditRecord} action="approve this invoice." />
    );
  }
  const downloadEditReportsBtn = (
    <Button
      variant="outlined"
      sx={{ minWidth: '200px' }}
      onClick={() => onOpenModal({ dialog: 'DownloadEditReports' })}
      startIcon={<CloudDownloadIcon className={classes.downloadIcon} />}
    >
      Download Edit Reports
    </Button>
  );

  const approveInvoiceBtn = (
    <Tooltip placement="top" title={approveTooltip}>
      <Box>
        <Button
          onClick={() => onOpenModal({ dialog: 'ApproveInvoice' })}
          disabled={approveDisabled || isUpdatingStatus}
        >
          Approve Invoice
        </Button>
      </Box>
    </Tooltip>
  );

  const statusPillInfo = getPillInfo(invoice.userFriendlyStatus);

  const statusPill = statusPillInfo?.label ? (
    <ColorPill label={statusPillInfo.label} color={statusPillInfo.pillColor} />
  ) : null;

  const [clipCharge, clipChargeSticky] = calcChargeNoWrap(
    invoice?.chargeDescription,
  );

  return (
    <div>
      <Box className={classes.navTopBar}>
        <Button
          variant="text"
          onClick={() => onNavToInvoices(projectDetails.id)}
          startIcon={<ArrowBack />}
        >
          Back to Invoices
        </Button>
        <Button variant="outlined" onClick={onNavToStudio}>
          Go to Studio+
        </Button>
      </Box>

      {!!currentInvoiceId ? (
        <Paper className={classes.Header} ref={HeaderRef}>
          <React.Fragment>
            {/*             ** HEADER             */}
            <Box className={classes.header}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{ fontSize: 32, fontWeight: 800, color: 'primary.main' }}
                >
                  Invoice:
                  <Box sx={{ display: 'inline' }}>{invoice.code || ''}</Box>
                </Box>
                {statusPill}
              </Box>

              <Box
                className={clsx(classes.chargeDescription, {
                  [classes.clipText]: clipCharge,
                })}
              >
                <Tooltip
                  arrow
                  placement="bottom"
                  title={clipCharge ? invoice.chargeDescription : ''}
                >
                  <span>{invoice.chargeDescription || ''}</span>
                </Tooltip>
              </Box>
            </Box>
            {/*             ** Actions             */}
            <Box
              className={clsx(classes.actions, 'PENDO_DigitalEdits_ActionBtns')}
            >
              <Box sx={{ display: 'flex', p: 1, alignItems: 'flex-end' }}>
                {/* --- Download Edit Reports ----- */}
                {downloadEditReportsBtn}
                <DataCardsControl useIntersectionObserver={true} />
                {approveInvoiceBtn}
              </Box>

              <HeaderActionButtons />
            </Box>
            {/*             ** Notifications             */}
            <Box className={classes.notifications}>
              <Box>
                {invoice.largePayment && (
                  <Typography className={classes.largePayment}>
                    This invoice contains a large payment.
                  </Typography>
                )}
              </Box>
            </Box>

            <UploadFilesModal />
            <ResubmitInvoiceModal />
            <DigitalEditsCommentsLite />
            <ApproveInvoiceModal invoice={invoice} />
            <DownloadEditReports invoice={invoice} />
            {/*                         ** info             */}
            <Box className={classes.info}>
              <HeaderCommentCard
                comments={comments}
                resubmitLoading={resubmitLoading}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: comments.length > 0 ? 'column' : 'row',
                  flexGrow: comments.length > 0 ? 1 : 1,
                  gap: '12px',
                }}
              >
                <DataCard
                  mainCard={true}
                  data={invoiceDetails.details}
                  isAllCurrency={false}
                />
                <DataCard
                  mainCard={true}
                  data={invoiceDetails.grossDetails}
                  isAllCurrency={false}
                />
              </Box>
            </Box>

            <CommentModal />
          </React.Fragment>

          <FadeInScroll customThreshold={240}>
            {/* Digital Edits - StickyHeader */}
            <Paper sx={{ width: stickyHeaderWidth }}>
              <Box className={classes.stickyHeader}>
                <Button
                  variant="text"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  startIcon={<ArrowUpwardIcon />}
                >
                  Back to Top
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {downloadEditReportsBtn}
                  <DataCardsControl />
                  {approveInvoiceBtn}
                </Box>
              </Box>
              <Box className={classes.stickyHeader}>
                <Box className={classes.stickyHeaderText}>
                  <Box sx={{ fontWeight: 500 }}>{projectDetails.name} </Box>
                  <Box>Invoice: {invoice.code}</Box>
                  <Box
                    className={clipChargeSticky ? classes.clipTextSticky : ''}
                  >
                    <Tooltip
                      arrow
                      placement="bottom-start"
                      title={invoice.chargeDescription || ''}
                    >
                      <span>{invoice.chargeDescription || ''}</span>
                    </Tooltip>
                  </Box>
                </Box>
                <Box>
                  <HeaderActionButtons noBorder={true} />
                </Box>
              </Box>
            </Paper>
          </FadeInScroll>
        </Paper>
      ) : (
        <Box sx={{ height: '300px', width: '90%', padding: '20px' }}>
          <Skeleton width="50%" height="40px">
            <Typography>.</Typography>
          </Skeleton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '250px',
            }}
          >
            <Skeleton variant="rounded" height="100%" width="30%" />
            <Skeleton variant="rounded" height="100%" width="30%" />
            <Skeleton variant="rounded" height="100%" width="30%" />
          </Box>
        </Box>
      )}
    </div>
  );
};

Header.propTypes = {
  currentInvoiceId: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  invoiceDetails: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onNavToInvoices: PropTypes.func.isRequired,
  isUpdatingStatus: PropTypes.bool.isRequired,
  resubmitLoading: PropTypes.bool.isRequired,
  projectDetails: PropTypes.object.isRequired,
  onNavToStudio: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  hasUnsubmitted: PropTypes.bool.isRequired,
  acEditRecord: PropTypes.string,
};

export default compose(connect(mapState, mapDispatch))(Header);
