import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// actions
import { impersonate } from 'feature/CrewList/actions';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import { userProps } from 'components/props/profiles';
// selectors
import { getEditingUser } from 'feature/CrewList/selectors';

const onSubmit = (values, dispatch) => {
  dispatch(impersonate());
};

const mapStateToProps = state => ({
  user: getEditingUser(state),
});

export const style = () => ({
  root: {},
});

class Impersonate extends Component {
  static propTypes = {
    user: userProps,
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { user, classes, handleSubmit } = this.props;

    const content = (
      <div>
        <Typography className={classes.content}>
          {`Are you sure you want to impersonate user ${user.fullName}?`}
        </Typography>
      </div>
    );
    const title = <Typography variant="h6">{'Impersonate User'}</Typography>;

    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Impersonate"
          buttonType="primary"
          raisedButton
          {...this.props}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'impersonate', maxWidth: 'md' }),
  connect(mapStateToProps),
  reduxForm({
    form: 'Impersonate',
    onSubmit,
  }),
)(Impersonate);
