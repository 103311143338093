import _ from 'lodash';

export const getFlags = state => _.get(state, 'flags.list', []);
export const getLoading = state => _.get(state, 'flags.loading', false);

export const isFlagEnabled = (state, name) => {
  const flag = _.find(state.flags.list, flag => flag.name === name);

  return (flag && flag.enabled) || false;
};

//TODO HOUR-11661
export const getUseNewNavBar = state => {
  const flag = _.find(state.flags.list, flag => flag.name === 'nav_bar');
  if (flag) {
    return flag.enabled;
  } else {
    return false;
  }
};
