import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
  all,
  race,
  take,
  delay,
} from 'redux-saga/effects';
import _ from 'lodash';
import { reset, change } from 'redux-form';
import { push } from 'connected-react-router';

//Actions
import * as actions from 'actions/moveTimecards';
import {
  clearTimecard,
  loadingTimecardsInDrawer,
  setLoadingNewBatch,
  fetchTimecardsInBatch,
  updateWTCTimecardIdsPostMove,
  multiBatchInit,
  setCurrentBatchWorksightId,
} from 'actions/wtc';
import { fetchTimecardsInBatch as fetchWTCTimecardsInBatch } from 'sagas/wtc';
import { hide as hideModal } from 'actions/modalDialog';
import { showAlert, hideAlert } from 'actions/alert';
import { signalRJobMoveComplete } from 'actions/events';

//Selectors
import {
  getMoveJobInfo,
  getSourceBatch,
  getMoveBatchTcs,
} from 'selectors/moveTimecards';
import {
  getTimecardsInDrawer,
  getCurrentTimecardHeaderId,
  getIsMultiBatch,
  getLoadingNewBatch,
} from 'selectors/wtc';
import { getProject } from 'selectors/routeParams';
import { getModalParams } from 'selectors/modalDialog';
import { currentUser } from 'selectors/session';
import { getSettings } from 'selectors/settings';

// utils
import { validateSourceBatch, WTC_FORM_NAME } from 'utils/wtcWeekUtils';
import { delayOnValueObj } from 'utils/helperFunctions';
import { fetchBatchInfo as fetchBatchInfoWTC } from 'sagas/wtc';

import { UPM, PA } from 'components/props/profiles';

/**
 * Called on init and on moveComplete
 */
function* initMoveModal(api, debug, params) {
  try {
    const projectId = yield select(getProject);

    const sourceBatch = yield getSourceBatchInfo(api, debug);
    const batchId = sourceBatch.id;

    yield put(actions.fetchTCbyBatchID({ batchId, projectId }));
    yield put(actions.fetchDestinationInvoices({ batchId }));
    yield put(actions.fetchDestinationBatches({ batchId }));
  } catch (error) {
    debug(error);

    yield put(showAlert());
    yield put(actions.setMoveLoading({ value: false }));
  }
}

function* getSourceBatchInfo(api, debug, params) {
  //on refresh, data will already be in sourceBatch
  let sourceBatch = yield select(getSourceBatch);

  if (validateSourceBatch(sourceBatch)) {
    return sourceBatch;
  }

  const modalParams = yield select(getModalParams, 'moveTimecardsToBatch');
  const { sourceBatch: sourceBatchParam } = modalParams;
  if (validateSourceBatch(sourceBatchParam)) {
    sourceBatch = _.cloneDeep(sourceBatchParam);
    yield put(actions.storeSourceBatch({ sourceBatch }));
  } else {
    //check sourceBatch

    //Fetch full batch info
    const { worksightId, id } = sourceBatchParam;
    yield put(actions.storeSourceBatch({ sourceBatch: {} }));
    yield put(actions.fetchSourceBatchInfo({ worksightId, batchId: id }));
    const result = yield delayOnValueObj(getSourceBatch, { timeout: 5000 });
    sourceBatch = _.cloneDeep(result);

    yield put(actions.storeSourceBatch({ sourceBatch }));
  }

  return sourceBatch;
}

/**
 *
 * @param {number} batchId - optional (this is the htgBatchNumber id (number))
 * @param {string} worksightId  - required if no batchId
 */
function* fetchSourceBatchInfo(api, debug, params) {
  try {
    const projectId = yield select(getProject);

    let { worksightId } = params;
    let { batchId } = params;
    const moveJobInfo = yield select(getMoveJobInfo);
    // on selection of 'go to batch' from move successful snackbar,
    // worksight id loading old one instead of new
    // updating new worksightId from getMoveJobInfo
    if (!_.isEmpty(moveJobInfo)) {
      worksightId = moveJobInfo.destinationBatchId;
    }

    let data;
    // UPM's can't use the fetchBatchInfo call
    // but most of the info there is only needed for move which is a PA only feature
    const activeUser = yield select(currentUser);
    const userRole = activeUser && activeUser.role;

    if (!batchId || userRole === UPM) {
      let tempBatchInfo = yield call(api.wtc.reviewBatch, {
        projectId,
        worksightId,
      });
      //only have worksightId, but need H+ id for more info
      const { id } = tempBatchInfo;
      batchId = id;
      if (userRole === UPM) {
        data = tempBatchInfo;
        data.htgBatchNumber = data.htgId;
      }
    }

    if (userRole === PA) {
      data = yield call(api.moveTimecards.fetchBatchInfo, {
        projectId,
        batchId,
      });
    }

    if (data?.invoice?.id) {
      data.invoiceId = data.invoice.id;
      delete data.invoice;
    }

    yield put(actions.storeSourceBatch({ sourceBatch: data }));
  } catch (error) {
    debug(error);
  }
}

function* fetchTCbyBatchID(api, debug, params) {
  try {
    yield put(actions.setMoveLoading({ loadType: 'timecards', value: true }));

    const result = yield call(api.reviews.timecardsByReviewBatchId, {
      projectId: params.projectId,
      type: params.reviewType || 'open',
      reviewBatchId: params.batchId,
    });

    yield put(actions.setMoveBatchTCs({ batchMoveTcs: result }));
    yield put(actions.setMoveLoading({ loadType: 'timecards', value: false }));
  } catch (e) {
    yield put(actions.setMoveLoading({ loadType: 'timecards', value: false }));

    debug(e);
  }
}

export function* fetchDestinationInvoices(api, debug, params) {
  try {
    yield put(actions.setMoveLoading({ loadType: 'invoices', value: true }));
    const projectId = yield select(getProject);
    let batchId = params.batchId;

    if (!batchId) {
      const sourceBatch = yield select(getSourceBatch);
      batchId = sourceBatch.id;
    }

    let result = yield call(api.moveTimecards.destinationInvoices, {
      projectId,
      batchId,
    });
    const destinationInvoices = result?.invoices || [];
    yield put(actions.storeDestinationInvoices({ destinationInvoices }));
    yield put(actions.setMoveLoading({ loadType: 'invoices', value: false }));
  } catch (e) {
    debug(e);
    yield put(actions.setMoveLoading({ loadType: 'invoices', value: false }));
    if (e.data?.Message === 'H+ batch not found') {
      //TODO this is a temp patch for when the batch is deleted after the move that touches a little code as possible and doesn't show errors to the user
      // Should be refactored to properly redirect to timecards page when the batch is empty or deleted after move
      console.error('Fetch destination invoices error: ', e.data?.Message);
      yield put(actions.storeDestinationInvoices({ destinationInvoices: [] }));
    } else {
      yield put(showAlert());
    }
  }
}
export function* fetchDestinationBatches(api, debug, params) {
  try {
    yield put(actions.setMoveLoading({ loadType: 'batches', value: true }));

    const projectId = yield select(getProject);
    let { batchId } = params;

    const sourceBatch = yield select(getSourceBatch);

    if (!batchId) batchId = sourceBatch.id;

    let data = yield call(api.moveTimecards.destinationBatches, {
      projectId,
      batchId,
    });

    yield put(
      actions.storeDestinationBatches({ destinationBatches: data.batches }),
    );
    yield put(actions.setMoveLoading({ loadType: 'batches', value: false }));
  } catch (e) {
    debug(e);
    yield put(actions.setMoveLoading({ loadType: 'batches', value: false }));
    if (e.data?.Message === 'H+ batch not found') {
      //TODO this is a temp patch for when the batch is deleted after the move that touches a little code as possible and doesn't show errors to the user
      // Should be refactored to properly redirect to timecards page when the batch is empty or deleted after move
      console.error('Fetch destination batches error: ', e.data?.Message);
      yield put(actions.storeDestinationBatches({ destinationBatches: [] }));
    } else {
      yield put(showAlert());
    }
  }
}

export function* moveTimecards(api, debug, params) {
  try {
    yield put(actions.setMoveLoading({ loadType: 'submitting', value: true }));
    const projectId = yield select(getProject);
    const moveBatchTcs = yield select(getMoveBatchTcs);
    const settings = yield select(getSettings);
    const {
      sourceBatchWorksightId,
      batch,
      invoice,
      timecardEntryIds,
      voidEmptyInvoice,
      deleteEmptyBatch,
    } = params;
    const tcWTCLayoutArray = timecardEntryIds.map(tcEntryHeaderId => {
      const tc = moveBatchTcs.find(
        moveTc => moveTc.entryHeaderId === tcEntryHeaderId,
      );
      return {
        timecardEntryId: tc.entryHeaderId,
        wtcLayoutName: tc.wtcLayoutName || settings?.wtcLayout?.label || null,
      };
    });
    const data = {
      batch: batch,
      invoice: invoice,
      timecardEntryIds: timecardEntryIds,
      voidEmptyInvoice: !!voidEmptyInvoice,
      deleteEmptyBatch: !!deleteEmptyBatch,
      tcWTCLayoutArray: tcWTCLayoutArray,
    };

    const result = yield call(api.moveTimecards.moveTimecards, {
      projectId,
      data,
    });

    if (result !== null) {
      const moveJobInfo = {
        jobId: result.jobId,
        destinationBatchId: result.batch.id,
        sourceBatchWorksightId,
        timecardEntryIds: timecardEntryIds,
      };
      /* Post move checklist: Success -
       * wtc: refetch source batch
       * reset form
       */

      yield put(actions.setMoveJobInfo({ moveJobInfo }));

      yield race({
        timeout: call(moveTimeout),
        completed: take(`${signalRJobMoveComplete}`),
      });
    }
  } catch (e) {
    yield put(hideModal({ dialog: 'ConfirmMoveTimecard' }));
    yield put(actions.setMoveLoading({ loadType: 'submitting', value: false }));
    debug(e);
    if (e?.data?.Message) {
      yield put(
        showAlert({
          message: e.data.Message,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

function* moveTimeout() {
  const seconds = 120;
  yield delay(seconds * 1000); //2min
  const message = `Move timecard timeout after ${seconds} seconds`;
  yield put(showAlert({ message }));
  console.warn(message);
  yield put(actions.setMoveLoading({ loadType: 'submitting', value: false }));
}

function* moveComplete(api, debug, params) {
  try {
    yield delay(750);
    yield put(actions.setMoveDone());
    const projectId = yield select(getProject);
    const { jobId: incomingJobId, isSuccessful, timecardHeaderIds } = params;

    const moveJobInfo = yield select(getMoveJobInfo);

    const { destinationBatchId, jobId, timecardEntryIds } = moveJobInfo;

    if (jobId !== incomingJobId) {
      // these aren't the droids we're looking for, you can go about your business
      return;
    }

    if (!isSuccessful) {
      yield put(
        actions.setMoveLoading({ loadType: 'submitting', value: false }),
      );
      yield put(
        showAlert({
          message:
            'An error occurred. If the problem persists, please contact support.',
          variant: 'error',
        }),
      );
      return;
    }

    const isMultiBatch = yield select(getIsMultiBatch);

    if (isMultiBatch) {
      yield put(updateWTCTimecardIdsPostMove({ timecardHeaderIds }));
    }

    yield put(
      showAlert({
        message: 'Move successful',
        variant: 'success',
        action: {
          batchWorksightId: destinationBatchId,
          link: true,
          projectId: projectId,
        },
      }),
    );

    yield put(actions.initMoveModal());

    const currentTimecardId = yield select(getCurrentTimecardHeaderId);
    if (timecardEntryIds?.includes(currentTimecardId)) {
      yield put(clearTimecard());
      yield put(reset(WTC_FORM_NAME));
    }

    yield put(hideModal({ dialog: 'ConfirmMoveTimecard' }));
    yield put(reset('moveTimecardsToBatch'));
    yield put(change('moveTimecardsToBatch', 'submitted', true));
    yield put(actions.setMoveLoading({ loadType: 'submitting', value: false }));
  } catch (error) {
    yield put(actions.setMoveLoading({ loadType: 'submitting', value: false }));
    yield put(showAlert());
    debug(error);
  }
}

export function* goToNewBatch(api, debug, params) {
  try {
    yield put(setLoadingNewBatch({ loadingNewBatch: true }));
    const projectId = yield select(getProject);
    yield put(
      setCurrentBatchWorksightId({
        currentBatchWorksightId: params.batchWorksightId,
      }),
    );
    let toURL = `/projects/${projectId}/review/open/${params.batchWorksightId}/wtc`;
    yield put(push(toURL));
    yield put(hideModal({ dialog: 'moveTimecardsToBatch' }));
    const batchParams = {
      worksightId: params.batchWorksightId,
      newBatch: true,
    };
    if (window.location.pathname.includes('/wtc')) {
      yield put(loadingTimecardsInDrawer({ loadingDrawerTimecards: true }));
      yield put(clearTimecard());
      yield delay(1500);
      yield put(reset(WTC_FORM_NAME));
      yield put(setLoadingNewBatch({ loadingNewBatch: false }));
      yield put(fetchTimecardsInBatch(batchParams));
      yield call(fetchBatchInfoWTC, api, debug, batchParams);
      yield put(hideAlert());
      yield put(loadingTimecardsInDrawer({ loadingDrawerTimecards: false }));
    }
  } catch (error) {
    debug(error);
  }
}
export function* moveModalClose(api, debug, params) {
  try {
    const moveJobInfo = yield select(getMoveJobInfo);

    if (_.isEmpty(moveJobInfo)) {
      //no move done, just close modal without refreshing
      return; //Reminder: the finally {} still runs
    }

    const isGoingToNewBatch = yield select(getLoadingNewBatch);
    //New batch behavior handled in that saga
    if (isGoingToNewBatch) return;

    const { sourceBatchWorksightId } = moveJobInfo;

    if (window.location.pathname.includes('/wtc')) {
      const isMultiBatch = yield select(getIsMultiBatch);
      if (isMultiBatch) {
        yield put(multiBatchInit());
      } else {
        //block on fetching new WTC timecards
        yield call(fetchWTCTimecardsInBatch, api, debug, {
          worksightId: sourceBatchWorksightId,
        });
      }

      const timecards = yield delayOnValueObj(getTimecardsInDrawer, {
        timeout: 250,
      });
      const isSourceBatchEmpty = timecards.length === 0;
      //wtc success actions
      if (isSourceBatchEmpty) {
        const projectId = yield select(getProject);
        const toURL = `/projects/${projectId}/review/search-timecards`;
        yield put(push(toURL));
      }
    } else {
      window.location.reload();
    }
  } catch (error) {
    debug(error);
  } finally {
    yield put(reset('moveTimecardsToBatch'));
    yield put(actions.resetMoveModal());
  }
}

export default function* flagsFlow({ api, debug }) {
  yield all([
    takeLatest(`${actions.initMoveModal}`, initMoveModal, api, debug),
    takeLatest(
      `${actions.fetchSourceBatchInfo}`,
      fetchSourceBatchInfo,
      api,
      debug,
    ),
    takeLatest(
      `${actions.fetchDestinationBatches}`,
      fetchDestinationBatches,
      api,
      debug,
    ),
    takeLatest(
      `${actions.fetchDestinationInvoices}`,
      fetchDestinationInvoices,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchTCbyBatchID}`, fetchTCbyBatchID, api, debug),
    takeEvery(`${actions.moveTimecards}`, moveTimecards, api, debug),
    takeEvery(`${actions.moveModalClose}`, moveModalClose, api, debug),
    takeEvery(`${signalRJobMoveComplete}`, moveComplete, api, debug),
    takeLatest(`${actions.onNavToBatchInWTC}`, goToNewBatch, api, debug),
  ]);
}
