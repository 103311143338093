import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro } from '@mui/x-data-grid-pro';

const useStyles = makeStyles(({ palette }) => ({
  DataGridTable: {
    fontWeight: 800,
    marginTop: '16px',
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: palette.primary['+8'],
      color: palette.primary.main,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 800,
    },
    '& .MuiDataGrid-cell': {
      border: `1px solid ${palette.primary['+7']}`,
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: palette.primary['+9'],
    },
    // odd row styling
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: palette.grey[50],
    },
    '& .MuiDataGrid-cell--editing': {
      backgroundColor: `${palette.misc.cellHighlight} !important`,
    },
  },
  editable: {
    fontWeight: 500,
  },
}));

/**
 *DataGridTable - A wrapper for the MUI DataGridPro component to handle editing and unsaved changes
 in a way consistent with H+ standards, namely tab/enter navigation.

 Currently semi hardwired for ACE editing, but could be made more generic in the future.
 **********************************************************************
 **********************************************************************
 **********************************************************************
 Expects the edit mode cells to call apiRef.current.update({id, field, value}) to update the table data.
 Saves changes in the unsavedChangesRef object, which is passed in as a prop.
 Update can be over written by passing in an apiRef with an update function already on it.
 *
 */
const DataGridTable = props => {
  const classes = useStyles();
  const { rows = [], columns = [], unsavedChangesRef, dataGridProps } = props;

  //optional props
  const {
    rowHeight = 25,
    columnHeaderHeight = 32,
    editable = true,
    hideEmptyCols = false,
  } = props;

  let { apiRef } = props;

  //resize columns, but only 1x every 200ms in case we get multiple resize events in a row
  // const resizePendingRef = React.useRef(false);
  // const resize = React.useCallback(
  //   (timeout = 150) => {
  //     if (apiRef.current) {
  //       if (resizePendingRef.current) clearTimeout(resizePendingRef.current);
  //       resizePendingRef.current = setTimeout(() => {
  //         apiRef.current.autosizeColumns({
  //           includeHeaders: true,
  //           includeOutliers: apiRef.current.editable,
  //           // outliersFactor: 4,
  //           expand: false,
  //         });
  //       }, timeout);
  //     }
  //   },
  //   [apiRef],
  // );

  //
  //Central update function.
  // const update = React.useCallback(
  //   ({ id, field, value, cta = false }) => {
  //     db('Update', id, field, value);
  //     if (apiRef.current.editable) {
  //       let row = _.cloneDeep(apiRef.current.getRow(id));
  //       let subRows = [];
  //       if (cta) {
  //         const rowModels = apiRef.current.getRowModels();
  //         subRows = Array.from(rowModels.values()).filter(
  //           r => ctaFilter(r) && r.id !== id,
  //         );
  //       } else if (row?.subRowIds?.length > 0) {
  //         row.subRowIds.forEach(sr => {
  //           const subRow = _.cloneDeep(apiRef.current.getRow(sr));
  //           subRows.push(subRow);
  //         });
  //       }

  //       const values = {};
  //       values[field] = _.cloneDeep(value);

  //       //Add cascading changes here
  //       if (field === 'episode') {
  //         if (value?.series) {
  //           values.series = value.series;
  //         }
  //         if (value?.location) {
  //           values.location = value.location;
  //         }
  //       }

  //       //update unsaved changes ref
  //       updateUnsavedChanges({
  //         unsavedChangesRef,
  //         values,
  //         rows: [row, ...subRows],
  //       });

  //       const rows = [{ ...row, ...values }];
  //       if (subRows.length > 0) {
  //         subRows.forEach(subRow => {
  //           rows.push({ ...subRow, ...values });
  //         });
  //       }
  //       //update table data
  //       apiRef.current.setEditCellValue({ id, field, value });
  //       apiRef.current.updateRows(rows);
  //       db('Unsaved Changes');
  //       db(unsavedChangesRef.current);
  //     }
  //   },
  //   [apiRef, ctaFilter, unsavedChangesRef],
  // );

  const [columnVisibility, setColumnVisibility] = React.useState({});

  React.useEffect(() => {
    setColumnVisibility(cv => {
      const newCV = {};
      if (editable || !hideEmptyCols) {
        columns.forEach(c => (newCV[c.field] = true));
        return newCV;
      } else {
        columns.forEach(c => (newCV[c.field] = false));
        rows.forEach(row => {
          const fields = Object.keys(row).filter(k => newCV[k] === false);
          fields.forEach(k => {
            if (row[k]) {
              newCV[k] = true;
            }
          });
        });
        return newCV;
      }
    });
  }, [apiRef, columns, rows, editable, unsavedChangesRef, hideEmptyCols]);

  React.useEffect(() => {
    if (apiRef.current) {
      apiRef.current.editable = editable;
      apiRef.current.unsavedChangesRef = unsavedChangesRef;
    }
  });

  React.useEffect(() => {
    if (apiRef.current) {
      // Need to up date the sort model when data updates if not sorting data outside of grid
      // apiRef.current.setSortModel([{ field: 'earnedDate', sort: 'asc' }]);
    }
  }, [apiRef, rows]);

  if (!rows || rows.length === 0) return null;

  return (
    <DataGridPro
      apiRef={apiRef}
      disableColumnFilter
      className={clsx(
        classes.DataGridTable,
        editable ? classes.editable : null,
      )}
      columnHeaderHeight={columnHeaderHeight}
      rowHeight={rowHeight}
      rows={rows}
      columns={columns}
      disableColumnReorder={true}
      rowSelection={false}
      hideFooter={true}
      onCellEditStart={(params, e, { api }) => {
        if (!api.editable) {
          //don't allow editing if table is not editable
          // this should be covered by other checks, but just in case
          e.defaultMuiPrevented = true;
        }
      }}
      onCellKeyDown={(params, e) => {
        //Tab
        if (editable) {
          if (e.key === 'Tab') {
            const rowIds = apiRef.current.getAllRowIds();
            const visibleColumns = apiRef.current.getVisibleColumns();
            e.preventDefault();
            const currRowIndex = rowIds.findIndex(id => id === params.id);
            const currColIndex = apiRef.current.getColumnIndex(params.field);
            const nextCell = {
              rowIndex: currRowIndex,
              columnIndex: currColIndex,
              valid: false,
            };

            while (!nextCell.valid) {
              if (!e.shiftKey) {
                if (nextCell.columnIndex < visibleColumns.length - 1) {
                  nextCell.columnIndex += 1;
                } else {
                  nextCell.rowIndex += 1;
                  nextCell.columnIndex = 0;
                }
              } else {
                if (nextCell.columnIndex > 0) {
                  nextCell.columnIndex -= 1;
                } else {
                  nextCell.rowIndex -= 1;
                  nextCell.columnIndex = visibleColumns.length - 1;
                }
              }
              const colDef = visibleColumns[nextCell.columnIndex] || {};

              const id = rowIds[nextCell.rowIndex];
              const field = colDef?.field;
              const editParams = {
                cellMode: apiRef.current.getCellMode(id, field),
                colDef,
                field,
                id,
                row: apiRef.current.getRow(id),
                rowNode: apiRef.current.getRowNode(id),
              };

              if (
                _.isEmpty(colDef) === false &&
                colDef?.field &&
                apiRef.current.isCellEditable(editParams)
              ) {
                nextCell.valid = true;
              }
            }

            const colDef = visibleColumns[nextCell.columnIndex] || {};
            apiRef.current.scrollToIndexes(nextCell);
            const { field } = colDef;
            const id = rowIds[nextCell.rowIndex];

            if (
              field &&
              id &&
              (currColIndex !== nextCell.columnIndex ||
                currRowIndex !== nextCell.rowIndex)
            ) {
              apiRef.current.setCellFocus(id, field);
              apiRef.current.startCellEditMode({ id, field });
            }
          }
          if (e.key === 'Enter') {
            const rowIds = apiRef.current.getAllRowIds();
            e.preventDefault();
            const currRowIndex = rowIds.findIndex(id => id === params.id);
            const nextCell = {
              rowIndex: currRowIndex,
            };
            const getNextCellIndex = (index, increment = 1) => {
              const nextCellIndex = index + increment;
              const row = apiRef.current.getRow(rowIds[nextCellIndex]);
              if (row) {
                if (row.isEditable) {
                  return nextCellIndex;
                } else {
                  return getNextCellIndex(nextCellIndex, increment);
                }
              } else {
                return currRowIndex;
              }
            };
            if (!e.shiftKey) {
              const nextIndex = getNextCellIndex(nextCell.rowIndex);
              if (nextIndex < rowIds.length) {
                nextCell.rowIndex = nextIndex;
              }
            } else {
              const nextIndex = getNextCellIndex(nextCell.rowIndex, -1);
              if (nextIndex >= 0) {
                nextCell.rowIndex = nextIndex;
              }
            }
            apiRef.current.scrollToIndexes(nextCell);
            const id = rowIds[nextCell.rowIndex];

            e.defaultMuiPrevented = true;
            if (currRowIndex !== nextCell.rowIndex) {
              apiRef.current.setCellFocus(id, params.field);
              apiRef.current.startCellEditMode({ id, field: params.field });
            }
          }
        }
      }}
      columnVisibilityModel={columnVisibility}
      onColumnVisibilityModelChange={e => {
        setColumnVisibility(e);
      }}
      {...dataGridProps}
    />
  );
};

DataGridTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  unsavedChangesRef: PropTypes.object.isRequired,
  //optionalProps
  apiRef: PropTypes.object,
  rowHeight: PropTypes.number,
  columnHeaderHeight: PropTypes.number,
  editable: PropTypes.bool,
  dataGridProps: PropTypes.shape({
    disableColumnMenu: PropTypes.bool,
    getRowClassName: PropTypes.func,
  }),
  hideEmptyCols: PropTypes.bool,
};

export default DataGridTable;
