import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'utils/helperFunctions';
// components
import { PageHeader } from 'components/Nav';
import { NavItem } from 'components/Shared/Nav';

//selector
import { getIsProjectArchived } from 'selectors/project';

const mapState = (state, { match, history }) => ({
  projectId: match.params.projectId,
  location: history.location,
  isProjectArchived: getIsProjectArchived(state),
});

class ReportPageHeader extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const { projectId, location, isProjectArchived } = this.props;

    const buttons = [
      <NavItem
        alternate
        showIndicator
        location={location}
        key={`/projects/${projectId}/reports/ondemand`}
        linkTo={`/projects/${projectId}/reports/ondemand`}
        navLabel="On Demand"
      />,
    ];

    if (!isProjectArchived) {
      buttons.push(
        <NavItem
          alternate
          showIndicator
          location={location}
          key={`/projects/${projectId}/reports/schedule`}
          linkTo={`/projects/${projectId}/reports/schedule`}
          navLabel="Schedule"
        />,
      );
    }

    return <PageHeader navItems={buttons} />;
  }
}

export default compose(withRouter(connect(mapState)(ReportPageHeader)));
