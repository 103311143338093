import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

export const style = ({ palette }) => ({
  root: {
    clear: 'both',
    display: 'block',
    padding: [8, 20],
    minHeight: 20,
    textAlign: 'left',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    background: palette.gray['+9'],
    fontSize: 14,
    color: palette.gray['+3'],
    '&:hover': {
      background: palette.gray['+7'],
    },
    borderBottom: [1, 'solid', palette.gray['+6']],
    cursor: 'pointer',
    verticalAlign: 'middle',
  },

  container: {
    cursor: 'initial',
    '&:hover': {
      background: palette.gray['+6'],
    },
  },

  menuTitle: {
    cursor: 'default',
    display: 'block',
    textAlign: 'left',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    paddingTop: 2,
    paddingBottom: 2,
  },

  titleElement: {
    extend: 'menuTitle',
    fontSize: '1.3333em',
  },

  secondatyElement: {
    extend: 'menuTitle',
    fontSize: '1em',
    fontWeight: 300,
    color: palette.gray['+0'],
  },
});

class MenuItem extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    container: PropTypes.bool,
    linkTo: PropTypes.string,
  };

  static defaultProps = {
    linkTo: undefined,
    container: false,
  };

  render() {
    const { classes, children, linkTo, container, url } = this.props;

    const menuItemCls = clsx(classes.root, {
      [classes.container]: container,
    });

    if (url) {
      return (
        <Typography className={menuItemCls}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        </Typography>
      );
    }
    if (linkTo) {
      return (
        <Link to={linkTo} className={menuItemCls}>
          {children}
        </Link>
      );
    }

    return <div className={menuItemCls}>{children}</div>;
  }
}

export default withStyleSheet('MenuItem', style)(MenuItem);
