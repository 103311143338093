import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Toolbar } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { currentUser, token } from 'selectors/session';
import { getProjectDetails } from 'selectors/project';
import { storeProject } from 'actions/routeParams';
import { fetchDetails } from 'actions/project';
import { AppBar } from 'components/Nav';
import { projectProps } from 'components/props/projects';
import { profileProps } from 'components/props/profiles';
import { ProfileNav } from '../Nav';

export const style = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: 'inherit',
  },
  brand: {
    paddingLeft: 24,
  },
  navItems: {
    order: 0,
    flex: '1 1 auto',
    alignSelf: 'auto',
  },
});

const mapStateToProps = state => ({
  isLoggedIn: !!token(state),
  currentUser: currentUser(state),
  project: getProjectDetails(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onSetProject: () => {
    dispatch(storeProject({ projectId: match.params.projectId }));
  },
  onFetchData: () => {
    dispatch(fetchDetails());
  },
});

//TODO - remove this component after cnc GlobalNav is implemented permanently
//TODO HOUR-11661

class ProfileHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    project: projectProps,
    currentUser: profileProps,
    isLoggedIn: PropTypes.bool,
    onFetchData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    project: undefined,
    isLoggedIn: false,
    currentUser: undefined,
  };

  componentDidMount() {
    const { onFetchData, project, onSetProject } = this.props;
    onSetProject();

    //only fetch data if we don't have project info
    if (!project || _.isEmpty(project)) onFetchData();
  }

  render() {
    const {
      classes,
      currentUser: loggedInUser,
      isLoggedIn,
      project,
    } = this.props;

    let isAdminUser = false;

    if (isLoggedIn && loggedInUser) {
      isAdminUser = loggedInUser.isAdmin;
    }

    return (
      <AppBar secondary>
        <Toolbar>
          <Grid
            container
            className={classes.root}
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Grid item md={4} xs />
            <Grid className={classes.navItems} item xs={8}>
              {isLoggedIn && project && (
                <ProfileNav isAdminUser={isAdminUser} />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyleSheet('SubHeader', style)(ProfileHeader)),
);
