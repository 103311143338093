import FileDownload from 'js-file-download';
import { buildUrl } from '../api';

const DOWNLOAD_TIMECARDS_REPORT_URL = `reports/projects/:projectId/downloadTimecards`;
const DOWNLOAD_BATCH_REPORT_URL = `reports/projects/:projectId/batches/:batchId`;
const DOWNLOAD_INVOICE_EDITS_FILE =
  '/projects/:projectId/digitalEdit/:documentId/downloadDocument';

const DOWNLOAD_EXPRESS_FORM_URL = `/projects/:projectId/digitalEdit/downloadForm/:code`;

const OPTIONS = { timeout: 180000 };

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async downloadInvoiceFile({ projectId, documentId, fileName }) {
    const url = buildUrl(DOWNLOAD_INVOICE_EDITS_FILE, {
      projectId,
      documentId,
    });
    const response = await clientV2.get(url);
    manualTriggerDownload({ filename: fileName, response });
  },
  async downloadTimecardsReport({ projectId, payload }) {
    const url = buildUrl(DOWNLOAD_TIMECARDS_REPORT_URL, { projectId });
    const response = await clientV2.post(url, payload, {
      ...OPTIONS,
    });
    manualTriggerDownload({ downloadUrl: null, response });
    return response;
  },
  async downloadBatchReport({ projectId, batchId, filename }) {
    const url = buildUrl(DOWNLOAD_BATCH_REPORT_URL, { projectId, batchId });
    const response = await clientV2.get(url, {
      ...OPTIONS,
    });
    // TO DO: We dont need to pass filename as the Url has the filename in it.
    manualTriggerDownload({ downloadUrl: null, filename, response });
    return response;
  },
  async downloadFromURI({ endpoint, filename }) {
    const response = await clientV2.get(endpoint, OPTIONS);
    manualTriggerDownload({ response, filename });
    return response;
  },
  async downloadFile({ endpoint, filename }) {
    const response = await clientV2.get(endpoint, OPTIONS);
    FileDownload(response.data, filename);
  },

  async downloadFileExcel({ endpoint, filename, type }) {
    const response = await clientV2(endpoint, {
      responseType: 'arraybuffer',
      ...OPTIONS,
    });
    if (response && response.data && response.data.byteLength > 0) {
      type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      FileDownload(response.data, filename, type);
      return response;
    } else {
      const hotCostNoDept =
        type === 'HotCostReport' &&
        response.status > 400 &&
        response.data &&
        response.data.includes('no departments');

      const err = {
        variant: 'warning',
        message:
          type === 'UserAccessReport'
            ? 'No records found or Client does not exists'
            : type === 'HotCostReport'
            ? hotCostNoDept
              ? 'You have no departments permissioned'
              : 'There are no timecards for the selected day'
            : 'No records found',
      };

      throw err;
    }
  },

  async checkTcWithMissingFields({ endpoint }) {
    const response = await clientV2.post(endpoint);
    return response.data;
  },

  async downloadFromURIPost({ endpoint, data, filename }) {
    const response = await clientV2.post(endpoint, data, {
      responseType: 'blob',
      ...OPTIONS,
    });

    var newBlob = new Blob([response.data], { type: 'application/pdf' });

    var downloadUrl = URL.createObjectURL(newBlob);
    manualTriggerDownload({ downloadUrl, filename, response });
  },

  // downloading files from studio plus aws S3 bucket url so using fetch
  async downloadFromURIGet({ endpoint, filename }) {
    const response = await fetch(endpoint).then(resp => resp.blob());
    var newBlob = new Blob([response], { type: 'application/pdf' });
    var downloadUrl = URL.createObjectURL(newBlob);
    manualTriggerDownload({ downloadUrl, filename, response });
  },
  async downloadFixedFile({ projectId, code }) {
    const url = buildUrl(DOWNLOAD_EXPRESS_FORM_URL, {
      projectId,
      code,
    });

    const response = await clientV2.get(url);
    manualTriggerDownload({ response });
  },
});

const manualTriggerDownload = ({ response, filename, downloadUrl }) => {
  const url = downloadUrl
    ? downloadUrl
    : response?.data?.fileLocation || response?.data;
  if (!response && !url) throw new Error('missing download link');

  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;

  // setting filename this way only works for 'blob' type downloads.
  // if we're getting a file link from the BE, then the filename is already set
  if (filename) {
    tempLink.setAttribute('download', filename);
  }

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(url);
};
