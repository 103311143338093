import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaPlusCircle as AddIcon } from 'react-icons/fa';
import { withStyleSheet } from 'shared/theme';
import { Paper, Typography, Button, Chip } from '@mui/material';
import { recipientsProps } from 'components/props/emailNotifications';
import CancelIcon from '@mui/icons-material/Cancel';

export const style = ({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
  },
  title: {
    color: palette.text.secondary,
    fontSize: 24,
    fontWeight: 400,
  },
  body: {
    padding: 20,
  },
  paragraph: {
    marginBottom: 20,
    color: palette.text.disabled,
    fontSize: 14,
    fontWeight: 400,
  },
  icon: {
    marginRight: 5,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
    marginTop: 5,
    marginBottom: 10,
  },
  chip: {
    margin: 3,
    height: 24,
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    fontSize: 14,
    fontWeight: 500,
    '& > svg': {
      height: 20,
      margin: [1, 2, 1, -4],
    },
  },
  deleteIcon: {
    color: `${palette.primary.contrastText} !important`,
  },
  button: {
    marginTop: 30,
    float: 'right',
    marginBottom: 24,
  },
  chipEdit: {
    extend: 'chip',
    fontWeight: 500,
  },
  [breakpoints.down('md')]: {
    chips: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    chip: { marginBottom: 5 },
  },
});

class BatchEmailNotifications extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onAddRecipient: PropTypes.func.isRequired,
    onRemoveRecipient: PropTypes.func.isRequired,
    recipients: recipientsProps.isRequired,
  };

  renderRecipients() {
    const { classes, onRemoveRecipient, recipients } = this.props;
    const recipientChips = recipients.map(recipient => (
      <Chip
        className={classes.chip}
        deleteIcon={<CancelIcon className={classes.deleteIcon} />}
        key={recipient.id}
        label={recipient.email}
        onDelete={() => onRemoveRecipient(recipient.id)}
      />
    ));

    return <div className={classes.chips}>{recipientChips}</div>;
  }

  render() {
    const { classes, onAddRecipient } = this.props;
    return (
      <Paper className={classes.root}>
        <div className={classes.body}>
          <Typography className={classes.title}>Email Notifications</Typography>
          <Typography className={classes.paragraph}>
            List of people notified when timecards are ready for
            processing.(Optional)
          </Typography>
          {this.renderRecipients()}
          <Button
            onClick={onAddRecipient}
            className={classes.button}
            color="primary"
            variant="contained"
            size="medium"
          >
            <AddIcon className={classes.icon} />
            {'Add Recipient'}
          </Button>
        </div>
      </Paper>
    );
  }
}

export default withStyleSheet(
  'BatchEmailNotifications',
  style,
)(BatchEmailNotifications);
