import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Button } from '@mui/material';
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// actions
import { hide as hideModal } from 'actions/modalDialog';
import { calculate } from 'actions/bulkEdit';
// selectors
import { projectId } from 'selectors/modalDialog';

import { createPendingCalculationsIgnoreTimeout } from 'utils/bulkEditUtils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minWidth: 500,
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8,

    '&:last-child': {
      marginRight: 4,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    overflow: 'visible',
  },
  warningIcon: {
    color: '#FF7800',
    verticalAlign: 'middle',
  },
  footer: {},
});

const modalDialogName = 'BulkEditTimeout';

const mapState = state => {
  return {
    projectId: projectId(state, modalDialogName),
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hideModal({ dialog: modalDialogName }));
  },
  onGoToBulkEdit: projectId => {
    dispatch(hideModal({ dialog: modalDialogName }));
    dispatch(push(`/projects/${projectId}/review/bulk-edit`));
  },
  onPendingCalculationsIgnoreTimeout: () => {
    dispatch(calculate());
  },
});

function BulkEditTimeout({
  projectId,
  onGoToBulkEdit,
  onClose,
  onPendingCalculationsIgnoreTimeout,
}) {
  const classes = useStyles();

  useEffect(() => {
    createPendingCalculationsIgnoreTimeout(() => {
      onPendingCalculationsIgnoreTimeout();
    }, projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ModalTitle>{'Bulk Edit Calculation Pending'}</ModalTitle>
      <ModalContent className={classes.content}>
        <Typography>
          There are saved changes that have not yet been calculated. These
          changes will be calculated in one minute. If you want to delay
          calculation of these timecards, go back to the bulk edit page.
        </Typography>
        <br />
        <Typography>
          <span className={classes.warningIcon}>
            <WarningIcon />
          </span>{' '}
          These changes will be calculated in 1 minute.
        </Typography>
      </ModalContent>
      <ModalFooter className={classes.footer}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          name="cancel"
          onClick={onClose}
        >
          {'Close Window'}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          type="submit"
          variant="contained"
          onClick={() => onGoToBulkEdit(projectId)}
        >
          {'Go to Bulk Edit'}
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
}

export default compose(
  connect(mapState, mapDispatchToProps),
  withModalDialog({
    dialog: modalDialogName,
    maxWidth: 'sm',
    skipUnmount: false,
  }),
)(BulkEditTimeout);
