import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import clsx from 'clsx';

export const style = ({ palette }) => ({
  root: {
    // border: '1px solid',
    borderRadius: 3,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: 3,
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1.2em',
    color: palette.common.white,
    textDecoration: 'none',
  },
  altWeight: {
    fontWeight: 200,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  secondary: {
    color: palette.primary.main,
  },
  warning: {
    color: palette.warning.main,
  },
  primary: {
    color: palette.secondary.light,
  },
  tertiary: {
    color: palette.gray['+2'],
  },
  error: {
    color: palette.error.main,
  },
});

class Badge extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    warning: PropTypes.bool,
    error: PropTypes.bool,
    uppercase: PropTypes.bool,
    altWeight: PropTypes.bool,
    status: PropTypes.string,
  };

  static defaultProps = {
    primary: false,
    uppercase: false,
    altWeight: false,
    status: '',
  };

  render() {
    const {
      classes,
      children,
      primary,
      secondary,
      tertiary,
      status,
      warning,
      error,
      uppercase,
      altWeight,
    } = this.props;

    const labelCls = clsx(classes.root, {
      [classes.primary]: primary || status === 'primary',
      [classes.uppercase]: uppercase,
      [classes.altWeight]: altWeight,
      [classes.secondary]: secondary || status === 'secondary',
      [classes.tertiary]: tertiary || status === 'tertiary',
      [classes.warning]: warning || status === 'warning',
      [classes.error]: error || status === 'error',
    });

    return <Typography className={labelCls}>{children}</Typography>;
  }
}

export default withStyleSheet('Badge', style)(Badge);
