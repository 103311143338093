import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { replace, push } from 'connected-react-router';
import { formValueSelector, isDirty } from 'redux-form';
import { Tabs, Box, styled, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';

import MobileTimecardTab from './TimecardTab';
import AllowanceTab from './AllowanceTab';
import { CountChip } from '../Shared/styledComponents';
import CommentsTab from '../Shared/CommentTab';
import HistoryTabModal from './HistoryTabModal';

import {
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from 'components/Shared/constants';
import { TAB_NAV_MAP, TAB_NAV_MAP_REVERSE } from '../empTimecardUtils';
import { UNSAVED_CHANGES_MODAL, HISTORY_DIALOG } from '../Modals/modalNames';

//actions
import { show as showModal } from 'actions/modalDialog';
//selectors
import * as sel from '../selectors';

const formSelector = formValueSelector(sel.FORM_NAME);

const useStyles = makeStyles(({ palette }) => ({
  tabs: {
    '&  .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
  tab: {
    borderRadius: '12px',
    margin: '0px',
    padding: '0px',
    height: '20px',
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'capitalize',
    '&.Mui-selected': {
      color: palette.primary.main,
      backgroundColor: palette.primary.table,
      padding: '0px 10px',
    },
  },
  historyIcon: {
    minWidth: '10px',
  },
}));
const mapState = state => ({
  allowanceCount: sel.getAllowanceCount(state),
  commentCount: sel.getCommentCount(state),
  tcStatus: sel.getTimecardStatus(state),
  prevRejected: !!formSelector(state, 'previouslyRejected'),
  disabledBtns: sel.getDisabledBtns(state),
  isFormDirty: isDirty(sel.FORM_NAME)(state),
  newComment: sel.getNewComment(state),
  loading: sel.getLoading(state, 'timecard'),
  saving: sel.getLoading(state, 'saving'),
  rejecting: sel.getLoading(state, 'rejecting'),
});

const mapDispatch = dispatch => ({
  onOpenHistoryModal: () => {
    dispatch(showModal({ dialog: HISTORY_DIALOG }));
  },
  onSetNav: url => dispatch(replace(url)),
  onChangeNav: url => dispatch(push(url)),
  onShowUnsavedModal: ({ oldTabName, newTabName }) =>
    dispatch(
      showModal({
        dialog: UNSAVED_CHANGES_MODAL,
        modalParams: { oldTabName, newTabName },
      }),
    ),
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tc-tab-panel-${index}`}
      aria-labelledby={`tc-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '10px 0px' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tc-tab-${index}`,
    'aria-controls': `tc-tab-panel-${index}`,
  };
}

const TabNavBox = styled(Box)(({ theme }) => ({
  marginLeft: '0px',
  marginTop: '20px',
  borderRadius: '12px',
}));

const makeTabLabel = (label, count) => (
  <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
    {label}
    {!!count ? (
      <CountChip label={count} sx={{ padding: '0px' }} />
    ) : (
      <Box sx={{ p: '12px' }}></Box>
    )}
  </Box>
);

const makeHistoryKebab = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <MoreVertSharpIcon style={{ alignItems: 'start' }} />
      <Box sx={{ p: '12px' }}></Box>
    </Box>
  );
};

const TabNav = props => {
  const classes = useStyles();
  const {
    allowanceCount,
    commentCount,
    tcStatus,
    prevRejected,
    onSetNav,
    onChangeNav,
    disabledBtns,
    isFormDirty,
    newComment,
    onShowUnsavedModal,
    onOpenHistoryModal,
    loading,
    saving,
    rejecting,
  } = props;
  const [currentTab, setCurrentTab] = React.useState(0);
  const { disableOnLoading } = disabledBtns;
  const location = useLocation();

  const changeTab = React.useCallback(
    tabName => {
      const oldTabName = TAB_NAV_MAP_REVERSE[currentTab];
      const newTabName = tabName;

      const isTimecard =
        oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.timecard];

      const isComments =
        oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.comments];

      if (isFormDirty && isTimecard) {
        onShowUnsavedModal({ newTabName, oldTabName });
      } else if (isComments && !!newComment) {
        onShowUnsavedModal({ newTabName, oldTabName });
      } else {
        const url = `${location.pathname}?${newTabName}`;
        onSetNav(url);
        setCurrentTab(TAB_NAV_MAP[newTabName]);
      }
    },
    [
      currentTab,
      isFormDirty,
      location.pathname,
      newComment,
      onSetNav,
      onShowUnsavedModal,
    ],
  );

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const path = location.pathname;

    let initTab,
      didUrlInit = false;

    for (const key in TAB_NAV_MAP) {
      if (queryParams.has(key)) {
        initTab = TAB_NAV_MAP[key];
        didUrlInit = true;
        break;
      }
    }
    if (didUrlInit) {
      setCurrentTab(initTab);
    } else {
      setCurrentTab(TAB_NAV_MAP.timecard);
      const url = `${path}?${TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.timecard]}`;
      onSetNav(url);
    }
  }, [location.pathname, location.search, onSetNav]);

  const statusRef = React.useRef(null);
  React.useEffect(() => {
    if (
      commentCount > 0 &&
      !statusRef.current &&
      ((tcStatus === TIMECARD_INCOMPLETE && prevRejected) ||
        tcStatus === TIMECARD_PENDING_EMP_REVIEW)
    ) {
      const tabNum = TAB_NAV_MAP.comments;
      const tabName = TAB_NAV_MAP_REVERSE[tabNum];
      const url = `${location.pathname}?${tabName}`;
      onChangeNav(url);
    }
    statusRef.current = tcStatus;
  }, [commentCount, location.pathname, onChangeNav, prevRejected, tcStatus]);

  const handleChange = (e, newValue) => {
    const tabName = TAB_NAV_MAP_REVERSE[newValue];
    if (newValue !== TAB_NAV_MAP.history) {
      changeTab(tabName);
    }
  };

  return (
    <TabNavBox>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="timecard tabs"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        className={classes.tabs}
      >
        <Tab
          disabled={disableOnLoading}
          className={classes.tab}
          label={makeTabLabel('Timecard', 0)}
          {...a11yProps(0)}
        />
        <Tab
          disabled={disableOnLoading}
          className={classes.tab}
          label={makeTabLabel('Allowances', allowanceCount)}
          {...a11yProps(1)}
        />
        <Tab
          disabled={disableOnLoading}
          className={classes.tab}
          label={makeTabLabel('Comments', commentCount)}
          {...a11yProps(2)}
        />
        <Tab
          disabled={disableOnLoading}
          className={clsx(classes.tab, classes.historyIcon)}
          label={makeHistoryKebab()}
          {...a11yProps(3)}
          onClick={onOpenHistoryModal}
        />
      </Tabs>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.timecard}>
        <MobileTimecardTab
          loading={loading}
          saving={saving}
          rejecting={rejecting}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.allowances}>
        <AllowanceTab />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.comments}>
        <CommentsTab />
      </TabPanel>
      <HistoryTabModal />
    </TabNavBox>
  );
};

TabNav.propTypes = {
  allowanceCount: PropTypes.number.isRequired,
  commentCount: PropTypes.number.isRequired,
  onOpenHistoryModal: PropTypes.func.isRequired,
  tcStatus: PropTypes.string,
  prevRejected: PropTypes.bool.isRequired,
  onSetNav: PropTypes.func.isRequired,
  onChangeNav: PropTypes.func.isRequired,
  disabledBtns: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  newComment: PropTypes.string,
  onShowUnsavedModal: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(TabNav);
