import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// containers
import DepartmentHead from './DepartmentHead';
import ReviewFlow from './ReviewFlow';
import { EditDepartment, RemoveDepartment } from './Modals';
import { EditReviewers, RemoveReviewer } from './Modals';
import { RemoveApprovalFlow } from './Modals';
// components
import { reviewFlowsProps } from 'components/props/reviewFlows';
// actions
import * as actions from 'actions/reviewFlows';
import { fetch as fetchMasterDepartments } from 'actions/masterDepartments';
import { fetch as fetchProjectUsers } from 'actions/users';

// selectors
import { getProject as project } from 'selectors/routeParams';
import { getReviewFlows, getLoading } from 'selectors/reviewFlows';

const mapStateToProps = state => ({
  reviewFlows: getReviewFlows(state),
  projectId: project(state),
  loading: getLoading(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(actions.fetch());
    dispatch(fetchMasterDepartments());
    dispatch(fetchProjectUsers());
  },
});

export const style = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: 'inherit',
  },
  progressBar: {
    width: '100%',
    marginTop: 26,
    marginBottom: -30,
    padding: 0,
  },
});

class ApprovalFlow extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onFetchData: PropTypes.func.isRequired,
    reviewFlows: reviewFlowsProps.isRequired,
  };

  componentDidMount() {
    const { onFetchData } = this.props;
    onFetchData();
  }

  renderReviewFlows() {
    const { reviewFlows } = this.props;
    let reviewFlowsContent = null;

    if (reviewFlows && reviewFlows.length > 0) {
      reviewFlowsContent = reviewFlows.map(flow => (
        <ReviewFlow key={flow.id} reviewFlow={flow} />
      ));
    }

    return reviewFlowsContent;
  }

  render() {
    const { classes, loading } = this.props;

    return (
      <div>
        {loading && (
          <div className={classes.progressBar}>
            <LinearProgress />
          </div>
        )}
        <DepartmentHead />
        <div>{this.renderReviewFlows()}</div>
        <RemoveReviewer />
        <EditReviewers />
        <RemoveApprovalFlow />
        <EditDepartment />
        <RemoveDepartment />
      </div>
    );
  }
}

export default compose(
  withStyleSheet('ReviewFlows', style),
  connect(mapStateToProps, mapDispatchToProps),
)(ApprovalFlow);
