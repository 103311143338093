import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';
import withModalDialog from 'decorators/withModalDialog';
import { getSource } from 'selectors/modalDialog';
import { ConfirmModal } from 'components/Shared/Modals';
import { hide as hideModal } from 'actions/modalDialog';

import { getDealMemoFields } from '../constants/DMTableFields';
import SelectTable from 'components/Shared/SelectTable/SelectTable';

import { fetchDealMemos, fetchAndSetPayAtScale } from 'actions/wtc';
import {
  getDealMemos,
  getRounding,
  getDMScaleRatesLoading,
} from 'selectors/wtc';
import { showAlert } from 'actions/alert';

import {
  handleChangeDealMemoWTCTimecard,
  handleChangeDealMemosWTCDay,
} from 'utils/wtcWeekUtils';

export const style = ({ palette }) => ({
  container: {
    fontSize: 14,
  },
  aboveTableBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  scopeAlert: {
    display: 'flex',
    justifyContent: 'flex-end',
    // height: 34,
    '& > div': {
      border: '1px solid #f89b57',
      backgroundColor: '#fdebdd', //hardCoded Colors
      color: '#f89b57',
      padding: 8,
    },
  },
  section: {
    color: palette.text.secondary,
    display: 'flex',
    '& > span': {
      padding: 2,
    },
  },
  title: {
    color: palette.text.secondary,
  },
  textGray: {
    color: palette.text.disabled,
  },
});

const modalDialog = 'WTCDealMemoModal';

const mapStateToProps = state => ({
  modalSource: getSource(state, modalDialog),
  dealMemos: getDealMemos(state),
  rounding: getRounding(state),
  dmScaleRatesLoading: getDMScaleRatesLoading(state),
});

const mapDispatchToProps = dispatch => ({
  onModalHide: () => dispatch(hideModal({ dialog: modalDialog })),
  onFetchDealMemos: (employeeId, startDate, endDate) => {
    dispatch(
      fetchDealMemos({
        employeeId,
        startDate,
        endDate,
        shouldAddRounding: true,
        withPayroll: true,
        shouldAddScale: true,
      }),
    );
  },
  onShowAlert: () => dispatch(showAlert()),
  onFetchAndSetScaleRate: ({ member, payAtScale }) => {
    dispatch(fetchAndSetPayAtScale({ member, payAtScale }));
  },
  onShowRoundingMsg: () => {
    dispatch(
      showAlert({
        message:
          'The selected deal memo has different rounding rules than the previous deal memo. Values have been rounded accordingly.',
        variant: 'info',
      }),
    );
  },
});

const WTCDealMemoModal = ({
  classes,
  error,
  modalSource,
  onModalHide,
  details,
  change,
  dealMemos,
  allowances,
  timecard,
  onFetchDealMemos,
  onShowAlert,
  showDragBar,
  readOnly,
  onFetchAndSetScaleRate,
  onShowRoundingMsg,
  timecardDealMemo,
  onSetFieldVisibility,
  dmScaleRatesLoading,
}) => {
  const [needToFetchDeals, setNeedToFetchDeal] = useState(true);
  const anyFringeEnabledDeal = dealMemos?.some(dm =>
    dm.guarantees.some(g => g.grossIncludesVACFGN === true),
  );

  useEffect(() => {
    if (needToFetchDeals) {
      if (modalSource.source === 'day') {
        setNeedToFetchDeal(false);
        onFetchDealMemos(
          timecard.employee.id,
          modalSource.startDate,
          modalSource.endDate,
        );
        return () => {
          onFetchDealMemos(
            //fetch week deal memo on close
            timecard.employee.id,
            timecard.weekStartingDate,
            timecard.weekEndingDate,
          );
        };
      } else if (modalSource.source === 'week') {
        const startDate = timecard.weekStartingDate
          ? timecard.weekStartingDate
          : undefined;
        const endDate = timecard.weekEndingDate
          ? timecard.weekEndingDate
          : undefined;

        if (startDate && endDate) {
          setNeedToFetchDeal(false);
          onFetchDealMemos(timecard.employee.id, startDate, endDate);
        }
      }
    }
  }, [modalSource, needToFetchDeals, onFetchDealMemos, timecard]);

  const [list, setList] = useState([]);
  let anyDisabled = React.useRef(false);
  const [invalidDays, setInvalidDays] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  useEffect(() => {
    //sorting by dealMemo code so the deal memo display matches the order in the drop down on WTC
    const newList = _.sortBy(_.cloneDeep(dealMemos), dm => Number(dm.code));

    //Disable deals that do not match the timecard deal payroll company
    const dealMemoTC = newList.find(deal => deal.id === timecardDealMemo?.id);
    const prCompanyId = dealMemoTC?.payrollCompany?.id;
    newList.forEach(deal => {
      const currPrCompanyId = deal?.payrollCompany?.id;
      if (prCompanyId && currPrCompanyId && currPrCompanyId !== prCompanyId) {
        deal.isDisabled = true;
        anyDisabled.current = true;
      }
    });

    setList(newList);
    const currentMemoIndex = _.findIndex(newList, {
      id: modalSource.currentDeal,
    });

    const newVal = currentMemoIndex === -1 ? -1 : currentMemoIndex;
    setSelectedIndex(newVal);
  }, [
    dealMemos,
    modalSource.currentDeal,
    modalSource.source,
    timecardDealMemo,
  ]);

  //Scan for invalid days on selected deal
  useEffect(() => {
    const selectedId = list[selectedIndex]?.id;

    const currentDeal = dealMemos.find(dm => dm.id === selectedId);
    if (!currentDeal) return;

    const { start, end } = currentDeal;
    const invalidDays = [];
    details.forEach(detail => {
      if (
        !detail.unusedDay &&
        detail.dealMemo &&
        (moment(detail?.effectiveDate).isBefore(start) ||
          moment(detail?.effectiveDate).isAfter(end))
      ) {
        const date = moment(detail.effectiveDate).format('dddd MMM DD');
        invalidDays.push(date);
      }
    });

    setInvalidDays(invalidDays);
  }, [dealMemos, details, list, selectedIndex]);

  const onSubmit = () => {
    const selectedId = list[selectedIndex].id;
    switch (modalSource.source) {
      case 'week':
        if (!selectedId) {
          throw new Error('Unable to change deal memo for selected day.');
        }
        handleChangeDealMemoWTCTimecard(selectedId, {
          change,
          timecard,
          details,
          dealMemos,
          allowances,
          onFetchAndSetScaleRate,
          onShowRoundingMsg,
          onSetFieldVisibility,
        });

        break;
      case 'day':
        const date = moment(modalSource.startDate).format('YYYY-MM-DD');
        const detailIndex = _.findIndex(details, detail => {
          const detailDate = moment(detail.effectiveDate).format('YYYY-MM-DD');
          if (detailDate === 'Invalid date') return false;
          return date === detailDate;
        });

        const detail = details[detailIndex];
        const member = `details[${detailIndex}]`;

        if (!detail || !selectedId) {
          throw new Error('Unable to change deal memo for selected day.');
        }

        handleChangeDealMemosWTCDay(selectedId, {
          change,
          detail,
          member,
          dealMemos,
          onFetchAndSetScaleRate,
          onShowRoundingMsg,
          onSetFieldVisibility,
        });
        break;

      default:
        throw new Error(`Invalid modal source:  ${modalSource}`);
    }
  };

  let title = (
    <Typography variant="h5" color="inherit">
      <span className={classes.title}>{`Deal Memo Details`}</span>
    </Typography>
  );

  const scopeAlertText =
    modalSource.source === 'day'
      ? 'Changing the deal here will change the deal memo for the only the selected day'
      : 'Changing the deal here will change the deal memo for the whole timecard, including allowances, and all days';

  let content = (
    <div
      className={classes.container}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onSubmit();
          onModalHide();
        }
      }}
    >
      <div className={classes.scopeAlert}>
        <div>{scopeAlertText}</div>
      </div>
      <div className={classes.aboveTableBar}>
        <section className={classes.section}>
          <span className={classes.textGray}>Crew Member:</span>
          <span>{timecard.employee.name}</span>
        </section>
      </div>
      <SelectTable
        list={list}
        fields={getDealMemoFields('wtc', anyFringeEnabledDeal)}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        readOnly={readOnly}
        dmScaleRatesLoading={dmScaleRatesLoading}
      />
      {anyDisabled.current && (
        <span>
          Some deal memos have been disabled because their invoice type doesn't
          match the invoice type on the timecard.
        </span>
      )}
      {invalidDays.length > 0 && (
        <Box className={classes.scopeAlert}>
          <div>
            The selected deal is not valid for the following days on this
            timecard:
            <br />
            <b> "{invalidDays.join(', ')}"</b> <br />
            Changing the deal will remove these days from the timecard.
          </div>
        </Box>
      )}
    </div>
  );

  if (list.length === 0) {
    title = '';
    content = (
      <Typography align="center" variant="h6">
        No valid deals exist.
      </Typography>
    );
    readOnly = true;
  }

  const cancelText = readOnly ? 'Close Window' : 'Cancel, and Close Window';

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        try {
          onSubmit();
          onModalHide();
        } catch (error) {
          console.error('Error when changing deal memo', error);
          onShowAlert();
        }
      }}
    >
      <ConfirmModal
        content={content}
        title={title}
        buttonText="Apply Changes"
        buttonType="secondary"
        cancelText={cancelText}
        showDragBar={showDragBar}
        raisedButton
        onModalHide={onModalHide}
        buttonHide={readOnly}
      />
    </form>
  );
};

WTCDealMemoModal.propTypes = {
  allowances: PropTypes.array,
  timecard: PropTypes.object,
  details: PropTypes.array,
  change: PropTypes.func,
  showDragBar: PropTypes.bool,
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: modalDialog, maxWidth: 'lg', draggable: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(WTCDealMemoModal);
