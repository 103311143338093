import {
  all,
  takeLatest,
  call,
  put,
  select,
  takeEvery,
  delay,
  throttle,
  race,
  take,
} from 'redux-saga/effects';
import pluralize from 'pluralize';
import { push } from 'connected-react-router';

//actions
import * as actions from 'actions/searchTimecards';
import { setFromURL } from 'actions/timecards';

import { showAlert } from 'actions/alert';
import { clearSelectedInvoice } from 'feature/SearchInvoices/actions';
import {
  hide as hideModal,
  hideAll as hideAllModals,
} from 'actions/modalDialog';
import { signalRJobSearchDelete } from 'actions/events';
import { fetchPermissions } from 'actions/userInfo';
import { fetchReviewLevels } from 'actions/reviewFlows';
import { setWTCTimecardHeaderIds } from 'actions/wtc';
//selectors
import { getProject } from 'selectors/routeParams';
import {
  getAllFilters,
  getPageSize,
  getSortByForReq,
  getRemovedSelectedOption,
  getTotalCount,
  getJobInfo,
} from 'selectors/searchTimecards';
import {
  getSelectedInvoice,
  getSelectedInvoiceCount,
} from 'feature/SearchInvoices/selectors';
import { getProjectDetails } from 'selectors/project';

//constant + utils
import { makeFilterArgFactory } from 'components/Employees/Reviews/SearchTimecards/searchUtils';

// import { dummyData } from 'components/Employees/Reviews/SearchTimecards/dummyData';

function* init() {
  yield put(actions.fetch({ init: true }));
  yield put(fetchPermissions());
  yield put(fetchReviewLevels());
}

export function* fetch(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));

    yield waitOnProjectDetails();

    const projectId = yield select(getProject);
    const filters = yield select(getAllFilters);
    const statusFilter = filters.find(f => f.field === 'status');
    const dbCodeFilter = filters.find(f => f.field === 'dbCode');
    const activeFilter = params.filterName;
    const init = params.init;
    const updateStatus = params.updateStatus || false;
    const shouldUpdateFilters = activeFilter === 'status' || init;

    const sortBy = yield select(getSortByForReq);

    const globalFilters = statusFilter
      ? [statusFilter, dbCodeFilter]
      : [dbCodeFilter];

    const makeFilterArgs = makeFilterArgFactory({
      fetchFilter,
      api,
      debug,
      globalFilters,
      projectId,
    });

    // filters to refresh with the current filter arguments
    const names = [
      'employee',
      'department',
      'invoice',
      'union',
      'weekEndingDate',
      'accountCodeHeader',
      'batch',
      'status',
    ];

    if (shouldUpdateFilters) {
      if (activeFilter === 'status') {
        const statusIndex = names.indexOf('status');
        names.splice(statusIndex, 1);
      }
      yield all([
        ...names.map(name => call(...makeFilterArgs(name))), //filter calls
        call(fetchData, api, debug, { filters, projectId, sortBy }), //Data Call
      ]);
    } else {
      yield call(fetchData, api, debug, { filters, projectId, sortBy });
      if (updateStatus) {
        yield delay(500);
        yield call(...makeFilterArgs('status'));
      }
    }

    const removedSelected = yield select(getRemovedSelectedOption);
    if (removedSelected) {
      // The last filter change removed a selected option.
      // Need to re-fetch data with the current filters
      const newFilters = yield select(getAllFilters);
      yield put(
        actions.setRemovedSelectedOption({ removedSelectedOption: false }),
      );
      yield call(fetchData, api, debug, {
        filters: newFilters,
        projectId,
        sortBy,
      });
    }
  } catch (e) {
    debug(e);
    showAlert();
  } finally {
    const totalCount = yield select(getTotalCount);
    const count = yield select(getSelectedInvoiceCount);
    const invoice = yield select(getSelectedInvoice);

    if (!!invoice && totalCount === count) {
      yield put(clearSelectedInvoice());
    } else if (!!invoice && totalCount !== count) {
      yield put(
        showAlert({
          message:
            'You may not have permission to see some items from this invoice.',
          variant: 'warning',
        }),
      );
      yield put(clearSelectedInvoice());
    }

    yield put(actions.loading({ loading: false }));
    yield put(
      actions.setRemovedSelectedOption({ removedSelectedOption: false }),
    );
  }
}

function* fetchFilter(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filterName = params.filterName;
    let filters = params.filters;

    if (filterName === 'status') {
      const dbCodeFilter = filters.find(f => f.field === 'dbCode');

      filters = dbCodeFilter ? [dbCodeFilter] : [];
    }

    const filterParams = {
      filters,
      type: filterName,
      page: 0,
      pageSize: 150,
    };

    const data = yield call(api.searchTimecards.filter, {
      projectId,
      filterParams,
    });

    yield put(actions.storeFilterOptions({ filterName, data }));
  } catch (e) {
    debug(e);
  }
}

function* fetchData(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filters = params.filters;
    const sortBy = params.sortBy;
    const pageSize = yield select(getPageSize);

    const dataParams = {
      filters,
      sortBy,
      page: 0,
      pageSize,
    };

    const data = yield call(api.searchTimecards.getData, {
      projectId,
      dataParams,
    });

    const removedSelected = yield select(getRemovedSelectedOption);
    if (!removedSelected) {
      //Only store data if we don't have another data fetch pending because we removed a selected filter option
      yield put(actions.store({ data }));

      yield put(actions.submittingTimecards({ submittingTimecards: false }));
      yield put(actions.deletingTimecards({ deletingTimecards: false }));
      yield put(hideAllModals());
    }
  } catch (e) {
    debug(e);
    // yield put(showAlert({ message: 'Error updating search data' }));
    yield put(showAlert());
  }
}
export function* submitDraftTimecards(api, debug, params) {
  const { timecardIds } = params;
  try {
    yield put(actions.submittingTimecards({ submittingTimecards: true }));

    const projectId = yield select(getProject);
    const dynamicDelay = timecardIds
      ? timecardIds.length === 1
        ? 2000
        : timecardIds.length > 6
        ? timecardIds.length * 700
        : timecardIds.length * 1000
      : 0;

    let data = {
      comment: params && params.notes ? params.notes : null,
      emergencyType:
        params && params.emergencyType ? params.emergencyType : null,
      timecardIds: timecardIds,
    };

    yield call(api.timecards.submitDraftToEmployees, {
      projectId,
      data,
    });

    yield delay(dynamicDelay);
    yield put(
      showAlert({
        message: `Success! ${pluralize(
          'timecard',
          timecardIds.length,
          true,
        )} submitted`,
        variant: 'success',
      }),
    );

    yield put(actions.fetch({ updateStatus: true }));
  } catch (e) {
    yield put(showAlert());
    debug(e);
    yield put(actions.submittingTimecards({ submittingTimecards: false }));
  }
}
export function* deleteTimecards(api, debug, params) {
  try {
    yield put(actions.submittingTimecards({ submittingTimecards: true }));
    yield put(actions.deletingTimecards({ deletingTimecards: true }));
    const projectId = yield select(getProject);
    const { timecardIds } = params;

    const data = {
      timecards: timecardIds,
    };

    const jobInfo = yield call(api.searchTimecards.deleteTimecards, {
      projectId,
      data,
    });
    yield put(
      actions.storeJobInfo({
        jobId: jobInfo.jobId,
        jobLength: timecardIds.length,
      }),
    );

    //race will cancel the deleteTimeout effect when the signalR action is dispatched
    yield race({
      timeout: call(deleteTimeout),
      completed: take(`${signalRJobSearchDelete}`),
    });
  } catch (e) {
    yield put(showAlert());
    yield put(actions.submittingTimecards({ submittingTimecards: false }));
    yield put(actions.deletingTimecards({ deletingTimecards: false }));
    debug(e);
  }
}
function* deleteTimeout() {
  yield delay(2 * 60 * 1000);
  yield put(actions.deletingTimecards({ deletingTimecards: false }));
  yield put(actions.submittingTimecards({ submittingTimecards: false }));
  yield put(showAlert());
  yield put(actions.deleteJobInfo());
  yield put(actions.fetch({ updateStatus: true }));
  yield put(hideModal({ dialog: 'DeleteSearchModal' }));
}

export function* wrapDeleteTimecards(api, debug, params) {
  try {
    const jobInfo = yield select(getJobInfo);

    if (jobInfo.jobId === params.jobId) {
      const dynamicDelay = (jobInfo.jobLength ?? 10) * 800;
      yield delay(dynamicDelay);
      yield put(actions.fetch({ updateStatus: true }));
      yield put(
        showAlert({
          message: `Success! ${pluralize(
            'timecard',
            jobInfo.jobLength,
            true,
          )} deleted`,
          variant: 'success',
        }),
      );

      yield put(actions.deleteJobInfo());
    }
  } catch (e) {
    yield put(showAlert());
    debug(e);
  }
}
function* bulkSubmitFromTimecard(api, debug, params) {
  try {
    //Flag turned off after data fetch
    yield put(actions.submittingTimecards({ submittingTimecards: true }));
    const { comment = '', status, timecardEntryHeaderIds } = params;
    const projectId = yield select(getProject);
    const data = { comment, status, timecardEntryHeaderIds };
    const isRejection = !!comment;
    yield call(api.searchTimecards.reviewBatch, { projectId, data });
    yield delay(1500); // Added delay because even after successful approve and reject in search timecards api response receiving all the timecards including approved and rejected,
    yield put(actions.fetch({ updateStatus: true }));
    if (isRejection) {
      yield put(
        showAlert({
          message:
            timecardEntryHeaderIds.length > 1
              ? 'Timecards have been Rejected'
              : 'Timecard has been Rejected',
          variant: 'success',
        }),
      );
    } else {
      yield put(
        showAlert({
          message:
            timecardEntryHeaderIds.length > 1
              ? 'Timecards have been Approved'
              : 'Timecard has been Approved',
          variant: 'success',
        }),
      );
    }
  } catch (e) {
    yield put(actions.submittingTimecards({ submittingTimecards: false }));
    debug(e);
    yield put(showAlert());
  }
}

function* openTimecardsInWTC(api, debug, params) {
  try {
    const { timecardHeaderIds } = params;

    yield put(
      setWTCTimecardHeaderIds({ wtcTimecardHeaderIds: timecardHeaderIds }),
    );
    const projectId = yield select(getProject);
    yield put(
      setFromURL({ fromURI: `/projects/${projectId}/review/search-timecards` }),
    );

    yield put(push(`/projects/${projectId}/review/ready/wtc`));
  } catch (error) {
    debug(error);
  }
}

//need to ensure dbCode and other project detail fields are loaded before we continue
function* waitOnProjectDetails() {
  let projectDetails = yield select(getProjectDetails);
  let tries = 10;
  while (!projectDetails.dbCode && tries > 0) {
    projectDetails = yield select(getProjectDetails);
    tries--;
    yield delay(50);
  }
}

export default function* searchTimecard({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    throttle(2000, `${actions.fetch}`, fetch, api, debug),
    takeLatest(`${actions.deleteTimecards}`, deleteTimecards, api, debug),
    takeEvery(
      `${actions.submitDraftTimecards}`,
      submitDraftTimecards,
      api,
      debug,
    ),
    takeLatest(
      `${actions.bulkSubmitFromTimecard}`,
      bulkSubmitFromTimecard,
      api,
      debug,
    ),
    takeEvery(`${signalRJobSearchDelete}`, wrapDeleteTimecards, api, debug),
    takeLatest(`${actions.openTimecardsInWTC}`, openTimecardsInWTC, api, debug),
  ]);
}
