import produce from 'immer';
import {
  ADD,
  EDIT_REQUEST,
  EDIT,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  INIT,
  REMOVE_REQUEST,
  REMOVE,
  UPDATE,
} from 'actions/actionTypes';

import {
  DEPARTMENT,
  DEPARTMENT_HEAD,
  REVIEW_FLOWS,
  REVIEWER,
  WF_DEPARTMENTS,
  WF_STEP_USERS,
  WF_STEPS,
} from 'actions/admin/reviewFlows';

const editApprovalFlow = {
  addReviewer: false,
  form: null,
  position: null,
  reviewers: [],
  reviewFlowId: null,
  role: null,
  stepId: null,
  userIds: [],
};

const editDepartment = {
  addNext: false,
  departmentId: null,
  departmentHeads: [],
  reviewFlowId: null,
  userIds: [],
};

const initialState = {
  list: [],
  steps: {},
  departments: {},
  stepUsers: {},
  updateDepartment: null,
  editApprovalFlow,
  editDepartment,
  loadingReviewFlows: true,
  loadingSteps: true,
  loadingDepartments: true,
  loadingReviewers: true,
};

export const initialReviewer = {
  key: 0,
  id: null,
  canSubmitEmergencyTimecard: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_REQUEST(REVIEW_FLOWS):
        draft.loadingReviewFlows = true;
        break;

      case FETCH_REQUEST(WF_STEPS):
        draft.loadingSteps = true;
        break;

      case FETCH_REQUEST(WF_STEP_USERS):
        draft.loadingReviewers = true;
        break;

      case FETCH_REQUEST(WF_DEPARTMENTS):
        draft.loadingDepartments = true;
        break;

      case FETCH_SUCCESS(REVIEW_FLOWS):
        draft.list = action.reviewFlows || [];
        draft.loadingReviewFlows = false;
        break;

      case FETCH_FAILURE(REVIEW_FLOWS):
        draft.list = [];
        draft.loadingReviewFlows = false;
        break;

      case FETCH_SUCCESS(WF_DEPARTMENTS):
        draft.departments[action.reviewFlowId] = action.departments || [];
        draft.loadingDepartments = false;
        break;

      case FETCH_SUCCESS(WF_STEPS):
        draft.steps[action.reviewFlowId] = action.steps || [];
        draft.loadingSteps = false;
        break;

      case FETCH_SUCCESS(WF_STEP_USERS): {
        draft.stepUsers[action.reviewFlowId][action.stepId] =
          action.users || [];
        draft.loadingReviewers = false;
        break;
      }

      case EDIT_REQUEST(REVIEWER):
        draft.editApprovalFlow = { ...editApprovalFlow, ...action };
        break;

      case REMOVE_REQUEST(REVIEWER):
        draft.editApprovalFlow = editApprovalFlow;
        break;

      case INIT(REVIEWER):
        draft.editApprovalFlow.reviewers = [];
        break;

      case ADD(REVIEWER):
        draft.editApprovalFlow.reviewers.push(initialReviewer);
        break;

      case REMOVE(REVIEWER):
        draft.editApprovalFlow.reviewers.splice(action.key, 1);
        break;

      case EDIT(DEPARTMENT):
        draft.updateDepartment = action.departmentId;
        draft.editDepartment = {
          ...editDepartment,
          departmentId: action.departmentId,
          reviewFlowId: action.reviewFlowId,
          departmentHeads: action.users || [],
        };
        break;

      case REMOVE_REQUEST(DEPARTMENT):
        draft.editDepartment = editDepartment;
        break;

      case ADD(DEPARTMENT_HEAD):
        draft.updateDepartment = null;
        break;

      case REMOVE(DEPARTMENT_HEAD):
        draft.editDepartment.departmentHeads.splice(action.key, 1);
        break;

      case UPDATE(DEPARTMENT_HEAD):
        draft.editDepartment.departmentHeads[action.key] = action.val;
        break;

      case UPDATE(REVIEWER):
        draft.editApprovalFlow.reviewers[action.key] = action.val;
        break;

      default:
    }
  });
