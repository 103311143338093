import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notifications from './Notifications';

class Preferences extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: 30 }}>
        <Notifications />
      </div>
    );
  }
}

export default withRouter(connect(null, null)(Preferences));
