import _ from 'lodash';
import moment from 'utils/moment';
import { createSelector } from 'reselect';
// Utils
import { getCurrentWeekending } from 'utils/weekUtils';
import { weekEndingFormat as format } from 'utils/formatDate';
// Selectors
import { getDealMemos } from 'selectors/userInfo';
import { getFormValues } from 'selectors/formValues';
import { getProject } from 'selectors/routeParams';
import { currentUser } from 'selectors/session';
import {
  getLoading as projectLoading,
  getProjectUsers,
} from 'selectors/project';
import * as common from 'selectors/timecard/common';
import { projectUsersLoading } from 'selectors/project';
import { getFiltering } from './gridFiltering';
// components
import * as constants from 'components/Shared/constants';
import { statusByRole } from 'components/Shared/constants';

const pendingTimecard = timecard => {
  if (constants.TIMECARD_PENDING_STATUS.indexOf(timecard.status) < 0) {
    return false;
  }

  if (
    timecard.rejection_workflow ===
      constants.REJECTION_FLOW_NO_EMPLOYEE_APPROVAL &&
    timecard.status === 'incomplete' &&
    timecard.previously_rejected
  ) {
    return false;
  }

  return true;
};

export const getLoading = state => _.get(state, 'timecards.loading', false);
export const getLoadingInit = state =>
  _.get(state, 'timecards.loadingInitialize', false);
export const getWeekendings = state =>
  _.get(state, 'timecards.weekendings', []);

export const getWeekendingStats = state =>
  _.get(state, 'timecards.weekendingStats', []);

export const getWeekendingTimecards = state =>
  _.get(state, 'timecards.weekendingTimecards', []);

export const getCrewTimecardsFilter = state =>
  getFiltering(state, 'crew-timecard');

export const getCrewWeekendingTimecardsFilter = state =>
  getFiltering(state, 'crew-weekending-timecard');

export const getTeamTimecardUsers = state =>
  _.get(state, `timecards.teamTimecard.users`, {});

export const getEmployees = state => _.get(state, `timecards.employees`, {});

export const getCopyPreviousFlag = state =>
  _.get(state, 'timecards.isCopyPrevious', false);

export const getLocalNotes = state => _.get(state, 'timecards.localNotes', []);
export const getLocalAllowances = state => {
  const tcAllowances = _.get(state, 'timecards.tcAllowances', {});
  return tcAllowances['fresh-0'] || [];
};

export const getFilteredCrewTimecards = createSelector(
  [getCrewTimecardsFilter, getWeekendingStats],
  (gridFilter, weekendingStats) => {
    if (!gridFilter) return weekendingStats;

    gridFilter = (gridFilter || '').toLowerCase();

    return weekendingStats.filter(
      stats => stats.endsOn.toLowerCase().indexOf(gridFilter) >= 0,
    );
  },
);

export const getFilteredWeekendingTimecards = weekEndingdate => role =>
  createSelector(
    [getCrewWeekendingTimecardsFilter, getProjectUsers, getWeekendingTimecards],
    (gridFilter, projectUsers, weekendingTimeCards) => {
      let result = [];
      const mapUserTimecards = _.groupBy(
        weekendingTimeCards,
        timecard => timecard.user.id,
      );

      _.each(projectUsers, user => {
        const userTimecards = mapUserTimecards[user.id] || [null];
        const dealMemos = occupationFilter(user.dealMemos, weekEndingdate);
        let occupationCodes = null;
        let occupationCodesFilter = null;

        if (dealMemos.length > 0) {
          occupationCodes = _.map(dealMemos, (memo, index) => {
            return memo.jobDescription;
          });
          occupationCodesFilter = _.join(occupationCodes);
        } else {
          occupationCodes = ['Pending'];
          occupationCodesFilter += occupationCodesFilter;
        }
        _.each(userTimecards, timecard => {
          let timecardStatus = (timecard && timecard.status) || 'blank';
          if (timecardStatus === 'processing') {
            timecardStatus = 'incomplete';
          }

          const statuses = statusByRole(role);
          const statusFilter = statuses.label[timecardStatus] || timecardStatus;

          result.push({
            key: `${user.id}-${timecard && timecard.id}`,
            fullName: user.fullName,
            dealMemos: dealMemos,
            occupationCodes: occupationCodes,
            status: timecardStatus,
            user: user,
            statusFilter: statusFilter,
            occupationCodesFilter: occupationCodesFilter,
            timecard: timecard,
          });
        });
      });
      result = result || [];

      if (!gridFilter) return result;

      gridFilter = (gridFilter || '').toLowerCase();

      return result.filter(
        item =>
          item.fullName.toLowerCase().indexOf(gridFilter) >= 0 ||
          (item.statusFilter || '').toLowerCase().indexOf(gridFilter) >= 0 ||
          (item.occupationCodesFilter || '')
            .toLowerCase()
            .indexOf(gridFilter) >= 0,
      );
    },
  );

export const getCrewTimecardLoading = createSelector(
  [projectLoading, common.getLoading, projectUsersLoading],
  (isProjectLoading, isTimecardLoading, isprojectUsersLoading) => {
    return isProjectLoading || isTimecardLoading || isprojectUsersLoading;
  },
);

export const getPendingTimecards = createSelector(
  [common.getTimecards],
  timecards => {
    const list = _.filter(timecards, timecard => pendingTimecard(timecard));
    return list;
  },
);

export const filterHistoryTimecards = createSelector(
  [common.getTimecards],
  timecards => {
    return _.filter(
      timecards,
      timecard =>
        constants.TIMECARD_HISTORY_STATUS.indexOf(timecard.status) >= 0 ||
        !pendingTimecard(timecard),
    );
  },
);

/**
 * Filters occupation codes with deal memo overlaped on weekending
 *
 * @param {array} occupationCodes - User occupation codes
 * @param {string} endsOn - weekending date
 * @param {bool} partial - True if selects with partial overlaping
 */
export const occupationFilter = (dealMemos, endsOn, partial = true) => {
  var startsOn = null;
  endsOn = moment(endsOn, ['MM-DD-YYYY', 'YYYY-MM-DD']);
  startsOn = endsOn.clone().subtract(6, 'days');
  endsOn = endsOn.toDate();
  startsOn = startsOn.toDate();

  return _.filter(dealMemos, dealMemo => {
    var starts = Date.parse(dealMemo.startsOn),
      ends = Date.parse(dealMemo.endsOn);

    if (partial) {
      return (
        (starts >= startsOn && starts <= endsOn) ||
        (startsOn >= starts && startsOn <= ends)
      );
    }

    return ends >= endsOn && starts <= startsOn;
  });
};

export const getFilteredDealMemos = formName =>
  createSelector(
    [getDealMemos, getFormValues(formName)],
    (dealMemos, values) =>
      occupationFilter(dealMemos, values.endsOn || values.weekEnding, true) ||
      [],
  );

export const getPreviousWeeks = formName =>
  createSelector(
    [getWeekendings, getFormValues(formName)],
    (weekEndings, values) => {
      const filtered = _.filter(
        weekEndings,
        ending => format(ending.endsOn) !== values.weekEnding,
      );

      return filtered;
    },
  );

export const createTimecardInitialValues = formName =>
  createSelector(
    [getWeekendings, getFilteredDealMemos(formName)],
    (weekEndings, memos) => {
      const endsOn = getCurrentWeekending(weekEndings);
      const occupationCode = memos[0] ? memos[0].id : '';

      const initialValues = { weekEnding: endsOn, occupationCode };
      return initialValues;
    },
  );

export const existingTimecards = formName =>
  createSelector(
    [getFormValues(formName), common.getTimecards, getProject],
    (values, timecards, project) => {
      const existing = _.filter(
        timecards,
        tc =>
          tc.projectId === Number(project) &&
          format(tc.endsOn) === values.weekEnding,
      );

      return existing;
    },
  );

export const existingTimecardForProject = createSelector(
  [common.getTimecards, getProject],
  (timecards, project) => {
    return _.filter(timecards, tc => tc.projectId === Number(project));
  },
);

export const getTimecardDetails = formName =>
  createSelector(
    [getFormValues(formName), currentUser],
    (timecard, activeUser) => {
      const startsOn = moment(timecard.weekEnding, 'MM-DD-YYYY')
        .subtract(6, 'days')
        .format('YYYY-MM-DD');

      const data = {
        htgDealmemoId: timecard.occupationCode,
        startsOn,
        endsOn: moment(timecard.weekEnding, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      };

      if (timecard.sourceTimecardId) {
        data.sourceTimecardId = timecard.sourceTimecardId;
        data.sourceTimecardDealMemoId = timecard.sourceTimecardDealMemoId;
      }

      return data;
    },
  );

export const getTimecardAllowancesFormValues = createSelector(
  [getFormValues('MyTimecard')],
  values => {
    const lstTimecardAllowances = values;
    return {
      ...lstTimecardAllowances,
    };
  },
);

export const getCopyFromPrevRoundFlag = state =>
  _.get(state, `timecards.copyFromPrevRoundFlag`, false);
