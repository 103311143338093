import { get, cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import _ from 'lodash';
import { push } from 'connected-react-router';

import {
  call,
  fork,
  put,
  select,
  all,
  takeLatest,
  delay,
  takeEvery,
  throttle,
  race,
  take,
} from 'redux-saga/effects';
import camelCase from 'camelcase-keys';
import { startSubmit, stopSubmit, reset, change } from 'redux-form';

// actions
import * as actions from 'actions/wtc';
import { hide as hideModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';
import { signalRNotification } from 'actions/events';
import { processingApproval } from 'actions/reviews';

// selectors
import { getProject } from 'selectors/routeParams';
import {
  getCurrentProjectWorkSight,
  getCurrentProject,
} from 'selectors/project';
import {
  getTimecard,
  isApprovingTimecard,
  getTimecardsInDrawer,
  getFullFormValues,
  getScaleLoading,
  getLoadingNewBatch,
  getDealMemos,
  getFilterSortedTimecards,
  getWTCTimecardHeaderIds,
  getCurrentBatchWorksightId,
  getCurrentTimecardHeaderId,
  getIsMultiBatch,
  getBatchInfo,
  getFilters,
  getResubmitComment,
  getIsDraft,
  getTableFieldOrder,
  getComments,
  getWorkLocationTypes,
} from 'selectors/wtc';
import { getFormValues } from 'selectors/formValues';
import { getReviewFlowLevels } from 'selectors/reviewFlows';

// utils
import { matchPath } from 'react-router';
import * as DOMPurify from 'dompurify';
import { createBrowserHistory } from 'history';
import {
  prepTimecardForSave,
  addAdditionalTimecardInfo,
  makeScaleKey,
  isDistantDay,
  getStudioAndDistant,
  getRateFromLocal,
  getPayCodeFromDealMemoAllowance,
  getActiveDealMemoIds,
  addRoundingToDeal,
  validateTimecard,
  WTC_FORM_NAME,
  scanForWorkTimeChanges,
  generateComment,
  WORK_TIME_FIELDS,
  WTC_LAYOUT_STANDARED_TEMPLATE,
} from 'utils/wtcWeekUtils';
import { formatDateTimezone } from 'utils/formatDate';

import { getTimecardHtgContractId } from 'selectors/timecard/form';
import { userInfoById } from 'selectors/session';
import {
  formatAllowancesAmount,
  delayOnValueObj,
  isValidGuid,
} from 'utils/helperFunctions';
import { convertToFormData, composeAllowanceV1 } from 'utils/weekUtils';
import { hostUrl } from 'constants/config/apiServer';
import { getSettings } from 'selectors/settings';

const purify = input => DOMPurify.sanitize(input);

export function* drawerInit(api, debug, params) {
  try {
    //Check for single batch URL
    let match = matchPath(window.location.pathname, {
      path: '/projects/:projectId/review/:reviewType/:batchWorksightId/wtc',
      exact: true,
      strict: true,
    });

    if (match !== null) {
      const { batchWorksightId: uncleanWorksightId } = match.params;
      const batchWorksightId = purify(uncleanWorksightId);
      yield put(actions.batchInit({ batchWorksightId }));
      return;
    }

    //Check for multi batch URL
    match = matchPath(window.location.pathname, {
      path: '/projects/:projectId/review/:reviewType/wtc',
      exact: true,
      strict: true,
    });
    if (match !== null) {
      yield put(actions.multiBatchInit());
      return;
    }

    //redirect to search timecards
    yield delay(0);
  } catch (error) {
    debug(error);
  }
}

function* batchInit(api, debug, params) {
  const { batchWorksightId } = params;
  yield put(actions.fetchBatchInfo({ worksightId: batchWorksightId }));
  yield put(actions.fetchTimecardsInBatch({ worksightId: batchWorksightId }));
  yield put(
    actions.setCurrentBatchWorksightId({
      currentBatchWorksightId: batchWorksightId,
    }),
  );

  //get current timecard from URL if present
  const searchParams = new URLSearchParams(window.location.search);
  const currentTimecardHeaderId = purify(searchParams.get('timecardHeaderId'));
  if (isValidGuid(currentTimecardHeaderId)) {
    yield put(actions.setCurrentTimecardHeaderId({ currentTimecardHeaderId }));
    //remove timecardHeader from url - only used for initial navigation
    const history = createBrowserHistory();
    history.replace(window.location.pathname);

    yield put(
      actions.loadTimecard({ timecardEntryHeaderId: currentTimecardHeaderId }),
    );
  }
}

function* fetchTimecardsByHeaderIds(api, debug, timecardHeaderIds) {
  const projectId = yield select(getProject);

  const data = {
    filters: [
      {
        field: 'timecardEntryHeaderId',
        type: 'key',
        values: timecardHeaderIds,
      },
    ],
    sortBy: [{ id: 'employee', order: 'asc' }],
  };
  let timecards = yield call(api.wtc.fetchDrawerTimecards, {
    projectId,
    data,
  });

  return timecards;
}

function* fetchTimecardsByBatchWorkSightId(api, debug, batchWorksightId) {
  const projectId = yield select(getProject);

  const data = {
    filters: [
      {
        field: 'batchWorksightId',
        type: 'key',
        values: [batchWorksightId],
      },
    ],
    sortBy: [{ id: 'employee', order: 'asc' }],
  };
  let payload = yield call(api.wtc.fetchDrawerTimecards, {
    projectId,
    data,
  });

  return payload;
}

function* multiBatchInit(api, debug, params) {
  try {
    yield put(actions.setIsMultiBatch({ isMultiBatch: true }));
    yield put(
      actions.loadingTimecardsInDrawer({ loadingDrawerTimecards: true }),
    );

    //clear existing timecard
    yield put(actions.storeParsedTimecard({ timecard: {} }));

    const currentTimecardHeaderId = yield select(getCurrentTimecardHeaderId);

    if (currentTimecardHeaderId) {
      yield put(
        actions.loadTimecard({
          timecardEntryHeaderId: currentTimecardHeaderId,
        }),
      );
    }

    const timecardHeaderIds = yield select(getWTCTimecardHeaderIds);

    const projectId = yield select(getProject);

    if (!timecardHeaderIds || timecardHeaderIds.length === 0) {
      yield put(push(`/projects/${projectId}/review/search-timecards`));
      return;
    }

    const timecards = yield fetchTimecardsByHeaderIds(
      api,
      debug,
      timecardHeaderIds,
    );

    let isSameBatch = true;
    let prevBatch = null;
    for (let i = 0; i < timecards.length; i++) {
      const t = timecards[i];
      if (i === 0) {
        prevBatch = t?.batchId;
      }
      if (prevBatch !== t?.batchId) {
        isSameBatch = false;
        break;
      }
      prevBatch = t?.batchId;
    }
    if (isSameBatch) {
      yield put(actions.fetchBatchInfo({ hoursBatchId: prevBatch }));
    }

    yield put(
      actions.storeTimecardsInDrawer({
        timecards,
        numTimecardsHidden: undefined,
      }),
    );

    yield put(actions.getFiltersFromTimecards());
  } catch (error) {
    debug(error);
  } finally {
    yield put(
      actions.loadingTimecardsInDrawer({ loadingDrawerTimecards: false }),
    );
  }
}

function* RefreshDrawer(api, debug, params) {
  const isMultiBatch = yield select(getIsMultiBatch);
  if (isMultiBatch) {
    yield put(actions.multiBatchInit());
  } else {
    const currentBatchWorksightId = yield select(getCurrentBatchWorksightId);
    yield put(
      actions.fetchTimecardsInBatch({ worksightId: currentBatchWorksightId }),
    );
  }
}

function* multiBatchInfoCheck(api, debug, params) {
  try {
    const isMultiBatch = yield select(getIsMultiBatch);
    if (!isMultiBatch) return;
    const batch = yield select(getBatchInfo);

    const timecard = yield delayOnValueObj(getTimecard, { timeout: 8000 });
    if (batch?.worksightId !== timecard.batch.id) {
      yield put(actions.storeBatchInfo({ batchInfo: null }));

      yield put(
        actions.fetchBatchInfo({
          worksightId: timecard.batch.id,
        }),
      );
    }
  } catch (error) {
    debug(error);
  }
}

function* updateWTCTimecardIdsPostMove(api, debug, params) {
  try {
    const currWTCTimecardHeaders = yield select(getWTCTimecardHeaderIds);
    const currentTimecard = yield select(getCurrentTimecardHeaderId);

    const { timecardHeaderIds } = params;

    let updated = false;
    let newCurrentTC = '';
    const wtcTimecardHeaderIds = currWTCTimecardHeaders.slice();
    timecardHeaderIds.forEach(entry => {
      //update timecardId List
      const index = currWTCTimecardHeaders.findIndex(id => id === entry.oldId);
      if (index !== -1) {
        updated = true;
        wtcTimecardHeaderIds[index] = entry.newId;
      }

      //update current timecard
      if (entry.oldId === currentTimecard) {
        newCurrentTC = entry.newId;
      }
    });
    if (updated) {
      yield put(actions.setWTCTimecardHeaderIds({ wtcTimecardHeaderIds }));
    }

    if (newCurrentTC) {
      yield put(
        actions.setCurrentTimecardHeaderId({
          currentTimecardHeaderId: newCurrentTC,
        }),
      );
    }
  } catch (error) {
    debug(error);
  }
}

export function* fetchBatchInfo(api, debug, params) {
  try {
    const projectId = yield select(getProject);

    const { worksightId } = params;
    let { hoursBatchId } = params;

    if (!hoursBatchId) {
      let tempBatchInfo = yield call(api.wtc.reviewBatch, {
        projectId,
        worksightId,
      });
      const { id } = tempBatchInfo;
      hoursBatchId = id;
    }

    const batchInfo = yield call(api.moveTimecards.fetchBatchInfo, {
      projectId,
      batchId: hoursBatchId,
    });

    yield put(actions.storeBatchInfo({ batchInfo }));
  } catch (error) {
    debug(error);
  }
}

export function* fetchTimecardsInBatch(api, debug, params) {
  try {
    yield put(
      actions.loadingTimecardsInDrawer({ loadingDrawerTimecards: true }),
    );
    const { worksightId } = params;
    const isNewBatchComing = yield select(getLoadingNewBatch);
    if (!isNewBatchComing) {
      const data = yield call(
        fetchTimecardsByBatchWorkSightId,
        api,
        debug,
        worksightId,
      );

      const timecards = data.timecards || []; //data.timecards can be null when no timecards are in batch
      const numTimecardsHidden = data.batch?.numTimecardsHidden || 0;

      yield put(
        actions.storeTimecardsInDrawer({ timecards, numTimecardsHidden }),
      );
      yield put(actions.getFiltersFromTimecards());
    } else {
      yield put(actions.setLoadingNewBatch({ loadingNewBatch: false }));
    }
  } catch (e) {
    yield put(actions.storeTimecardsInDrawer({ timecards: [] }));
    debug(e);
    console.warn('No Data Available. Please reload from Timecards page');
    yield put(showAlert());
  } finally {
    yield put(
      actions.loadingTimecardsInDrawer({ loadingDrawerTimecards: false }),
    );
  }
}

/**
 * Pull department options from timecards
 * Make call to get Review Flow levels for status filter
 */
export function* getFiltersFromTimecards(api, debug, params) {
  try {
    const timecardsInDrawer = yield delayOnValueObj(getTimecardsInDrawer);
    const existingFilters = yield select(getFilters);

    const departmentOptions = [];
    let count = 0;
    const departments = timecardsInDrawer.map(t => ({
      id: t.departmentId,
      name: t.department,
    }));

    const existingDept = existingFilters?.department;

    departments.forEach(d => {
      const isInOptions = departmentOptions.find(
        option => option.value === d.id,
      );
      if (!isInOptions) {
        const currValFilter = existingDept?.find(f => f.value === d.id);
        const selected = !!currValFilter?.selected;
        departmentOptions.push({
          index: count,
          label: d.name,
          selected,
          value: d.id,
        });
        count++;
      }
    });

    yield put(
      actions.storeFilterOptions({
        filterName: 'department',
        options: departmentOptions,
      }),
    );

    const existingStatus = existingFilters?.status;

    const levels = yield delayOnValueObj(getReviewFlowLevels);
    const statusOptions = levels.map((level, index) => {
      const currValFilter = existingStatus?.find(
        f => f.value === level.description,
      );
      const selected = !!currValFilter?.selected;

      return {
        index,
        label: level.description,
        selected,
        value: level.description,
      };
    });
    yield put(
      actions.storeFilterOptions({
        filterName: 'status',
        options: statusOptions,
      }),
    );
  } catch (error) {
    debug(error);
    yield put(showAlert());
  }
}

//All the action around loading a new timecard
//This one is invoked from the UI and onLoad
function* loadTimecard(api, debug, params) {
  const { id: projectId } = yield select(getCurrentProject);
  const { timecardEntryHeaderId } = params;
  try {
    yield put(actions.clearTimecard());
    yield put(actions.setActionType({ actionType: '' }));
    yield put(actions.onEmployeesWWChange({ employeeWWchanged: false }));
    yield put(actions.onProducersWWChange({ producerWWChanged: false }));
    yield put(
      actions.fetchTimecard({
        projectId,
        timecardEntryHeaderId,
        initialLoad: true,
      }),
    );
    yield put(actions.multiBatchInfoCheck());
    yield put(actions.fetchPaidHours({ timecardEntryHeaderId }));
    yield put(
      actions.setCurrentTimecardHeaderId({
        currentTimecardHeaderId: timecardEntryHeaderId,
      }),
    );
    yield put(actions.fetchEpisodes({ initialLoad: true }));
    yield put(actions.fetchBreakdown());
    yield put(actions.fetchComments());
    yield put(actions.fetchSplitHourTypes({ initialLoad: true }));
    yield put(actions.clearScaleRateError());
  } catch (error) {
    debug(error);
  }
}

export function* fetchTimecard(api, debug, params) {
  try {
    yield put(actions.loadingTimecard({ loadingTimeCard: true }));
    yield put(actions.resetTableFields());
    yield put(actions.resetAllAdditionalFields());
    yield put(actions.storeTimecard({ timecard: {} }));
    yield put(actions.storeComments({ comments: [] }));
    const project = yield select(getCurrentProject);
    const { id: projectId } = project;
    const { timecardEntryHeaderId, initialLoad } = params;

    const [timecard, timecardAdditional] = yield all([
      call(api.wtc.timecard, { projectId, timecardEntryHeaderId }),
      call(api.wtc.getHPlusTimecardInfo, { projectId, timecardEntryHeaderId }),
    ]);
    addAdditionalTimecardInfo(timecard, timecardAdditional);

    if (
      (timecard.status === 'SignedOff' || timecard.status === 'Approved') &&
      timecard.timecardId
    ) {
      const nextAppParams = { timecardId: timecard.timecardId };

      yield fork(fetchNextApprovers, api, debug, nextAppParams);
      yield fork(fetchCurrentApprovers, api, debug, nextAppParams);
    }

    const dmParams = {
      initialLoad,
      employeeId: timecard.employee.id,
      startDate: timecard.weekStartingDate,
      endDate: timecard.weekEndingDate,
    };
    yield fork(fetchDealMemos, api, debug, dmParams);

    const rounding = yield fetchRounding(api, debug, { timecard });
    addAdditionalTimecardInfo(timecard, null, rounding);

    const drawerTimecards = yield delayOnValueObj(getTimecardsInDrawer, {
      timeout: 5000,
    });

    validateTimecard(timecard, drawerTimecards);

    const htgContractId =
      timecard &&
      timecard.dealMemo &&
      timecard.dealMemo.htgContract &&
      timecard.dealMemo.htgContract.id;

    var data = {
      timecard: {
        ...timecard,
        htgContractId,
        employeesWorkWeek: `${moment(timecard.employeesWorkWeek).format(
          'YYYY-MM-DD',
        )}`,
        producersWorkWeek: `${moment(timecard.producersWorkWeek).format(
          'YYYY-MM-DD',
        )}`,
      },
    };

    const settings = yield select(getSettings);
    //WTC templates api
    const resp = yield call(api.projects.getTimecardTemplates);
    const templates = resp.documents;

    const columns = yield call(api.dts.fetchTemplateColumns, {
      name:
        timecard?.wtcLayoutName ||
        settings?.wtcLayout?.label ||
        WTC_LAYOUT_STANDARED_TEMPLATE, // if wtcLayoutName is not found in timecard sending default to H+ WTC Standard
    });

    //filter selected template fields from the list of all templates
    let selected = templates.find(item => item.name === timecard.wtcLayoutName);

    //if wtc layout name not found/mismatch in the list of all templates then checking default H+ template
    if (_.isEmpty(selected)) {
      if (settings?.wtcLayout?.label) {
        selected = templates.find(
          item => item.name === settings?.wtcLayout?.label,
        );
      } else {
        selected = templates.find(
          item => item.name === WTC_LAYOUT_STANDARED_TEMPLATE,
        );
      }
    }

    // store timecard
    yield put(actions.storeTimecard(data));

    if (!_.isEmpty(data)) {
      yield delayOnValueObj(getTimecard, { timeout: 5000 });
    }

    yield put(
      actions.initTableFields({
        project,
        columns,
        templateFields: selected?.templateFields || [],
      }),
    );
    yield put(reset(WTC_FORM_NAME));
    yield put(actions.loadingTimecard({ loadingTimeCard: false }));
    //storeTimecard is async - ensure we have data to init

    const fetchParams = {
      initialLoad: initialLoad,
      contractId: timecard.dealMemo.contract?.id,
      htgUnionId: timecard.dealMemo.htgUnion?.id,
      dbCodeId: project.dbCodeId,
      pensionUnionId: timecard.dealMemo.pensionUnion?.id,
    };

    Object.keys(fetchParams).forEach(key => {
      const value = fetchParams[key];
      if (!value) {
        throw new Error(
          `Missing parameter for fetching supporting data. ${key} value is "${value}"`,
        );
      }
    });

    yield all([
      fork(occupationCodes, api, debug, fetchParams),
      fork(dayType, api, debug, {
        initialLoad: initialLoad,
        parentValue: timecard.dealMemo.htgContract.id,
      }),
      fork(workLocations, api, debug, fetchParams),
      fork(fetchAllowancePayCode, api, debug, fetchParams),
    ]);
  } catch (e) {
    debug(e);
    yield put(showAlert());

    yield put(actions.loadingTimecard({ loadingTimeCard: false }));
  }
}

export function* fetchPaidHours(api, debug, params) {
  try {
    const { timecardEntryHeaderId, resetLoading } = params;
    if (!resetLoading) {
      yield put(actions.processing({ processing: { paidHours: true } }));
    }

    const project = yield select(getCurrentProject);
    const projectId = project.id;
    const data = yield call(api.wtc.paidHours, {
      projectId,
      timecardEntryHeaderId,
      region: project.region,
    });
    let paidHours = isEmpty(data) ? { empty: true } : data;
    yield put(actions.storePaidHours({ paidHours }));
    if (!resetLoading) {
      yield put(actions.processing({ processing: { paidHours: false } }));
    }
  } catch (e) {
    debug(e);
    if (!params.resetLoading) {
      yield put(actions.processing({ processing: { paidHours: false } }));
    }
  }
}

export function* fetchBreakdown(api, debug) {
  try {
    yield put(actions.processing({ processing: { TCBreakdown: true } }));

    const projectId = yield select(getProject);
    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);
    const breakdown = yield call(api.wtc.getTimecardBreakdown, {
      projectId,
      timecardEntryHeaderId,
    });
    yield put(actions.storeTCBreakdown({ breakdown }));
    yield put(actions.processing({ processing: { TCBreakdown: false } }));
  } catch (e) {
    debug(e);
    yield put(actions.processing({ processing: { TCBreakdown: false } }));
  }
}

export function* signalRNotificationVerification(api, debug, params) {
  try {
    const { requestType, timecards: msgTimecards } = params;

    if (requestType !== 'NotifyCalculate') {
      // console.debug('WTC ASYNC: Incorrect request type');
      return;
    }

    const currentTimecardHeaderId = yield select(getCurrentTimecardHeaderId);

    const msgTimecard = msgTimecards.find(
      tc => tc.headerId === currentTimecardHeaderId,
    );

    if (!msgTimecard) {
      // console.debug('WTC ASYNC: Current timecard not in SignalR Msg');
      return;
    }

    yield put(actions.fetchTCUpdate({ msgTimecard }));
  } catch (error) {
    debug(error);
  }
}

// Update triggered by HTG update (webSocket / signalR)
export function* fetchTCUpdate(api, debug, params) {
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));
    const { msgTimecard } = params;
    const project = yield select(getCurrentProject);
    const projectId = project.id;

    yield call(RefreshTimecard, api, debug);
    const breakdownData = yield call(api.wtc.getTimecardBreakdown, {
      projectId,
      timecardEntryHeaderId: msgTimecard.headerId,
    });

    const paidHoursData = yield call(api.wtc.paidHours, {
      projectId,
      timecardEntryHeaderId: msgTimecard.headerId,
      region: project.region,
    });
    let paidHours = isEmpty(paidHoursData) ? { empty: true } : paidHoursData;

    yield put(actions.storeTCBreakdown({ breakdown: breakdownData }));
    yield put(actions.storePaidHours({ paidHours }));

    yield put(
      showAlert({
        message: 'Timecard has been updated',
        variant: 'info',
      }),
    );

    yield put(stopSubmit(WTC_FORM_NAME));
    yield put(reset(WTC_FORM_NAME));

    const timecard = yield select(getFormValues(WTC_FORM_NAME));
    const timecardId = timecard.timecardId;
    yield call(fetchCurrentApprovers, api, debug, { timecardId });
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.savingTimecard({ savingTimecard: false }));
  }
}

export function* fetchComments(api, debug) {
  try {
    yield put(actions.processing({ processing: { comments: true } }));
    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);
    const projectId = yield select(getProject);
    const comments = yield call(api.wtc.getTimecardComments, {
      projectId,
      timecardEntryHeaderId,
    });

    yield put(actions.storeComments({ comments }));
    yield put(actions.processing({ processing: { comments: false } }));
  } catch (e) {
    debug(e);
    yield put(actions.processing({ processing: { comments: false } }));
  }
}

export function* fetchNextApprovers(api, debug, params) {
  try {
    const timecardId = params.timecardId;

    const projectId = yield select(getProject);

    const nextApprovers = yield call(api.wtc.getTimecardNextApprovers, {
      timecardId,
      projectId,
    });

    yield put(actions.storeNextApprovers({ nextApprovers }));
  } catch (e) {
    debug(e);
  }
}

function* reviewUPMTimecards(api, debug, params) {
  const { status, comment, timecardEntryHeaderIds } = params;
  try {
    yield put(processingApproval({ loading: true }));
    const projectId = yield select(getProject);
    const currentTimecardHeaderId = yield select(getCurrentTimecardHeaderId);
    const data = { comment: comment || '', status, timecardEntryHeaderIds };
    yield call(api.reviews.reviewBatch, { projectId, data });
    yield delay(1500);

    yield call(RefreshDrawer, api, debug);

    if (currentTimecardHeaderId) {
      yield put(
        actions.loadTimecard({
          timecardEntryHeaderId: currentTimecardHeaderId,
        }),
      );
    }

    let message =
      timecardEntryHeaderIds.length > 1 ? 'Timecards have' : 'Timecard has';
    if (params.comment) {
      message += ` been Rejected`;
    } else {
      message += ` been Approved`;
    }
    yield put(
      showAlert({
        message,
        variant: 'info',
      }),
    );
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(processingApproval({ loading: false }));
  }
}

// timecardId - H+ id: 4550
export function* fetchCurrentApprovers(api, debug, params) {
  try {
    let timecardId = params && params.timecardId;
    const projectId = yield select(getProject);

    //get timecardId if not passed in
    if (!timecardId) {
      const timecard = yield select(getFormValues(WTC_FORM_NAME));
      timecardId = (params && params.timecardId) || timecard.timecardId;
    }
    const currentApprovers = yield call(api.wtc.getTimecardCurrentApprovers, {
      timecardId,
      projectId,
    });

    yield put(actions.storeCurrentApprovers({ currentApprovers }));
  } catch (e) {
    debug(e);
  }
}

function* approveTimecard(api, debug, param) {
  const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);

  try {
    yield put(
      actions.approvingTimecard({
        approving: true,
        headerId: timecardEntryHeaderId,
      }),
    );
    const timecard = yield select(getFormValues(WTC_FORM_NAME));
    const timecardId = timecard && timecard.timecardId;
    const approvingTimecard = yield select(state =>
      isApprovingTimecard(state, timecardEntryHeaderId),
    );

    const hasErrors = (timecard.errors || []).some(
      e => e && e.level === 'Error',
    );

    if (approvingTimecard && !hasErrors) {
      yield call(approveRejectAction, {
        api,
        debug,
        timecardId,
        status: 'approved',
        comment: '',
        timecardEntryHeaderId,
      });
      //left drawer tcs missing information
      yield delay(1000);
      yield call(RefreshDrawer, api, debug);
      yield call(RefreshTimecard, api, debug);
      yield put(hideModal({ dialog: 'ForceCommentWTC' }));
      yield put(reset(WTC_FORM_NAME));
    }
  } catch (e) {
    debug(e);
    yield onError(e, timecardEntryHeaderId);
  } finally {
    yield put(
      actions.approvingTimecard({
        approving: false,
        headerId: timecardEntryHeaderId,
      }),
    );
  }
}

function* onError(e, timecardEntryHeaderId) {
  const errors = e.data || {};
  yield put(
    actions.approvingTimecard({
      approving: false,
      headerId: timecardEntryHeaderId,
    }),
  );
  if (errors.ExceptionMessage) {
    yield put(
      showAlert({
        message: errors.ExceptionMessage,
        variant: 'error',
      }),
    );
  } else {
    yield put(showAlert());
  }
}

export function* saveTimecardIso(api, debug) {
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));
    yield* saveTimecard(api, debug);

    yield put(stopSubmit(WTC_FORM_NAME));
    yield put(hideModal({ dialog: 'ForceCommentWTC' }));
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    yield put(actions.savingTimecard({ savingTimecard: false }));
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      showAlert();
    }
  }
}

function* checkAllowancesDoc(api, debug, saveTimecardData, projectId) {
  const timecardId = saveTimecardData.timecardId;
  const allowancesList = saveTimecardData.allowances || [];
  const callCount = { count: 0 };
  // don't use spawn here
  yield all(
    allowancesList.map(allowance => {
      return call(saveAllowance, api, debug, allowance, {
        projectId,
        timecardId,
        callCount,
      });
    }),
  );
  return callCount;
}

function* saveAllowance(
  api,
  debug,
  allowance,
  { projectId, timecardId, callCount },
) {
  try {
    if (
      (allowance.document && allowance.document instanceof File) ||
      (allowance.rowId && allowance.document === null && !allowance.comment) ||
      (allowance.rowId && allowance.copied)
    ) {
      const composedAllowance = composeAllowanceV1(allowance, {});
      const data = convertToFormData(composedAllowance);

      let response;
      let matchedResponse;

      if (allowance.rowId && !allowance.copied) {
        // existing allowance with new supportDocument
        // or remove supportDocument from existing allowance
        callCount.count++;
        response = yield call(api.employeeTimecard.updateTimecardAllowance, {
          projectId,
          timecardId,
          worksightId: allowance.rowId,
          data,
          saveHTGTimecard: false,
        });

        matchedResponse = response;
      } else if (allowance.rowId && allowance.copied) {
        // For copied allowance
        callCount.count++;
        response = yield call(api.employeeTimecard.copyTimecardAllowance, {
          projectId,
          timecardId,
          worksightId: allowance.rowId,
          data,
          saveHTGTimecard: false,
        });

        matchedResponse = response;
      } else {
        // new allowance with new supportDocument
        callCount.count++;
        response = yield call(api.employeeTimecard.saveTimecardAllowance, {
          projectId,
          timecardId,
          data,
          saveHTGTimecard: false,
        });

        matchedResponse = response;
      }
      delete allowance.document;
      if (matchedResponse.token) {
        allowance.comment = `${hostUrl}/allowances/${
          matchedResponse && matchedResponse.token
        }`;
      } else {
        delete allowance.comment;
      }
      allowance.rowId = matchedResponse.worksightId;
      if (allowance.copied) delete allowance.copied;
    }
  } catch (e) {
    debug(e);
  }
}

export function* saveTimecard(api, debug) {
  yield put(startSubmit(WTC_FORM_NAME));
  const project = yield select(getCurrentProject);
  let projectId = project.id;
  const user = yield select(userInfoById(projectId));
  const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);
  const updates = yield select(getFormValues(WTC_FORM_NAME));
  //removing empty dealmemo rows -> applicable for partial dealmemo scenarios
  const validDealMemoDays = updates?.details.filter(tc => tc.dealMemo !== null);
  const updatedPayload = {
    ...updates,
    details: validDealMemoDays,
  };
  const initial = yield select(getTimecard);
  const timecardData = isEmpty(updatedPayload) ? initial : updatedPayload;

  let saveTimecardData = cloneDeep(timecardData);
  if (project?.region === 'Canada') {
    for (let i = 0; i < saveTimecardData?.allowances.length; i++) {
      const allowance = saveTimecardData?.allowances[i];

      const locationTypes = yield select(getWorkLocationTypes);
      const studioLocation = locationTypes?.find(x => x.code === 'S');
      if (!studioLocation) {
        yield put(
          showAlert({
            message: 'No "Studio" location type found. Please contact support.',
            variant: 'error',
          }),
        );
        throw new Error(
          'Expected Location Types to contain option with code "S"',
        );
      }

      allowance.locationType = studioLocation;
    }
  }

  saveTimecardData = {
    ...saveTimecardData,
    updatedBy: {
      name: user.fullName,
      email: user.email,
      oktaId: user.oktaId,
    },
  };

  // save allowances in this new function which is just the same process extracted out
  yield call(checkAllowancesDoc, api, debug, saveTimecardData, projectId);

  let needToAddChangeComment = false;
  const isDraft = yield select(getIsDraft);
  if (saveTimecardData.canEditWorkTimes && !isDraft) {
    needToAddChangeComment = true;
  }

  prepTimecardForSave(saveTimecardData);
  yield call(api.wtc.saveTimecard, {
    projectId,
    timecardEntryHeaderId,
    timecard: saveTimecardData,
  });

  if (needToAddChangeComment) {
    yield call(addForceResubmitComment, api, debug);
  }
  yield race({
    timeout: call(saveTimeout),
    completed: take(`${actions.fetchTCUpdate}`),
  });
  //refresh left drawer to get hasTimecardErrors flag updated value
  yield delay(1500);
  yield call(RefreshDrawer, api, debug);
}

function* saveTimeout() {
  yield delay(15000);
  yield put(actions.savingTimecard({ savingTimecard: false }));
  yield put(
    showAlert({
      message: 'Error saving timecard. Please try again',
      variant: 'warning',
    }),
  );
}

export function* reject(api, debug, params) {
  try {
    const { comment, referenceDate, timecardId } = params;

    yield put(actions.rejectingTimecard({ rejectingTimecard: true }));
    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);

    const data = {
      comment: comment,
      timecardEntryHeaderId: timecardEntryHeaderId,
    };
    if (referenceDate) {
      data.referenceDate = referenceDate;
    }

    yield put(hideModal({ dialog: 'ForceCommentWTC' }));

    //PA or UPM Reject
    yield call(approveRejectAction, {
      api,
      debug,
      timecardId,
      status: 'rejected',
      comment: data.comment,
      timecardEntryHeaderId,
    });
    //left drawer tcs missing information
    yield delay(1000);
    yield call(RefreshDrawer, api, debug);
    yield call(RefreshTimecard, api, debug);

    yield call(fetchComments, api, debug);
    yield put(actions.rejectingTimecard({ rejectingTimecard: false }));
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    yield put(actions.rejectingTimecard({ rejectingTimecard: false }));
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}
export function* resubmit(api, debug, params) {
  try {
    const { comment, referenceDate } = params;

    yield put(actions.rejectingTimecard({ rejectingTimecard: true }));

    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);

    const data = {
      comment: comment,
      timecardEntryHeaderId: timecardEntryHeaderId,
    };
    if (referenceDate) {
      data.referenceDate = referenceDate;
    }

    yield put(hideModal({ dialog: 'ForceCommentWTC' }));

    const projectId = yield select(getProject);
    yield call(api.wtc.resubmit, { data, projectId, timecardEntryHeaderId });

    yield call(RefreshDrawer, api, debug);
    yield call(fetchComments, api, debug);
    yield put(actions.rejectingTimecard({ rejectingTimecard: false }));

    const currentTimecardHeaderId = yield select(getCurrentTimecardHeaderId);

    yield put(
      actions.loadTimecard({ timecardEntryHeaderId: currentTimecardHeaderId }),
    );
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    yield put(actions.rejectingTimecard({ rejectingTimecard: false }));
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

export function* recall(api, debug, params) {
  try {
    const { comment, referenceDate } = params;

    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);

    const data = {
      comment: comment,
      timecardEntryHeaderId: timecardEntryHeaderId,
    };
    if (referenceDate) {
      data.referenceDate = referenceDate;
    }

    yield put(hideModal({ dialog: 'ForceCommentWTC' }));

    const projectId = yield select(getProject);
    yield call(api.wtc.recall, { data, projectId, timecardEntryHeaderId });

    yield call(RefreshDrawer, api, debug);
    yield call(fetchComments, api, debug);

    const currentTimecardHeaderId = yield select(getCurrentTimecardHeaderId);

    yield put(
      actions.loadTimecard({ timecardEntryHeaderId: currentTimecardHeaderId }),
    );
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

function* addForceResubmitComment(api, debug) {
  let comment = yield select(getResubmitComment);

  if (!comment) comment = yield call(calcResubmitComment, api, debug);

  if (comment) {
    yield put(actions.addComment({ comment, resubmitComment: true }));
  }
  yield put(actions.storeResubmitComment({ comment: '' }));
}

export function* addComment(api, debug, params) {
  const { comment, formName, resubmitComment } = params;
  try {
    if (formName) yield put(startSubmit(formName));

    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);

    let data = {
      comment: comment,
      timecardEntryHeaderId: timecardEntryHeaderId,
      commentType: 'note',
    };
    if (resubmitComment) {
      data.commentType = 'corrections';
    }
    const projectId = yield select(getProject);

    yield call(api.wtc.addComment, { data, projectId });
    yield put(hideModal({ dialog: 'AddWTCComment' }));
    yield call(fetchComments, api, debug);
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  } finally {
    if (formName) yield put(stopSubmit(formName));
  }
}

export function* deleteTimecard(api, debug, params) {
  const { timecard, formName, comment } = params;
  try {
    if (formName) yield put(startSubmit(formName));
    const timecardId = timecard && timecard.timecardId;
    const timecardEntryHeaderId = timecard && timecard.timecardEntryHeaderId;
    const project = yield select(getCurrentProject);
    const projectId = project.id;

    //delete timecard on server
    yield call(api.timecards.deleteTimecard, {
      projectId,
      timecardId,
      comment,
    });

    //remove timecard from drawer list
    const timecardsInDrawer = yield select(getTimecardsInDrawer);
    const newTimecardsInDrawer = timecardsInDrawer.filter(
      x => x.timecardEntryHeaderId !== timecardEntryHeaderId,
    );
    yield put(
      actions.storeTimecardsInDrawer({ timecards: newTimecardsInDrawer }),
    );

    //reset the WTC
    yield put(actions.resetTableFields());
    yield put(actions.resetAllAdditionalFields());
    yield put(actions.storeTimecard({ timecard: {} }));
    yield put(actions.storeComments({ comments: [] }));
    yield put(reset(WTC_FORM_NAME));
    yield put(hideModal({ dialog: 'DeleteTimecardWTC' }));
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  } finally {
    if (formName) yield put(stopSubmit(formName));
  }
}

export function* undo(api, debug) {
  try {
    yield put(actions.loadingUndo({ loadingUndo: true }));
    const projectId = yield select(getProject);
    const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);

    yield call(fetchTimecardReset, api, debug, {
      projectId,
      timecardEntryHeaderId,
      resetLoading: true,
      timecardUpdate: true,
    });
    yield put(actions.loadingUndo({ loadingUndo: false }));
    yield put(reset(WTC_FORM_NAME));
  } catch (e) {
    yield put(actions.loadingUndo({ loadingUndo: false }));
  }
}

export function* fetchWorkSchedules(api, debug, params) {
  try {
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { workSchedule: true } }));
    }
    const type = 'workSchedule';
    if (params.initialLoad || (params.search && params.search.trim() !== '')) {
      const workSchedules = yield call(api.wtc.searchByType, {
        type,
        params,
      });
      yield put(actions.storeWorkSchedules({ workSchedules }));
    }
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { workSchedule: false } }));
    }
  } catch (e) {
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { workSchedule: false } }));
    }
  }
}

export function* fetchEpisodes(api, debug, params) {
  try {
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { episode: true } }));
    }
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    const type = 'episode';
    const parentValue = projectWorkSightId;
    if (params.initialLoad || (params.search && params.search.trim() !== '')) {
      const data = yield call(api.wtc.searchByType, {
        type,
        params: { ...params, parentValue },
      });
      yield put(actions.storeEpisodes({ episodes: data }));
    }
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { episode: false } }));
    }
  } catch (e) {
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { episode: false } }));
    }
  }
}

export function* fetchDealMemos(api, debug, params) {
  try {
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { dealMemo: true } }));
    }
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    const {
      employeeId,
      startDate,
      endDate,
      withPayroll,
      shouldAddRounding,
      shouldAddScale,
    } = params;
    const data = yield call(api.employees.dealMemos, {
      projectId: projectWorkSightId,
      employeeId,
      startDate,
      endDate,
      withPayroll,
    });
    if (!Array.isArray(data)) {
      yield put(showAlert()); //Call is returning with code 200 but not proper data, see hour-2113 for details.
      throw new Error('Response expected to be Array');
    }

    if (shouldAddRounding) {
      const dealMemoIds = data.map(dm => dm.id);
      const rounding = yield fetchRounding(api, debug, { dealMemoIds });
      data.forEach(deal => addRoundingToDeal(deal, rounding));
    }

    if (shouldAddScale) {
      //This does have the side effect of modifying the dealmemo data that sticks around afterwards
      //but that shouldn't matter as its getting replaced by the scale rates everywhere important
      yield call(addScaleToDeals, api, debug, { dealMemos: data });
    }

    yield put(actions.storeDealMemos({ dealMemos: data }));

    if (params.initialLoad) {
      yield put(actions.loading({ loading: { dealMemo: false } }));
    }
  } catch (e) {
    debug(e);
    if (params.initialLoad) {
      yield put(actions.loading({ loading: { dealMemo: false } }));
    }
  }
}

export function* addScaleToDeals(api, debug, params) {
  try {
    const { dealMemos } = params;
    const timecard = yield select(getTimecard);

    for (let i = 0; i < dealMemos.length; i++) {
      const dealMemo = dealMemos[i];

      const guarantees = dealMemo?.guarantees || [];

      const anyYPayAtScale = guarantees.some(g => g.payAtScale === 'Y');
      const anyCPayAtScale = guarantees.some(g => g.payAtScale === 'C');

      const scaleYParams = {
        dealMemoId: dealMemo.id,
        weekEndingDate: timecard.weekEndingDate,
        payAtScale: 'Y',
      };

      const scaleCParams = { ...scaleYParams, payAtScale: 'C' };

      const rates = {};
      if (anyYPayAtScale) {
        rates.Y = yield fetchScaleRate(api, debug, scaleYParams);
      }
      if (anyCPayAtScale) {
        rates.C = yield fetchScaleRate(api, debug, scaleCParams);
      }

      guarantees.forEach(g => {
        if (g.payAtScale === 'Y' || g.payAtScale === 'C') {
          const localRates = rates[g.payAtScale];

          if (g.onLocation?.code === 'D' || g.onLocation?.code === 'DS') {
            if (localRates.distant) g.rate = localRates.distant;
            if (localRates.distantGross) g.gross = localRates.distantGross;
          } else {
            if (localRates.studio) g.rate = localRates.studio;
            if (localRates.studioGross) g.gross = localRates.studioGross;
          }
        }
      });
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* occupationCodes(api, debug, params) {
  try {
    const project = yield select(getCurrentProject);

    if (params.initialLoad || (params.search && params.search.trim() !== '')) {
      const data = yield call(api.wtc.searchByType, {
        type: 'occupationCode',
        params: {
          parentValue:
            params.dbCodeId.length > 1 ? params.dbCodeId : project.dbCodeId,
          options:
            params.contractId && params.pensionUnionId
              ? { contract: params.contractId, union: params.pensionUnionId }
              : {
                  contract: params.htgContractId,
                  union: params.pensionUnionId,
                },
          search: params.search,
          pageSize: 20,
        },
      });
      yield put(actions.storeOccupationCodes({ data }));
    }
  } catch (e) {
    debug(e);
    console.error('Error fetching occupation codes');
  }
}

export function* dayType(api, debug, params) {
  try {
    const type = 'dayType';
    const parentValue = yield select(getTimecardHtgContractId(WTC_FORM_NAME));
    const data = yield call(api.wtc.searchByType, {
      type,
      params: parentValue ? { ...params, parentValue } : params,
    });

    const dayTypes = camelCase(data, { deep: true });
    yield put(actions.storeDayTypes({ dayTypes }));
  } catch (e) {
    debug(e);
    console.error('Error fetching day types');
  }
}

export function* workLocations(api, debug, params) {
  try {
    const wtc = yield select(getFormValues(WTC_FORM_NAME));
    const htgContractId =
      params.contractId || get(wtc, 'dealMemo.htgContract.id', '');
    const htgUnionId =
      params.htgUnionId || get(wtc, 'dealMemo.htgUnion.id', '');

    const data = yield call(api.wtc.searchByType, {
      type: 'locationType',
      params: {
        pageSize: 20,
        parentValue: params.parentValue,
        options:
          htgContractId && htgUnionId ? { htgContractId, htgUnionId } : {},
      },
    });
    yield put(actions.storeWorkLocations({ data }));
  } catch (e) {
    debug(e);
    console.error('Error fetching work locations');
  }
}

export function* fetchSplitHourTypes(api, debug, params) {
  try {
    const type = 'hoursTypeSplit';
    const splitHourTypes = yield call(api.wtc.searchByType, {
      type,
      params,
    });
    splitHourTypes.push({
      id: 'SelectAllIdEnabledWhenOptionsStarrable',
      name: 'All',
      code: '*',
    });
    yield put(actions.storeSplitHourTypes({ splitHourTypes }));
  } catch (e) {
    debug(e);
    console.error('Error fetching split hour types');
  }
}

export function* fetchAllowancePayCode(api, debug, params) {
  try {
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    const wtc = yield select(getFormValues(WTC_FORM_NAME));
    const pensionUnionId =
      params.pensionUnionId || get(wtc, 'dealMemo.pensionUnion.id', '');
    const type = 'allowancePayCodeInclude';
    const payCodes = yield call(api.wtc.searchByType, {
      type,
      params: {
        options:
          projectWorkSightId && pensionUnionId
            ? { project: projectWorkSightId, union: pensionUnionId }
            : {},
        pageSize: 100,
      },
    });
    yield put(actions.storeAllowancePayCode({ payCodes }));
  } catch (e) {
    debug(e);
    console.error('Error fetching allowance pay codes');
  }
}
export function* RefreshTimecard(api, debug, params) {
  const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);
  const { id: projectId } = yield select(getCurrentProject);
  const [timecard, timecardAdditional] = yield all([
    call(api.wtc.timecard, { projectId, timecardEntryHeaderId }),
    call(api.wtc.getHPlusTimecardInfo, { projectId, timecardEntryHeaderId }),
  ]);

  const rounding = yield fetchRounding(api, debug, {
    timecard,
  });

  addAdditionalTimecardInfo(timecard, timecardAdditional, rounding);

  yield put(actions.storeTimecard({ timecard }));
}

export function* printTimecardReport(api, debug, params) {
  try {
    yield put(actions.processing({ processing: { timecardReport: true } }));
    let data = '';
    if (params && params.params) {
      if (params.params === 'all') {
        const timecardsInDrawer = yield select(getFilterSortedTimecards);
        if (timecardsInDrawer) {
          data = {
            timecardEntryHeaderIds: yield timecardsInDrawer.map(
              item => item.timecardEntryHeaderId,
            ),
          };
        }
      } else {
        const timecardEntryHeaderId = yield select(getCurrentTimecardHeaderId);
        data = {
          timecardEntryHeaderIds: [timecardEntryHeaderId],
        };
      }
    }
    const projectId = yield select(getProject);
    const endpoint = `reports/projects/${projectId}/timecards`;
    yield call(api.downloader.downloadFromURIPost, {
      endpoint,
      data,
      filename: 'timecard.pdf',
    });

    yield put(actions.processing({ processing: { timecardReport: false } }));
  } catch (e) {
    yield put(actions.processing({ processing: { timecardReport: false } }));
  }
}

export function* fetchTimecardReset(api, debug, params) {
  try {
    const { resetLoading } = params;
    if (!resetLoading) {
      yield put(actions.loadingTimecard({ loadingTimeCard: true }));
    }

    yield call(RefreshTimecard, api, debug);

    if (!resetLoading) {
      yield put(actions.loadingTimecard({ loadingTimeCard: false }));
    }
  } catch (e) {
    if (!params.resetLoading) {
      yield put(actions.loadingTimecard({ loadingTimeCard: false }));
    }
  }
}

export function* fetchWTCHistory(api, debug, params) {
  try {
    yield put(actions.processing({ processing: { wtcHistory: true } }));

    const timecard = yield select(getFormValues(WTC_FORM_NAME));
    const timecardId = (params && params.timecardId) || timecard.timecardId;
    const projectId = yield select(getProject);
    const data = yield call(api.timecards.history, {
      projectId,
      timecardId,
    });
    const history = camelCase(data, { deep: true });
    yield put(actions.storeWTCHistory({ history, timecardId }));
    yield put(actions.processing({ processing: { wtcHistory: false } }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.processing({ processing: { wtcHistory: false } }));
  }
}

function* approveRejectAction({
  api,
  debug,
  timecardId,
  status,
  comment,
  timecardEntryHeaderId,
}) {
  const project = yield select(getCurrentProject);
  const projectId = project && project.id;

  const data = {
    comment: comment || '',
    status,
    timecardEntryHeaderIds: [timecardEntryHeaderId],
  };

  yield call(api.reviews.reviewBatch, {
    projectId,
    data,
  });
  yield call(fetchCurrentApprovers, api, debug, { timecardId });
}

// initial call after timecard load
// When parsing the incoming timecard we mark the rate as 'C' or 'Y' if its a payAtScale
// Then here run this to check which are marked and dispatch the action to fill them

export function* storeTimecard(api, debug, params) {
  try {
    const { timecard } = params;

    if (_.isEmpty(timecard)) {
      yield put(actions.storeParsedTimecard({ timecard: {} }));
      return;
    }

    const dealMemos = yield delayOnValueObj(getDealMemos);

    let parsedTimecard = _.cloneDeep(timecard);

    const parsedDealmemoId = parsedTimecard?.dealMemo?.id;

    const tcDealMemo = _.find(dealMemos, memo => memo.id === parsedDealmemoId);

    //helper functions start here
    const isDistantDay = day => {
      if (day?.locationType?.code) {
        const code = day.locationType.code;
        if (code === 'D' || code === 'DS') return true;
      }
      return false;
    };

    const getDealForDay = day => {
      const dayDealMemoId = day?.dealMemo?.id;
      const deal = _.find(dealMemos, memo => memo.id === dayDealMemoId);
      return deal;
    };

    //use studio or distant guarantee?
    const getGuaranteeForDay = day => {
      const dealMemo = getDealForDay(day);

      if (!dealMemo?.guarantees) return {};

      let studioGuarantee = _.find(
        dealMemo.guarantees,
        g => g.onLocation?.code === 'S',
      );
      if (!studioGuarantee) studioGuarantee = {};

      let distantGuarantee = _.find(
        dealMemo.guarantees,
        g => g.onLocation?.code === 'D',
      );
      if (!distantGuarantee) distantGuarantee = {};

      const isDistant = isDistantDay(day);
      if (isDistant && !_.isEmpty(distantGuarantee)) {
        return distantGuarantee;
      } else {
        return studioGuarantee;
      }
    };

    // function setGuaranteeDefaults() {
    //   const guaranteeFields = [
    //     'location',
    //     'accountCode',
    //     'insurance',
    //     'series',
    //     'set',
    //     'customField1',
    //     'customField2',
    //     'customField3',
    //     'customField4',
    //   ];
    //   for (let i = 0; i < parsedTimecard.details.length; i++) {
    //     const day = parsedTimecard.details[i];
    //     const theGuarantee = getGuaranteeForDay(day);

    //     const fieldsToCheck = guaranteeFields.filter(
    //       gf => theGuarantee && theGuarantee[gf] !== null,
    //     );

    //     fieldsToCheck.forEach(field => {
    //       const dayField = field.replace('customField', 'freeField');

    //       if (!day[dayField] && theGuarantee[field]) {
    //         day[dayField] = theGuarantee[field];
    //       }
    //     });
    //   }
    // }

    // workSchedule / paySchedule
    function setWorkSchedule() {
      for (let i = 0; i < parsedTimecard.details.length; i++) {
        const day = parsedTimecard.details[i];
        if (!day.schedule || !day.schedule.code) {
          const dealMemo = getDealForDay(day);
          let workSchedule = _.cloneDeep(dealMemo?.workSchedule);
          if (workSchedule) {
            day.schedule = workSchedule;
          }
        }
      }
    }

    function setOccupationCode() {
      for (let i = 0; i < parsedTimecard.details.length; i++) {
        const day = parsedTimecard.details[i];

        if (!day.occupationCode || !day.occupationCode.code) {
          const dealMemo = getDealForDay(day);
          const occupationCode = _.cloneDeep(dealMemo?.occupationCode);
          if (occupationCode) {
            day.occupationCode = occupationCode;
          }
        }
      }
    }

    function* setRate() {
      for (let i = 0; i < parsedTimecard.details.length; i++) {
        const day = parsedTimecard.details[i];
        const theGuarantee = getGuaranteeForDay(day);

        if (theGuarantee && !day.rate) {
          if (
            theGuarantee.payAtScale &&
            (theGuarantee.payAtScale === 'C' || theGuarantee.payAtScale === 'Y')
          ) {
            try {
              const scaleParams = {
                dealMemoId: day.dealMemo.id,
                weekEndingDate: parsedTimecard.weekEndingDate,
                payAtScale: theGuarantee.payAtScale,
              };

              const rates = yield fetchScaleRate(api, debug, scaleParams);
              if (!rates || rates === null || rates === 'fetching') {
                throw new Error('Invalid scale rate found');
              }

              const isDistant = isDistantDay(day);
              day.rate = isDistant ? rates.distant : rates.studio;
            } catch (error) {
              yield put(actions.storeScaleRateError());
              day.rate = '';
              console.error('scaleRateError');
            }
          } else if (
            theGuarantee.rate !== null &&
            theGuarantee.rate !== 0 &&
            theGuarantee.rate !== ''
          ) {
            day.rate = theGuarantee.rate;
          } else if (!theGuarantee.rate) {
            day.rate = '';
          }
        } else {
          //rate already set - do nothing
        }
      } //rof
    }

    function populateAllowanceData() {
      if (parsedTimecard.allowances && parsedTimecard.allowances.length > 0) {
        parsedTimecard.allowances.forEach(allowance => {
          if (typeof allowance.amount === 'number') {
            const newValue = formatAllowancesAmount(allowance.amount);
            allowance.amount = newValue;
          }
          if (_.isEmpty(allowance && allowance.workState)) {
            const stateId = _.get(allowance, 'workState.id', '');
            const cityId = _.get(allowance, 'workCity.id', '');
            const countyId = _.get(parsedTimecard, 'workCounty.id', '');
            const subdivisionId = _.get(
              parsedTimecard,
              'workSubdivision.id',
              '',
            );

            if (stateId) {
              // if state is true
              allowance.workState = _.cloneDeep(allowance.workState);
            }
            if (cityId) {
              allowance.workCity = _.cloneDeep(allowance.workCity);
            }
            if (countyId) {
              allowance.workCounty = _.cloneDeep(parsedTimecard.workCounty);
            }
            if (subdivisionId) {
              allowance.subdivisionId = _.cloneDeep(
                parsedTimecard.subdivisionId,
              );
            }

            const locationType = _.get(parsedTimecard, 'locationType.id', '');
            const episode = _.get(parsedTimecard, 'episode.id', '');
            const cck = parsedTimecard?.combineCheck;
            const accountCode = parsedTimecard?.accountCode;
            const series = parsedTimecard?.series;
            const set = parsedTimecard?.set;
            const freeField1 = parsedTimecard?.freeField1;
            const freeField2 = parsedTimecard?.freeField2;

            if (locationType && _.isEmpty(allowance.locationType)) {
              allowance.locationType = _.cloneDeep(parsedTimecard.locationType);
            }
            if (episode && _.isEmpty(allowance.episode)) {
              allowance.episode = _.cloneDeep(parsedTimecard.episode);
            }
            if (cck && !allowance.cck) {
              allowance.combineCheck = parsedTimecard.combineCheck;
            }
            if (accountCode && !allowance.accountCode) {
              allowance.accountCode = parsedTimecard.accountCode;
            }
            if (series && !allowance.series) {
              allowance.series = parsedTimecard.series;
            }
            if (set && !allowance.set) allowance.set = parsedTimecard.set;
            if (freeField1 && !allowance.freeField1) {
              allowance.freeField1 = parsedTimecard.freeField1;
            }
            if (freeField2 && !allowance.freeField2) {
              allowance.freeField2 = parsedTimecard.freeField2;
            }
          }

          if (!_.isEmpty(allowance)) {
            if (!allowance.accountCode && parsedTimecard.accountCode) {
              if (tcDealMemo) {
                const dealPayCode = getPayCodeFromDealMemoAllowance(
                  allowance?.reason?.id,
                  tcDealMemo,
                );
                allowance.accountCode = dealPayCode?.account
                  ? dealPayCode.account
                  : parsedTimecard.accountCode;
                allowance.freeField1 = dealPayCode?.customField1
                  ? dealPayCode.customField1
                  : parsedTimecard.freeField1;
                allowance.freeField2 = dealPayCode?.customField2
                  ? dealPayCode.customField2
                  : parsedTimecard.freeField2;
                allowance.freeField3 = dealPayCode?.customField3
                  ? dealPayCode.customField3
                  : parsedTimecard.freeField3;
                allowance.freeField4 = dealPayCode?.customField4
                  ? dealPayCode.customField4
                  : parsedTimecard.freeField4;
                allowance.location = dealPayCode?.location
                  ? dealPayCode.location
                  : parsedTimecard.location;
                allowance.series = dealPayCode?.series
                  ? dealPayCode.series
                  : parsedTimecard.series;
                allowance.set = dealPayCode?.set
                  ? dealPayCode.set
                  : parsedTimecard.set;
                allowance.insurance = dealPayCode?.insurance
                  ? dealPayCode.insurance
                  : parsedTimecard.insurance;
              } else {
                allowance.accountCode = parsedTimecard?.accountCode;
              }
            }

            if (!allowance.workSubdivision && parsedTimecard.workSubdivision) {
              allowance.workSubdivision = _.cloneDeep(
                parsedTimecard.workSubdivision,
              );
            }
          }
        });
      }
    }

    function setDefaultCCKValue() {
      if (!parsedTimecard.combineCheck) parsedTimecard.combineCheck = 'Y';
    }

    function sortDetailByDate() {
      if (parsedTimecard?.details?.length > 0) {
        parsedTimecard.details.sort((a, b) => {
          const aNum = new Date(a.effectiveDate).getTime();
          const bNum = new Date(b.effectiveDate).getTime();
          return aNum - bNum;
        });
      }
    }

    function formatWeekDates() {
      if (parsedTimecard.producersWorkWeek) {
        parsedTimecard.producersWorkWeek =
          parsedTimecard.producersWorkWeek.replace(/T00:00:00/, '');
      }
      if (parsedTimecard.employeesWorkWeek) {
        parsedTimecard.employeesWorkWeek =
          parsedTimecard.employeesWorkWeek.replace(/T00:00:00/, '');
      }
    }

    if (!_.isEmpty(parsedTimecard)) {
      setWorkSchedule();
      setOccupationCode();

      yield setRate();
      //setGuaranteeDefaults();
      populateAllowanceData();
      setDefaultCCKValue();
      sortDetailByDate();
      formatWeekDates();
    }

    yield put(actions.storeParsedTimecard({ timecard: parsedTimecard }));
  } catch (error) {
    debug(error);
    yield put(showAlert());
  }
}

/**
 * Action for setting scale based on user changes.  Expects multiple to be dispatched at
 * once. If rate is in localStorage, then fills from there but if need to fetch, 1st one will
 * trigger fetch and subsequent will do nothing.  Once fetch is complete all scale rates that were loading will be filled.
 * @param {string} member - The name of the day in redux-form - details[x]
 * @param {char} payAtScale - either Y or C - indicates what kind of scale we need
 */
function* fetchAndSetPayAtScale(api, debug, params = {}) {
  try {
    const { member, payAtScale } = params;
    yield put(actions.loadingScaleRate({ loading: true, member }));

    const formValues = yield select(getFullFormValues);

    const weekEndingDate = formValues.weekEndingDate;

    const memberIndex = member.match(/(\d)/)[1];
    const day = formValues.details[memberIndex];

    const isDistant = isDistantDay(day);

    const dealMemoId = day.dealMemo.id;

    const localRates = getRateFromLocal({
      dealMemoId,
      weekEndingDate,
      payAtScale,
    });

    switch (localRates) {
      case null:
        //Need to fetch rate from service
        const callParams = {
          dealMemoId,
          weekEndingDate,
          payAtScale,
        };

        const newRates = yield call(fetchScaleRate, api, debug, callParams);

        if (!newRates) throw new Error('Invalid Scale Rate');

        yield setRateAndTurnOffLoading(newRates);
        break;
      case 'fetching':
        // Rate being fetched for this deal/weekEnding already

        break;
      default:
        // rate was in localStorage already
        const localRate = isDistant ? localRates.distant : localRates.studio;
        const cachedRate = parseFloat(localRate);

        if (!cachedRate) throw new Error('Invalid Scale Rate');

        yield put(change(WTC_FORM_NAME, member + '.rate', cachedRate));

        yield put(actions.loadingScaleRate({ loading: false, member }));
        break;
    }
  } catch (e) {
    debug(e);

    yield put(actions.storeScaleRateError());
    yield setRateAndTurnOffLoading('');
  }
}

//helper function for fetchAndSetPayAtScale
function* setRateAndTurnOffLoading(rates) {
  //get all members currently loading
  const membersObj = yield select(getScaleLoading);
  const members = [];
  for (const member in membersObj) {
    if (Object.hasOwnProperty.call(membersObj, member)) {
      const loading = membersObj[member];
      if (loading) {
        members.push(member);
      }
    }
  }

  const studioRate = rates.studio;
  const distantRate = rates.distant;

  let details;
  if (rates !== '') {
    const formValues = yield select(getFullFormValues);
    details = formValues.details;
  }

  const memberRates =
    rates !== ''
      ? members.map(member => {
          const memberIndex = member.match(/(\d)/)[1];
          const day = details[memberIndex];
          const isDistant = isDistantDay(day);
          return isDistant ? distantRate : studioRate;
        })
      : members.map(m => '');

  yield put(actions.loadingScaleRate({ loading: false, members }));

  yield all([
    ...members.map((member, i) =>
      put(change(WTC_FORM_NAME, member + '.rate', memberRates[i])),
    ),
  ]);
}

// get scale rate for given scaleKey (dealMemoId + weekending)
function* fetchScaleRate(api, debug, params) {
  const { dealMemoId, weekEndingDate, payAtScale } = params;
  const scaleKey = makeScaleKey({ dealMemoId, weekEndingDate, payAtScale });
  try {
    let localRates = getRateFromLocal(params);

    switch (localRates) {
      case null:
        sessionStorage.setItem(scaleKey, 'fetching');
        //valid - return
        //null - fetch
        //fetching - delay
        yield put(
          actions.setDMScaleRatesLoading({ dmScaleRatesLoading: true }),
        );

        const project = yield select(getCurrentProject);
        const { dbCode, id: projectId } = project;

        if (payAtScale !== 'Y' && payAtScale !== 'C') {
          throw new Error(
            `Invalid payAtScale value. Expected 'Y' or 'C' found:${payAtScale}`,
          );
        }
        const data = yield call(api.employees.payAtScales, {
          projectId,
          dealMemoId,
          weekEndingDate,
          payAtScale,
          dbCode,
        });

        const rates = getStudioAndDistant(data);

        sessionStorage.setItem(scaleKey, JSON.stringify(rates));
        localRates = getRateFromLocal(params);
        yield put(
          actions.setDMScaleRatesLoading({ dmScaleRatesLoading: false }),
        );

        return rates;

      case 'fetching':
        let count = 0;
        while (localRates === 'fetching' && count < 50) {
          yield delay(50);
          localRates = getRateFromLocal(params);
          count++;
        }
        if (count === 50) {
          throw new Error('Local scale rate lookup timeout');
        }
        return localRates;
      default:
        return localRates;
    }
  } catch (e) {
    debug(e);
    sessionStorage.removeItem(scaleKey);
    return null;
  }
}

function* fetchRounding(api, debug, params) {
  try {
    const projectId = yield select(getProject);
    let dealMemoIds;
    if (params.timecard) {
      dealMemoIds = getActiveDealMemoIds(params.timecard);
    } else if (params.dealMemoIds) {
      dealMemoIds = params.dealMemoIds;
    } else {
      throw new Error(
        'Invalid params for fetchRounding. Needs either timecard or dealMemoIds array',
      );
    }

    const rounding = yield call(api.employees.roundTo, {
      projectId,
      dealMemoIds,
    });
    yield put(actions.storeRounding({ rounding }));
    return rounding;
  } catch (error) {
    debug(error);
    yield put(showAlert());
  }
}

function* calcResubmitComment(api, debug, params) {
  try {
    const prevTimecard = yield select(getTimecard);
    const nextTimecard = yield select(getFullFormValues);

    const changedTimes = scanForWorkTimeChanges(prevTimecard, nextTimecard);

    const tableFields = yield select(getTableFieldOrder);

    const comment = generateComment(changedTimes, tableFields);
    if (params?.action === 'resubmit') {
      yield calcPrevChangeComments(comment);
    }
    yield put(actions.storeResubmitComment({ comment }));
    return comment;
  } catch (error) {
    debug(error);
  }
}

function* calcPrevChangeComments(currentComment) {
  const allComments = yield select(getComments);
  const comments = allComments.filter(c => c.type === 'corrections');
  const project = yield select(getCurrentProject);
  let prevChangeComments = '';

  if (currentComment) {
    prevChangeComments = currentComment + '\n';
  }
  comments.forEach(c => {
    const timestamp = formatDateTimezone(
      c.createdAt,
      'MM/DD/YYYY HH:mm:ss',
      project.timeZone,
    );
    prevChangeComments += `${timestamp} - ${c.userName}\n`;
    prevChangeComments += `${c.comment}\n`;
  });
  yield put(actions.storePrevChangeComments({ prevChangeComments }));
}

function* downloadTimecardsReport(api, debug, params) {
  try {
    const { timecards } = params;
    const projectId = yield select(getProject);
    if (timecards?.length < 1) {
      throw new Error('No timecard in the list');
    }
    yield put(actions.downloadingReport({ loading: true }));
    const payload = {
      timecardEntryHeaderIds: timecards,
    };

    const response = yield call(api.downloader.downloadTimecardsReport, {
      projectId,
      payload,
    });

    if (response?.data?.fileStatus === 'Failed') {
      yield put(
        showAlert({
          message:
            'Some allowance documents cannot be converted to PDF and are not included in the report',
          variant: 'warning',
        }),
      );
    }
  } catch (e) {
    debug(e);
    yield put(showAlert({ variant: 'warning', message: e.message }));
  } finally {
    yield put(actions.downloadingReport({ loading: false }));
  }
}

function* addAllDaysOfWeek(api, debug, params) {
  try {
    const timecard = yield select(getFullFormValues);
    const oldDetails = timecard.details;
    const newDetails = [];
    const dateStr = timecard.weekStartingDate;
    const momentDate = moment(dateStr);
    const DATE_FORMAT = 'YYYY-MM-DD';
    const tcDealMemo = timecard.dealMemo;
    for (let i = 0; i < 7; i++) {
      const effectiveDate = momentDate.format(DATE_FORMAT);

      let newDay;
      const oldDay = oldDetails.find(
        d => moment(d.effectiveDate).format(DATE_FORMAT) === effectiveDate,
      );

      if (oldDay) {
        newDay = _.cloneDeep(oldDay);
      } else {
        newDay = { unusedDay: true };
        newDay.effectiveDate = effectiveDate;
        newDay.employee = _.cloneDeep(tcDealMemo.employee);
        newDay.dealMemo = tcDealMemo;
      }

      newDetails.push(newDay);
      momentDate.add(1, 'day');
    }

    yield put(change(WTC_FORM_NAME, 'details', newDetails));
  } catch (error) {
    debug(error);
  }
}

function* populateUnusedDay(api, debug, params) {
  try {
    const { detail, isNoTimeDayType, member } = params;
    const timecard = yield select(getFullFormValues);

    const newDay = _.cloneDeep(detail);
    const tableFields = yield select(getTableFieldOrder);
    for (const fieldName in tableFields) {
      if (fieldName === 'dayType') continue;

      if (Object.hasOwnProperty.call(tableFields, fieldName)) {
        const field = tableFields[fieldName];
        //don't populate times for non-time day types
        if (isNoTimeDayType && field.group === 'time') continue;

        if (Object.hasOwnProperty.call(timecard, fieldName)) {
          newDay[fieldName] = _.cloneDeep(timecard[fieldName]);
        }
      }
    }
    newDay.unusedDay = false;

    yield put(change(WTC_FORM_NAME, member, newDay));
  } catch (error) {
    debug(error);
  }
}

/**
 * Use when dayType is flipped from non-time dayType to time dayType
 */
function* populateTimeFields(api, debug, params) {
  try {
    const timecard = yield select(getFullFormValues);
    const { detail, member } = params;
    const newDay = _.cloneDeep(detail);

    WORK_TIME_FIELDS.forEach(field => {
      if (timecard[field] || timecard[field] === 0) {
        newDay[field] = timecard[field];
      }
    });
    yield put(change(WTC_FORM_NAME, member, newDay));
  } catch (error) {
    debug(error);
  }
}
export function* fetchPaycodeById(api, debug, params) {
  try {
    const data = yield call(api.wtc.getPaycodeById, {
      paycodeId: params.params,
    });

    const factor = parseFloat(data?.factor || 1);
    const units = params?.allowanceData?.hours;
    const rate = params.rate || params?.allowanceData?.rate;
    if (rate) {
      const amount = factor * rate * units;
      if (!isNaN(amount)) {
        yield put(
          change(WTC_FORM_NAME, params.member + '.amount', amount.toFixed(2)),
        );
      }
    } else {
      yield put(change(WTC_FORM_NAME, params.member + '.amount', 0));
    }

    yield put(change(WTC_FORM_NAME, params.member + '.factor', factor));
  } catch (e) {}
}
export default function* HTGactions({ api, debug }) {
  yield all([
    takeLatest(`${actions.drawerInit}`, drawerInit, api, debug),
    takeLatest(`${actions.batchInit}`, batchInit, api, debug),
    takeLatest(`${actions.multiBatchInit}`, multiBatchInit, api, debug),
    takeLatest(
      `${actions.fetchTimecardsInBatch}`,
      fetchTimecardsInBatch,
      api,
      debug,
    ),
    takeLatest(
      `${actions.getFiltersFromTimecards}`,
      getFiltersFromTimecards,
      api,
      debug,
    ),
    throttle(
      750,
      `${actions.fetchWorkSchedules}`,
      fetchWorkSchedules,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchBreakdown}`, fetchBreakdown, api, debug),
    takeLatest(`${actions.fetchComments}`, fetchComments, api, debug),
    takeLatest(`${actions.fetchNextApprovers}`, fetchNextApprovers, api, debug),
    takeLatest(
      `${actions.fetchCurrentApprovers}`,
      fetchCurrentApprovers,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchDealMemos}`, fetchDealMemos, api, debug),
    throttle(750, `${actions.fetchEpisodes}`, fetchEpisodes, api, debug),
    takeLatest(`${actions.saveTimecard}`, saveTimecardIso, api, debug),
    takeLatest(`${actions.reject}`, reject, api, debug),
    takeLatest(`${actions.resubmit}`, resubmit, api, debug),
    takeLatest(`${actions.recall}`, recall, api, debug),
    takeLatest(`${actions.addComment}`, addComment, api, debug),
    takeLatest(`${actions.deleteTimecard}`, deleteTimecard, api, debug),
    takeLatest(`${actions.undo}`, undo, api, debug),
    throttle(750, `${actions.occupationCodes}`, occupationCodes, api, debug),
    throttle(750, `${actions.dayType}`, dayType, api, debug),
    throttle(750, `${actions.workLocations}`, workLocations, api, debug),
    takeLatest(`${actions.loadTimecard}`, loadTimecard, api, debug),
    takeLatest(`${actions.fetchTimecard}`, fetchTimecard, api, debug),
    takeLatest(`${actions.fetchPaidHours}`, fetchPaidHours, api, debug),
    takeLatest(
      `${actions.printTimecardReport}`,
      printTimecardReport,
      api,
      debug,
    ),
    takeLatest(
      `${actions.wtcDownloadTimecardsReport}`,
      downloadTimecardsReport,
      api,
      debug,
    ),
    takeLatest(
      `${actions.fetchSplitHourTypes}`,
      fetchSplitHourTypes,
      api,
      debug,
    ),
    takeLatest(
      `${actions.fetchAllowancePayCode}`,
      fetchAllowancePayCode,
      api,
      debug,
    ),
    takeEvery(
      `${signalRNotification}`,
      signalRNotificationVerification,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchWTCHistory}`, fetchWTCHistory, api, debug),
    takeLatest(`${actions.approveTimecard}`, approveTimecard, api, debug),

    takeEvery(
      `${actions.fetchAndSetPayAtScale}`,
      fetchAndSetPayAtScale,
      api,
      debug,
    ),
    takeLatest(`${actions.storeTimecard}`, storeTimecard, api, debug),
    takeLatest(`${actions.fetchBatchInfo}`, fetchBatchInfo, api, debug),
    takeLatest(
      `${actions.multiBatchInfoCheck}`,
      multiBatchInfoCheck,
      api,
      debug,
    ),
    takeLatest(
      `${actions.updateWTCTimecardIdsPostMove}`,
      updateWTCTimecardIdsPostMove,
      api,
      debug,
    ),
    takeLatest(
      `${actions.calcResubmitComment}`,
      calcResubmitComment,
      api,
      debug,
    ),
    takeLatest(`${actions.reviewUPMTimecards}`, reviewUPMTimecards, api, debug),
    takeLatest(`${actions.addAllDaysOfWeek}`, addAllDaysOfWeek, api, debug),
    takeLatest(`${actions.populateUnusedDay}`, populateUnusedDay, api, debug),
    takeLatest(`${actions.populateTimeFields}`, populateTimeFields, api, debug),
    takeLatest(`${actions.fetchTCUpdate}`, fetchTCUpdate, api, debug),
    takeLatest(`${actions.fetchPayCodeById}`, fetchPaycodeById, api, debug),
  ]);
}
