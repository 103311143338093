import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { List, ListItem } from '@mui/material';

import UserAnchor from './UserAnchor';

export const style = ({ palette }) => ({
  root: {
    flex: 'initial',
    width: '100%',
    '& > * ': {
      width: '100%',
      color: palette.common.white,
    },
  },
  listItem: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'flex-start',
    width: '100%',
    '& > *': {
      padding: [12, 16],
      width: '100%',
      textAlign: 'justify',
      fontWeight: 900,
      fontSize: 15,
    },
  },
  subMenuItem: {
    extend: 'listItem',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
});

class UserNav extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fullName: PropTypes.string.isRequired,
    onMenuHide: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  renderSubMenuItems = () => {
    const { children, classes } = this.props;
    let menuItems = null;

    if (children && children.length > 0) {
      menuItems = children.map(menuItem => (
        <ListItem key={menuItem.linkTo} divider className={classes.subMenuItem}>
          {menuItem}
        </ListItem>
      ));
    } else if (children) {
      return (
        <ListItem divider className={classes.subMenuItem}>
          {children}
        </ListItem>
      );
    }

    return menuItems;
  };

  render() {
    const { email, classes, fullName, onMenuHide } = this.props;

    return (
      <List className={classes.root} disablePadding>
        <ListItem divider className={classes.listItem}>
          <UserAnchor
            email={email}
            fullName={fullName}
            backButton={onMenuHide}
          />
        </ListItem>
        {this.renderSubMenuItems()}
      </List>
    );
  }
}

export default withStyleSheet('UserNav', style)(UserNav);
