import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import clsx from 'clsx';
import MenuToggler from './MenuToggler';

export const style = ({ palette }) => {
  const tipColor = `${palette.gray['+9']} transparent`;
  const tipWidth = '0 6px 6px 6px';

  return {
    root: {
      paddingRight: 25,
      textAlign: 'right',
    },

    dropdownHidden: {
      display: 'none',
    },

    dropdownContentMenu: {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },

    dropdownContent: {
      boxShadow: '1px 1px 2px 1px rgba(0,0,0,0.15)',
      borderRadius: 5,
      background: palette.gray['+9'],
      color: palette.gray['+3'],
      fontWeight: 700,
      position: 'absolute',
      right: 30,
      minWidth: 220,
      padding: 10,
      zIndex: 200,
      marginTop: 12,
      '& > :first-child': {
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        '-webkit-border-top-left-radius': 6,
        '-webkit-border-top-right-radius': 6,
      },
      '& > :last-child': {
        borderBottom: 'none',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        '-webkit-border-bottom-left-radius': 6,
        '-webkit-border-bottom-right-radius': 6,
      },
      '&::before': {
        border: 'solid',
        borderColor: tipColor,
        borderWidth: tipWidth,
        content: '""',
        position: 'absolute',
        right: 25,
        top: -6,
      },
    },
  };
};

class DropdownMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    menuAnchorEl: PropTypes.node.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    onMenuShow: PropTypes.func.isRequired,
    onMenuHide: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      children,
      menuAnchorEl,
      menuOpen,
      onMenuShow,
      onMenuHide,
    } = this.props;

    const menuContentCls = clsx(classes.dropdownContent, {
      [classes.dropdownHidden]: !menuOpen,
    });

    return (
      <div
        className={classes.root}
        onMouseOut={onMenuHide}
        onMouseOver={onMenuShow}
        onClick={onMenuHide}
      >
        <MenuToggler>{menuAnchorEl}</MenuToggler>

        <div className={menuContentCls}>{children}</div>
      </div>
    );
  }
}

export default withStyleSheet('DropdownMenu', style)(DropdownMenu);
