import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select,
  spawn,
  delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import camelCase from 'camelcase-keys';
import _ from 'lodash';
import { startSubmit, stopSubmit } from 'redux-form';
//actions
import { storeBatches } from 'actions/bulkEdit';
import { showAlert } from 'actions/alert';
import * as actions from 'actions/reviews';
import {
  enableESignature,
  fetchTimecardDetails,
  setCurrentTimecard,
  addRecentComment,
  onApprove,
  clearEditComment,
} from 'actions/timecards';

import { hide as hideModal } from 'actions/modalDialog';
//components
import { DH, PA, UPM, EMPLOYEE } from 'components/props/profiles';
//selector
import { getProject as project } from 'selectors/routeParams';
import { getFromURI } from 'selectors/timecard/common';
import { currentUser } from 'selectors/session';
import {
  getComments,
  isShowingPaid,
  getLoadingByBatchId,
} from 'selectors/reviews';
import { getTimecardFormValues } from 'selectors/timecard/form';
// saga
import { onSave } from './timecards';
import { getProjectYear } from './projects';
import { getCurrentTimecard } from 'selectors/timecard/common';
import { BATCH_REVIEW_DEFAULT_PAGE_SIZE } from 'components/Shared/constants';

export function* fetchTimecardCount(api, debug, params) {
  try {
    const projectId = yield select(project);
    const activeUser = yield select(currentUser);
    const showPaid = yield select(isShowingPaid);
    let reviewTypes = params.reviewTypes || [];

    if (activeUser.role === UPM || activeUser.role === DH) {
      if (reviewTypes.length === 0) {
        reviewTypes = ['ready', 'pending', 'processed'];
      }
      yield all(
        reviewTypes.map(reviewType =>
          spawn(() =>
            spawnFetchTimecardCount({
              api,
              debug,
              projectId,
              reviewType,
              showPaid,
            }),
          ),
        ),
      );
    } else if (activeUser.role === PA) {
      //Count handled in review fetch now
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* spawnFetchTimecardCount({
  api,
  debug,
  projectId,
  reviewType,
  showPaid,
}) {
  const year = yield getProjectYear();

  try {
    yield put(actions.loadingCounts({ loading: true, reviewType }));
    const callReviewType = reviewType !== 'ready' ? reviewType : '';
    const result = yield call(api.reviews.timecardCount, {
      projectId,
      year,
      type: callReviewType,
      showPaid,
    });

    const storeReviewType = reviewType === 'processed' ? 'history' : reviewType;
    const count = result.TimecardCount;
    yield put(
      actions.storeTimecardCount({ reviewType: storeReviewType, count }),
    );
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.loadingCounts({ loading: false, reviewType }));
  }
}

//get open review batches only, without polling for Timecard data
export function* fetchReviewBatchesOnly(api, debug) {
  try {
    const projectId = yield select(project);
    const year = yield getProjectYear();
    const type = 'open';

    let result = yield call(api.reviews.reviewBatches, {
      projectId,
      year,
      type,
      page: 1,
      size: 50,
    });
    const openBatchList = camelCase(result, { deep: true });
    yield put(actions.storeReviewBatchesOnly({ openBatchList }));
    yield put(storeBatches({ batches: openBatchList }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* fetchReviewBatches(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true, area: 'batches' }));
    const projectId = yield select(project);
    const year = yield getProjectYear();
    const activeUser = yield select(currentUser);
    let showPaid = yield select(isShowingPaid);
    let loadApprovalFlow = false;

    const { reviewType, page, skipBatchTCFetch = true } = params;

    let { size } = params;
    if ((!size || size < BATCH_REVIEW_DEFAULT_PAGE_SIZE) && size !== -1) {
      size = BATCH_REVIEW_DEFAULT_PAGE_SIZE;
    }

    let type = reviewType === 'ready' ? 'open' : reviewType;
    type = reviewType === 'history' ? 'processed' : type;
    if (
      (reviewType === 'ready' &&
        (activeUser.role === DH || activeUser.role === UPM)) ||
      (reviewType === 'history' && activeUser.role === UPM)
    ) {
      loadApprovalFlow = true;
    }

    showPaid =
      reviewType === 'history' || reviewType === 'complete' ? showPaid : false;

    const apiCall =
      activeUser.role === PA
        ? api.reviews.reviewBatchesPA
        : api.reviews.reviewBatches;

    let reviewBatches;
    if (activeUser.role !== EMPLOYEE) {
      let result = yield call(apiCall, {
        projectId,
        year,
        type,
        page: page > 0 ? page : 1,
        size,
        showPaid,
      });

      if (activeUser.role === PA) {
        if (result.open || result.open === 0) {
          yield put(
            actions.storeTimecardCount({
              reviewType: 'open',
              count: result.open || 0,
            }),
          );
        }
        if (result.complete || result.complete === 0) {
          yield put(
            actions.storeTimecardCount({
              reviewType: 'complete',
              count: result.complete || 0,
            }),
          );
        }
        reviewBatches = result.batches;
      } else {
        //DH / UPM - result is reviewBatches
        reviewBatches = result;
      }
      yield put(
        actions.storeReviewBatches({ projectId, reviewBatches, reviewType }),
      );

      if (loadApprovalFlow && !skipBatchTCFetch) {
        yield put(actions.fetchApprovalFlows({ reviewType, reviewBatches }));
      } else if (!skipBatchTCFetch) {
        yield put(
          actions.fetchTimecardsByBatchId({ reviewType, reviewBatches }),
        );
      }

      yield put(actions.loading({ loading: false, area: 'batches' }));
    }
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false, area: 'batches' }));
    yield put(showAlert());
  }
}

export function* fetchTimecardsByBatchId(api, debug, params) {
  try {
    const { reviewType, reviewBatches } = params;
    const projectId = yield select(project);
    const showPaid = yield select(isShowingPaid);

    const apiCall = api.reviews.timecardsByReviewBatchId;

    const type = reviewType === 'history' ? 'processed' : reviewType;

    yield all(
      reviewBatches.map(batch =>
        spawn(() =>
          spawnFetchTCbyBatchID(apiCall, debug, {
            projectId,
            reviewType: type,
            batch,
            showPaid,
          }),
        ),
      ),
    );
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* spawnFetchTCbyBatchID(apiCall, debug, callInfo) {
  const { batch, projectId, showPaid, reviewType } = callInfo;
  try {
    const isLoading = yield select(getLoadingByBatchId, [batch.id]);
    if (isLoading) return;

    yield put(
      actions.storeBatchLoadingByID({
        batchId: batch.id,
        loading: true,
      }),
    );

    const result = yield call(apiCall, {
      projectId: projectId,
      type: reviewType,
      reviewBatchId: batch.id,
      showPaid: showPaid,
    });

    const type = reviewType === 'processed' ? 'history' : reviewType;

    yield put(
      actions.storeBatchTimecards({
        reviewType: type,
        batchId: batch.id,
        timecards: result,
      }),
    );
    yield put(
      actions.storeBatchLoadingByID({
        batchId: batch.id,
        loading: false,
      }),
    );
    return result;
  } catch (e) {
    yield put(
      actions.storeBatchLoadingByID({
        batchId: batch.id,
        loading: null,
      }),
    );
    debug(e);
    return null;
  }
}

function* fetchApprovalFlows(api, debug, params) {
  try {
    const projectId = yield select(project);
    const showPaid = yield select(isShowingPaid);
    const { reviewType, reviewBatches } = params;
    let type = reviewType === 'ready' ? 'open' : reviewType;
    type = reviewType === 'history' ? 'processed' : type;
    for (let i = 0; i < reviewBatches.length; i++) {
      const batch = reviewBatches[i];
      yield put(
        actions.storeBatchLoadingByID({
          batchId: batch.id,
          loading: true,
        }),
      );
    }
    const result = yield all(
      _.map(reviewBatches, batch => {
        return call(api.reviews.approvalFlows, {
          projectId,
          type,
          reviewBatchId: batch.id,
          showPaid,
        });
      }),
    );
    const approvalFlows = camelCase(result, { deep: true });
    // loading flags are turned off in the reducer in store action
    yield put(
      actions.storeApprovalFlows({ projectId, approvalFlows, reviewType }),
    );
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}
// to-do: looking into review reducer loading action:
// {area: dlVerifiedBatch / dlBatchReport}

function* downloadTimecardsReport(api, debug, params) {
  const { timecards, batchId, htgId, reviewType, fetchBatch = false } = params;
  try {
    yield put(actions.storeBatchDownloadingByID({ batchId, loading: true }));

    const projectId = yield select(project);
    const showPaid = yield select(isShowingPaid);
    const filename = `batch_${htgId}_report.pdf`;
    const activeUser = yield select(currentUser);
    let list = timecards;

    // to-do: if timecards list has 0 item, show alert instead
    if (
      fetchBatch &&
      ((activeUser.role === PA && !showPaid) ||
        (activeUser.role === UPM && reviewType === 'history' && !showPaid))
    ) {
      let type = reviewType === 'history' ? 'processed' : reviewType;
      const res = yield call(
        spawnFetchTCbyBatchID,
        api.reviews.timecardsByReviewBatchId,
        debug,
        { batch: { id: batchId }, projectId, showPaid, reviewType: type },
      );
      if (res && res.length > 0) {
        list = res.map(tc => tc.entryHeaderId);
      }
    }
    let response = [];
    if (
      (activeUser.role === PA && showPaid) ||
      (activeUser.role === UPM && reviewType === 'history' && showPaid)
    ) {
      response = yield call(api.downloader.downloadBatchReport, {
        projectId,
        batchId,
        filename,
      });
    } else {
      const payload = {
        timecardEntryHeaderIds: list,
      };
      response = yield call(api.downloader.downloadTimecardsReport, {
        projectId,
        payload,
      });
    }
    if (response?.data?.fileStatus === 'Failed') {
      yield put(
        showAlert({
          message:
            'Some allowance documents cannot be converted to PDF and are not included in the report',
          variant: 'warning',
        }),
      );
    }
  } catch (e) {
    yield put(showAlert());
  } finally {
    yield put(actions.storeBatchDownloadingByID({ batchId, loading: false }));
  }
}

function* reviewTimecard(debug, params) {
  try {
    const projectId = yield select(project);
    const reviewType = params.reviewType || 'ready';
    const role = params.role || null;
    let toURI = null;
    if (reviewType === 'ready' && role === DH) {
      const { approvalFlowId, batchId } = params;
      toURI = [
        `/projects/${projectId}`,
        `/reviews/batches/${batchId}`,
        `/approval-flows/${approvalFlowId}`,
      ].join('');
    } else {
      const { timecardId } = params;
      toURI = [
        `/projects/${projectId}`,
        `/reviews/timecards/${timecardId}`,
      ].join('');
    }

    if (toURI) yield put(push(toURI));
  } catch (e) {
    debug(e);
  }
}

export function* addModalComment(api, debug, params) {
  const { comment, formName } = params;
  try {
    const timecardEntryHeaderId = yield select(getCurrentTimecard);
    const comments = yield select(getComments);

    if (formName) startSubmit(formName);
    let data = {
      comment: comment,
      timecardEntryHeaderId,
      commentType: 'note',
    };
    const projectId = yield select(project);

    yield call(api.reviews.addModalComment, { data, projectId });
    yield put(actions.storeComments({ comments: [...comments, data] }));
    yield put(addRecentComment({ timecardEntryHeaderId }));
    yield put(hideModal({ dialog: 'AddComment' }));
  } catch (e) {
    debug(e);
    const errors = e.data ? e.data : null;
    if (errors && errors.ExceptionMessage) {
      yield put(
        showAlert({
          message: errors.ExceptionMessage,
          variant: 'error',
        }),
      );
    } else {
      yield put(showAlert());
    }
  } finally {
    if (formName) stopSubmit(formName);
  }
}

export function* fetchComments(api, debug, params) {
  try {
    yield put(actions.loadingComments({ loadingComments: true }));
    const projectId = yield select(project);
    const comments = yield call(api.reviews.getTimecardComments, {
      projectId,
      timecardEntryHeaderId: params.timecardEntryHeaderId,
    });
    yield put(actions.storeComments({ comments }));
    yield put(actions.loadingComments({ loadingComments: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loadingComments({ loadingComments: false }));
  }
}

function* loadApprovalFlow(api, debug, params) {
  try {
    yield put(actions.loadingTimecards({ loading: true }));
    const projectId = yield select(project);
    const { approvalFlowId } = params;
    const approvalFlow = yield call(api.reviews.approvalFlowByID, {
      projectId,
      approvalFlowId,
    });
    yield put(actions.setCurrentApprovalFlow({ approvalFlow }));
    yield put(setCurrentTimecard({ timecardId: approvalFlow.timecardId }));
    yield put(fetchTimecardDetails({ timecardId: approvalFlow.timecardId }));
    yield put(actions.loadingTimecards({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loadingTimecards({ loading: false }));
    yield put(showAlert());
  }
}

function* reviewApprovalFlow(api, debug, params) {
  const {
    status,
    comment = '',
    isTimecardDirty,
    formName,
    timecardEntryHeaderId,
  } = params;
  try {
    yield put(actions.loadingTimecards({ loading: true }));
    if (formName) yield put(startSubmit(formName));
    const projectId = yield select(project);

    const timecardEntryHeaderIds = [timecardEntryHeaderId];
    let data = {
      status,
      comment,
      timecardEntryHeaderIds,
    };

    if (params.electronicSignature) {
      data = {
        ...data,
        AgreeToTerms: true,
        ElectronicSignature: params.electronicSignature,
      };
    }

    if (isTimecardDirty) {
      let timecard = yield select(getTimecardFormValues, projectId, 'timecard');
      const days = yield call(onSave, api, debug, { timecard });
      if (days) {
        yield put(stopSubmit('timecard', { _error: { days } }));
        throw new Error('ValidationFailed');
      }
    }

    if (isTimecardDirty && data.status === 'approved') {
      const user = yield select(currentUser);
      if (user.role === DH) {
        data = {
          ...data,
          dhAcctCodeModified: true,
        };
      }
    }

    yield call(api.reviews.reviewTimecardEmp, {
      projectId,
      data,
    });
    let fromURI = yield select(getFromURI);

    //need to give time for approval to propagate
    yield delay(2000);

    yield put(actions.setCurrentApprovalFlow({ approvalFlow: null }));
    yield put(setCurrentTimecard({ timecardId: null }));
    if (formName) yield put(stopSubmit(formName));
    yield put(enableESignature({ enableESignature: false }));
    yield put(actions.fetchReviewBatches({ reviewType: 'ready', page: 1 }));
    yield put(actions.fetchTimecardCount());

    fromURI = fromURI || `/projects/${projectId}/me/review/batches/ready`;

    yield put(actions.loadingTimecards({ loading: false }));
    yield put(push(fromURI));
  } catch (e) {
    debug(e);
    if (formName) yield put(stopSubmit(formName));
    yield put(actions.loadingTimecards({ loading: false }));
    if (e.message === 'ValidationFailed') {
      yield put(
        showAlert({
          message: 'Timecard validation failed.',
          variant: 'warning',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

function* reviewBatch(api, debug, params) {
  const { formName, timecardEntryHeaderIds } = params;
  try {
    yield put(actions.processingApproval({ loading: true }));
    if (formName) yield put(startSubmit(formName));
    const projectId = yield select(project);
    const data = {
      comment: params.comment || '',
      status: params.status,
      timecardEntryHeaderIds,
    };

    yield call(api.reviews.reviewBatch, { projectId, data });

    yield put(actions.setCurrentApprovalFlow({ approvalFlow: null }));
    yield put(setCurrentTimecard({ timecardId: null }));

    yield delay(2000);
    yield put(
      actions.fetchReviewBatches({
        reviewType: 'ready',
        page: 1,
        skipBatchTCFetch: false,
      }),
    );
    yield put(actions.fetchTimecardCount());

    //redirect to prev, or review page by default
    let fromURI = yield select(getFromURI);
    fromURI = fromURI || `/projects/${projectId}/me/review/batches/ready`;
    yield put(push(fromURI));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    if (formName) yield put(stopSubmit(formName));
    yield put(actions.processingApproval({ loading: false }));
    yield put(hideModal({ dialog: 'RejectApprovalFlow' }));
  }
}

function* submitAllAsRejected(api, debug, params) {
  try {
    yield put(actions.processingApproval({ loading: true }));

    const projectId = yield select(project);
    const { reviewType, selected } = params;
    const data = { ids: [...selected], type: reviewType };

    yield call(api.reviews.bulkSubmitAsRejected, { projectId, data });
    yield put(actions.setCurrentApprovalFlow({ approvalFlow: null }));
    yield put(setCurrentTimecard({ timecardId: null }));
    yield delay(2000);
    yield put(actions.fetchReviewBatches({ reviewType, page: 1 }));
    yield put(actions.fetchTimecardCount());
    yield put(actions.processingApproval({ loading: false }));
  } catch (error) {
    debug(error);
    yield put(actions.processingApproval({ loading: false }));
    yield put(showAlert());
  }
}

export function* setBatchLockStatus(api, debug, params) {
  try {
    //set loading status to true
    yield put(actions.updatingBatchLockStatus({ updatingLockStatus: true }));
    const { hoursBatchId, reviewBatchLockStatus } = params;
    const path = window.location.pathname.split('/');
    const type = path[path.length - 1];

    const projectId = yield select(project);

    yield call(api.reviews.batchLockStatusSwitch, {
      projectId,
      batchId: hoursBatchId,
      lockStatus: reviewBatchLockStatus,
    });

    yield put(
      actions.updateBatchLockStatus({
        batchType: type,
        hoursBatchId,
        reviewBatchLockStatus,
      }),
    );

    yield put(actions.updatingBatchLockStatus({ updatingLockStatus: false }));
  } catch (e) {
    debug(e);
    yield put(actions.updatingBatchLockStatus({ updatingLockStatus: false }));
  }
}

function* EditTimecardApprovalFlow(api, debug, params) {
  const {
    status,
    comment = '',
    formName,
    esignature,
    agreed,
    timecardEntryHeaderId,
  } = params;
  try {
    yield put(actions.loadingTimecards({ loading: true }));
    if (formName) yield put(startSubmit(formName));
    const projectId = yield select(project);

    let data = {
      status,
      comment,
      timecardEntryHeaderIds: [timecardEntryHeaderId],
    };

    if (params.electronicSignature) {
      data = {
        ...data,
        AgreeToTerms: true,
        ElectronicSignature: params.electronicSignature,
      };
    }
    yield call(api.reviews.reviewTimecardEmp, {
      projectId,
      data,
    });

    yield put(onApprove({ esignature, agreed, role: 'employee' }));

    yield put(actions.loadingTimecards({ loading: false }));
    yield put(clearEditComment());
  } catch (e) {
    debug(e);
    if (formName) yield put(stopSubmit(formName));
    yield put(actions.loadingTimecards({ loading: false }));
    yield put(clearEditComment());
    yield put(showAlert());
  }
}

export default function* reviewsFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.reviewTimecard}`, reviewTimecard, debug),
    takeEvery(`${actions.fetchTimecardCount}`, fetchTimecardCount, api, debug),
    takeEvery(`${actions.addModalComment}`, addModalComment, api, debug),
    takeEvery(`${actions.fetchComments}`, fetchComments, api, debug),
    takeLatest(
      `${actions.fetchReviewBatchesOnly}`,
      fetchReviewBatchesOnly,
      api,
      debug,
    ),

    takeEvery(`${actions.fetchReviewBatches}`, fetchReviewBatches, api, debug),
    takeEvery(
      `${actions.fetchTimecardsByBatchId}`,
      fetchTimecardsByBatchId,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchApprovalFlows}`, fetchApprovalFlows, api, debug),
    takeEvery(
      `${actions.reviewDownloadTimecardsReport}`,
      downloadTimecardsReport,
      api,
      debug,
    ),
    takeEvery(`${actions.loadApprovalFlow}`, loadApprovalFlow, api, debug),
    takeLatest(`${actions.reviewApprovalFlow}`, reviewApprovalFlow, api, debug),
    takeEvery(`${actions.reviewBatch}`, reviewBatch, api, debug),
    takeEvery(
      `${actions.submitAllAsRejected}`,
      submitAllAsRejected,
      api,
      debug,
    ),
    takeEvery(`${actions.setBatchLockStatus}`, setBatchLockStatus, api, debug),
    takeLatest(
      `${actions.EditTimecardApprovalFlow}`,
      EditTimecardApprovalFlow,
      api,
      debug,
    ),
  ]);
}
