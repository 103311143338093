import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TableRow, TableCell } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TableHeadWTC from 'components/Shared/TableList/TableHeadWTC';
import FringeTip from 'components/Shared/FringeTooltip/FringeTip';
import { canadianLabel } from 'utils/helperFunctions';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  breakdownTitle: {
    fontSize: 24,
    fontWeight: 500,
  },
  cell: {
    padding: 5,
    fontSize: '0.775rem',
    textAlign: 'left',
    borderRight: `1px solid ${palette.gray['+7']}`,
  },
  numberCell: {
    textAlign: 'right',
  },

  table: {
    '& > tr:nth-child(even)': {
      backgroundColor: palette.primary['+9'],
    },
  },
  bulkChangesWarning: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 16,
    '& > p': {
      border: `1px solid ${palette.warning.light}`,
      backgroundColor: palette.primary.background,
      color: palette.warning.main,
      padding: 8,
      fontSize: 14,
    },
  },
});

const Breakdown = ({
  classes,
  breakdownData,
  bulkCalcPending,
  showFringeInfo,
}) => {
  let isAllowanceIndex;
  const headers = breakdownData.heading.map((headerName, i) => {
    if (headerName === 'IsAllowance:boolean') {
      isAllowanceIndex = i;
      return { columnId: '', label: '', visible: false };
    }

    if (headerName === '') {
      return { columnId: '', label: '', visible: true };
    }
    const heading = headerName.split(':'); //remove type from headerName
    const label = canadianLabel(heading[0]);
    const type = heading[1];
    const columnId = label.replace(/\s+/g, '');
    const headObj = {
      label,
      columnId,
      group: 'time',
      type,
      visible: true,
    };
    return headObj;
  });

  const bulkChangesAlert = useMemo(() => !!bulkCalcPending, [bulkCalcPending]);

  const checkIsLastLaborRow = (rowIndex, data) => {
    const row = data[rowIndex];
    if (row[isAllowanceIndex] === false) {
      const nextRow = data[rowIndex + 1];
      if (
        nextRow[isAllowanceIndex] === '' ||
        nextRow[isAllowanceIndex] === true
      ) {
        return true;
      }
    }
    return false;
  };

  //How many labor lines do we have in the breakdown?
  const laborCount = breakdownData.data.reduce((total, row) => {
    if (row[isAllowanceIndex] === false) total += 1;
    return total;
  }, 0);

  if (laborCount === 0) {
    showFringeInfo = false;
  }

  let haveAddedFringeTip = false; //ensure we only add icon 1x

  const renderData = (row, rowIndex, data) => {
    const isAddFringeRow =
      showFringeInfo && checkIsLastLaborRow(rowIndex, data);

    return (
      <TableRow className={classes.row} key={`row-${rowIndex}`}>
        {row.map((cell, cellIndex) => {
          if (cellIndex === isAllowanceIndex) return null;

          const isNum = headers[cellIndex].type === 'number';
          const isAmountCol = headers[cellIndex].label === 'Amount';
          const showFringeTip =
            isAddFringeRow && isAmountCol && !haveAddedFringeTip;
          if (showFringeTip) haveAddedFringeTip = true;
          return (
            <TableCell
              className={clsx(classes.cell, { [classes.numberCell]: isNum })}
              // eslint-disable-next-line react/no-array-index-key
              key={`${rowIndex}-${cellIndex}`}
            >
              <div style={{ display: 'flex' }}>
                {cell}
                {showFringeTip && (
                  <FringeTip fringeData={breakdownData.estdata} />
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };
  const renderTableData = breakdownData.data.map(renderData);

  return (
    <div>
      <Accordion className={classes.root} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} variant="iconOnRight">
          <Typography>
            <span className={classes.breakdownTitle}>Timecard Breakdown</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper className={classes.root}>
            {bulkChangesAlert && (
              <div className={classes.bulkChangesWarning}>
                <Typography>
                  Bulk changes have not yet been reflected in the timecard
                  breakdown. Save and calculate before submitting.
                </Typography>
              </div>
            )}
            <MuiTable aria-label="customized table">
              {/* TODO -  This uses  the same head creation as the main wTC table 
                          which has much more functionality. This could be its own very basic column
                          array to table header */}
              <TableHeadWTC columns={headers} breakdown={true} />
              <TableBody className={classes.table}>{renderTableData}</TableBody>
            </MuiTable>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(style)(Breakdown);
