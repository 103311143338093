import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { canadianLabel, compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import withModalDialog from 'decorators/withModalDialog';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';
import { change, reset } from 'redux-form';

import { TIMECARD_PENDING_EMP_REVIEW } from 'components/Shared/constants/index';

import SelectCell from '../Shared/Cells/SelectCell';
import TimeCell from '../Shared/Cells/TimeCell';
import TextCell from '../Shared/Cells/TextCell';

import {
  Box,
  styled,
  LinearProgress,
  IconButton,
  Autocomplete,
  TextField,
  Tooltip,
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FaRegTimesCircle } from 'react-icons/fa';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import TimeValidator from 'utils/TimeValidator';
import {
  EMP_ADDITIONAL_FIELD_DESC_MAP,
  getTCDaysOptions,
} from '../empTimecardUtils';

import {
  StyledInputLabel,
  StyledFieldBox,
  AutoCompTextField,
  TitleBox,
  ContentBox,
  FooterBox,
  Button,
} from '../Shared/styledComponents';

//actions
import * as actions from '../actions';
import { hide as hideModal, show as showModal } from 'actions/modalDialog';

//selectors
import * as sel from '../selectors';
import { getFormValues } from 'selectors/formValues';
import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';

import {
  CONFIRM_EDIT_MODAL,
  SETTINGS_DIALOG,
  MOBILE_DAY_MODAL,
} from './modalNames';

import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '85vw',
  },
  inActiveDay: {
    backgroundColor: palette.gray['+8'],
  },
}));

const mapState = state => {
  return {
    selectedDay: sel.getSelectedDay(state) || {},
    timecard: getFormValues(FORM_NAME)(state),
    tcInitValues: sel.getInitialValues(state),
    copyToOtherDays: sel.getCopyToOtherDays(state),
    isTcEditable: sel.getIsTcEditable(state),
    columns: sel.getVisibleColumns(state),
    activeDeal: sel.getActiveDealMemo(state),
    saving: sel.getLoading(state, 'saving'),
    dayTypes: sel.getDayTypes(state),
    comments: sel.getComments(state),
    newComment: sel.getNewComment(state),
  };
};

const mapDispatch = (dispatch, ownProps) => ({
  dispatch,
  onCloseModal: () => {
    dispatch(actions.setTCCopyToOtherDays({ copyToOtherDays: [] }));
    dispatch(hideModal({ dialog: MOBILE_DAY_MODAL }));
  },
  onSetTCCopyToOtherDays: value =>
    dispatch(actions.setTCCopyToOtherDays({ copyToOtherDays: value })),
  onShowSettings: () => dispatch(showModal({ dialog: SETTINGS_DIALOG })),
  onSaveTimecard: () => {
    dispatch(actions.setTCCopyToOtherDays({ copyToOtherDays: [] }));
    dispatch(actions.saveTimecard());
  },
  onShowEditModal: () => dispatch(showModal({ dialog: CONFIRM_EDIT_MODAL })),
  onChangeDayType: (index, newVal, oldVal) => {
    dispatch(actions.changeDayType({ index, newVal, oldVal }));
  },
  onUpdateDailyAutoAllowances: () =>
    dispatch(actions.updateDailyAutoAllowances()),
  onSetNewComment: comment =>
    dispatch(actions.setNewComment({ newComment: comment })),
  onSaveComment: date =>
    dispatch(actions.saveComment({ referenceDate: date, commentType: 'day' })),
});

const TitleRowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: '10px',
  paddingBottom: '0px',
}));

const MoreOptionsBtn = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  height: '25px',
  borderRadius: '7px',
  // marginRight: '20px',
  '&>svg': {
    fontSize: '1rem',
  },
}));

const MultiAutoComp = styled(Autocomplete)(({ theme }) => ({
  //white background
  '& .MuiChip-filled': {
    backgroundColor: theme.palette.background.paper,
  },
}));
const MobileDayModal = props => {
  const {
    onCloseModal,
    selectedDay,
    timecard,
    copyToOtherDays = [],
    onSetTCCopyToOtherDays,
    onShowSettings,
    columns,
    isTcEditable,
    dispatch,
    onSaveTimecard,
    activeDeal,
    tcInitValues,
    saving,
    onShowEditModal,
    dayTypes,
    onChangeDayType,
    onUpdateDailyAutoAllowances,
    comments,
    onSaveComment,
    newComment,
    onSetNewComment,
  } = props;
  const classes = useStyles();
  //To show tooltip comment on tap
  const [open, setOpen] = React.useState(false);

  const isPendingReview = timecard.status === TIMECARD_PENDING_EMP_REVIEW;

  const roundTo = timecard.dealMemo.roundTo || 0.1;

  const timeV = React.useMemo(() => new TimeValidator(roundTo), [roundTo]);
  const { index, member } = selectedDay;

  const selectedTCDay = timecard?.days[index];

  const { date: selDate, htgDayTypeId } = selectedTCDay;
  const isDayActive = !!htgDayTypeId;
  const isPartialDealMemo =
    moment(selDate).isBefore(activeDeal.start) ||
    moment(selDate).isAfter(activeDeal.end);

  const isDisabled = !isDayActive || !isTcEditable || isPartialDealMemo;

  const formattedDate = moment(selDate).format('MM/DD/YYYY');
  const day = moment(selDate).format('dddd');
  //menu list options
  const copyDayOptions = getTCDaysOptions(selDate, timecard, activeDeal);

  const initialDay = React.useMemo(
    () => tcInitValues?.days[index],
    [tcInitValues, index],
  );
  React.useEffect(() => {
    //For mobile set dayType to work if
    //1. If day does not already have a dayType value
    //2. Dealmemo should applicable for that day - no partial deal memo
    //3. timecard should be in editable mode
    if (!isPartialDealMemo && isTcEditable && !initialDay?.dayType?.name) {
      const dayType = dayTypes?.find(dayType => dayType.code === '1');
      if (!_.isEmpty(dayType)) {
        onChangeDayType(index, dayType, {});
      }
    }
  }, [
    dayTypes,
    index,
    initialDay,
    isPartialDealMemo,
    isTcEditable,
    onChangeDayType,
  ]);

  const handleSave = () => {
    const days = timecard.days;
    const referenceDay = days.find(day => moment(day.date).isSame(selDate));
    let dayTypeChange = false;
    copyToOtherDays.forEach(day => {
      const newDay = _.cloneDeep(referenceDay);
      newDay.date = day.value;
      newDay.id = day.id || null;
      days.forEach((tcDay, index) => {
        if (moment(tcDay.date).isSame(day.value)) {
          dispatch(change(FORM_NAME, `days[${index}]`, newDay));
          if (newDay.htgDayTypeId !== tcDay.htgDayTypeId) {
            dayTypeChange = true;
          }
        }
      });
      if (dayTypeChange) {
        onUpdateDailyAutoAllowances();
      }
    });
    onSaveTimecard();
  };
  const handleOptionSelect = (event, option) => {
    let updatedOptions = [];
    const found = copyToOtherDays.some(item => item.value === option.value);
    if (found) {
      updatedOptions = copyToOtherDays.filter(
        item => item.value !== option.value,
      );
    } else {
      updatedOptions = [...copyToOtherDays, option];
    }
    onSetTCCopyToOtherDays(updatedOptions);
  };

  const handleClose = () => {
    if (!isDisabled) {
      //reset form cancel
      onSetNewComment('');
      dispatch(reset(FORM_NAME));
    }

    onCloseModal();
  };
  const handleAddComment = () => {
    if (newComment) {
      onSaveComment(selDate);
    }
  };

  const dayLevelComments = comments?.filter(c => c.type === 'day');
  const commentObj = dayLevelComments?.find(d =>
    moment(d.referenceDate, 'YYYY-MM-DD').isSame(selDate, 'YYYY-MM-DD'),
  );
  return (
    <Box className={classes.root}>
      <TitleBox sx={{ flexDirection: 'column', pb: 3 }}>
        <TitleRowBox>
          <Box sx={{ color: 'gray.-9', fontWeight: 'bold' }}>{day}</Box>
          <IconButton sx={{ color: 'gray.-9', p: 0 }} onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </TitleRowBox>
        <TitleRowBox>
          <Box sx={{ fontWeight: '300', color: 'text.secondary' }}>
            {formattedDate}
          </Box>
          <MoreOptionsBtn
            startIcon={
              <SettingsOutlinedIcon sx={{ height: '17px', width: '17px' }} />
            }
            onClick={onShowSettings}
          >
            More Options
          </MoreOptionsBtn>
        </TitleRowBox>
      </TitleBox>

      {/* <Divider sx={{ mt: '15px' }} /> */}
      <Box sx={{ height: '68vh', overflow: 'auto' }}>
        <ContentBox
          sx={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {columns.map(column => {
            const { accessor } = column;
            const name = `${member}.${accessor}`;
            const cellProps = {
              index,
              name,
              member,
              column,
              columns,
              isDayActive,
              isTcEditable,
              isPartialDealMemo,
            };

            let halfWidthStyle = {};
            if (column.flexField) {
              halfWidthStyle = {
                width: '45%',
                display: 'inline-block',
              };
            }
            let label = column.label;
            const isLabelIncluded = Object.keys(
              EMP_ADDITIONAL_FIELD_DESC_MAP,
            )?.includes(label);
            if (isLabelIncluded) {
              label = EMP_ADDITIONAL_FIELD_DESC_MAP[label]; //applicable for occupation
              if (label.includes(' - ')) {
                label = label?.split(' - ')[1];
              }
            }
            switch (column.type) {
              case 'autocomplete':
                return (
                  <StyledFieldBox key={accessor} sx={halfWidthStyle}>
                    <Box>
                      <StyledInputLabel>
                        {canadianLabel(label)}
                      </StyledInputLabel>
                      <SelectCell {...cellProps} />
                    </Box>
                  </StyledFieldBox>
                );
              case 'time':
                return (
                  <StyledFieldBox key={accessor} sx={halfWidthStyle}>
                    <StyledInputLabel sx={{ textWrap: 'wrap' }}>
                      {label}
                    </StyledInputLabel>
                    <TimeCell {...cellProps} timeV={timeV} />
                  </StyledFieldBox>
                );
              case 'text':
                return (
                  <StyledFieldBox key={accessor}>
                    <StyledInputLabel>{label}</StyledInputLabel>
                    <TextCell {...cellProps} />
                  </StyledFieldBox>
                );
              case 'special':
              default:
                return '';
            }
          })}
          {/* Day level comment */}
          <StyledFieldBox>
            <StyledInputLabel>Day Comment</StyledInputLabel>
            <TextField
              value={newComment}
              onChange={e => onSetNewComment(e.target.value)}
              variant={'outlined'}
              sx={{ width: '100%' }}
              disabled={isDisabled}
              onKeyDown={e => {
                if (e.key === 'Enter' && !isDisabled && newComment.length > 0) {
                  handleAddComment();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleAddComment}
                    disabled={isDisabled || newComment.length === 0}
                  >
                    <ArrowUpwardIcon
                      sx={{
                        backgroundColor:
                          isDisabled || newComment.length === 0
                            ? 'gray.+8'
                            : 'primary.main',
                        color:
                          isDisabled || newComment.length === 0
                            ? 'gray.+6'
                            : 'primary.contrastText',
                        height: '24px',
                        width: '24px',
                        p: '6px',
                        pr: '5px',
                        borderRadius: ' 10px',
                      }}
                    />
                  </IconButton>
                ),
              }}
              inputProps={{
                maxLength: commentsLimit,
                style: { height: '15px', paddingRight: '7px' },
              }}
            />
          </StyledFieldBox>
          {!_.isEmpty(commentObj) && (
            <StyledFieldBox
              sx={{
                backgroundColor: 'gray.+9',
                border: 'solid 1px gray.+9',
                p: '10px',
                borderRadius: ' 8px',
              }}
            >
              <StyledInputLabel>Day Comment</StyledInputLabel>
              <Box
                sx={{
                  color: 'text.secondary',
                  WebkitBoxOrient: 'vertical',
                  fontSize: '14px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                }}
              >
                <Tooltip open={open} title={commentObj.comment} placement="top">
                  <span onTouchStart={() => setOpen(!open)}>
                    {commentObj.comment}
                  </span>
                </Tooltip>
              </Box>
            </StyledFieldBox>
          )}
          {!isDisabled && (
            <StyledFieldBox sx={{ mb: 6 }}>
              <StyledInputLabel>Select A Day To Copy Times</StyledInputLabel>
              <MultiAutoComp
                options={copyDayOptions || []}
                clearIcon={<FaRegTimesCircle />}
                getOptionLabel={option => option.label}
                className={clsx({ [classes.inActiveDay]: isDisabled })}
                multiple
                disabled={isDisabled}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderOption={(props, option) => {
                  const selected = copyToOtherDays.some(
                    item => item.value === option.value,
                  );
                  const isCurrentDay = moment(option.value).isSame(
                    moment(selDate),
                  );
                  return (
                    <li
                      {...props}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={e => {
                        if (!isCurrentDay) handleOptionSelect(e, option);
                      }}
                    >
                      <Box sx={{ color: isCurrentDay ? 'text.disabled' : '' }}>
                        {option.label}
                      </Box>
                      <Box sx={{ visibility: selected ? 'visible' : 'hidden' }}>
                        <CheckCircleIcon
                          variant="primary"
                          sx={{ color: 'primary.main' }}
                        />
                      </Box>
                    </li>
                  );
                }}
                disableCloseOnSelect
                value={copyToOtherDays}
                onChange={(e, value) => onSetTCCopyToOtherDays(value)}
                renderInput={params => <AutoCompTextField {...params} />}
              />
            </StyledFieldBox>
          )}
        </ContentBox>
      </Box>
      {saving && <LinearProgress />}
      <FooterBox>
        {isPendingReview ? (
          <>
            <Button
              sx={{ width: '49%' }}
              variant="outlined"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button sx={{ width: '49%' }} onClick={onShowEditModal}>
              Edit
            </Button>
          </>
        ) : (
          <>
            <Button
              sx={{ width: '49%' }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={isDisabled || newComment.length > 0}
              sx={{ width: '49%' }}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        )}
      </FooterBox>
    </Box>
  );
};

MobileDayModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  selectedDay: PropTypes.object.isRequired,
  timecard: PropTypes.object.isRequired,
  copyToOtherDays: PropTypes.array.isRequired,
  onSetTCCopyToOtherDays: PropTypes.func.isRequired,
  onShowSettings: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSaveTimecard: PropTypes.func.isRequired,
  activeDeal: PropTypes.object.isRequired,
  tcInitValues: PropTypes.object.isRequired,
  saving: PropTypes.bool.isRequired,
  onShowEditModal: PropTypes.func.isRequired,
  dayTypes: PropTypes.array.isRequired,
  onChangeDayType: PropTypes.func.isRequired,
  onUpdateDailyAutoAllowances: PropTypes.func.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: MOBILE_DAY_MODAL,
    maxWidth: 'md',
    roundedCorners: true,
  }),
)(MobileDayModal);
