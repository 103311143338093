import { buildUrl } from 'providers/api';

const FETCH_TIMECARD = '/projects/:projectId/timecards/:timecardId';
const URL_TIMECARDS = '/projects/:projectId/timecards';
const URL_TIMECARD = '/projects/:projectId/timecards/:timecardId';
const URL_TIMECARD_COMMENT =
  '/projects/:projectId/timecards/:timecardId/comment/:comment';

const URL_ALLOWANCES_LIST = '/projects/:projectId/timecard_allowances';

const URL_TIMECARD_ALLOWANCES =
  '/projects/:projectId/timecards/:timecardId/allowances';
const URL_DELETE_TIMECARD_ALLOWANCE =
  '/projects/:projectId/timecards/:timecardId/allowances/:worksightId';

const URL_UPDATE_TIMECARD_ALLOWANCE =
  '/projects/:projectId/timecards/:timecardId/allowances/:worksightId/:saveHTGTimecard';
const URL_SAVE_TIMECARD_ALLOWANCE =
  '/projects/:projectId/timecards/:timecardId/allowances/:saveHTGTimecard';

const URL_SAVE_COMMENT = '/projects/:projectId/timecards/:timecardId/notes';
const URL_ADD_NOTES = '/projects/:projectId/timecards/:timecardId/notes';

const URL_OCC_CODE_CHECK = '/occupationCode/validation';
const URL_MILITARY_TIME = '/me/user_settings';

const employeeTimecardApi = ({ clientV2 }) => ({
  async fetchTimecard({ projectId, timecardId }) {
    const url = buildUrl(FETCH_TIMECARD, { projectId, timecardId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async createTimecard({ projectId, timecard }) {
    const url = buildUrl(URL_TIMECARDS, { projectId });
    const rsp = await clientV2.post(url, timecard);
    return rsp.data;
  },
  async fetchAllowanceTypes({ projectId }) {
    const url = buildUrl(URL_ALLOWANCES_LIST, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async saveComment({ projectId, timecardId, data }) {
    const url = buildUrl(URL_SAVE_COMMENT, { projectId, timecardId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
  async deleteTimecard({ projectId, timecardId, comment }) {
    let url;
    if (comment) {
      const encodedComment = encodeURIComponent(comment);
      url = buildUrl(URL_TIMECARD_COMMENT, {
        projectId,
        timecardId,
        comment: encodedComment,
      });
    } else {
      url = buildUrl(URL_TIMECARD, { projectId, timecardId });
    }

    const rsp = await clientV2.delete(url);

    return rsp.data;
  },
  async checkOccCodes(data) {
    const rsp = await clientV2.post(URL_OCC_CODE_CHECK, data);
    return rsp.data;
  },
  async loadTCAllowances({ projectId, timecardId }) {
    const URL = URL_TIMECARD_ALLOWANCES;
    const url = buildUrl(URL, { projectId, timecardId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async deleteTimecardAllowance({ projectId, timecardId, worksightId }) {
    const URL = URL_DELETE_TIMECARD_ALLOWANCE;
    const url = buildUrl(URL, {
      projectId,
      timecardId,
      worksightId,
    });
    const rsp = await clientV2.delete(url);
    return rsp.data;
  },
  async updateTimecardAllowance({
    projectId,
    timecardId,
    worksightId,
    data,
    saveHTGTimecard = true,
  }) {
    const URL = URL_UPDATE_TIMECARD_ALLOWANCE;
    const url = buildUrl(URL, {
      projectId,
      timecardId,
      worksightId,
      saveHTGTimecard,
    });
    const rsp = await clientV2.put(url, data);
    return rsp.data;
  },
  async copyTimecardAllowance({
    projectId,
    timecardId,
    worksightId,
    data,
    saveHTGTimecard = true,
  }) {
    const URL = URL_UPDATE_TIMECARD_ALLOWANCE;
    const url = buildUrl(URL, {
      projectId,
      timecardId,
      worksightId,
      saveHTGTimecard,
    });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
  async saveTimecardAllowance({
    projectId,
    timecardId,
    data,
    saveHTGTimecard = true,
  }) {
    const URL = URL_SAVE_TIMECARD_ALLOWANCE;
    const url = buildUrl(URL, { projectId, timecardId, saveHTGTimecard });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
  async createTimeCardNotes({ projectId, timecardId, data }) {
    const url = buildUrl(URL_ADD_NOTES, { projectId, timecardId });
    const rsp = await clientV2.post(url, data);

    return rsp.data;
  },
  async fetchMilitaryTime() {
    const rsp = await clientV2.get(URL_MILITARY_TIME);
    return rsp.data;
  },
  async saveMilitaryTime({ data }) {
    const rsp = await clientV2.post(URL_MILITARY_TIME, data);
    return rsp.data;
  },
});

export default employeeTimecardApi;
