import produce from 'immer';
import _ from 'lodash';
import * as _isEmpty from 'lodash/isEmpty';
import * as actions from 'actions/reviews';

const initialState = {
  open: { timecardCount: null, batches: {} },
  complete: { timecardCount: null, batches: {} },
  ready: { timecardCount: null, batches: {} },
  pending: { timecardCount: null, batches: {} },
  history: { timecardCount: null, batches: {} },
  loading: {
    batches: false,
    batchesById: false,
    dlVerifiedBatch: false,
    dlBatchReport: false,
    count: {},
  },
  loadingTimecards: false,
  loadingBatches: false,
  currentApprovalFlow: null,
  selectedApprovalFlows: [],
  processingApproval: false,
  openBatchList: [],
  batchLoading: {},
  batchDownloading: {},
  updatingLockStatus: false,
  loadingComments: false,
  comments: [],
  isShowingPaid: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading[action.area] = action.loading;
        break;
      case `${actions.loadingCounts}`:
        draft.loading.count[action.reviewType] = action.loading;
        break;
      case `${actions.loadingTimecards}`:
        draft.loadingTimecards = action.loading;
        break;

      case `${actions.storeTimecardCount}`:
        const { reviewType, count } = action;
        if (draft[reviewType] === undefined) draft[reviewType] = {};
        draft[action.reviewType].timecardCount = count;
        break;

      case `${actions.storeReviewBatches}`:
        const currentBatches = _.cloneDeep(state[action.reviewType].batches);
        const batches = action.reviewBatches.reduce((map, obj) => {
          if (
            !_isEmpty(map) &&
            map[obj.id] &&
            map[obj.id]?.details?.id === obj.id
          ) {
            const stats = map[obj.id]?.details?.stats || {};
            // status counts are calculating from duplicate array objects values
            const readyForReview =
              map[obj.id]?.details?.stats?.readyForReview +
              obj?.stats?.readyForReview;
            const total =
              map[obj.id]?.details?.stats?.total + obj?.stats?.total;
            const approved =
              map[obj.id]?.details?.stats?.approved + obj?.stats?.approved;
            const draft =
              map[obj.id]?.details?.stats?.draft + obj?.stats?.draft;
            const incomplete =
              map[obj.id]?.details?.stats?.incomplete + obj?.stats?.incomplete;
            const paid = map[obj.id]?.details?.stats?.paid + obj?.stats?.paid;
            const processed =
              map[obj.id]?.details?.stats?.processed + obj?.stats?.processed;
            const rejected =
              map[obj.id]?.details?.stats?.rejected + obj?.stats?.rejected;
            const submittedForApproval =
              map[obj.id]?.details?.stats?.submittedForApproval +
              obj?.stats?.submittedForApproval;
            const submittedToCnc =
              map[obj.id]?.details?.stats?.submittedToCnc +
              obj?.stats?.submittedToCnc;
            const totalAllowances =
              map[obj.id]?.details?.stats?.totalAllowances +
              obj?.stats?.totalAllowances;

            map[obj.id].details.stats = {
              ...stats,
              readyForReview: readyForReview,
              total: total,
              approved: approved,
              draft: draft,
              incomplete: incomplete,
              paid: paid,
              processed: processed,
              rejected: rejected,
              submittedForApproval: submittedForApproval,
              submittedToCnc: submittedToCnc,
              totalAllowances: totalAllowances,
            };
          } else {
            map[obj.id] = {
              details: obj,
            };
          }
          //keep approvalFlow (timecard) data until its refreshed
          if (currentBatches[obj.id]) {
            map[obj.id].approvalFlows = currentBatches[obj.id].approvalFlows;
          }
          return map;
        }, {});

        draft[action.reviewType].batches = { ...batches };
        break;

      case `${actions.storeReviewBatchesOnly}`:
        draft.openBatchList = action.openBatchList;
        break;
      //expects storeReviewBatches to have been run before to initialize the batch obj
      case `${actions.storeBatchTimecards}`:
        draft[action.reviewType].batches[action.batchId]['timecards'] =
          action.timecards;
        break;

      case `${actions.storeBatchLoadingByID}`:
        draft['batchLoading'][action.batchId] = action.loading;
        break;

      case `${actions.storeBatchDownloadingByID}`:
        draft['batchDownloading'][action.batchId] = action.loading;
        break;

      case `${actions.storeApprovalFlows}`:
        _.forEach(action.approvalFlows, obj => {
          draft[action.reviewType].batches[obj.reviewBatchId]['approvalFlows'] =
            obj.approvalFlow;
          draft['batchLoading'][obj.reviewBatchId] = false;
        });
        break;

      case `${actions.setReviewFromURL}`:
        draft[action.reviewType].batches[action.reviewBatchId]['fromURI'] =
          action.fromURI;
        break;

      case `${actions.setCurrentApprovalFlow}`:
        if (action.approvalFlow && action.approvalFlow.id) {
          localStorage.setItem('currentApprovalFlow', action.approvalFlow.id);
        } else {
          localStorage.removeItem('currentApprovalFlow');
        }
        draft.currentApprovalFlow = action.approvalFlow;
        break;

      case `${actions.setSelectedFlows}`:
        draft.selectedApprovalFlows = action.selectedApprovalFlows;
        break;

      case `${actions.processingApproval}`:
        draft.processingApproval = action.loading;
        break;
      case `${actions.updateBatchLockStatus}`:
        draft[action.batchType].batches[
          action.hoursBatchId
        ].details.lockStatus = action.reviewBatchLockStatus;
        break;
      case `${actions.updatingBatchLockStatus}`:
        draft.updatingLockStatus = action.updatingLockStatus;
        break;
      case `${actions.storeComments}`:
        draft.comments = action.comments;
        break;
      case `${actions.loadingComments}`:
        draft.loadingComments = action.loadingComments;
        break;
      case `${actions.clearLocalComments}`:
        draft.comments = [];
        break;

      case `${actions.toggleShowPaid}`:
        draft.isShowingPaid = !draft.isShowingPaid;
        break;
      case `${actions.reset}`:
        const skipReset = ['isShowingPaid'];
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (
            Object.hasOwnProperty.call(initialState, key) &&
            !skipReset.includes(key)
          ) {
            draft[key] = initialState[key];
          }
        }

        break;
      default:
        break;
    }
  });
