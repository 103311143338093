import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';

export const style = () => ({
  root: {
    width: '100%',
  },
});

class MenuToggler extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  render() {
    const { classes, children } = this.props;

    return <div className={classes.root}>{children}</div>;
  }
}

export default withStyleSheet('MenuToggler', style)(MenuToggler);
