import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar as MuiAppBar } from '@mui/material';
import clsx from 'clsx';

import { withStyleSheet } from 'shared/theme';

export const style = ({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    // marginBottom: 20,
  },

  appBar: {
    position: 'static',
    backgroundImage: palette.brand,
    width: '100%',
  },

  secondaryNav: {
    // marginTop: -20,
    boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,0.1)',
  },

  alternateNav: {
    // marginTop: -20,
    background: palette.background.default,
    height: 48,
    lineHeight: '48px',
    boxShadow: '0 0px 0 0 rgba(67,79,89,0.1)',
  },

  impersonationNav: {
    // marginTop: -20,
    background: '#f25e5b',
    boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,0.1)',
  },

  [breakpoints.down('sm')]: {
    alternateNav: {
      height: 'auto',
    },
  },
});

class AppBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    secondary: PropTypes.bool,
    alternate: PropTypes.bool,
    impersonate: PropTypes.bool,
  };

  static defaultProps = {
    children: undefined,
    secondary: false,
    alternate: false,
  };

  render() {
    const { classes, children, secondary, alternate, impersonate } = this.props;

    const barClass = clsx({
      [classes.appBar]: true,
      [classes.secondaryNav]: secondary,
      [classes.alternateNav]: alternate,
      [classes.impersonationNav]: impersonate,
    });

    return (
      <div className={classes.root}>
        <MuiAppBar className={barClass}>{children}</MuiAppBar>
      </div>
    );
  }
}

export default withStyleSheet('AppBar', style)(AppBar);
