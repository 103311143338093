import produce from 'immer';
import * as actions from 'actions/session';

const initialState = {
  // the current user
  currentUser: {},

  // url to redirect back to after login
  redirectUrl: null,

  //show 'unsaved data on page' system alert
  promptOnLeavePage: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.store}`:
        draft.currentUser = action.currentUser;
        break;

      case `${actions.redirect}`:
        draft.redirectUrl = action.url;
        break;

      case `${actions.setPromptOnLeavePage}`:
        draft.promptOnLeavePage = action.promptOnLeavePage;
        break;

      default:
    }
  });
