import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Divider,
  Button,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  LinearProgress,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import FormControlSwitch from 'components/Shared/FormControlSwitch';
import ProjectSettingRow from './ProjectSettingRow';

export const style = ({ palette }) => ({
  button: {
    margin: 20,
  },
  noPadding: {
    padding: 0,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  tableHead: {
    background: palette.misc.tableHeader,
    textTransform: 'uppercase',
    color: palette.text.primary,
    fontWeight: 700,
    '& > *': {
      height: 50,
    },
  },
  center: {
    textAlign: 'center',
  },
  innerCenter: {
    margin: '0 auto',
    textAlign: 'center',
  },
  rejectionAction: {
    float: 'right',
    padding: '16px 32px',
    maxWidth: 300,
  },
  arrowContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    // margin: '7px 0px 0 6px',
    cursor: 'pointer',
  },
  arrow: {
    '&& :hover': {
      color: palette.primary.main,
    },
    color: palette.action.disabled,
    padding: 0,
  },
  primaryColor: {
    color: palette.primary.main,
  },
  selectOption: {
    color: palette.action.disabledBackground,
  },
  settingsTitle: {
    cursor: 'pointer',
  },
  upArrow: {
    marginBottom: '-4px',
    marginLeft: '8px',
    fontSize: '20',
  },
  downArrow: {
    marginTop: '-5px',
    marginLeft: '8px',
    fontSize: '20',
  },
  selectAllLabel: {
    fontWeight: '400',
    fontSize: '14px',
  },
});

const ProjectAllowances = ({
  classes,
  projectAllowances,
  projectAllowancesList,
  sortProjectAllowances,
  selectAll,
  loading,
  settings,
  onUpdate,
  onUpdateProjectAllowance,
  onUpdateIndividual,
  dayTypes,
}) => {
  const [allVisible, setAllVisible] = useState(false);
  const [allMandatory, setAllMandatory] = useState(false);
  const [filterSelected, setFilterSelected] = useState({
    currentKey: 'description',
    direction: 'asc',
  });

  const setCurrent = (currentKey, direction) => {
    setFilterSelected({
      currentKey,
      direction,
    });
  };

  const setFromLabel = (inputKey, direction, sortBoolean) => {
    const currentDirection =
      inputKey === filterSelected.currentKey && direction === 'asc'
        ? 'desc'
        : 'asc';

    setFilterSelected({
      currentKey: inputKey,
      direction: currentDirection,
    });

    sortProjectAllowances(inputKey, currentDirection, sortBoolean);
  };

  const SortArrows = props => {
    const { sort, sortKey, sortBoolean, setCurrent, selected } = props;
    const { currentKey, direction } = selected;

    return (
      <span className={classes.arrowContainer}>
        <ArrowDropUp
          onClick={e => {
            e.stopPropagation();
            sort(sortKey, 'asc', sortBoolean);
            setCurrent(sortKey, 'asc');
          }}
          className={clsx({
            [classes.upArrow]: true,
            [classes.arrow]: currentKey !== sortKey,
            [classes.primaryColor]:
              currentKey === sortKey && direction === 'asc',
            [classes.selectOption]:
              currentKey === sortKey && direction !== 'asc',
          })}
        />

        <ArrowDropDown
          onClick={e => {
            e.stopPropagation();
            sort(sortKey, 'desc', sortBoolean);
            setCurrent(sortKey, 'desc');
          }}
          className={clsx({
            [classes.downArrow]: true,
            [classes.arrow]: currentKey !== sortKey,
            [classes.primaryColor]:
              currentKey === sortKey && direction === 'desc',
            [classes.selectOption]:
              currentKey === sortKey && direction !== 'desc',
          })}
        />
      </span>
    );
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Allowances</Typography>
        </AccordionSummary>
        <Divider />
        {loading && <LinearProgress />}
        <AccordionDetails className={classes.noPadding}>
          <div style={{ width: '100%' }}>
            <FormGroup className={classes.toggleColumn}>
              <FormControlSwitch
                checked={settings?.myTimecardAutoAllowances || false}
                onChange={e =>
                  onUpdateIndividual(
                    e.target.checked,
                    'myTimecardAutoAllowances',
                  )
                }
                label="My Timecard - Auto-Populate from Deal Memo"
              />
              <FormControlSwitch
                checked={settings?.crewTimecardAutoAllowances || false}
                onChange={e =>
                  onUpdateIndividual(
                    e.target.checked,
                    'crewTimecardAutoAllowances',
                  )
                }
                label="Crew Timecard - Auto-Populate from Deal Memo"
              />
            </FormGroup>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel(
                          'description',
                          filterSelected.direction,
                          false,
                        )
                      }
                    >
                      Reimbursement Type
                    </span>
                    <SortArrows
                      sortKey={'description'}
                      sortBoolean={false}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel('code', filterSelected.direction, false)
                      }
                    >
                      Pay Code
                    </span>
                    <SortArrows
                      sortKey={'code'}
                      sortBoolean={false}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel(
                          'employeeVisible',
                          filterSelected.direction,
                          true,
                        )
                      }
                    >
                      Visible for Crew
                    </span>
                    <SortArrows
                      sortKey={'employeeVisible'}
                      sortBoolean={true}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel(
                          'employeeMandatory',
                          filterSelected.direction,
                          true,
                        )
                      }
                    >
                      Supporting Doc Required
                    </span>
                    <SortArrows
                      sortKey={'employeeMandatory'}
                      sortBoolean={true}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    Auto-Apply Day Types
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.center}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            selectAll('allVisible');
                            setAllVisible(!allVisible);
                          }}
                          icon={
                            <CheckBoxOutlineBlankOutlinedIcon color="primary" />
                          }
                          checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                        />
                      }
                      label={
                        <p className={classes.selectAllLabel}>Select all</p>
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            selectAll('allMandatory');
                            setAllMandatory(!allMandatory);
                          }}
                          icon={
                            <CheckBoxOutlineBlankOutlinedIcon
                              // className={classes.primaryColor}
                              color="primary"
                            />
                          }
                          checkedIcon={
                            <CheckBoxOutlinedIcon
                              // className={classes.primaryColor}
                              color="primary"
                            />
                          }
                        />
                      }
                      label={
                        <p className={classes.selectAllLabel}>Select all</p>
                      }
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {projectAllowancesList.map((row, index) => {
                  return (
                    <ProjectSettingRow
                      key={`${row.id}`}
                      index={index}
                      classes={classes}
                      onUpdateProjectAllowance={onUpdateProjectAllowance}
                    />
                  );
                })}
              </TableBody>
            </Table>

            {loading && <LinearProgress />}
          </div>
        </AccordionDetails>
        <AccordionActions className={classes.noPadding}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.button}
            onClick={onUpdate}
          >
            Save Settings
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};
export default withStyles(style)(ProjectAllowances);
