import produce from 'immer';
import * as actions from 'actions/appReady';

const initialState = {
  authReady: false,
  flagsReady: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.authReady}`:
        draft.authReady = true;
        break;
      case `${actions.flagsReady}`:
        draft.flagsReady = true;
        break;
      default:
        return state;
    }
  });
