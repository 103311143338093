import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { TimecardDetail as TimecardDetailComp } from 'components/Employees/Reviews/Reviewer';
//actions
import { fetchDetails } from 'actions/timecards';
import { fetch as fetchProjectAllowances } from 'actions/projectAllowances';
//selector
import {
  getDetails,
  getDayTypes,
  getTimecardLoadingDetails,
} from 'selectors/timecard/common';

const mapState = (state, { timecardId }) => ({
  timecard: getDetails(state, timecardId),
  loading: getTimecardLoadingDetails(timecardId)(state),
  dayTypes: getDayTypes(state),
});

const mapDispatch = (dispatch, ownProps) => ({
  onFetchData: () => {
    const { timecardId } = ownProps;
    dispatch(fetchDetails({ timecardId }));
    dispatch(fetchProjectAllowances());
  },
});

class TimecardDetail extends Component {
  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    const { timecard, dayTypes, loading } = this.props;
    if (loading) {
      return <LinearProgress />;
    }
    return <TimecardDetailComp timecard={timecard} dayTypes={dayTypes} />;
  }
}
export default withRouter(connect(mapState, mapDispatch)(TimecardDetail));
