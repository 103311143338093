import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography, LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import withModalDialog from 'decorators/withModalDialog';
import { getSource } from 'selectors/modalDialog';
import { ConfirmModal } from 'components/Shared/Modals';
import { hide as hideModal } from 'actions/modalDialog';

import { getDealMemoFields } from '../constants/DMTableFields';
import SelectTable from 'components/Shared/SelectTable/SelectTable';

import { fetchDealMemos } from 'actions/wtc';
import { showAlert } from 'actions/alert';
import { getDealMemos, loadingChecks } from 'selectors/wtc';

export const style = ({ palette }) => ({
  container: {
    fontSize: 14,
  },
  aboveTableBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  section: {
    display: 'flex',
    '& > span': {
      padding: 2,
    },
  },
  title: {
    color: palette.text.secondary,
  },
  textGray: {
    color: palette.text.disabled,
  },
});

const modalDialog = 'EmployeeDealMemoModal';

const mapStateToProps = state => ({
  modalSource: getSource(state, modalDialog),
  dealMemos: getDealMemos(state),
  loadings: loadingChecks(state),
});

const mapDispatchToProps = dispatch => ({
  onModalHide: () => dispatch(hideModal({ dialog: modalDialog })),
  onFetchDealMemos: (employeeId, startDate, endDate) => {
    dispatch(
      fetchDealMemos({
        employeeId,
        startDate,
        endDate,
        initialLoad: true,
        withPayroll: true,
      }),
    );
  },
  onShowAlert: () => dispatch(showAlert()),
});

//handle possible date inputs
const DATE_INPUT_FORMATS = [
  'MM-DD-YYYY',
  'ddd MMM D YYYY HH:mm:ss [PST]',
  'YYYY-MM-DDTHH:mm:ssZ',
];

const EmployeeDealMemoModal = ({
  //mappedProps
  classes,
  dealMemos,
  loadings,
  onFetchDealMemos,
  onShowAlert,
  onModalHide,
  //passedProps
  change,
  activeUser,
  weekendingSelected,
  occupationCodeSelected,
  readOnly,
}) => {
  const anyFringeEnabledDeal = dealMemos?.some(dm =>
    dm.guarantees.some(g => g.grossIncludesVACFGN === true),
  );

  useEffect(() => {
    const employeeId = activeUser.workSightId;
    const weekEnding = moment(weekendingSelected, DATE_INPUT_FORMATS);
    const endDate = weekEnding.format('YYYY-MM-DDT00:00:00');

    const weekStarting = weekEnding.clone().subtract(6, 'd');
    const startDate = weekStarting.format('YYYY-MM-DDT00:00:00');

    onFetchDealMemos(employeeId, startDate, endDate);
  }, [activeUser, onFetchDealMemos, weekendingSelected]);

  const list = useMemo(
    () => _.sortBy(dealMemos, dm => parseInt(dm.code, 10)),
    [dealMemos],
  );

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (readOnly) {
      setSelectedIndex(-1);
      return;
    }
    const index = _.findIndex(list, l => l.id === occupationCodeSelected);
    const initialIndex = index !== -1 ? index : 0;
    setSelectedIndex(initialIndex);
  }, [list, occupationCodeSelected, readOnly]);

  const onSubmit = () => {
    const selectedId = list[selectedIndex].id;

    if (!selectedId) throw new Error(`Submit Error`);

    change('occupationCode', selectedId);
  };

  const title = (
    <Typography variant="h5" color="inherit">
      <span className={classes.title}>{`Occupation Code`}</span>
    </Typography>
  );

  const content = (
    <div
      className={classes.container}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          try {
            onSubmit();
            onModalHide();
          } catch (error) {
            console.error('Error when changing deal memo', error);
            onShowAlert();
          }
        }
      }}
    >
      <div className={classes.aboveTableBar}>
        <section className={classes.section}>
          <span className={classes.textGray}>Crew Member:</span>
          <span>{activeUser.fullName}</span>
        </section>
      </div>
      {loadings ? (
        <>
          <LinearProgress />
          <h3>Loading...</h3>
        </>
      ) : (
        <SelectTable
          list={list}
          fields={getDealMemoFields('emp', anyFringeEnabledDeal)}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          readOnly={readOnly}
        />
      )}
    </div>
  );

  return (
    <form>
      <ConfirmModal
        content={content}
        title={title}
        buttonText="Apply Changes"
        buttonType="secondary"
        cancelText="Cancel"
        raisedButton
        onModalHide={onModalHide}
        hasSubmitAction
        customSubmit
        customOnSubmit={e => {
          e.preventDefault();
          try {
            onSubmit();
            onModalHide();
          } catch (error) {
            onShowAlert();
            console.error('Error while changing deal memo.');
          }
        }}
      />
    </form>
  );
};

EmployeeDealMemoModal.propTypes = {
  dealMemoList: PropTypes.array,
  allowances: PropTypes.array,
  timecard: PropTypes.object,
  details: PropTypes.array,
  change: PropTypes.func,
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: modalDialog, maxWidth: 'lg' }),
  connect(mapStateToProps, mapDispatchToProps),
)(EmployeeDealMemoModal);
