import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { Listing } from 'components/Employees/Reviews/Reviewer';
//actions
import {
  fetchReviewBatches,
  reviewTimecard,
  submitAllAsRejected,
  fetchComments,
  fetchTimecardsByBatchId,
} from 'actions/reviews';
import { setFromURL, setCurrentTimecard } from 'actions/timecards';
import { show as showModal } from 'actions/modalDialog';
//selector
import {
  reviewBatchesByReviewType,
  getLoading,
  getTimecardLoadingByBatchID,
  isProcessingApproval,
} from 'selectors/reviews';
import { getProjectDetails } from 'selectors/project';
import { currentUser } from 'selectors/session';
import AddCommentModal from './Modals/AddComment';
import { BATCH_REVIEW_DEFAULT_PAGE_SIZE } from 'components/Shared/constants';
const pageSize = BATCH_REVIEW_DEFAULT_PAGE_SIZE;

const reviewType = 'pending';

const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  batches: reviewBatchesByReviewType(reviewType)(state),
  isLoading: getLoading(state),
  isProcessingApproval: isProcessingApproval(state),
  activeUser: currentUser(state),
  project: getProjectDetails(state),
  reviewType,
  timecardLoadingByBatch: getTimecardLoadingByBatchID(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onFetchData: () => {
    dispatch(fetchReviewBatches({ reviewType }));
  },
  onViewTimecard: (batch, record) => {
    dispatch(setFromURL({ fromURI: location.pathname }));
    dispatch(setCurrentTimecard({ timecardId: record.timecardId }));
    dispatch(reviewTimecard({ timecardId: record.timecardId, reviewType }));
  },
  onSubmitAsRejected: selected => {
    dispatch(submitAllAsRejected({ reviewType, selected }));
  },
  refreshData: (page, size) => {
    dispatch(fetchReviewBatches({ reviewType, page, size }));
  },
  displayComment: record => {
    dispatch(fetchComments({ timecardEntryHeaderId: record.entryHeaderId }));
    dispatch(setCurrentTimecard({ timecardId: record.entryHeaderId }));
    dispatch(showModal({ dialog: 'AddComment' }));
  },
  onFetchBatchTimecards: batchId => {
    const reviewBatches = [{ id: batchId }];
    dispatch(fetchTimecardsByBatchId({ reviewType, reviewBatches }));
  },
});

// DH - Pending Review
class Pending extends Component {
  state = {
    page: 0,
    pageSize: pageSize,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  onRefreshPage(page, pageSize) {
    this.setState({
      page,
      pageSize,
    });

    this.props.refreshData(page, pageSize);
  }

  render() {
    const { activeUser } = this.props;

    if (this.props.isLoading) {
      return <LinearProgress />;
    }

    return (
      <React.Fragment>
        <Listing
          {...this.props}
          role={activeUser.role}
          page={this.state.page}
          pageSize={this.state.pageSize}
          onRefreshPage={this.onRefreshPage.bind(this)}
        />
        <AddCommentModal />
      </React.Fragment>
    );
  }
}
export default withRouter(connect(mapState, mapDispatch)(Pending));
