import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { Grid } from '@mui/material';

import { withStyleSheet } from 'shared/theme';

export const style = () => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    margin: 0,
  },
  wrap: { flexGrow: 1 },
});

class TofuLayout extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    headers: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  };

  static defaultProps = {
    header: undefined,
  };

  render() {
    const { classes, children, header: Header } = this.props;

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        {Header && <Header />}
        <Grid item xs={12} sm={8} md={4}>
          {children}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyleSheet('TofuLayout', style))(TofuLayout);
