import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, LinearProgress, TextField, Chip } from '@mui/material';

import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';

//selectors
import { getModalParams } from 'selectors/modalDialog';
import { getSubmitting } from 'selectors/searchTimecards';

//actions
import { hide as hideModal } from 'actions/modalDialog';
import { submitDraftTimecards } from 'actions/searchTimecards';
import { submitToEmployees as timecardSubmitToEmployees } from 'actions/timecards';

import { withStyleSheet } from 'shared/theme';
import withModalDialog from 'decorators/withModalDialog';

//components
import Notice from 'components/Shared/Text/Notice';
import { ConfirmModal } from 'components/Shared/Modals';

//utils
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
import { anyInvalidTimeEntry } from 'utils/reactTableUtils';

const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

export const style = ({ palette }) => ({
  textField: {
    width: '100%',
    marginBottom: 32,
    marginTop: 24,
  },
  content: {
    margin: '0px 24px',
  },
  chip: {
    height: 24,
    backgroundColor: palette.primary['+9'],
    color: 'inherit',
    margin: 5,
  },
});

const modalDialog = 'SubmitDraftModal';

const mapStateToProps = state => {
  return {
    modalParams: getModalParams(state, modalDialog),
    submitting: getSubmitting(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitToEmployee: (timecardIds, notes) => {
    dispatch(submitDraftTimecards({ timecardIds, notes }));
    dispatch(hideModal({ dialog: modalDialog }));
  },

  onSaveAndSubmit: (timecardId, notes) => {
    dispatch(timecardSubmitToEmployees({ timecardId, notes }));
    dispatch(hideModal({ dialog: modalDialog }));
  },
  onModalHide: () => dispatch(hideModal({ dialog: modalDialog })),
});

const SubmitDraftModal = props => {
  const {
    classes,
    modalParams: { timecards = [], variant },
    onModalHide,
    onSaveAndSubmit,
    onSubmitToEmployee,
    submitting,
  } = props;

  const [comment, setComment] = useState('');

  const isSubmitEmergency = variant === 'submitEmergency';
  const isReviewTimecard = variant === 'reviewTimecard';
  const isAddComment = variant === 'addComment' || isReviewTimecard;

  const invalidTimes = timecards.filter(tc => {
    switch (variant) {
      case 'reviewTimecard':
        return !tc.isValid;
      default:
        return anyInvalidTimeEntry(tc.workedDaysDetails);
    }
  });
  const hasInvalidTimes = invalidTimes.length > 0;

  const title = (
    <Typography variant="h6" color="inherit">
      {isAddComment ? `Add Comment` : 'Cannot submit emergency timecard'}
    </Typography>
  );

  const invalidTimesWarning = hasInvalidTimes && (
    <Notice type="warning">
      <section>
        <Typography>
          The following timecards are missing some times. <br />
          {isSubmitEmergency
            ? 'They cannot be submitted as emergency timecards.'
            : 'Employees will have to add these times themselves.'}
        </Typography>
        {invalidTimes.map(tc => (
          <Chip
            key={tc.timecardEntryHeaderId}
            className={classes.chip}
            display={'inline'}
            label={tc.employee}
          />
        ))}
      </section>
    </Notice>
  );

  const commentPrompt = (
    <div>
      <TextField
        className={classes.textField}
        variant="outlined"
        value={comment}
        onChange={e => setComment(e.target.value)}
        multiline
        rows="8"
        inputProps={{ maxLength: commentsLimit }}
      />
      <Typography>
        Add a comment to give further instruction to employees
      </Typography>
    </div>
  );

  const content = (
    <section className={classes.content}>
      {invalidTimesWarning}
      {isAddComment && commentPrompt}
      {submitting && <LinearProgress />}
    </section>
  );

  const onSubmit = () => {
    let tcIds = timecards.map(tc => tc.timecardId);
    if (isReviewTimecard) {
      //save before submitting to employee
      onSaveAndSubmit(tcIds[0], comment);
    } else {
      onSubmitToEmployee(tcIds, comment);
    }
  };

  return (
    <div>
      <ConfirmModal
        content={content}
        title={title}
        buttonText={`Submit to employees`}
        cancelText="Cancel"
        submitDisabled={submitting}
        onModalHide={onModalHide}
        hasSubmitAction={!isSubmitEmergency}
        buttonHide={isSubmitEmergency}
        customSubmit
        customOnSubmit={onSubmit}
      />
    </div>
  );
};

SubmitDraftModal.propTypes = {
  classes: PropTypes.object.isRequired,
  modalParams: PropTypes.object.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onSubmitToEmployee: PropTypes.func.isRequired,
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'SubmitDraftModal', maxWidth: 'sm' }),
  connect(mapStateToProps, mapDispatchToProps),
)(SubmitDraftModal);
