import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { Typography, LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import withModalDialog from 'decorators/withModalDialog';
import { ConfirmModal } from 'components/Shared/Modals';
import WarningIcon from '@mui/icons-material/Warning';
import { deleteTimecard } from 'actions/wtc';
import { formatMessages } from 'utils/messages';
import TextField from 'components/Shared/redux/TextField';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

export const style = ({ palette }) => ({
  dialogBox: {
    padding: 10,
    width: 600,
    height: 600,
  },
  textField: {
    width: '100%',
    marginBottom: 32,
  },
  warningIcon: {
    verticalAlign: 'middle',
  },
  warningMessage: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  errorField: {
    extend: 'field',
    '& > label': {
      color: palette.error.main,
    },
    '& > p': {
      color: palette.error.main,
    },
  },
});

const onSubmit = (values, dispatch, ownProps) => {
  const timecard = ownProps.timecard;
  dispatch(deleteTimecard({ ...values, timecard, formName }));
};

const formName = 'DeleteTimecardWTC';

class DeleteTimecardWTC extends Component {
  static propTypes = {
    error: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: undefined,
  };

  render() {
    const { classes, error, handleSubmit, timecard, submitting, ...others } =
      this.props;

    const title = (
      <Typography variant="h6" color="inherit">
        {'Delete Timecard'}
      </Typography>
    );

    const content = (
      <div>
        <Typography>
          This timecard can be deleted based on its current status. Deleting
          timecards should only be used as a last resort. Do you really want to
          delete this timecard?
        </Typography>
        <div className={classes.warningMessage}>
          <WarningIcon color={'warning'} className={classes.warningIcon} />{' '}
          <b>This cannot be undone.</b>
        </div>
        <div>
          <Field
            className={
              error && error.email ? classes.errorField : classes.textField
            }
            hinttext={
              error && error.comment ? formatMessages(error.comment) : null
            }
            component={TextField}
            name="comment"
            variant="outlined"
            multiline
            rows="4"
            inputProps={{ maxLength: commentsLimit }}
            required
            placeholder="Add a comment as to why you’re deleting this timecard. Your comment will be sent in a notification email to the creator of this timecard."
          />
        </div>
      </div>
    );
    return (
      <form onSubmit={handleSubmit}>
        {submitting && <LinearProgress />}
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Yes, Delete Timecard"
          buttonType="secondary"
          raisedButton
          cancelText="No, Take Me Back"
          submitDisabled={submitting}
          {...others}
        />
      </form>
    );
  }
}
export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'DeleteTimecardWTC', maxWidth: 'md' }),
  reduxForm({
    form: formName,
    onSubmit,
  }),
)(DeleteTimecardWTC);
