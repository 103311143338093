import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

//actions
import { downloadDefaultAllowanceDocument } from 'actions/profile';
// selectors
import { downloadingSupportingDocument } from 'selectors/profileDetails';
const mapState = state => ({
  loading: downloadingSupportingDocument(state),
});
const mapDispatch = (dispatch, { match }) => ({
  onDownloadDocument: () => {
    const token = match.params.token;
    dispatch(downloadDefaultAllowanceDocument({ token, fileName: token }));
  },
});

class AllowanceDocument extends Component {
  componentDidMount() {
    this.props.onDownloadDocument();
  }

  render() {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        {this.props.loading && <CircularProgress />}
      </div>
    );
  }
}
export default withRouter(connect(mapState, mapDispatch)(AllowanceDocument));
