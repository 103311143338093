import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, IconButton, Tooltip, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers-pro';

//icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
//components
import AdditionalFields from 'feature/DTS/AdditionalFieldsContainer';

//utils
import { getMaxWeekEnding } from './dtsUtils';

const useStyles = makeStyles(({ palette }) => ({
  dateText: {
    marginBottom: 8,
    marginRight: 8,
    fontWeight: 500,
  },
  datePicker: {
    width: 200,
  },
  dateArrow: {
    fontSize: 14,
    color: palette.primary.main,
  },
  disabledColor: {
    color: palette.gray['+7'],
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 8,
  },
  additionalFields: {
    marginLeft: 'auto',
    width: 175,
  },
}));
const Header = props => {
  const classes = useStyles();
  const {
    save,
    listTimecards,
    hiddenColumns,
    setHiddenColumns,
    tableColumns,
    onSetEffectiveDate,
    effectiveDate,
    confirmModalIfDirty,
    dtsDirty,
    hasError,
    loading,
    clearChanges,
    isProjectCompleted,
    isSaving,
  } = props;

  const [disableDoubleSave, setDisableDoubleSave] = React.useState(false);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const disableNextDayArrow = false;

  const saveTooltip = isProjectCompleted
    ? 'This project is complete, no new timecards can be created'
    : !dtsDirty
    ? 'No changes to save'
    : hasError
    ? 'Cannot save with errors'
    : '';

  return (
    <header className={classes.header}>
      <h1 className={classes.dateText}>Date:</h1>
      <Box sx={{ display: 'flex', flexWrap: 'no-wrap' }}>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            const date = effectiveDate.clone().subtract(1, 'days');
            confirmModalIfDirty(onSetEffectiveDate, [{ date }]);
          }}
          size="large"
          disabled={isSaving}
        >
          <ArrowBackIosIcon
            className={clsx(classes.dateArrow, {
              [classes.disabledColor]: isSaving,
            })}
          />
        </IconButton>
        <DatePicker
          format="dddd, MM/DD"
          value={effectiveDate}
          open={datePickerOpen}
          disabled={isSaving}
          onChange={date => {
            if (date !== effectiveDate) {
              confirmModalIfDirty(onSetEffectiveDate, [{ date }]);
            }
          }}
          maxDate={getMaxWeekEnding()}
          onClose={() => setDatePickerOpen(false)}
          slotProps={{
            textField: {
              className: classes.datePicker,
              onClick: () => setDatePickerOpen(true),
            },
          }}
        />

        <IconButton
          className={classes.iconButton}
          onClick={() => {
            const date = effectiveDate.clone().add(1, 'days');
            confirmModalIfDirty(onSetEffectiveDate, [{ date }]);
          }}
          disabled={disableNextDayArrow || isSaving}
          size="large"
        >
          <ArrowForwardIosIcon
            className={clsx(classes.dateArrow, {
              [classes.disabledColor]: disableNextDayArrow || isSaving,
            })}
          />
        </IconButton>
      </Box>
      <Tooltip title={saveTooltip} arrow>
        <div>
          <Button
            color={'primary'}
            variant="contained"
            disabled={
              !dtsDirty ||
              hasError ||
              loading ||
              disableDoubleSave ||
              isProjectCompleted
            }
            onClick={() => {
              setDisableDoubleSave(true);
              save();
              setTimeout(() => {
                setDisableDoubleSave(false);
              }, 1000);
            }}
          >
            Save
          </Button>
        </div>
      </Tooltip>
      <div>
        <Button
          color={'primary'}
          variant="outlined"
          onClick={() => confirmModalIfDirty(listTimecards)}
        >
          List timecards
        </Button>
      </div>
      <div>
        <Button
          color={'primary'}
          variant="text"
          onClick={clearChanges}
          disabled={!dtsDirty}
        >
          Clear all changes
        </Button>
      </div>
      <div className={classes.additionalFields}>
        <AdditionalFields
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          tableColumns={tableColumns}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  save: PropTypes.func.isRequired,
  listTimecards: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  setHiddenColumns: PropTypes.func.isRequired,
  tableColumns: PropTypes.array.isRequired,
  onSetEffectiveDate: PropTypes.func.isRequired,
  effectiveDate: PropTypes.object.isRequired,
  confirmModalIfDirty: PropTypes.func.isRequired,
  dtsDirty: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  clearChanges: PropTypes.func.isRequired,
  isProjectCompleted: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default memo(Header);
