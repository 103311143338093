import createAction from './createAction';

//Profile
export const updateProfileDetails = createAction('updateProfileDetails');

///notifications
export const fetchNotifications = createAction('fetch/profile/notifications');
export const loadingNotifications = createAction(
  'loading/profile/notifications',
);
export const storeNotifications = createAction('store/profile/notifications');
export const editPreferences = createAction('edit/profile/preferences');
export const updateNotifications = createAction('update/profile/notifications');
export const setUpdateStatus = createAction(
  'set/profile/updateStatus/notifications',
);

//phone number
export const fetchPhoneNumber = createAction('fetch/profile/phoneNumber');
export const loadingPhoneNumber = createAction('loading/profile/phoneNumber');
export const storePhoneNumber = createAction('store/profile/phoneNumber');
export const verifyPhoneNumber = createAction('verify/profile/phoneNumber');
export const resendVerificationCode = createAction(
  'resendVerificationCode/profile/code',
);
export const verifyCode = createAction('verifyCode/profile/code');
export const deletePhoneNumber = createAction('delete/profile/phoneNumber');

///Profile Allowance
export const fetchAllowanceTypes = createAction('fetch/profile/allowanceTypes');
export const loadingAllowanceTypes = createAction(
  'loading/profile/allowanceTypes',
);
export const storeAllowanceTypes = createAction('store/profile/allowanceTypes');

export const fetchDefaultAllowances = createAction(
  'fetch/profile/defaultAllowances',
);
export const loadingDefaultAllowances = createAction(
  'loading/profile/defaultAllowances',
);
export const storeDefaultAllowances = createAction(
  'store/profile/defaultAllowances',
);

export const downloadDefaultAllowanceDocument = createAction(
  'download/profile/defaultAllowance',
);
export const downloadingSupportingDocument = createAction(
  'download/supporting-document',
);

export const deleteDefaultAllowanceDocument = createAction(
  'delete/profile/defaultAllowance',
);

export const editDefaultAllowance = createAction(
  'edit/profile/defaultAllowance',
); // update
export const updateDefaultAllowance = createAction(
  'update/profile/defaultAllowance',
); // update
export const saveDefaultAllowance = createAction(
  'save/profile/defaultAllowance',
); // update
