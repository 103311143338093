import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import alert from './alert';
import appReady from './appReady';
import auth from './auth';

import masterDepartments from './masterDepartments';
import locations from './locations';
import menu from './menu';
import bulkEdit from './bulkEdit';
import dts from './dts';
import events from './events';
import users from './users';
import flags from './flags';
import reviewFlows from './reviewFlows';
import modalDialog from './modalDialog';
import routeParams from './routeParams';
import pagination from './pagination';
import projects from './projects';
import session from './session';
import searchInvoices from '../feature/SearchInvoices/reducer';
import searchTimecards from './searchTimecards';
import sorting from './sorting';
import settings from './settings';
import gridFiltering from './gridFiltering';
import userInfo from './userInfo';
import timecards from './timecards';
import projectAllowances from './projectAllowances';
import profile from './profile';
import reports from './reports';
import reviews from './reviews';
import invitations from './invitations';
import departments from './departments';
import pages from './pages';
import wtc from './wtc';
import invoiceTemplate from './invoiceTemplate';
import crewList from '../feature/CrewList/reducer';
import moveTimecards from './moveTimecards';
import digitalEdits from './digitalEdits';
import empTimecard from 'feature/EmployeeTimecard/reducer';

// eslint-disable-next-line import/no-anonymous-default-export
export default history =>
  combineReducers({
    alert,
    appReady,
    auth,
    bulkEdit,
    crewList,
    departments,
    dts,
    events,
    empTimecard,
    flags,
    form,
    gridFiltering,
    invitations,
    digitalEdits,
    invoiceTemplate,
    locations,
    masterDepartments,
    menu,
    modalDialog,
    moveTimecards,
    projectAllowances,
    pages,
    profile,
    pagination,
    projects,
    reports,
    reviewFlows,
    reviews,
    routeParams,
    router: connectRouter(history),
    session,
    searchInvoices,
    searchTimecards,
    sorting,
    settings,
    timecards,
    userInfo,
    users,
    wtc,
  });
