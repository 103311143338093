import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import {
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  Checkbox,
  Tooltip,
  // Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import TableCellSortable, { sortProps } from './TableCellSortable';
import { isRegionCanada } from 'utils/helperFunctions';

export const style = ({ palette }) => ({
  root: {},
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    textTransform: 'uppercase',
    color: palette.text.secondary,
    fontWeight: 700,
    '& > *': {
      height: 50,
      fontWeight: 700,
      color: palette.text.secondary,
    },
  },
  tableCell: {
    fontWeight: 700,
    color: palette.text.secondary,
  },
  compactCell: {
    padding: 5,
    fontWeight: 700,
    color: palette.text.secondary,
  },
  optionalSignal: {
    marginLeft: 4,
    fontSize: '1rem',
    color: palette.primary.main,
  },
  subText: {
    fontWeight: 400,
    textTransform: 'lowercase',
  },
  optionalCell: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
  },
  customRow: {
    verticalAlign: 'baseline',
    '& > th': {
      paddingBottom: 2,
    },
  },
});

export const columnHeaderProps = PropTypes.arrayOf(
  PropTypes.shape({
    columnId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    compact: PropTypes.bool,
    alignRight: PropTypes.bool,
    align: PropTypes.string,
    isCheck: PropTypes.bool,
  }),
);

class TableHead extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    columns: columnHeaderProps.isRequired,
    onApplySorting: PropTypes.func,
    sortParams: sortProps,
    onSelectAll: PropTypes.func,
    checkIndeterminate: PropTypes.bool,
    showCheck: PropTypes.bool,
    selectAll: PropTypes.bool,
  };

  static defaultProps = {
    onApplySorting: undefined,
    sortParams: undefined,
    showCheck: false,
  };

  renderHeaderColumns() {
    const {
      classes,
      columns = [],
      onApplySorting,
      sortParams,
      checkIndeterminate,
      selectAll,
      onSelectAll,
      showCheck,
    } = this.props;
    const creatingTimecard = columns[2] && columns[2].columnId === 'dayType';
    const region = localStorage.getItem('region');
    const headerRows = columns.map(header => {
      if (header.hidden) return null;
      let align = header.align || 'left';
      if (header.alignRight) align = 'right';
      if (header.optional) {
        return (
          <TableCell
            className={header.compact ? classes.compactCell : classes.tableCell}
            size={header.compact ? 'small' : 'medium'}
            key={header.columnId}
            align={align}
            style={{
              lineHeight: '1.2rem',
            }}
          >
            <div className={classes.optionalCell}>
              {header.label}

              <Tooltip
                placement="top"
                arrow
                variant="largeText"
                title={
                  <span>
                    Your Payroll Accountant can
                    <br /> select this option for you.
                  </span>
                }
              >
                <ErrorOutlineIcon className={classes.optionalSignal} />
              </Tooltip>
            </div>
            <div className={classes.subText}>{`(Optional)`}</div>
          </TableCell>
        );
      }
      if (header.sortable && onApplySorting && sortParams) {
        return (
          <TableCellSortable
            className={header.compact ? classes.compactCell : classes.tableCell}
            applySorting={onApplySorting}
            columnId={header.columnId}
            compact
            key={header.columnId}
            align={align}
            {...sortParams}
          >
            {header.label}
          </TableCellSortable>
        );
      } else if (header.isCheck && showCheck) {
        return (
          <TableCell
            padding="checkbox"
            key={`check-all-${header.columnId}`}
            align="center"
          >
            <Checkbox
              indeterminate={checkIndeterminate}
              checked={selectAll}
              color="primary"
              onChange={e => onSelectAll(e.target.checked)}
              inputProps={{ 'aria-label': 'Select All' }}
            />
          </TableCell>
        );
      } else if (
        isRegionCanada(region) &&
        header.columnId === 'locationType.name'
      ) {
        return '';
      }
      return (
        <TableCell
          className={header.compact ? classes.compactCell : classes.tableCell}
          size={header.compact ? 'small' : 'medium'}
          key={header.columnId}
          align={align}
          style={creatingTimecard ? { lineHeight: '1.2rem' } : {}}
        >
          {header.label}
          {header.columnId === 'dayType' ? (
            <div className={classes.subText}>{`(Required)`}</div>
          ) : (
            ''
          )}
        </TableCell>
      );
    });

    return (
      <TableRow className={creatingTimecard ? classes.customRow : ''}>
        {headerRows}
      </TableRow>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiTableHead className={classes.tableHead}>
        {this.renderHeaderColumns()}
      </MuiTableHead>
    );
  }
}

export default withStyleSheet('TableHead', style)(TableHead);
