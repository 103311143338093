import produce from 'immer';
import * as actions from 'actions/sorting';

const initialState = {};

export const defaultSorting = {
  orderBy: null,
  direction: 'asc',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.initialize}`:
        if (!draft[action.grid]) {
          draft[action.grid] = defaultSorting;
        }
        break;

      case `${actions.remove}`:
        delete draft[action.grid];
        break;

      case `${actions.apply}`:
        draft[action.grid].orderBy = action.orderBy;
        draft[action.grid].direction = action.direction;
        break;

      default:
    }
  });
