import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavItem } from 'components/Shared/Nav';

// The key to avoiding blocked re-renders after location changes is,
// to pass the blocking component the location object as a prop.
// We can achieve this in multiple ways & best approach is to use the `withRouter` decorator.
// `withRouter` passes down routing params to components underneath as props.
// Links below explains the problem and solutions to it in a better way.
// https://reacttraining.com/react-router/web/guides/redux-integration
// https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking
const mapStateToProps = (state, { history }) => ({
  location: history.location,
});

export default withRouter(connect(mapStateToProps)(NavItem));
