import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { redirect } from 'actions/session';

const mapStateToProps = (state, ownProps) => ({
  isLoggedIn: !!state.getIn(['auth', 'authenticated']),
  currentUrl: ownProps.location.pathname,
});

class Authenticated extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    currentUrl: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    loginPath: PropTypes.string,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loginPath: '/login',
  };

  componentDidMount() {
    const { currentUrl, dispatch, isLoggedIn, loginPath, match } = this.props;

    if (!isLoggedIn && match.path !== loginPath) {
      dispatch(redirect({ url: currentUrl }));
      dispatch(push(loginPath));
    }
  }

  render() {
    const { children, isLoggedIn, loginPath, match } = this.props;

    if (isLoggedIn) {
      return children;
    }

    if (match.path === loginPath) {
      throw new Error(`Infinite redirect to ${loginPath} detected!`);
    }

    return null;
  }
}

export default connect(mapStateToProps)(Authenticated);
