import produce from 'immer';
import * as actions from 'actions/wtc';
import _ from 'lodash';
import { defaultAllowancesTableOrder as allowancesOrder } from 'components/Employees/Reviews/WTC/tableFields';
import {
  PROJECT_REQ_FIELDS,
  SORT_BYS,
  fieldHasValue,
  hasAutoCoding,
  getFieldType,
  sortWTCFieldsByPosition,
} from 'utils/wtcWeekUtils';

import { getFilteredDropdown } from 'selectors/wtc';
import { setVisibleSelected } from 'components/Employees/Reviews/SearchTimecards/searchUtils';

export const initialState = {
  loading: {
    workSchedule: false,
    episode: false,
    dealMemo: false,
    occupationCode: false,
    workLocation: false,
    splitHour: false,
    allowancePayCode: false,
    dayType: false,
  },
  processing: {
    comments: false,
    paidHours: false,
    TCBreakdown: false,
    timecardReport: false,
    wtcHistory: false,
  },
  wtcTimecardHeaderIds: [],
  loadingDrawerTimecards: false,
  loadingTimeCard: false,
  loadingUndo: false,
  loadingMove: false,
  savingTimecard: false,
  approvingTimecards: {},
  rejectingTimecard: false,
  downloadingReport: false,
  timecard: {},
  breakdown: {},
  paidHours: {},
  comments: [],
  nextApprovers: [],
  currentApprovers: [],
  workSchedules: [],
  dayTypes: [],
  series: [],
  episodes: [],
  subdivisions: [],
  occupationCodes: [],
  workLocations: [],
  dealMemos: [],
  allowancesTableOrder: _.cloneDeep(allowancesOrder),
  tableFieldOrder: {},
  projectReqFields: _.cloneDeep(PROJECT_REQ_FIELDS),
  payCodes: [],
  splitHourTypes: [],
  prospectiveTimecard: {},
  producerWWChanged: false,
  employeeWWchanged: false,
  actionType: '',
  scaleRateError: false,
  loadingScaleRate: {},
  autoCoding: false,
  loadingNewBatch: false,
  numTimecardsHidden: null,
  //drawer state
  timecardsInDrawer: [],
  sortBy: SORT_BYS[0],
  textFilter: '',
  filters: {},
  searchFilters: {
    departments: '',
    status: '',
  },
  drawerSearchFilters: {},
  batchInfo: null,
  rounding: [],
  currentBatchWorksightId: null,
  currentTimecardHeaderId: null,
  isMultiBatch: false,
  canEditWorkTimes: false,
  resubmitComment: '',
  prevChangeComments: '',
  awaitingCalculate: false,
  dmScaleRatesLoading: false,
  pendingCalculation: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.storeOccupationCodes}`:
        let codes = [...state.occupationCodes];
        const codesExistingIds = codes.map(code => code.id);
        action.data.forEach(code => {
          if (codesExistingIds.includes(code.id) === false) codes.push(code);
        });
        draft.occupationCodes = codes;
        break;
      case `${actions.storeWorkLocations}`:
        draft.workLocations = [...action.data];
        break;
      case `${actions.loadingTimecardsInDrawer}`:
        draft.loadingDrawerTimecards = action.loadingDrawerTimecards;
        break;
      case `${actions.loadingTimecard}`:
        draft.loadingTimeCard = action.loadingTimeCard;
        break;
      case `${actions.loading}`:
        let key = Object.keys(action.loading)[0];
        draft.loading[key] = action.loading[key];
        break;
      case `${actions.processing}`:
        let processKey = Object.keys(action.processing)[0];
        draft.processing[processKey] = action.processing[processKey];
        break;
      case `${actions.loadingUndo}`:
        draft.loadingUndo = action.loadingUndo;
        break;

      case `${actions.storeTimecardsInDrawer}`:
        draft.timecardsInDrawer =
          action.timecards.length > 0
            ? [
                ...new Map(
                  action.timecards.map(timecard => [
                    timecard['timecardEntryHeaderId'],
                    timecard,
                  ]),
                ).values(),
              ]
            : [];

        if (action.numTimecardsHidden || action.numTimecardsHidden === 0) {
          draft.numTimecardsHidden = action.numTimecardsHidden;
        }
        break;
      case `${actions.storeEpisodes}`:
        let episodes = [...state.episodes];
        const episodesExistingIds = episodes.map(epi => epi.id);
        action.episodes.forEach(epi => {
          if (episodesExistingIds.includes(epi.id) === false) {
            episodes.push(epi);
          }
        });
        draft.episodes = episodes;
        break;
      case `${actions.storeWorkSchedules}`:
        let schedules = [...state.workSchedules];
        const schedulesExistingIds = schedules.map(schedule => schedule.id);
        action.workSchedules.forEach(schedule => {
          if (schedulesExistingIds.includes(schedule.id) === false) {
            schedules.push(schedule);
          }
        });
        draft.workSchedules = schedules;
        break;
      case `${actions.storeParsedTimecard}`:
        const timecard = action.timecard;

        draft.timecard = timecard;
        draft.autoCoding = hasAutoCoding(timecard);
        break;

      // fall through list of things to clear data
      case `${actions.clearTimecard}`:
        draft.timecard = {};
      // eslint-no-fallthrough
      case `${actions.clearOptions}`:
        draft.workLocations = [];
        draft.dayTypes = [];
        draft.occupationCodes = [];
        draft.dealMemos = [];
        break;
      case `${actions.storeTCBreakdown}`:
        draft.breakdown = action.breakdown;
        break;
      case `${actions.storeComments}`:
        draft.comments = action.comments;
        break;
      case `${actions.storeNextApprovers}`:
        draft.nextApprovers = action.nextApprovers;
        break;
      case `${actions.storeCurrentApprovers}`:
        draft.currentApprovers = action.currentApprovers;
        break;
      case `${actions.storePaidHours}`:
        draft.paidHours = action.paidHours;
        break;
      case `${actions.storeDealMemos}`:
        draft.dealMemos = action.dealMemos;
        break;

      case `${actions.setTextFilter}`: //timecard filter
        draft.textFilter = action.filter;
        break;
      case `${actions.setSearchFilter}`: //individual filter option search/filter
        draft.searchFilters[action.filterName] = action.value;
        break;
      case `${actions.onSelect}`:
        const selected =
          draft.filters[action.filterName][action.index].selected;
        draft.filters[action.filterName][action.index].selected = !selected;
        break;

      case `${actions.onSelectAll}`:
        const fullList = draft.filters[action.filterName];

        const mockState = { wtc: state };
        const visibleList = getFilteredDropdown(mockState, action.filterName);
        const visibleSelectedCount = visibleList.filter(
          f => f.selected === true,
        ).length;

        let newValue;
        if (visibleSelectedCount === visibleList.length) {
          //unselect all visible
          newValue = false;
        } else {
          //select all visible
          newValue = true;
        }

        setVisibleSelected({ fullList, visibleList, newValue });
        draft.filters[action.filterName] = fullList;
        draft.filtersPristine = false;

        break;
      case `${actions.storeFilterOptions}`:
        draft.filters[action.filterName] = action.options;
        break;
      case `${actions.clearAllFilters}`:
        for (const filterName in draft.filters) {
          if (Object.hasOwnProperty.call(draft.filters, filterName)) {
            const filter = draft.filters[filterName];
            filter.forEach(f => (f.selected = false));
          }
        }

        break;

      case `${actions.initTableFields}`:
        const project = action.project;
        const incomingCols = _.cloneDeep(action.columns); //user defined fields

        // H+ application expecting 'lastMan1In' to display validations and field value,
        // so changing key to 'lastMan1In' instead of 'LastMan1In'
        if (incomingCols.LastMan1In) {
          incomingCols.lastMan1In = { ...incomingCols.LastMan1In };
          delete incomingCols.LastMan1In;
        }
        let columns = {};
        const templateFields = action.templateFields; //all wtc templates

        const accountCodePosition = incomingCols.accountCode?.position;
        for (let key in incomingCols) {
          const field = incomingCols[key];

          if (field.hide === 1) continue;

          if (field.type === 'time') {
            field.group = 'time';
          }

          //setting meta-code group after accountCode field
          if (accountCodePosition && field.position >= accountCodePosition) {
            field.group = 'meta-codes';
          }
          const rowId = field.rowId;
          const isFieldVisible = templateFields.some(
            templateField => templateField.id === rowId,
          );

          columns[key] = {
            columnId: key,
            group: field.group,
            label: field.display || field.label,
            position: parseInt(field.position) + 2, //first 2 positions for split and dayInfo
            type: getFieldType(field.type, key),
            visible: isFieldVisible,
            skipValidation: field.skipValidation === 1 ? true : false,
            additionalField: !isFieldVisible,
            rowId: field.rowId,
          };
          if (key === 'hoursWorked') {
            columns[key].visible = true;
            columns[key].group = 'special';
            columns[key].additionalField = false;
          }
          if (key === 'combineCheck') {
            columns[key].type = 'auto-complete';
          }
        }

        columns['splitButton'] = {
          position: 0,
          columnId: 'splitButton',
          label: '',
          type: 'buttons',
          group: 'special',
          visible: true,
          additionalField: false,
        };
        //if splitButton and dayInfo has same position 0, during sorting one column is skipping because of same position.
        columns['dayInfo'] = {
          position: 1,
          columnId: 'dayInfo',
          label: '',
          type: 'buttons',
          group: 'special',
          visible: true,
          additionalField: false,
        };

        columns = sortWTCFieldsByPosition(columns);
        draft.tableFieldOrder = _.cloneDeep(columns);
        const length = state?.timecard?.details?.length ?? 0;

        for (let key in columns) {
          //make fields in timecard visible

          const templateFields = action?.templateFields || [];
          const isFieldVisible = templateFields?.some(
            field => field.id === columns[key].rowId,
          );

          for (let i = 0; i < length; i++) {
            let checkingProperty = _.get(
              state.timecard.details[i],
              columns[key].columnId,
            );
            if (
              fieldHasValue(checkingProperty) &&
              !draft.tableFieldOrder[key]?.visible
            ) {
              draft.tableFieldOrder[key] = {
                ...columns[key],
                visible: true,
              };
              //If field is not available in WTC template setting additional field to true
              if (!isFieldVisible) {
                draft.tableFieldOrder[key] = {
                  ...draft.tableFieldOrder[key],
                  additionalField: true,
                };
              }
            }
          }
        }
        //make required fields visible
        if (state.projectReqFields.hasOwnProperty(key)) {
          let projectLabel = state.projectReqFields[key].projectLabel;
          if (
            project &&
            (project[projectLabel] === 'Y' || project[projectLabel] === true)
          ) {
            draft.tableFieldOrder[key] = {
              ...columns[key],
              visible: true,
              additionalField: true,
            };
          }
        }

        //Init Allowances Table Fields
        const allowancesLength = state.timecard?.allowances?.length ?? 0;

        for (let field in allowancesOrder) {
          //if field is visible in WTC template
          //then setting the field visibility to true to visible in allowances grid
          const wtcGridColumns = draft.tableFieldOrder;
          if (wtcGridColumns[field]?.visible) {
            draft.allowancesTableOrder[field] = {
              ...draft.allowancesTableOrder[field],
              visible: true,
            };
          }
          for (let i = 0; i < allowancesLength; i++) {
            const checkingProperty = _.get(
              state.timecard.allowances[i],
              allowancesOrder[field].path,
            );
            let property = !!checkingProperty;
            if (typeof checkingProperty === 'object') {
              property = !_.isEmpty(checkingProperty);
            }
            if (property && !draft.allowancesTableOrder[field]?.visible) {
              draft.allowancesTableOrder[field] = {
                ...allowancesOrder[field],
                visible: true,
              };
            }
          }
        }
        break;

      case `${actions.downloadingReport}`:
        draft.downloadingReport = action.loading;
        break;

      case `${actions.toggleTableFields}`:
        const fieldName = action.columnId;

        if (draft.tableFieldOrder[fieldName]) {
          if (action.visible !== undefined) {
            draft.tableFieldOrder[fieldName].visible = action.visible;
          } else {
            const prevValue = state.tableFieldOrder[fieldName].visible;
            draft.tableFieldOrder[fieldName].visible = !prevValue;
          }
        }
        break;
      case `${actions.resetTableFields}`:
        draft.tableFieldOrder = {};
        break;
      case `${actions.toggleAllowanceField}`:
        const columnId = action.columnId;
        if (draft.allowancesTableOrder[columnId]) {
          if (action.visible !== undefined) {
            draft.allowancesTableOrder[columnId].visible = action.visible;
          } else {
            const prevValue = state.allowancesTableOrder[columnId].visible;
            draft.allowancesTableOrder[columnId].visible = !prevValue;
          }
        }
        break;
      case `${actions.resetAllAdditionalFields}`:
        draft.allowancesTableOrder = _.cloneDeep(allowancesOrder);
        break;
      case `${actions.savingTimecard}`:
        draft.savingTimecard = action.savingTimecard;
        break;
      case `${actions.approvingTimecard}`:
        const approvingTimecards = draft.approvingTimecards;
        approvingTimecards[action.headerId] = action.approving;
        draft.approvingTimecards = approvingTimecards;
        break;
      case `${actions.rejectingTimecard}`:
        draft.rejectingTimecard = action.rejectingTimecard;
        break;
      case `${actions.storeSplitHourTypes}`:
        draft.splitHourTypes = action.splitHourTypes;
        break;
      case `${actions.storeAllowancePayCode}`:
        draft.payCodes = action.payCodes;
        break;
      case `${actions.setProspectiveTimecard}`:
        draft.prospectiveTimecard = action.prospectiveTimecard;
        break;
      case `${actions.onProducersWWChange}`:
        draft.producerWWChanged = action.producerWWChanged;
        break;
      case `${actions.onEmployeesWWChange}`:
        draft.employeeWWchanged = action.employeeWWchanged;
        break;
      case `${actions.setActionType}`:
        draft.actionType = action.actionType;
        break;
      case `${actions.storeWTCHistory}`:
        draft.history = action.history;
        break;
      case `${actions.setSortBy}`:
        draft.sortBy = action.sortBy;
        break;
      case `${actions.storeDayTypes}`:
        let dayTypes = [...state.dayTypes];
        const dayTypesExistingIds = dayTypes.map(type => type.id);
        action.dayTypes.forEach(type => {
          if (dayTypesExistingIds.includes(type.id) === false) {
            dayTypes.push(type);
          }
        });
        draft.dayTypes = dayTypes;
        break;
      case `${actions.storeScaleRateError}`:
        draft.scaleRateError = true;
        break;
      case `${actions.clearScaleRateError}`:
        draft.scaleRateError = false;
        break;
      case `${actions.storeRounding}`:
        draft.rounding = action.rounding;
        break;
      case `${actions.loadingScaleRate}`:
        if (action.member) {
          draft.loadingScaleRate[action.member] = action.loading;
        } else if (Array.isArray(action.members)) {
          action.members.forEach(member => {
            draft.loadingScaleRate[member] = action.loading;
          });
        }
        break;
      case `${actions.setLoadingNewBatch}`:
        draft.loadingNewBatch = action.loadingNewBatch;
        break;
      case `${actions.setWTCTimecardHeaderIds}`:
        draft.wtcTimecardHeaderIds = action.wtcTimecardHeaderIds;
        break;
      case `${actions.setCurrentBatchWorksightId}`:
        //Also set in actions.storeBatchInfo
        draft.currentBatchWorksightId = action.currentBatchWorksightId;
        break;
      case `${actions.setCurrentTimecardHeaderId}`:
        draft.currentTimecardHeaderId = action.currentTimecardHeaderId;
        break;
      case `${actions.setIsMultiBatch}`:
        draft.isMultiBatch = action.isMultiBatch;
        break;

      case `${actions.storeBatchInfo}`:
        draft.batchInfo = action.batchInfo;
        if (action.batchInfo?.worksightId) {
          draft.currentBatchWorksightId = action.batchInfo?.worksightId;
        }
        break;

      case `${actions.storeResubmitComment}`:
        draft.resubmitComment = action.comment;
        break;
      case `${actions.storePrevChangeComments}`:
        draft.prevChangeComments = action.prevChangeComments;
        break;
      case `${actions.onUnmountWTC}`:
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const field in initialState) {
          if (Object.hasOwnProperty.call(initialState, field)) {
            const element = initialState[field];
            draft[field] = _.cloneDeep(element);
          }
        }
        break;
      case `${actions.setDMScaleRatesLoading}`:
        draft.dmScaleRatesLoading = action.dmScaleRatesLoading;
        break;
      default:
        break;
    }
  });
