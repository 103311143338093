import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import { FaCheck as Verified } from 'react-icons/fa';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'components/Shared/redux';
import InputAdornment from '@mui/material/InputAdornment';
import { normalizePhone } from 'utils/weekUtils';
import {
  Divider,
  Button,
  TableRow,
  TableCell,
  Typography,
  FormGroup,
  Switch,
  FormControlLabel,
  Table,
  TableBody,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  IconButton,
  LinearProgress,
  Checkbox,
  Chip,
  Avatar,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

// components
import NotificationRow from './NotificationRow';
export const style = ({ palette }) => ({
  div: {
    width: '100%',
  },
  successMessage: {
    color: palette.primary.main,
  },
  failedMessage: {
    color: palette.error.main,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  noPadding: {
    padding: 0,
  },
  padding: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  textLeftPadding: {
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: 'small',
  },
  textRightPadding: {
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'small',
  },
  tableHead: {
    color: palette.text.secondary,
    backgroundColor: palette.misc.tableHeader,
    textTransform: 'uppercase',
    fontWeight: 700,
    '& > *': {
      height: 10,
    },
  },
  iconButton: {
    padding: 8,
    fontSize: '1em',
    display: 'inline',
    verticalAlign: 'bottom',
  },
  fieldColumn: {
    padding: 15,
    borderBottom: 0,
    width: 50,
  },
  fieldColumnWide: {
    padding: 15,
    borderBottom: 0,
    width: 75,
  },
  inputFieldColumn: {
    padding: 15,
    borderBottom: 0,
    width: '70%',
  },
  field: {
    width: '100%',
  },
  saveButton: {
    margin: 20,
    width: 150,
  },
  phoneNumberRow: {
    position: 'relative',
  },
});

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSMSNotification: props.phoneNumberVerified || false,
      phoneNumber: '',
      inPhoneNumberEditMode: false,
    };
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.phoneNumInput = React.createRef();
  }

  renderPreferences({ fields, notifications, onSelectIndividual }) {
    return fields.map((member, index) => {
      const preference = notifications && notifications[index];
      return (
        <NotificationRow
          member={member}
          key={preference.notificationtype}
          preference={preference}
          onSelectIndividual={onSelectIndividual}
        />
      );
    });
  }

  toggleSMSNotifications(checked) {
    this.setState({ enableSMSNotification: checked });

    if (_.isEmpty(this.props.phoneNumbers.number)) {
      this.toggleEditMode();
    }
  }

  onPhoneNumberChange = evt => {
    this.setState({ phoneNumber: evt.target.value });
  };

  onClearPhoneNumber = () => {
    this.props.change('phoneNumber', '');
    this.setState({ phoneNumber: '' });
  };

  toggleEditMode = () => {
    const { inPhoneNumberEditMode } = this.state;
    this.setState({
      inPhoneNumberEditMode: !inPhoneNumberEditMode,
    });
  };

  cancelEditPhoneNumber = () => {
    this.toggleEditMode();
    this.props.change('phoneNumber', this.props.phoneNumbers.number);
    this.setState({
      phoneNumber: this.props.phoneNumbers.number,
    });
  };

  sendVerification = () => {
    if (this.state.inPhoneNumberEditMode) {
      this.toggleEditMode();
    }
    this.props.onVerifyPhoneNumber();
  };

  render() {
    const {
      classes,
      notifications,
      selectedAll,
      phoneNumbers,
      updateStatus = '',
      loadNotification,
      loadPhoneNumber,
      onSelectIndividual,
      onSelectAll,
      onDeletePhoneNumber,
    } = this.props;

    const { inPhoneNumberEditMode } = this.state;

    const isVerified = phoneNumbers.verified === true;

    const aNumberIsPresent =
      (phoneNumbers.number || this.state.phoneNumber).length !== 0;

    const diffNumber =
      (this.state.phoneNumber || '').length > 11 &&
      this.state.phoneNumber !== phoneNumbers.number;

    const enableToggle =
      !_.isEmpty(phoneNumbers.number) ||
      !_.isEmpty(this.state.phoneNumber) ||
      this.state.enableSMSNotification;

    const selectAllCheckbox = selectedAll === true;

    const statusMessageCls =
      updateStatus === 'F' ? classes.failedMessage : classes.successMessage;
    let updateStatusMessage = '';
    if (updateStatus === 'S') {
      updateStatusMessage = 'Notifications saved successfully';
    } else if (updateStatus === 'F') {
      updateStatusMessage = 'Notifications save failed';
    }

    return (
      <div style={{ marginTop: 30 }}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Notifications</Typography>
          </AccordionSummary>
          <Divider />
          {(loadNotification || loadPhoneNumber) && <LinearProgress />}
          {!(loadNotification || loadPhoneNumber) && [
            <AccordionDetails className={classes.noPadding} key={'details'}>
              <div className={classes.div}>
                <FormGroup className={classes.padding}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(event, checked) =>
                          this.toggleSMSNotifications(checked)
                        }
                        checked={enableToggle}
                      />
                    }
                    label="Enable SMS Notifications"
                  />
                </FormGroup>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectAllCheckbox}
                          color="primary"
                          onChange={onSelectAll(
                            'selectedAll',
                            selectAllCheckbox,
                          )}
                        />
                      </TableCell>
                      <TableCell>Notification</TableCell>
                      <TableCell>Delivery</TableCell>
                      <TableCell>Delivery Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name="notifications"
                      component={this.renderPreferences}
                      notifications={notifications}
                      onSelectIndividual={onSelectIndividual}
                    />
                  </TableBody>
                </Table>
                <Divider />
                {enableToggle && (
                  <div className={classes.div}>
                    <Table>
                      <TableBody>
                        <TableRow className={classes.phoneNumberRow}>
                          <TableCell className={classes.inputFieldColumn}>
                            <Field
                              className={classes.field}
                              component={TextField}
                              placeholder="Phone Number"
                              name={'phoneNumber'}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +1
                                  </InputAdornment>
                                ),
                              }}
                              normalize={normalizePhone}
                              disabled={!inPhoneNumberEditMode}
                              onChange={event =>
                                this.onPhoneNumberChange(event)
                              }
                            />
                          </TableCell>
                          {inPhoneNumberEditMode && (
                            <TableCell className={classes.fieldColumn}>
                              <div className={classes.actionContainer}>
                                <IconButton
                                  className={classes.iconButton}
                                  onClick={this.onClearPhoneNumber}
                                  aria-label="clear phone number"
                                  size="large"
                                >
                                  <ClearIcon size={18} />
                                </IconButton>
                              </div>
                            </TableCell>
                          )}
                          {inPhoneNumberEditMode && isVerified && (
                            <TableCell className={classes.fieldColumn}>
                              <div className={classes.actionContainer}>
                                <Button
                                  color="primary"
                                  onClick={this.cancelEditPhoneNumber}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </TableCell>
                          )}
                          {aNumberIsPresent && !inPhoneNumberEditMode && (
                            <TableCell className={classes.fieldColumnWide}>
                              <IconButton
                                className={classes.iconButton}
                                onClick={this.toggleEditMode}
                                size="large"
                              >
                                <EditIcon size={18} />
                              </IconButton>
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => onDeletePhoneNumber()}
                                size="large"
                              >
                                <DeleteIcon size={18} />
                              </IconButton>
                            </TableCell>
                          )}
                          {inPhoneNumberEditMode && (
                            <TableCell className={classes.fieldColumn}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.saveButton}
                                onClick={() => this.sendVerification()}
                                disabled={!diffNumber}
                              >
                                {'Send Verification'}
                              </Button>
                            </TableCell>
                          )}
                          {aNumberIsPresent &&
                            !isVerified &&
                            !inPhoneNumberEditMode && (
                              <TableCell className={classes.fieldColumn}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.saveButton}
                                  onClick={() => this.sendVerification()}
                                >
                                  {'Send Verification'}
                                </Button>
                              </TableCell>
                            )}
                          {isVerified && !diffNumber && !inPhoneNumberEditMode && (
                            <TableCell className={classes.fieldColumn}>
                              <Chip
                                color="primary"
                                label="Verified"
                                size="small"
                                avatar={
                                  <Avatar>
                                    <Verified />
                                  </Avatar>
                                }
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Typography className={classes.textRightPadding}>
                      {
                        'Please Note: If you update this number for notfications it will also be changed on your profile. Standard rates & charges may apply.'
                      }
                    </Typography>
                    <Divider />
                  </div>
                )}
                <Typography className={classes.textLeftPadding}>
                  {'*All times are in PST timezone'}
                </Typography>
                <Divider />
                {(loadNotification || loadPhoneNumber) && <LinearProgress />}
              </div>
            </AccordionDetails>,
            <AccordionActions className={classes.noPadding} key={'actions'}>
              <div className={classes.spacer}>
                <Typography variant="subtitle2" className={statusMessageCls}>
                  {updateStatusMessage}
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.saveButton}
                >
                  Save
                </Button>
              </div>
            </AccordionActions>,
          ]}
        </Accordion>
      </div>
    );
  }
}
export default withStyles(style)(Notifications);
