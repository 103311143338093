import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { canadianLabel, compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import {
  Paper,
  Box,
  Divider,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import { Button } from 'feature/EmployeeTimecard/Shared/styledComponents';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileQuestion from 'components/images/FileQuestion';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { show as showModal } from 'actions/modalDialog';
import {
  ALLOWANCE_DIALOG,
  DELETE_TC_ALLOWANCE_DIALOG,
} from '../Modals/modalNames';
import { TIMECARD_PENDING_EMP_REVIEW } from 'components/Shared/constants';
import { getMissedFileAllowanceId } from 'feature/EmployeeTimecard/empTimecardUtils';
import * as sel from '../selectors';

import * as actions from '../actions';

import TooltipText from './TooltipText';
import AlloFlagBadge from 'components/Shared/Text/AlloFlagBadge';
import { useQuery } from 'utils/customHooks';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  AllowanceCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    maxWidth: '300px',
    width: '90%',
  },
}));
const formSelector = formValueSelector('empTimecard');

const mapState = state => ({
  isTcEditable: sel.getIsTcEditable(state),
  allowanceTypes: sel.getAllowanceTypes(state),
  allowances: formSelector(state, 'allowances'),
  disabledBtns: sel.getDisabledBtns(state),
  status: sel.getTimecardStatus(state),
});

const mapDispatch = dispatch => ({
  onShowAlloModal: params => {
    dispatch(showModal({ dialog: ALLOWANCE_DIALOG, modalParams: params }));
  },
  onDownloadDocument: (file, token) => {
    dispatch(actions.downloadDocument({ token, file }));
  },
  onShowDeleteAllowanceModal: params => {
    dispatch(
      showModal({ dialog: DELETE_TC_ALLOWANCE_DIALOG, modalParams: params }),
    );
  },
});

const AllowanceCard = props => {
  const classes = useStyles();
  const {
    allowance,
    onShowAlloModal,
    onDownloadDocument,
    onShowDeleteAllowanceModal,
    isTcEditable,
    allowanceTypes,
    allowances,
    disabledBtns,
    status,
  } = props;

  const query = useQuery();
  const history = useHistory();

  const { disableOnLoading } = disabledBtns;

  const {
    amount,
    allowanceType,
    filename,
    document,
    token,
    allowanceTypeFlag,
    worksightId,
    isEdited,
    combineCheckCode,
  } = allowance;

  //To display tooltip on mobile
  const [open, setOpen] = React.useState(false);
  const allowanceTypeFull = allowanceTypes.find(
    a => a?.id === allowanceType?.id,
  );

  const name = allowanceType?.name || 'default';
  const isAutoDeal = !!allowanceTypeFlag && allowanceTypeFlag === 'A';
  const fileRequired = allowanceTypeFull?.employeeMandatory || false;
  const isTcPending = status === TIMECARD_PENDING_EMP_REVIEW;
  const flagType = isAutoDeal ? (isEdited ? 'modified' : 'deal') : 'manual';

  //local allowance will have document
  //server allowance will have filename
  const hasFile = !!document || !!filename;

  const displayAmount = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const displayFilename = filename || document?.name || '';
  const hasFileError = fileRequired && !hasFile;

  const [isDocReqWrapped, setIsDocReqWrapped] = React.useState(false);

  React.useEffect(() => {
    if (Number(amount) > 1000) {
      setIsDocReqWrapped(true);
    } else {
      setIsDocReqWrapped(false);
    }
  }, [amount]);
  React.useEffect(() => {
    const openMissingDoc = query.get('openMissingDoc');
    if ((isTcEditable || isTcPending) && openMissingDoc && !disableOnLoading) {
      query.delete('openMissingDoc');
      history.replace({
        search: query.toString(),
      });
      const id = getMissedFileAllowanceId(allowances, allowanceTypes);
      if (id) {
        onShowAlloModal({ worksightId: id });
      }
    }
  }, [
    allowanceTypes,
    allowances,
    disableOnLoading,
    history,
    isTcEditable,
    isTcPending,
    onShowAlloModal,
    query,
  ]);

  const checkLabel = canadianLabel('check');
  const liveCheckLabel = canadianLabel('Live Check');
  return (
    <>
      {!disableOnLoading && (
        <Paper className={classes.AllowanceCard}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
            <AlloFlagBadge flagType={flagType} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              whiteSpace: '',
              pl: 1,
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
            >
              <TooltipText text={name} />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                disabled={disableOnLoading}
                onClick={() => {
                  onShowAlloModal({ worksightId });
                }}
              >
                {isTcEditable ? (
                  <EditIcon
                    sx={{
                      color: disableOnLoading ? 'disabled' : 'primary.main',
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{
                      color: disableOnLoading ? 'disabled' : 'primary.main',
                    }}
                  />
                )}
              </IconButton>
              {isTcEditable && (
                <IconButton
                  disabled={disableOnLoading}
                  onClick={() => onShowDeleteAllowanceModal({ worksightId })}
                >
                  <DeleteIcon
                    sx={{ color: disableOnLoading ? 'disabled' : 'error.main' }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: '100%',
              p: 2,
            }}
          >
            <Box sx={{ flex: '0 0 auto', overflow: 'hidden' }}>
              <Box sx={{ color: 'text.secondary', fontSize: 14 }}>Total</Box>

              <TooltipText
                text={displayAmount}
                textProps={{ sx: { fontSize: 14 } }}
              />
            </Box>
            <Box sx={{ display: 'block' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Switch disabled checked={combineCheckCode} />
                <Box sx={{ fontWeight: 500, mr: '5px', fontSize: '14px' }}>
                  {liveCheckLabel}
                </Box>
                <Tooltip
                  open={open}
                  title={`When enabled, this allowance will be paid on a separate paper ${checkLabel}.`}
                  placement="top"
                >
                  <span
                    style={{ marginTop: '5px' }}
                    //for mobile
                    onTouchStart={() => setOpen(!open)}
                    //for desktop
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </span>
                </Tooltip>
              </Box>
              {hasFile && (
                <Button
                  variant="text"
                  disabled={!token}
                  endIcon={<CloudDownloadIcon />}
                  onClick={() => onDownloadDocument(filename, token)}
                  sx={{ flex: 1, p: '0px 5px', width: '140px' }}
                >
                  <TooltipText
                    text={displayFilename}
                    textProps={{ sx: { fontSize: 14 } }}
                  />
                </Button>
              )}
              {hasFileError && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'error.main',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    onShowAlloModal({ worksightId });
                  }}
                >
                  <FileQuestion />
                  <Box sx={{ width: isDocReqWrapped ? '100px' : 'auto' }}>
                    Document Required
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

AllowanceCard.propTypes = {
  allowance: PropTypes.object.isRequired,
  onShowAlloModal: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onShowDeleteAllowanceModal: PropTypes.func.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  allowanceTypes: PropTypes.array.isRequired,
  allowances: PropTypes.array.isRequired,
  disabledBtns: PropTypes.shape({
    disableOnLoading: PropTypes.bool,
  }),
  status: PropTypes.string.isRequired,
};

export default compose(connect(mapState, mapDispatch))(AllowanceCard);
