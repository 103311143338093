import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import moment from 'moment';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableRow,
  styled,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { getHideEmptyCols } from 'selectors/digitalEdits';
import { getIsRegionCAN } from 'selectors/project';

import { formatNum2DecOrNone } from 'utils/helperFunctions';
import { weekDayCharFormat } from 'utils/formatDate';

const DataCell = styled(TableCell)(({ theme }) => ({
  padding: '1px',
  border: `1px solid ${theme.palette.primary['+7']}`,
  minWidth: '60px',
  fontWeight: 800,
  whiteSpace: 'nowrap',
}));

const useStyles = makeStyles(({ palette }) => ({
  DataTableDigitalEdits: {
    padding: '18px',
  },
  headerRow: {
    borderBottom: `2px solid ${palette.primary.main}`,
    backgroundColor: palette.primary['+8'],
    height: '30px',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: palette.primary['+9'],
    },
  },
  tooltip: {
    cursor: 'default',
    borderBottom: `1px dashed ${palette.gray.primary}`,
  },
}));

const mapState = state => ({
  hideEmptyCols: getHideEmptyCols(state),
  isRegionCAN: getIsRegionCAN(state),
});

const DataTableDigitalEdits = props => {
  const classes = useStyles();
  let {
    headers,
    data,
    title,
    hideEmptyCols,
    viewEnabled,
    isRegionCAN,
    loading = false,
  } = props;

  if (!viewEnabled) return null;

  if (!headers) {
    headers = Object.keys(data[0]).map(h => ({
      label: h.toUpperCase(),
      accessor: h,
    }));
  }
  headers = headers.filter(h => {
    const rowHasValue = row => {
      return row[h.accessor] !== null && row[h.accessor] !== '';
    };

    const allRowsEmpty = data.every(row => rowHasValue(row) === false);

    if (h.hideInCanada && isRegionCAN && allRowsEmpty) {
      return false;
    }
    if (hideEmptyCols && allRowsEmpty) {
      return false;
    }
    return true;
  });

  return (
    <Box className={classes.DataTableDigitalEdits}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!!title && (
          <Typography sx={{ fontWeight: 800 }} variant={'h4'}>
            {title}
          </Typography>
        )}
      </Box>
      {loading && <LinearProgress />}
      <Table sx={{ width: 'fit-content', mt: 2 }} size="small">
        <TableHead>
          <TableRow className={classes.headerRow}>
            {headers.map(header => (
              <DataCell
                key={header.label}
                sx={{ color: loading ? 'text.disabled' : 'primary.main' }}
              >
                <Box sx={{ display: 'inline', mx: '4px' }}>
                  {header.tooltip ? (
                    <>
                      <Tooltip title={header.tooltip} placement="top">
                        <span className={classes.tooltip}>{header.label}</span>
                      </Tooltip>
                    </>
                  ) : (
                    header.label
                  )}
                </Box>
              </DataCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={i} className={classes.row}>
              {headers.map(header => {
                let value = row[header.accessor];
                let day = null;
                if (header.isDate && value) {
                  if (header.accessor === 'earnedDate') {
                    const weekDay = moment(value).format('dddd');
                    day = weekDayCharFormat(weekDay);
                  }
                  value = moment(value).format('MM/DD');
                } else if (header.isCurrency && value !== null) {
                  const maximumFractionDigits = header.maxDecimal || 2;
                  if (
                    header.accessor === 'baseRate' &&
                    value === 0 &&
                    (row['units'] === 0 || row['units'] === null)
                  ) {
                    //if hours is blank AND rate is 0, display blank for rate
                    value = ' ';
                  } else {
                    //fyi will format null to $0.00
                    value = Number(value).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits,
                    });
                  }
                } else if (typeof value === 'number') {
                  if (header.accessor === 'units' && value === 0) {
                    //if hours is 0, display blank
                    value = ' ';
                  } else {
                    value = formatNum2DecOrNone(value);
                  }
                }
                return (
                  <DataCell
                    key={header.label}
                    align={header.isCurrency ? 'right' : 'left'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        mx: '4px',
                        color: loading ? 'text.disabled' : undefined,
                      }}
                    >
                      {day && <div style={{ width: '22px' }}>{day}</div>}
                      {value}
                    </Box>
                  </DataCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

DataTableDigitalEdits.propTypes = {
  headers: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  data: PropTypes.array.isRequired,
  hideEmptyCols: PropTypes.bool.isRequired,
  viewEnabled: PropTypes.bool.isRequired,
  isRegionCAN: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

export default compose(connect(mapState, null))(DataTableDigitalEdits);
