import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'utils/helperFunctions';
// components
import { PageHeader } from 'components/Nav';
// decorators
import withSearchFilter from 'decorators/withSearchFilter';

const mapDispatchToProps = dispatch => ({});

const mapState = (state, { match, history }) => ({
  location: history.location,
});

class CrewTimecardListPageHeader extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  render() {
    return (
      <PageHeader
        title="Crew Timecards"
        searchBarPlaceHolder="Search by Date"
        showSearch
        {...this.props}
      />
    );
  }
}

export default compose(
  withRouter(
    connect(
      mapState,
      mapDispatchToProps,
    )(withSearchFilter('crew-timecard')(CrewTimecardListPageHeader)),
  ),
);
