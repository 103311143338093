import React, { Component } from 'react';
import _ from 'lodash';
import {
  reduxForm,
  getFormSyncErrors,
  getFormSyncWarnings,
  formValueSelector,
  change as changeAction,
} from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { push } from 'connected-react-router';
// components
import TimecardDays from 'components/Employees/Reviews/WTC/TimecardDays';
import Header from 'components/Employees/Reviews/WTC/WTCHeader';
import Drawer from 'components/Employees/Reviews/WTC/Drawer';
import StickyHeader from 'components/Employees/Reviews/WTC/StickyHeader';
import Comments from 'components/Employees/Reviews/WTC/WTCComments';
import Breakdown from 'components/Employees/Reviews/WTC/Breakdown';
import Allowances from 'components/Employees/Reviews/WTC/Allowances';
import ConfirmTakeMeBack from './Modals/ConfirmTakeMeBack';
import ProducerWWAlert from './Modals/ProducerWWAlert';
import EmployeeWWAlert from './Modals/EmployeeWWAlert';

import {
  wtcTimecardValidate as validate,
  wtcTimecardWarn,
} from 'utils/timecardValidationUtils';
// containers
import Notifications from './Notifications';
import ForceCommentWTC from './Modals/ForceCommentWTC.js';
import DeleteTimecardWTC from './Modals/DeleteTimecardWTC.js';
import AddCommentModal from './Modals/AddComment';
import WTCDealMemoModal from 'components/Shared/Modals/WTCDealMemoModal';

// actions
import * as actions from 'actions/wtc';
import {
  fetchCountriesV1,
  fetchStatesV1,
  fetchCitiesV1,
  fetchCountiesV1,
  fetchSubdivisionsV1,
  storeStatesV1,
  storeCitiesV1,
  storeCountiesV1,
} from 'actions/location';

// import { storeTimecard as setCurrentTimecard } from 'actions/routeParams';
import { showAlert } from 'actions/alert';
import { show as showModal, setDmModal } from 'actions/modalDialog';
import { fetchReviewLevels } from 'actions/reviewFlows';

// selector
import {
  getWorkSchedules,
  getOccupationCodes,
  getWorkLocationTypes,
  getTimecard,
  parsedPaidHour,
  getTCBreakdown,
  getComments,
  getAllowancesTableOrder,
  getTableFieldOrder,
  loadingChecks,
  wtcProcessingChecks,
  getCanRenderTimecard,
  getLoadingTimecard,
  isCurrentUserUPM,
  isWTCUpdating,
  isWTCDisabled,
  getDealMemoOptions,
  getEpisodes,
  getSplitHourTypes,
  getEmployeeId,
  getPayCodes,
  getHPlusStatus,
  getTCEntryHeaderId,
  getProducerWWStatus,
  getEmployeeWWStatus,
  isFieldUPMEnabled,
  getDayTypeOptions,
  getDateOptions,
  isCurrentUserPA,
  getLoadingHistory,
  getScaleLoading,
  getScaleRateError,
  getAutoCoding,
  isAllowanceDisabled,
  getSubdivisionOptions,
  getShowFringeInfo,
  getCountriesV2,
  getStatesV2,
  getCountiesV2,
  getCitiesV2,
  getShowForceResubmitModal,
  getHasWorkTimeChanges,
  getIsMultiBatch,
} from 'selectors/wtc';
import { getCurrentProject } from 'selectors/project';
import { isProcessingApproval } from 'selectors/reviews';
import { getSettings } from 'selectors/settings';
import WtcHistory from '../../../../components/Employees/Reviews/WTC/WTCHistory';

//utils
import { makeMaskFunc, validateAccountMask } from 'utils/weekUtils';
import { downHandler, checkParent, WTC_FORM_NAME } from 'utils/wtcWeekUtils';

const style = theme => ({
  WTC: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  tc: {
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    padding: 10,
    flexDirection: 'column',
    overflow: 'auto',
  },
  form: {
    width: '100%',
  },
  bottomFlex: {
    display: 'flex',
    marginBottom: 300,
  },
  altTables: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: 770,
    overflow: 'auto',
  },
  notifications: {
    width: 375,
  },
});

const selector = formValueSelector(WTC_FORM_NAME);
let hideDirtyAlertAfterMove = false;

const mapState = state => ({
  employeeId: getEmployeeId(state),
  timecard: getTimecard(state),
  paidHours: parsedPaidHour(state),
  breakdown: getTCBreakdown(state),
  comments: getComments(state),
  allowancesOrder: getAllowancesTableOrder(state),
  tableFields: getTableFieldOrder(state),
  //
  project: getCurrentProject(state),
  settings: getSettings(state),
  hPlusStatus: getHPlusStatus(state),
  currentTimecardHeaderId: getTCEntryHeaderId(state),
  isUPM: isCurrentUserUPM(state),
  isPA: isCurrentUserPA(state),
  hasScaleRateError: getScaleRateError(state),
  // dropdown Options
  dayTypes: getDayTypeOptions(state),
  workSchedules: getWorkSchedules(state),
  occupationCodes: getOccupationCodes(state),
  workLocations: getWorkLocationTypes(state),
  dealMemos: getDealMemoOptions(state),
  episodes: getEpisodes(state),
  payCodes: getPayCodes(state),
  splitHourTypes: getSplitHourTypes(state),
  dateOptions: getDateOptions(state),
  //
  countries: getCountriesV2(state),
  countyOptions: getCountiesV2(state),
  stateOptions: getStatesV2(state),
  cityOptions: getCitiesV2(state),

  subdivisions: getSubdivisionOptions(state),
  showFringeInfo: getShowFringeInfo(state),

  // Redux Form
  // This used to be { ...getTimecard(state)}
  // but that was causing the form to re-render more than needed, but leaving it here and this comment
  // in case we get odd behavior after this is pushed, this might be a root cause that we can use to find other
  // bugs that this brings out.
  initialValues: getTimecard(state),

  details: selector(state, 'details'),
  allowances: selector(state, 'allowances'),
  workingWorkState: selector(state, 'workState'),
  dealMemoId: selector(state, 'dealMemo.id'),
  masterRowData: selector(
    state,
    'workCountry',
    'workState',
    'workCounty',
    'workCity',
    'workSubdivision',
    'locationType',
    'accountCode',
    'episode',
    'series',
    'location',
    'set',
    'insurance',
    'freeField1',
    'freeField2',
    'freeField3',
    'freeField4',
    'combineCheck',
    'dealMemo',
    'project',
    'status',
  ),
  timecardDealMemo: selector(state, 'dealMemo'),
  hasWorkTimeChanges: getHasWorkTimeChanges(state),
  workflowAction: selector(state, 'workflowAction'),
  hasWorkTimeChangesForm: selector(state, 'hasWorkTimeChanges'),
  formSyncErrors: getFormSyncErrors(WTC_FORM_NAME)(state),
  formSyncWarnings: getFormSyncWarnings(WTC_FORM_NAME)(state),
  // all loadings here
  loadings: loadingChecks(state),
  processing: wtcProcessingChecks(state),
  canRenderTimecard: getCanRenderTimecard(state),
  wtcDisabled: isWTCDisabled(state),
  allowanceDisabled: isAllowanceDisabled(state),
  upmEnabled: isFieldUPMEnabled(state),
  wtcUpdating: isWTCUpdating(state),
  loadingTimecard: getLoadingTimecard(state),
  loadingWtcHistory: getLoadingHistory(state),
  scaleLoading: getScaleLoading(state),

  //non loading flags
  producerWWStatus: getProducerWWStatus(state),
  employeeWWStatus: getEmployeeWWStatus(state),
  autoCoding: getAutoCoding(state),
  showForceResubmitModal: getShowForceResubmitModal(state),
  isMultiBatch: getIsMultiBatch(state),
  isBulkApprovalProcessing: isProcessingApproval(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(actions.fetchWorkSchedules({ initialLoad: true }));
    dispatch(fetchReviewLevels()); //needed for getting current approvers
  },

  onTimecardLoad: timecardEntryHeaderId => {
    dispatch(actions.loadTimecard({ timecardEntryHeaderId }));
  },
  setProspectiveTimecard: prospectiveTimecard => {
    dispatch(actions.setProspectiveTimecard({ prospectiveTimecard }));
  },
  onSetFieldVisibility: (fieldName, visible = true) => {
    dispatch(actions.toggleTableFields({ columnId: fieldName, visible }));
    dispatch(actions.toggleAllowanceField({ columnId: fieldName, visible }));
  },
  loadWorkSchedules: inputText => {
    dispatch(actions.fetchWorkSchedules({ search: inputText }));
  },
  loadOccupationCodes: params => {
    dispatch(actions.occupationCodes(params));
  },
  loadWorkLocations: inputText => {
    dispatch(actions.workLocations({ search: inputText }));
  },
  loadDayTypes: inputText => {
    dispatch(actions.dayType({ search: inputText }));
  },
  loadEpisodes: inputText => {
    dispatch(actions.fetchEpisodes({ search: inputText }));
  },
  onSaveTimecard: () => {
    dispatch(actions.saveTimecard());
  },
  onApprovalWithoutChange: () => dispatch(actions.approveTimecard()),
  onForceCommentAction: action => {
    const dialog = 'ForceCommentWTC';
    dispatch(showModal({ dialog: dialog, modalParams: { action } }));
  },
  onAddComment: () => {
    dispatch(showModal({ dialog: 'AddWTCComment' }));
  },
  onBackToListAction: () => {
    const projectId = match.params.projectId;
    const defaultURL = `/projects/${projectId}/review/search-timecards`;
    const fromURI = localStorage.getItem('fromURI') || defaultURL;

    setTimeout(() => {
      dispatch(
        actions.setCurrentTimecardHeaderId({ currentTimecardHeaderId: null }),
      );
      dispatch(push(fromURI));
    }, 1000);
  },
  onShowDirtyAlert: () => {
    dispatch(showModal({ dialog: 'TakeMeBack', maxWidth: 'md' }));
  },
  onUnmountWTC: () => {
    dispatch(actions.onUnmountWTC());
  },
  onProducerWWChange: producerWWChanged => {
    dispatch(actions.onProducersWWChange({ producerWWChanged }));
  },
  onEmployeeWWChange: employeeWWchanged => {
    dispatch(actions.onEmployeesWWChange({ employeeWWchanged }));
  },

  onShowMemoModal: ({ source, startDate, endDate, currentDeal }) => {
    const modalDialog = 'WTCDealMemoModal';
    dispatch(showModal({ dialog: modalDialog }));
    dispatch(
      setDmModal({
        dialog: modalDialog,
        source,
        startDate,
        endDate,
        currentDeal,
      }),
    );
  },
  onFetchAndSetScaleRate: ({ member, payAtScale }) => {
    dispatch(actions.fetchAndSetPayAtScale({ member, payAtScale }));
  },
  onSetAutoCoding: (autoCodingStatus, dayCount) => {
    dispatch(
      changeAction(WTC_FORM_NAME, 'defaultAccountOverride', !autoCodingStatus),
    );
    for (let i = 0; i < dayCount; i++) {
      dispatch(
        changeAction(
          WTC_FORM_NAME,
          `details[${i}].defaultAccountOverride`,
          !autoCodingStatus,
        ),
      );
    }
    dispatch(actions.setAutoCoding({ autoCodingStatus, dayCount }));
    dispatch(actions.saveTimecard());
  },
  onFetchCountries: () => dispatch(fetchCountriesV1()),
  onFetchStates: args => dispatch(fetchStatesV1(args)),
  onFetchCities: args => dispatch(fetchCitiesV1(args)),
  onFetchCounties: args => dispatch(fetchCountiesV1(args)),
  onFetchSubdivisions: args => dispatch(fetchSubdivisionsV1(args)),
  onResetLoc: variant => {
    switch (variant) {
      case 'workState':
        dispatch(storeStatesV1({ states: [] }));
        break;
      case 'workCity':
        dispatch(storeCitiesV1({ cities: [] }));
        break;
      case 'workCounty':
        dispatch(storeCountiesV1({ counties: [] }));
        break;

      default:
        break;
    }
  },
  onShowAlert: params => dispatch(showAlert(params)),
  onLoadSingleBatch: (timecard, reloadTimecard = false) => {
    const projectId = match.params.projectId;
    const reviewType = match.params.reviewType;
    const batchWorksightId = timecard?.batch.id;
    dispatch(actions.setIsMultiBatch({ isMultiBatch: false }));
    dispatch(actions.setWTCTimecardHeaderIds({ wtcTimecardHeaderIds: [] }));
    let url = `/projects/${projectId}/review/${reviewType}/${batchWorksightId}/wtc`;
    if (reloadTimecard) {
      url += `?timecardHeaderId=${timecard.timecardEntryHeaderId}`;
    }
    dispatch(push(url));
    dispatch(actions.drawerInit());
  },
});

class WTC extends Component {
  componentDidMount() {
    const { onFetchData } = this.props;
    onFetchData();
    window.addEventListener('keydown', this.enterKeyHandler);
  }

  enterKeyHandler = e => {
    if (e.target.tagName === 'INPUT' && e.which === 13) {
      const days = this.props.details.length || 0;
      const mstRow = document.querySelector('.masterRow');
      const tcTable = document.querySelector('.tcTable');
      const splitRow = document.querySelector('.splitRow');
      const allowancesCount = this.props.allowances.length || 0;
      if (checkParent(splitRow, e.target)) {
        return;
      }
      if (checkParent(tcTable, e.target)) {
        downHandler(e, days, 'details', mstRow);
      } else {
        downHandler(e, allowancesCount, 'allowances', null);
      }
    }
  };
  componentWillUnmount() {
    const { onUnmountWTC } = this.props;
    onUnmountWTC();
    window.removeEventListener('keydown', this.enterKeyHandler);
  }

  componentDidUpdate() {
    if (this.props.canRenderTimecard) {
      this.updateIsForceResubmitActive();
      this.updateHasWorkTimeChange();
    }
  }

  updateIsForceResubmitActive() {
    const {
      hasWorkTimeChanges,
      workflowAction,
      timecard,
      showForceResubmitModal,
      change,
    } = this.props;
    const isEmergency = !!timecard?.emergencyType;
    const isDraft = timecard?.hPlusStatus === 'draft';
    const isResubmitToEmployee = workflowAction === 'resubmitToEmployee';

    if (
      !isEmergency &&
      !isDraft &&
      !isResubmitToEmployee &&
      hasWorkTimeChanges
    ) {
      change('workflowAction', 'resubmitToEmployee');
    } else if (
      isResubmitToEmployee &&
      showForceResubmitModal &&
      hasWorkTimeChanges === false
    ) {
      //handle case where they make a change to a non-ResubmitToEmployee timecard then change it back to the original value
      change('workflowAction', null);
    } else if (isResubmitToEmployee && (isEmergency || isDraft)) {
      let tcType;
      if (isEmergency) tcType = 'Emergency';
      if (isDraft) tcType = 'Draft';
      console.warn(
        `workflowAction cannot be 'resubmitToEmployee' on ${tcType} Timecard.`,
        `Setting to null`,
      );
      change('workflowAction', null);
    }
  }

  updateHasWorkTimeChange() {
    const { hasWorkTimeChanges, hasWorkTimeChangesForm, change } = this.props;
    if (hasWorkTimeChanges !== hasWorkTimeChangesForm) {
      change('hasWorkTimeChanges', hasWorkTimeChanges);
    }
  }

  _createNotifications(timecardErrors, validationErrors, validationWarnings) {
    let notificationErrors = [];

    if (timecardErrors) {
      notificationErrors = [...timecardErrors];
    }

    if (_.isEmpty(validationWarnings) === false) {
      for (const field in validationWarnings) {
        if (Object.hasOwnProperty.call(validationWarnings, field)) {
          const text = validationWarnings[field];
          notificationErrors.push({
            completed: false,
            created: Date.now(),
            field: field,
            level: 'Warning',
            text,
            user: undefined,
          });
        }
      }
    }

    if (
      validationErrors &&
      validationErrors.timecard &&
      validationErrors.timecard.length
    ) {
      validationErrors.timecard.forEach(x => {
        for (const p in x) {
          notificationErrors.push({
            completed: false,
            created: Date.now(),
            field: p,
            level: 'Error',
            text: x[p],
            user: undefined,
          });
        }
      });
    }
    if (
      validationErrors &&
      validationErrors.details &&
      validationErrors.details.length
    ) {
      validationErrors.details.forEach(x => {
        for (const p in x) {
          notificationErrors.push({
            completed: false,
            created: Date.now(),
            field: p,
            level: 'Error',
            text: x[p],
            user: undefined,
          });
        }
      });
    }

    if (
      validationErrors &&
      validationErrors.allowances &&
      validationErrors.allowances.length
    ) {
      validationErrors.allowances.forEach(x => {
        for (const p in x) {
          notificationErrors.push({
            completed: false,
            created: Date.now(),
            field: p,
            level: 'Error',
            text: x[p],
            user: undefined,
          });
        }
      });
    }

    return notificationErrors;
  }

  render() {
    // data
    const {
      classes,
      handleSubmit,
      workSchedules,
      occupationCodes,
      employeeId,
      workLocations,
      dealMemos,
      episodes,
      dayTypes,
      masterRowData,
      timecardDealMemo,
      breakdown,
      timecard,
      paidHours,
      allowancesOrder,
      tableFields,
      comments,
      details,
      allowances,
      dealMemoId,
      workingWorkState,
      splitHourTypes,
      payCodes,
      formSyncErrors,
      formSyncWarnings,
      dateOptions,
      showFringeInfo,
      hPlusStatus,
      project,
      settings,
      currentTimecardHeaderId,
      isUPM,
      isPA,
      pristine,
      hasScaleRateError,
      autoCoding,
      //
      countries,
      stateOptions,
      countyOptions,
      cityOptions,
      subdivisions,
      isMultiBatch,
      isBulkApprovalProcessing,
    } = this.props;
    // dispatch
    const {
      setProspectiveTimecard,
      onSetFieldVisibility,
      loadWorkSchedules,
      loadOccupationCodes,
      loadWorkLocations,
      loadDayTypes,
      loadEpisodes,
      onTimecardLoad,
      onApprovalWithoutChange,
      onSaveTimecard,

      onForceCommentAction,
      onAddComment,
      change,
      blur,

      onShowDirtyAlert,
      onBackToListAction,
      onProducerWWChange,
      onEmployeeWWChange,
      onShowMemoModal,
      onFetchAndSetScaleRate,
      onSetAutoCoding,
      onFetchCountries,
      onFetchStates,
      onFetchCities,
      onFetchCounties,
      onFetchSubdivisions,
      onResetLoc,
      onShowAlert,
      onLoadSingleBatch,
    } = this.props;

    const locationFuncs = {
      onFetchCountries,
      onFetchStates,
      onFetchCities,
      onFetchCounties,
      onFetchSubdivisions,
      onResetLoc,
    };

    // loading
    const {
      loadings,
      processing,
      wtcUpdating,
      canRenderTimecard,
      loadingTimecard,
      wtcDisabled,
      upmEnabled,
      allowanceDisabled,
      loadingWtcHistory,
      scaleLoading,
    } = this.props;

    const accountCodeMask = validateAccountMask(project && project.accountMask);
    const maskingFunc = makeMaskFunc(accountCodeMask);

    const loadTimecard = (timecardEntryHeaderId, employeeId) => {
      const { pristine } = this.props;
      if (!pristine && !hideDirtyAlertAfterMove) {
        setProspectiveTimecard({
          timecardEntryHeaderId,
          employeeId,
          actionType: 'loadTimecard',
        });
        onShowDirtyAlert();
      } else {
        hideDirtyAlertAfterMove = false;
        onTimecardLoad(timecardEntryHeaderId);
      }
    };

    const onTimecardLeave = () => {
      const { pristine } = this.props;

      if (!pristine && !hideDirtyAlertAfterMove) {
        setProspectiveTimecard({
          timecardEntryHeaderId: null,
          employeeId: null,
          actionType: 'onTimecardLeave',
        });

        onShowDirtyAlert();
      } else {
        hideDirtyAlertAfterMove = false;
        onBackToListAction(currentTimecardHeaderId);
      }
    };

    const loadSingleBatch = () => {
      if (pristine) {
        onLoadSingleBatch(timecard);
      } else {
        setProspectiveTimecard({
          actionType: 'loadSingleBatch',
          actionOnLeave: () => onLoadSingleBatch(timecard, true),
        });
        onShowDirtyAlert();
      }
    };

    const notificationErrors = this._createNotifications(
      timecard.errors,
      formSyncErrors,
      formSyncWarnings,
    );

    return (
      <div className={classes.WTC}>
        <Drawer
          pristine={pristine}
          wtcUpdating={wtcUpdating}
          isPA={isPA}
          isUPM={isUPM}
          loadTimecard={loadTimecard}
          isMultiBatch={isMultiBatch}
        />

        <div id="wtcTimecard" className={classes.tc}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <StickyHeader
              //Sticky Header Only
              processing={processing}
              wtcUpdating={wtcUpdating}
              //shared header + sticky header props
              timecard={timecard}
              canRenderTimecard={canRenderTimecard}
              onForceCommentAction={onForceCommentAction}
              onApprovalWithoutChange={onApprovalWithoutChange}
              onSaveTimecard={onSaveTimecard}
              onSetAutoCoding={onSetAutoCoding}
              isMultiBatch={isMultiBatch}
              loadSingleBatch={loadSingleBatch}
            />
            <Header
              //Main Header only Props
              onBackToList={onTimecardLeave}
              onProducerWWChange={onProducerWWChange}
              onEmployeeWWChange={onEmployeeWWChange}
              onShowMemoModal={onShowMemoModal}
              dealMemoId={dealMemoId}
              wtcDisabled={wtcDisabled}
              isBulkApprovalProcessing={isBulkApprovalProcessing}
              //shared header + sticky header props
              timecard={timecard}
              canRenderTimecard={canRenderTimecard}
              onForceCommentAction={onForceCommentAction}
              onApprovalWithoutChange={onApprovalWithoutChange}
              onSaveTimecard={onSaveTimecard}
              onSetAutoCoding={onSetAutoCoding}
              isMultiBatch={isMultiBatch}
              loadSingleBatch={loadSingleBatch}
            />
            {canRenderTimecard ? ( //renderingTimecard === true when timecard ready to be shown
              <div>
                {(processing || wtcUpdating) && <LinearProgress />}
                <TimecardDays
                  project={project}
                  timecardDealMemo={timecardDealMemo}
                  paidHours={paidHours}
                  employeeId={employeeId}
                  workSchedules={workSchedules}
                  loadWorkSchedules={loadWorkSchedules}
                  occupationCodes={occupationCodes}
                  loadOccupationCodes={loadOccupationCodes}
                  workLocations={workLocations}
                  loadWorkLocations={loadWorkLocations}
                  dealMemos={dealMemos}
                  loadDayTypes={loadDayTypes}
                  episodes={episodes}
                  loadEpisodes={loadEpisodes}
                  dayTypes={dayTypes}
                  change={change}
                  blur={blur}
                  tableFields={tableFields}
                  details={details}
                  splitHourTypes={splitHourTypes}
                  comments={comments}
                  allowancesCount={allowances ? allowances.length : 0}
                  error={formSyncErrors}
                  wtcDisabled={wtcDisabled}
                  onShowMemoModal={onShowMemoModal}
                  dealMemoId={dealMemoId}
                  upmEnabled={upmEnabled}
                  settings={settings}
                  maskingFunc={maskingFunc}
                  accountCodeMask={accountCodeMask}
                  onFetchAndSetScaleRate={onFetchAndSetScaleRate}
                  scaleLoading={scaleLoading}
                  //
                  workingWorkState={workingWorkState}
                  masterRowData={masterRowData}
                  countries={countries}
                  stateOptions={stateOptions}
                  countyOptions={countyOptions}
                  cityOptions={cityOptions}
                  subdivisions={subdivisions}
                  locationFuncs={locationFuncs}
                />
                <div className={classes.bottomFlex}>
                  <div className={classes.altTables}>
                    <div>
                      <Comments
                        hPlusStatus={hPlusStatus}
                        comments={comments}
                        onAddComment={onAddComment}
                        project={project}
                      />
                    </div>
                    <div>
                      <Allowances
                        project={project}
                        onShowAlert={onShowAlert}
                        allowances={allowances}
                        allowancesOrder={allowancesOrder}
                        masterRowData={masterRowData}
                        payCodes={payCodes}
                        workLocations={workLocations}
                        occupationCodes={occupationCodes}
                        loadOccupationCodes={loadOccupationCodes}
                        dealMemos={dealMemos}
                        dealMemoId={dealMemoId}
                        episodes={episodes}
                        loadEpisodes={loadEpisodes}
                        change={change}
                        blur={blur}
                        error={formSyncErrors}
                        allowanceDisabled={allowanceDisabled}
                        upmEnabled={upmEnabled}
                        isUPM={isUPM}
                        maskingFunc={maskingFunc}
                        accountCodeMask={accountCodeMask}
                        dateOptions={dateOptions}
                        countries={countries}
                        stateOptions={stateOptions}
                        countyOptions={countyOptions}
                        cityOptions={cityOptions}
                        subdivisions={subdivisions}
                        locationFuncs={locationFuncs}
                        details={details}
                      />
                    </div>
                    <div>
                      {!_.isEmpty(breakdown) ? (
                        <Breakdown
                          breakdownData={breakdown}
                          bulkCalcPending={timecard.isPendingBulkCalculation}
                          showFringeInfo={showFringeInfo}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <WtcHistory
                        timecardId={timecard.timecardId}
                        projectSettings={settings}
                        loadingWtcHistory={loadingWtcHistory}
                      />
                    </div>
                  </div>
                  <div className={classes.notifications}>
                    <Notifications
                      notifications={notificationErrors}
                      onSetFieldVisibility={onSetFieldVisibility}
                      hasScaleRateError={hasScaleRateError}
                      tableFields={tableFields}
                    />
                  </div>
                </div>
              </div>
            ) : (
              //When not rendering timecard
              <div>
                {loadings || loadingTimecard ? (
                  <>
                    <LinearProgress />
                    <h3>Loading...</h3>
                  </>
                ) : (
                  <h3>Select a timecard.</h3>
                )}
              </div>
            )}
          </form>
          <ForceCommentWTC
            details={details}
            onApprovalWithoutChange={onApprovalWithoutChange}
            onSaveTimecard={onSaveTimecard}
            isUPM={isUPM}
            wtcUpdating={wtcUpdating}
            onSetAutoCoding={onSetAutoCoding}
            autoCoding={autoCoding}
            timecardId={timecard.timecardId}
          />
          <AddCommentModal />
          <DeleteTimecardWTC timecard={timecard} />
          <WTCDealMemoModal
            allowances={allowances}
            timecard={timecard}
            details={details}
            change={change}
            showDragBar={true}
            readOnly={wtcDisabled}
            timecardDealMemo={timecardDealMemo}
            onSetFieldVisibility={onSetFieldVisibility}
          />
          <ConfirmTakeMeBack hasSubmitAction />
          <ProducerWWAlert onApprovalWithoutChange={onApprovalWithoutChange} />
          <EmployeeWWAlert onApprovalWithoutChange={onApprovalWithoutChange} />
        </div>
        <div id="drawerFilterAnchor"></div>
      </div>
    );
  }
}

export default compose(
  connect(mapState, mapDispatch),
  withStyles(style),

  reduxForm({
    form: WTC_FORM_NAME,
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: false,
    validate,
    warn: wtcTimecardWarn,
    shouldError: () => true,
  }),
)(WTC);
