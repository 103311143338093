import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography as Text } from '@mui/material';
import { withStyleSheet } from 'shared/theme';

export const style = ({ breakpoints }) => ({
  root: {
    verticalAlign: 'middle',
    display: 'flex',
  },

  title: {
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: '1.1em',
  },

  [breakpoints.down('md')]: {
    root: {
      padding: '0px 24px',
      textAlign: 'center',
    },
  },

  [breakpoints.up('md')]: {
    root: {
      paddingLeft: 12,
      textAlign: 'left',
    },
  },
  [breakpoints.down('sm')]: {
    root: {
      padding: '0px',
      textAlign: 'center',
    },
  },
});

const Title = ({ classes, title, className, textClassName }) => (
  <div className={clsx(classes.root, className)}>
    <Text variant="h6" className={clsx(classes.title, textClassName)}>
      {title}
    </Text>
  </div>
);

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default withStyleSheet('BrandTitle', style)(Title);
