import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Paper, LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { eventsProps, ACTION_LABELS } from 'components/props/events';
import { formatDate } from 'utils';

import { TableList } from 'components/Shared/TableList';

export const style = () => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'auto',
  },
  empty: {
    textAlign: 'center',
  },
  row: {
    height: '64px',
  },
});

class Events extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    sortParams: PropTypes.object.isRequired,
    onApplySorting: PropTypes.func.isRequired,
    events: eventsProps.isRequired,
    loading: PropTypes.bool,
  };

  static formatEvent(event) {
    const label = ACTION_LABELS[event.action] || event.action;
    const { subjectType, message } = event;
    return `${label} ${subjectType} "${message}"`;
  }

  render() {
    const { classes, events, loading, ...others } = this.props;
    let contentRows = null;

    if (events && events.length <= 0) {
      contentRows = (
        <TableRow>
          <TableCell colSpan={3} className={classes.empty}>
            {loading ? 'Loading Events...' : 'No Events Found'}
          </TableCell>
        </TableRow>
      );
    } else {
      contentRows = events.map(event => (
        <TableRow hover key={event.id} className={classes.row}>
          <TableCell>
            {formatDate(event.createdAt, 'MM-DD-YYYY - HH:mm UTC')}
          </TableCell>
          <TableCell>{event.profile && event.profile.fullName}</TableCell>
          <TableCell>{Events.formatEvent(event)}</TableCell>
        </TableRow>
      ));
    }

    const headers = [
      { columnId: 'createdAd', label: 'Time', compact: true },
      { columnId: 'profile.fullName', label: 'User', compact: true },
      { columnId: 'action', label: 'Event', compact: true },
    ];

    return (
      <Paper className={classes.root}>
        {loading && events.length <= 0 && <LinearProgress />}
        <TableList headers={headers} {...others}>
          {contentRows}
        </TableList>
      </Paper>
    );
  }
}

export default withStyleSheet('EventsList', style)(Events);
