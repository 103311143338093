import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { FieldArray } from 'redux-form';
import SplitRow from './SplitRow';

const useStyles = makeStyles(({ palette }) => ({
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 16,
  },
  button: {
    width: 300,
    marginLeft: '27vw',
  },
  buttonLabel: {
    marginLeft: 10,
  },
  firsfix: {
    zIndex: 1,
    position: 'sticky',
    left: 0,
    backgroundColor: palette.background.paper,
    borderBottom: palette.gray['+8'],
  },
  fontSize: {
    fontSize: 15,
    marginBottom: 2,
  },
}));

const RenderBody = ({ fields, props }) => {
  const {
    // classes,
    allowancesOrder,
    occupationCodes,
    loadOccupationCodes,
    workLocations,
    member,
    change,
    paidHours,
    headerArray,
    splitHourTypes,
    detail,
    tcDates,
    dayError,
    episodes,
    loadEpisodes,
    masterRowData,
    wtcDisabled,
    maskingFunc,
    accountCodeMask,
    canEditWorkTimes,
    //
    countries,
    stateOptions,
    countyOptions,
    cityOptions,
    locationFuncs,
    project,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    //add initial split row if there is no split present
    if (!(masterRowData.status === 'Approved') && !wtcDisabled) {
      if (!('percentBreakdown' in detail)) {
        fields.push({ splitDate: detail.effectiveDate });
      }
      if (detail.percentBreakdown && detail.percentBreakdown.length === 0) {
        if (fields.length === detail.percentBreakdown.length) {
          fields.push({ splitDate: detail.effectiveDate });
        }
      }
    }
    // leaving this one for the time being
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Split fields aren't getting errors properly without this.  Maybe the nesting is too deep?
  const getErrorText = useCallback(
    field => {
      return dayError && dayError[field] ? _.startCase(dayError[field]) : null;
    },
    [dayError],
  );
  return (
    <React.Fragment>
      {fields.map((percentBreakdown, index) => {
        return (
          <SplitRow
            removeRow={() => fields.remove(index)}
            // eslint-disable-next-line react/no-array-index-key
            key={`split_${index}`} //HOUR-8905
            ProperRenderOrder={allowancesOrder}
            percentBreakdown={percentBreakdown}
            member={member}
            workLocations={workLocations}
            day={detail}
            // currentState={currentState}
            index={index}
            occupationCodes={occupationCodes}
            loadOccupationCodes={loadOccupationCodes}
            change={change}
            paidHours={paidHours}
            headerArray={headerArray}
            splitHourTypes={splitHourTypes}
            detail={detail}
            tcDates={tcDates}
            getErrorText={getErrorText}
            episodes={episodes}
            loadEpisodes={loadEpisodes}
            wtcDisabled={wtcDisabled}
            maskingFunc={maskingFunc}
            accountCodeMask={accountCodeMask}
            canEditWorkTimes={canEditWorkTimes}
            //
            countries={countries}
            stateOptions={stateOptions}
            countyOptions={countyOptions}
            cityOptions={cityOptions}
            locationFuncs={locationFuncs}
            project={project}
          />
        );
      })}
      <tr>
        <td colSpan="9" className={classes.firsfix}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={e => {
              e.preventDefault();
              fields.push({ splitDate: detail.effectiveDate });
            }}
            disabled={wtcDisabled}
          >
            <AddCircleOutlineIcon className={classes.fontSize} />
            <span className={classes.buttonLabel}>Add Row</span>
          </Button>
        </td>
      </tr>
    </React.Fragment>
  );
};

const Splits = props => {
  return (
    <React.Fragment>
      <FieldArray
        name={`${props.member}.percentBreakdown`}
        component={RenderBody}
        props={{
          props,
        }}
      />
    </React.Fragment>
  );
};
export default Splits;
