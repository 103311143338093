import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { push } from 'connected-react-router';

import { ENTRY_HEADER_REGEX } from 'components/Shared/constants';

import { navTo } from 'actions/timecards';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
}));

const mapDispatch = (dispatch, { match }) => ({
  onNavTo: navParams => dispatch(navTo(navParams)),
  onRedirect: () => dispatch(push('/')),
});

const getParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

const NavTo = props => {
  const { onNavTo, onRedirect } = props;
  const classes = useStyles();

  useEffect(() => {
    const params = getParams();

    const { timecardEntryHeaderId } = params;

    const headerOk = ENTRY_HEADER_REGEX.test(timecardEntryHeaderId);

    if (timecardEntryHeaderId && headerOk) {
      onNavTo({ timecardEntryHeaderId });
    } else {
      onRedirect();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={classes.root}>
      <CircularProgress />
    </main>
  );
};

NavTo.propTypes = {
  onNavTo: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatch)(NavTo));
