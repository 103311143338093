import React, { useEffect, useMemo } from 'react';
import { reduxForm, Field } from 'redux-form';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography, LinearProgress, Box } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import TextField from 'components/Shared/redux/TextField';
// actions
import {
  addComment,
  reject,
  resubmit,
  recall,
  calcResubmitComment,
  storeResubmitComment,
} from 'actions/wtc';

import AutoComplete from 'components/Shared/AutoComplete/AutoComplete';
import { formatDate } from 'utils';
import { formatMessages } from 'utils/messages';

//selectors
import { getModalParams } from 'selectors/modalDialog';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
import { getResubmitComment, getPrevChangeComments } from 'selectors/wtc';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

export const style = ({ palette }) => ({
  rejectIntro: {
    marginBottom: 32,
  },
  selectionField: {
    width: 184,
    height: 40,
    marginBottom: 32,
  },
  textField: {
    width: '100%',
    marginBottom: 32,
  },
  changeField: {
    backgroundColor: palette.gray['+8'],
  },
  errorField: {
    extend: 'field',
    '& > label': {
      color: palette.error.main,
    },
    '& > p': {
      color: palette.error.main,
    },
  },
});

const modalDialog = 'ForceCommentWTC';
let _submitAction;

const mapStateToProps = state => ({
  modalParams: getModalParams(state, modalDialog),
  resubmitComment: getResubmitComment(state),
  prevChangeComments: getPrevChangeComments(state),
});

const mapDispatchToProps = dispatch => ({
  onCalcResubmitComment: action => {
    dispatch(calcResubmitComment({ action }));
  },
  onResetResubmitComment: () => {
    storeResubmitComment({ comment: '' });
  },
});

const onSubmit = (values, dispatch, ownProps) => {
  const timecardId = ownProps.timecardId;
  const autoCoding = ownProps.autoCoding;
  const dayCount = ownProps.details.length;

  switch (_submitAction) {
    case 'reject':
      dispatch(reject({ ...values, timecardId }));
      break;
    case 'resubmit':
      dispatch(resubmit({ ...values }));
      break;
    case 'recall':
      dispatch(recall({ ...values }));
      break;
    case 'save':
      dispatch(addComment({ ...values }));
      ownProps.onSaveTimecard();
      break;
    case 'submit':
      dispatch(addComment({ ...values }));
      ownProps.onApprovalWithoutChange();
      break;
    case 'autoCode':
      dispatch(addComment({ ...values }));
      ownProps.onSetAutoCoding(autoCoding, dayCount);
      break;
    case 'resubmitSave':
      //comment is added after save in case of error during save
      ownProps.onSaveTimecard();
      break;
    default:
      break;
  }
};

const ForceCommentWTC = props => {
  const {
    classes,
    error,
    handleSubmit,
    details = [],
    isUPM,
    wtcUpdating,
    //modal params
    onModalHide,
    submitting,
    //connected props
    modalParams,
    onCalcResubmitComment,
    resubmitComment,
    prevChangeComments,
    //reduxform
    change,
  } = props;
  _submitAction = modalParams.action;

  useEffect(() => {
    if (_submitAction === 'resubmitSave' || _submitAction === 'resubmit') {
      onCalcResubmitComment(_submitAction);
    }
  }, [onCalcResubmitComment]);

  useEffect(() => {
    if (resubmitComment && _submitAction === 'resubmitSave') {
      change('resubmitComment', resubmitComment);
    }
    if (prevChangeComments && _submitAction === 'resubmit') {
      change('resubmitComment', prevChangeComments);
    }
  }, [change, prevChangeComments, resubmitComment]);

  //Re-used Components
  const DayPicker = () => {
    let list =
      !isUPM &&
      details?.map(day => {
        const effectiveDt = formatDate(day.effectiveDate, 'MM-DD-YYYY');
        return {
          value: effectiveDt,
          label: effectiveDt,
        };
      });

    return (
      <div className={classes.selectionField}>
        <AutoComplete
          list={list || []}
          placeholder="Select day of the week"
          name="referenceDate"
        />
      </div>
    );
  };

  const commentField = useMemo(
    () => (
      <Field
        className={
          error && error.email ? classes.errorField : classes.textField
        }
        hinttext={error && error.comment ? formatMessages(error.comment) : null}
        component={TextField}
        name="comment"
        variant="outlined"
        multiline
        rows="8"
        inputProps={{ maxLength: commentsLimit }}
        required
      />
    ),
    [error, classes],
  );

  const ChangeTimesCommentField = () => (
    <Field
      className={clsx(classes.textField, classes.changeField)}
      component={TextField}
      disabled={true}
      name="resubmitComment"
      variant="outlined"
      multiline
      rows="8"
    />
  );

  const modalText = {
    paReject: {
      title: 'Reject Timecard',
      content:
        "You're rejecting a timecard. Please select a date then add a comment. An Email will be sent to the crew member.",
      submitBtn: 'Add Comment and Reject',
    },
    save: {
      title: 'Save Timecard',
      content: 'Please leave a comment to explain the changes you’ve made.',
      submitBtn: 'Add Comment and Save',
    },
    submit: {
      title: 'Submit for Approval',
      content: 'Please leave a comment to explain the changes you’ve made.',
      submitBtn: 'Add Comment and Submit',
    },
    upmReject: {
      title: 'Reject Timecard',
      content: "You're rejecting a timecard. Please add a comment.",
      submitBtn: 'Add Comment and Reject',
    },
    autoCode: {
      title: 'Save Timecard',
      content: 'Please leave a comment to explain the changes you’ve made.',
      submitBtn: 'Add Comment and Save',
    },
    resubmitSave: {
      title: 'Days/Times Changed',
      submitBtn: 'Save / Calculate',
    },
    resubmit: {
      title: 'Resubmit to Employee',
      submitBtn: 'Resubmit to Employee',
    },
    recall: {
      title: 'Recall Timecard',
      submitBtn: 'Recall Timecard',
    },
    default: {
      //This should never be used,
      title: 'Comment Timecard',
      content: 'Please add a comment.',
    },
  };

  let textChoice;
  switch (_submitAction) {
    case 'reject':
      textChoice = isUPM ? 'upmReject' : 'paReject';
      break;
    case 'autoCode':
      textChoice = 'save';
      break;
    case 'save':
    case 'resubmitSave':
    case 'resubmit':
    case 'recall':
    case 'submit':
      textChoice = _submitAction;
      break;

    default:
      textChoice = 'default';
      break;
  }

  // const textChoice = modalText[_submitAction] || _submitAction;
  const text = modalText[textChoice];

  const title = (
    <Typography variant="h6" color="inherit">
      {text.title}
    </Typography>
  );

  let content = null;
  if (_submitAction === 'resubmitSave') {
    content = (
      <Box>
        <ChangeTimesCommentField />
        <Typography>
          The above record will be permanently added to the timecard.{' '}
        </Typography>
        <br />
        <Typography>
          The timecard <strong>must be resubmitted to the employee</strong>{' '}
          after you're finished making changes{' '}
        </Typography>
      </Box>
    );
  } else if (_submitAction === 'resubmit') {
    content = (
      <Box>
        <Typography className={classes.rejectIntro}>
          An email with the below comments will be sent to the employee
        </Typography>
        <DayPicker />
        <ChangeTimesCommentField />
        {commentField}
      </Box>
    );
  } else if (_submitAction === 'recall') {
    content = (
      <Box>
        <Typography className={classes.rejectIntro}>
          The timecard will be returned to the status "Ready for Payroll
          Accountant"
        </Typography>
        <DayPicker />
        {commentField}
      </Box>
    );
  } else {
    content = (
      <div>
        <Typography className={classes.rejectIntro}>{text.content}</Typography>
        {!isUPM && _submitAction === 'reject' && <DayPicker />}
        {commentField}
        {wtcUpdating && <LinearProgress />}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <ConfirmModal
        content={content}
        title={title}
        buttonText={text.submitBtn}
        buttonType="secondary"
        raisedButton
        submitDisabled={wtcUpdating}
        onModalHide={onModalHide}
        submitting={submitting}
      />
    </form>
  );
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: modalDialog, maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: modalDialog,
    onSubmit,
  }),
)(ForceCommentWTC);
