import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
//actions
import {
  fetchReviewBatches,
  setReviewFromURL,
  setBatchLockStatus,
  toggleShowPaid,
  fetchTimecardCount,
  fetchTimecardsByBatchId,
  reviewDownloadTimecardsReport,
} from 'actions/reviews';
import { setFromURL } from 'actions/timecards';
import { fetchDetails } from 'actions/project';

//components
import { Listing } from 'components/Employees/Reviews/PayrollAccountant';
//selector
import {
  reviewBatchesByReviewType,
  getTimecardLoadingByBatchID,
  getBatchesLoading,
  isShowingPaid,
} from 'selectors/reviews';
import { getSettings, getSettingsList } from 'selectors/settings';
import LinearProgress from '@mui/material/LinearProgress';
//util
import { getProjectFromURL } from 'utils/helperFunctions';
import { getProjectDetails } from 'selectors/project';

import { BATCH_REVIEW_DEFAULT_PAGE_SIZE } from 'components/Shared/constants';

const pageSize = BATCH_REVIEW_DEFAULT_PAGE_SIZE;
const reviewType = 'complete';

const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  projectDetails: getProjectDetails(state),
  batches: reviewBatchesByReviewType(reviewType)(state),
  settings: getSettings(state),
  list: getSettingsList(state),
  reviewType: reviewType,
  pageSize: pageSize,
  timecardLoadingByBatch: getTimecardLoadingByBatchID(state),
  loading: getBatchesLoading(state),
  showPaid: isShowingPaid(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onFetchData: () => {
    dispatch(
      fetchReviewBatches({
        reviewType: reviewType,
        page: 1,
        size: pageSize,
      }),
    );
  },
  onApproveBatch: (projectId, id) => {
    dispatch(
      setReviewFromURL({
        reviewType,
        reviewBatchId: id,
        fromURI: location.pathname,
      }),
    );
    const toURL = `/projects/${projectId}/review/${reviewType}/${id}/htg`;
    dispatch(push(toURL));
  },
  onWTCApproveBatch: (projectId, id, reviewBatchId, htgId) => {
    dispatch(setFromURL({ fromURI: location.pathname }));

    dispatch(
      setReviewFromURL({
        reviewType,
        reviewBatchId: reviewBatchId,
        fromURI: location.pathname,
        worksightId: id,
      }),
    );
    const toURL = `/projects/${projectId}/review/${reviewType}/${id}/wtc`;
    dispatch(push(toURL));
  },
  onDownloadTimecardsReport: ({ timecards, batchId, htgId, fetchBatch }) => {
    dispatch(
      reviewDownloadTimecardsReport({
        timecards,
        batchId,
        htgId,
        fetchBatch,
        reviewType,
      }),
    );
  },
  refreshData: (page, size) => {
    dispatch(fetchReviewBatches({ reviewType, page, size }));
  },
  onSetBatchLockStatus: (hoursBatchId, reviewBatchLockStatus) => {
    dispatch(setBatchLockStatus({ hoursBatchId, reviewBatchLockStatus }));
  },
  onNavToWTC: (timecard, batchWorksightId, batchHtgId) => {
    const projectId = getProjectFromURL(location.pathname);
    dispatch(setFromURL({ fromURI: location.pathname }));
    const toURL = `/projects/${projectId}/review/ready/${batchWorksightId}/wtc?timecardHeaderId=${timecard.entryHeaderId}`;
    dispatch(push(toURL));
  },
  getProjectData: () => {
    dispatch(fetchDetails());
  },
  onTogglePaid: () => {
    dispatch(toggleShowPaid());
    dispatch(fetchReviewBatches({ reviewType, page: 1, size: pageSize }));
    dispatch(fetchTimecardCount());
  },
  onFetchBatchTimecards: batchId => {
    const reviewBatches = [{ id: batchId }];
    dispatch(fetchTimecardsByBatchId({ reviewType, reviewBatches }));
  },
});

class Complete extends Component {
  state = {
    page: 0,
    pageSize: pageSize,
  };

  onRefreshPage(page, pageSize) {
    this.setState({
      page,
      pageSize,
    });

    this.props.refreshData(page, pageSize);
  }

  componentDidMount() {
    this.props.onFetchData();
    const projectDetails = this.props.projectDetails;
    if (
      !projectDetails ||
      !projectDetails.hasOwnProperty('nextYearProjectId') ||
      !projectDetails.hasOwnProperty('previousYearProjectId')
    ) {
      // Not sure exactly what this was solving, but nextYearProjectId doesn't work like it did pre-YEP21/22
      // leaving this in, but with this debug log.  Reviews page hopefully wont be around much longer.
      console.debug('refetching project data');
      this.props.getProjectData();
    }
  }

  render() {
    if (this.props.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Listing
        {...this.props}
        page={this.state.page}
        pageSize={this.state.pageSize}
        onRefreshPage={this.onRefreshPage.bind(this)}
      />
    );
  }
}
export default withRouter(connect(mapState, mapDispatch)(Complete));
