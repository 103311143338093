import produce from 'immer';
import _ from 'lodash';
import * as actions from 'actions/moveTimecards';

const initialState = {
  batchInfo: null,
  moveJobInfo: {},
  sourceBatch: {},
  batchMoveTcs: [],
  destinationInvoices: [],
  destinationBatches: [],
  moveLoading: {
    timecards: true,
    invoices: true,
    batches: true,
    submitting: false,
  },
  moveDone: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      //Move reducer actions
      case `${actions.storeDestinationBatches}`:
        draft.destinationBatches = action.destinationBatches;
        break;
      case `${actions.storeDestinationInvoices}`:
        draft.destinationInvoices = action.destinationInvoices;
        break;
      case `${actions.storeSourceBatch}`:
        draft.sourceBatch = action.sourceBatch;
        break;
      case `${actions.setMoveJobInfo}`:
        draft.moveJobInfo = action.moveJobInfo;
        break;
      case `${actions.setMoveBatchTCs}`:
        draft.batchMoveTcs = action.batchMoveTcs;
        break;
      case `${actions.setMoveLoading}`:
        if (action.loadType) {
          draft.moveLoading[action.loadType] = action.value;
        } else {
          for (const loadType in draft.moveLoading) {
            if (Object.hasOwnProperty.call(draft.moveLoading, loadType)) {
              draft.moveLoading[loadType] = action.value;
            }
          }
        }
        break;
      case `${actions.setMoveDone}`:
        draft.moveDone = true;
        break;
      case `${actions.resetMoveModal}`:
        for (const field in initialState) {
          if (Object.hasOwnProperty.call(initialState, field)) {
            const element = initialState[field];
            draft[field] = _.cloneDeep(element);
          }
        }
        break;
      default:
        break;
    }
  });
