import moment from './moment';
import _ from 'lodash';
import debug from 'debug';
import TimeValidator from 'utils/TimeValidator';
import {
  lowerCaseLastManIn,
  removeNoValueObj,
  isRegionCanada,
} from 'utils/helperFunctions';
import { ACCOUNT_FIELDS_MAP } from 'components/Shared/constants';

const db = debug('wtcUtils');

export const WTC_FORM_NAME = 'weeklyTimecard';

export const WTC_LAYOUT_STANDARED_TEMPLATE = 'H+ WTC Basic Template';

// Returns type double of hours worked in a day minus meals
// similar functionality to calculateDayHours in weekUtils
export const dayHoursCalc = day => {
  if (!day.dayType?.id) return 0.0;
  let hoursWorked;
  if (day.wrapTime === undefined || day.callTime === undefined) {
    hoursWorked = 0.0;
  } else {
    const dayTotal = day.wrapTime - day.callTime;
    const meal1 = day.meal1Out ? day.meal1In - day.meal1Out : 0;
    const meal2 = day.meal2Out ? day.meal2In - day.meal2Out : 0;
    const meal3 = day.meal3Out ? day.meal3In - day.meal3Out : 0;
    hoursWorked = dayTotal - meal1 - meal2 - meal3;
  }
  return Math.max(hoursWorked, 0.0);
};

export const cckList = [
  { value: 'Y', name: 'Y' },
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
];

export const combineCheckOptions = [
  'Y',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const WORK_TIME_FIELDS = [
  'callTime',
  'meal1Out',
  'meal1In',
  'meal2Out',
  'meal2In',
  'meal3Out',
  'meal3In',
  'wrapTime',
];

export const convertPaidHours = (data, dayOfWeekIdxs = []) => {
  let paidHours;

  if (data && data.details && dayOfWeekIdxs.length > 0) {
    const tableData =
      data &&
      data.details &&
      dayOfWeekIdxs.map(date => {
        return data.details[date];
      });

    const existingHeaders = new Set();

    tableData.forEach(day => {
      for (const header in day) {
        if (day.hasOwnProperty(header)) {
          const dayInfo = day[header];
          if (dayInfo) {
            existingHeaders.add(header);
          }
        }
      }
    });

    const filteredHeaders =
      data &&
      data.headers &&
      data.headers.filter(header => existingHeaders.has(header));

    const headerObjArray = filteredHeaders.map(field => {
      return {
        columnId: field,
        label: field,
        group: 'calc',
        type: 'read-only',
        visible: true,
      };
    }, []);
    paidHours = {
      ...data,
      filteredHeaders,
      headerObjArray,
      tableData,
    };
  } else {
    paidHours = {
      details: [{}],
      headers: [],
      filteredHeaders: [],
      headerObjArray: [],
      tableData: [],
    };
  }

  return paidHours;
};

//shared style classes

export const rightShadow = {
  boxShadow: '6px 1px 5px -1px #CCCCCC',
};

const WTC_TIME_FIELDS = [
  ...WORK_TIME_FIELDS,
  'ndmIn',
  'ndmOut',
  'ndbIn',
  'ndbOut',
  'lastMan1In',
];

const WTC_NUM_FIELDS = [...WTC_TIME_FIELDS, 'rate', 'hours'];

const formatNumValues = obj => {
  WTC_NUM_FIELDS.forEach(field => {
    if (obj[field] || obj[field] === '') {
      let numVal = parseFloat(obj[field]);
      if (isNaN(numVal)) numVal = null;
      obj[field] = numVal;
    }
  });
};

export function prepTimecardForSave(saveTimecardData) {
  if (saveTimecardData.details && Array.isArray(saveTimecardData.details)) {
    if (saveTimecardData.canEditWorkTimes) {
      saveTimecardData.details = saveTimecardData.details.filter(
        d => d.unusedDay !== true,
      );
    }
    //remove days without dealMemo
    saveTimecardData.details = saveTimecardData.details.filter(
      d => d.isPartialDealMemo !== true,
    );

    const length = saveTimecardData.details.length;

    for (let i = 0; i < length; i++) {
      //removes breakdown array element from timecard details if its empty
      const day = saveTimecardData.details[i];
      delete day.unusedDay;
      //modify percent breakdown data before sending to backend API
      if (day?.percentBreakdown?.length >= 0) {
        const percentBreakdownLength = day.percentBreakdown.length;
        for (let j = 0; j < percentBreakdownLength; j++) {
          const split = day.percentBreakdown[j];
          const percentBreakdownControlsProperty = 'percentBreakdownControls';
          if (
            !saveTimecardData.hasOwnProperty(percentBreakdownControlsProperty)
          ) {
            saveTimecardData.percentBreakdownControls = {};
          }

          if (split.unitsSplit) {
            split.percentSplit = null;
            split.timeInSplit = null;
            split.timeOutSplit = null;
            saveTimecardData.percentBreakdownControls.unitsSplitCheck = true;
          }
          if (split.timeInSplit) {
            split.timeInSplit = parseFloat(split.timeInSplit);
            split.timeOutSplit = parseFloat(split.timeOutSplit);
            split.percentSplit = null;
            split.unitsSplit = null;
            saveTimecardData.percentBreakdownControls.timeSplitCheck = true;
          }
          if (split.percentSplit) {
            split.unitsSplit = null;
            split.timeInSplit = null;
            split.timeOutSplit = null;
            saveTimecardData.percentBreakdownControls.percentSplitCheck = true;
            saveTimecardData.percentBreakdownControls.roundingPrecision =
              '10ths';
          }
          const onProductionProperty = 'onProduction';
          if (!split.hasOwnProperty(onProductionProperty)) {
            split.onProduction = false;
          }
          split.position = i;
          const newDate = moment(split.splitDate).format('YYYY-MM-DD');
          split.splitDate = newDate;
          delete split.splitType;
          if (split.workState) {
            delete split.workState.cities;
            delete split.workState.counties;
          }
          if (!split.workCounty) delete split.workCounty;
          if (!split.workCity) delete split.workCity;

          removeNoValueObj(split);
        }
      }

      //update num fields for WTC weekdays

      formatNumValues(saveTimecardData.details[i]);

      removeNoValueObj(day);
    }
  }

  if (saveTimecardData.allowances && saveTimecardData.allowances.length > 0) {
    saveTimecardData.allowances.forEach(allowance => {
      removeNoValueObj(allowance);
      formatNumValues(allowance);
    });
  }

  //Whole Timecard actions

  removeNoValueObj(saveTimecardData);

  //update number fields for wtc top level AKA Master Row
  formatNumValues(saveTimecardData);

  //remove FE only flags from timecard
  delete saveTimecardData.canEditWorkTimes;
}

export const PROJECT_REQ_FIELDS = {
  accountCode: { projectLabel: 'accountCodeRequired' },
  dateWorked: { projectLabel: 'dateWorkedRequired' },
  episode: { projectLabel: 'episodeRequired' },
  series: { projectLabel: 'seriesRequired' },
  insurance: { projectLabel: 'insuranceRequired' },
  location: { projectLabel: 'locationRequired' },
  set: { projectLabel: 'setRequired' },
  freeField1: { projectLabel: 'customField1Required' },
  freeField2: { projectLabel: 'customField2Required' },
  freeField3: { projectLabel: 'customField3Required' },
  freeField4: { projectLabel: 'customField4Required' },
};

export function handleChangeDealMemoWTCTimecard(
  newDealId,
  {
    change,
    timecard,
    details,
    dealMemos,
    allowances,
    onFetchAndSetScaleRate,
    onShowRoundingMsg,
    onSetFieldVisibility,
  },
) {
  //Change top level timecard info
  handleChangeDealMemoWTCTimecardTop(newDealId, {
    dealMemos,
    timecard,
    change,
    onSetFieldVisibility,
  });

  //change info for each day
  for (let index = 0; index < details.length; index++) {
    const detail = details[index];
    const member = `details[${index}]`;
    handleChangeDealMemosWTCDay(newDealId, {
      change,
      detail,
      member,
      dealMemos,
      onFetchAndSetScaleRate,
      onShowRoundingMsg,
      onSetFieldVisibility,
    });
  }

  //change each allowance
  for (let index = 0; index < allowances.length; index++) {
    handleChangeDealmemosWTCAllowance(newDealId, {
      dealMemos,
      change,
      allowance: `allowances[${index}]`,
    });
  }
}

function handleChangeDealMemoWTCTimecardTop(
  newDealId,
  { dealMemos, timecard, change, onSetFieldVisibility },
) {
  const dealMemo = dealMemos.find(dealMemo => dealMemo.id === newDealId);

  if (!_.isEmpty(timecard) && !_.isEmpty(dealMemo)) {
    if (dealMemo.occupationCode) {
      const occupationCode = {
        id: dealMemo.occupationCode.id,
        code: dealMemo.occupationCode.code,
        name: dealMemo.occupationCode.name,
      };

      change(`occupationCode`, occupationCode);
    }

    if (dealMemo.workSchedule) {
      const workSchedule = {
        id: dealMemo.workSchedule.id,
        code: dealMemo.workSchedule.code,
        name: dealMemo.workSchedule.name,
      };

      change(`schedule`, workSchedule);
    }
  }

  ACCOUNT_FIELDS_MAP.forEach(field => {
    const { name, dealName } = field;
    if (dealMemo[dealName]) {
      change(name, dealMemo[dealName]);
      onSetFieldVisibility(name);
    }
  });

  change('rate', '');

  change(`dealMemo`, dealMemo ? dealMemo : null);
}

export function handleChangeDealMemosWTCDay(
  newDealId,
  {
    dealMemos,
    member,
    detail,
    change,
    onFetchAndSetScaleRate = undefined,
    onShowRoundingMsg = () => {},
    onSetFieldVisibility,
  },
) {
  var guarantee;

  const dealMemo = dealMemos.find(dealMemo => dealMemo.id === newDealId);
  change(`${member}.dealMemo`, dealMemo ? dealMemo : null);

  if (dealMemo) {
    if (detail.isPartialDealMemo) {
      change(`${member}.isPartialDealMemo`, undefined);
    }

    //Timecard days row
    ACCOUNT_FIELDS_MAP.forEach(field => {
      const { name, dealName } = field;
      if (dealMemo[dealName]) {
        change(`${member}.${name}`, dealMemo[dealName]);
        onSetFieldVisibility(name);
      }
    });
  }

  if (!_.isEmpty(detail) && newDealId) {
    if (!_.isEmpty(dealMemo)) {
      if (dealMemo.guarantees && dealMemo.guarantees.length > 0) {
        if (detail.locationType && detail.locationType.code) {
          guarantee = dealMemo.guarantees.find(g => {
            const gCode = g.onLocation && g.onLocation.code.toUpperCase();
            const workCode =
              detail.locationType.code === 'D'
                ? 'D'
                : detail.locationType.code === 'DS'
                ? 'D'
                : detail.locationType.code;
            return gCode === workCode;
          });
        }
        if (!guarantee) {
          guarantee = dealMemo.guarantees.find(
            g =>
              g.onLocation &&
              g.onLocation.code &&
              g.onLocation.code.toUpperCase() === 'S',
          );
        }

        if (
          onFetchAndSetScaleRate &&
          guarantee &&
          guarantee.payAtScale &&
          (guarantee.payAtScale === 'C' || guarantee.payAtScale === 'Y')
        ) {
          onFetchAndSetScaleRate({ member, payAtScale: guarantee.payAtScale });
        } else if (guarantee && guarantee.rate) {
          change(`${member}.rate`, guarantee.rate);
        } else {
          change(`${member}.rate`, '');
        }
      } else {
        change(`${member}.rate`, '');
      }
      if (dealMemo.occupationCode) {
        const occupationCode = {
          id: dealMemo.occupationCode.id,
          code: dealMemo.occupationCode.code,
          name: dealMemo.occupationCode.name,
        };
        change(`${member}.occupationCode`, occupationCode);
      }
      if (dealMemo.workSchedule) {
        const workSchedule = {
          id: dealMemo.workSchedule.id,
          code: dealMemo.workSchedule.code,
          name: dealMemo.workSchedule.name,
        };
        change(`${member}.schedule`, workSchedule);
      }

      //Update Rounding
      const oldDealId = detail?.dealMemo?.id;
      const oldDealMemo = dealMemos.find(dealMemo => dealMemo.id === oldDealId);
      if (dealMemo?.roundTo && oldDealMemo?.roundTo !== dealMemo.roundTo) {
        onShowRoundingMsg();
        const tv = new TimeValidator(dealMemo.roundTo);
        const TIME_FIELDS = [
          'callTime',
          'ndbOut',
          'ndbIn',
          'meal1Out',
          'meal1In',
          'lastMan1In',
          'ndmOut',
          'ndmIn',
          'meal2Out',
          'meal2In',
          'meal3Out',
          'meal3In',
          'wrapTime',
        ];
        TIME_FIELDS.forEach(fieldName => {
          if (detail[fieldName]) {
            const roundedVal = tv.parse(`${detail[fieldName]}`);
            change(`${member}.${fieldName}`, roundedVal);
          }
        });
      }
    }
  }
}

export function handleChangeDealmemosWTCAllowance(
  newDealId,
  { dealMemos, allowance, change },
) {
  const dealMemo = dealMemos.find(dealMemo => dealMemo.id === newDealId);

  if (!_.isEmpty(dealMemo) && newDealId) {
    if (!_.isEmpty(dealMemo.occupationCode)) {
      const occupationCode = {
        id: dealMemo.occupationCode.id,
        code: dealMemo.occupationCode.code,
        name: dealMemo.occupationCode.name,
      };
      change(`${allowance}.occupationCode`, occupationCode);
    }

    change(`${allowance}.dealMemo`, dealMemo ? dealMemo : null);
    if (!_.isEmpty(dealMemo)) {
      ACCOUNT_FIELDS_MAP.forEach(field => {
        const { name, dealName } = field;
        if (dealMemo[dealName]) {
          change(`${allowance}.${name}`, dealMemo[dealName]);
        }
        // no need to set field visibility here, handled in dealmemo day level.
      });
    }
  }
}

const exemptList = [
  'onLocation',
  'start',
  'accountCode',
  'end',
  'gross',
  'hours',
  'days',
  'type',
  'category',
];
//Helper function for handleChangeWorkLocationsDay
function applyGuarantee({ guarantee, change, member }) {
  for (let key in guarantee) {
    if (exemptList.indexOf(key) >= 0) continue;
    if (key === 'rate' && !guarantee[key]) {
      change(`${member}.${key}`, '');
      continue;
    }
    if (guarantee[key]) {
      change(
        `${member}.${key.replace('customField', 'freeField')}`,
        guarantee[key],
      );
    }
  }
}

export function handleChangeWorkLocationsDay(
  newValue,
  { detail, dealMemos, change, member, onFetchAndSetScaleRate },
) {
  const result = newValue;

  const check = detail?.dealMemo?.code;
  const currentDealMemo = dealMemos.find(dealMemo => dealMemo.code === check);

  if (!currentDealMemo) {
    console.warn('No dealMemo found on timecard day:', detail);
  }

  if (!_.isEmpty(currentDealMemo) && currentDealMemo.guarantees.length > 0) {
    const theGuarantee = getGuarBasedOnWorkLoc(currentDealMemo, result);

    if (theGuarantee) {
      applyGuarantee({ guarantee: theGuarantee, change, member });
      if (
        theGuarantee.payAtScale &&
        (theGuarantee.payAtScale === 'C' || theGuarantee.payAtScale === 'Y') &&
        onFetchAndSetScaleRate
      ) {
        onFetchAndSetScaleRate({ member, payAtScale: theGuarantee.payAtScale });
      }
    }
  } else {
    // for deal memo with 0 guarantee
    change(`${member}.rate`, '');
  }
}

//used in DTS as well, but need wtcUtil functions so leaving here.
export const getGuarBasedOnWorkLoc = (dealMemo, workLoc) => {
  const currentGuarantee = getGuarFromDeal(dealMemo, workLoc && workLoc.code);
  const defaultStudio = getGuarFromDeal(dealMemo, 'S');
  const defaultDistant = getGuarFromDeal(dealMemo, 'D');
  let theGuarantee = null;
  if (!_.isEmpty(currentGuarantee)) {
    theGuarantee = currentGuarantee;
  } else if (!_.isEmpty(defaultDistant) && workLoc && workLoc.code === 'DS') {
    theGuarantee = defaultDistant;
  } else if (!_.isEmpty(defaultStudio)) {
    theGuarantee = defaultStudio;
  }
  return theGuarantee;
};

const getGuarFromDeal = (dealMemo, code) =>
  dealMemo &&
  dealMemo.guarantees &&
  dealMemo.guarantees.find(
    guarantee =>
      guarantee && guarantee.onLocation && guarantee.onLocation.code === code,
  );

export function addAdditionalTimecardInfo(
  timecard,
  timecardAdditional,
  rounding,
) {
  if (timecard && timecard.details) lowerCaseLastManIn(timecard.details);

  if (timecardAdditional) {
    timecard.timecardId = timecardAdditional.timecard.id;
    timecard.emergencyType = timecardAdditional.timecard.emergencyType;
    timecard.hPlusStatus = timecardAdditional.timecard.status;
    timecard.worksightHtgId = timecardAdditional.timecard.batch?.worksightHtgId;
    timecard.workflowAction = timecardAdditional.timecard.workflowAction;
    timecard.batchStatus = timecardAdditional?.batch?.batchStatus;
    timecard.invoice = timecardAdditional?.invoice || '';
  }

  if (rounding) {
    addRoundingToDeal(timecard.dealMemo, rounding);
    timecard.details.forEach(day => addRoundingToDeal(day.dealMemo, rounding));
  }

  timecard.hasWorkTimeChanges = false;
}

export function addRoundingToDeal(deal, rounding) {
  const currRounding = rounding.find(r => r.id === deal.id);
  deal.roundTo = currRounding.roundTo;
}

export function getActiveDealMemoIds(timecard) {
  const dealMemoIds = new Set();

  if (timecard?.dealMemo?.id) dealMemoIds.add(timecard.dealMemo.id);

  timecard.details.forEach(day => {
    if (day?.dealMemo?.id) dealMemoIds.add(day.dealMemo.id);
  });

  return Array.from(dealMemoIds);
}

export const getPayCodeFromDealMemoAllowance = (payCodeId, dealMemo) => {
  const dealMemoAllowance =
    dealMemo.dealMemoAllowance || dealMemo.dealMemoAllowances;

  if (
    dealMemoAllowance === undefined ||
    dealMemoAllowance.length === 0 ||
    !payCodeId
  ) {
    return;
  }

  let result = _.find(dealMemoAllowance, a => {
    if (a.payCode1 || a.payCode2) {
      return a?.payCode1?.id === payCodeId || a?.payCode2?.id === payCodeId;
    }
    return false;
  });

  return result;
};

//often the autoComp use an emptyOption that has fields
// so it doesn't get seen as an empty object
// Counting as empty if id is undefined/null/empty-string
// false is used to hide checkboxes visibility
export const fieldHasValue = input => {
  if (input === undefined || input === null || input === false) return false;

  if (
    _.isObject(input) &&
    (_.isEmpty(input) ||
      input.id === undefined ||
      input.id === '' ||
      input.id === null)
  ) {
    return false;
  }

  return true;
};

export const normalizeNumberEntry = (value, prev) => {
  const regex = /^([0-9]*)$|^([0-9]*\.[0-9]*)$/;
  if (value.match(regex) === null) return prev;
  return value;
};

/**
 *
 * @param {*} value
 * @returns empty string for NaN values
 */
export const onBlurNumber = value => {
  const numValue = parseFloat(value);

  if (isNaN(numValue)) return '';

  const strVal = numValue.toString();

  const hasDecimal = strVal.includes('.');
  const decimalValue = (hasDecimal ? strVal.split('.') : [strVal, ''])[1];
  const decimalPlaces = decimalValue.length;

  //parse to at least 2 decimal places or more if provided
  const parsed = parseFloat(strVal).toFixed(
    decimalPlaces <= 2 ? 2 : decimalPlaces,
  );

  //This version limits decimal places to 4.  Going with unlimited for now
  // const parsed = parseFloat(strVal).toFixed(
  //   decimalPlaces <= 2 ? 2 : decimalPlaces > X ? X : decimalPlaces,
  // );

  return parsed.toString();
};

export const makeScaleKey = ({ dealMemoId, weekEndingDate, payAtScale }) =>
  `scale-${weekEndingDate}-${dealMemoId}-${payAtScale}`;

export const isDistantDay = day => {
  const locationType = day.locationType;

  if (locationType?.code === 'D' || locationType?.code === 'DS') {
    return true;
  } else {
    return false;
  }
};

export const getRateFromLocal = params => {
  let scaleKey;
  try {
    scaleKey = makeScaleKey(params);
    const result = sessionStorage.getItem(scaleKey);

    if (result === null || result === 'fetching') return result;

    const parsedResult = JSON.parse(result);

    const obj = {
      distant: +parsedResult.distant,
      studio: +parsedResult.studio,
      studioGross: +parsedResult.studioGross,
      distantGross: +parsedResult.distantGross,
    };

    // parsing item from sessionStorage
    // validate obj
    if (
      Object.keys(obj).length !== 4 ||
      obj.distant === undefined ||
      obj.studio === undefined ||
      obj.studioGross === undefined ||
      obj.distantGross === undefined ||
      Number.isNaN(obj.distant) ||
      Number.isNaN(obj.studio) ||
      Number.isNaN(obj.studioGross) ||
      Number.isNaN(obj.distantGross)
    ) {
      throw new Error('Invalid scale rate obj');
    }

    return obj;
  } catch (e) {
    console.error('sessionStorage lookup failed:', scaleKey);
    console.error(e);
    return null;
  }
};

export const getStudioAndDistant = data => {
  let studio = 0;
  let distant = 0;
  let studioGross = 0;
  let distantGross = 0;

  if (data.length === 1) {
    const result = data[0];
    studio = result.rate;
    distant = studio;
  } else if (data.length === 2) {
    let result = data.find(d => d.onLocation === 'S');
    if (result) {
      studio = result.rate;
      studioGross = result.htgWeeklyRate || result.htgDailyRate;
    }
    result = data.find(d => d.onLocation === 'D');
    if (result) {
      distant = result.rate;
      //Assumes only one of these will be non-zero - otherwise we'd need to check the source guarantee for weekly / daily
      distantGross = result.htgWeeklyRate || result.htgDailyRate;
    }
  } else {
    throw new Error('Invalid scale rate response. Must be 1 or 2 obj array');
  }

  return { studio, distant, studioGross, distantGross };
};

export const hasAutoCoding = tc => {
  let hasIt = false;
  if ('defaultAccountOverride' in tc && tc.defaultAccountOverride) {
    return true;
  }
  if (tc.details) {
    tc.details.forEach(day => {
      if ('defaultAccountOverride' in day && day.defaultAccountOverride) {
        hasIt = true;
      }
    });
  }
  return hasIt;
};

export const validateSourceBatch = batch => {
  if (!batch || _.isEmpty(batch)) return false;

  const schema = {
    id: 'number',
    name: 'string',
    endsOn: 'string',
    invoiceId: 'string',
    worksightId: 'string',
    htgBatchNumber: 'number',
  };

  for (const fieldPath in schema) {
    if (Object.hasOwnProperty.call(schema, fieldPath)) {
      const value = _.get(batch, fieldPath);
      const expectedType = schema[fieldPath];
      if (typeof value !== expectedType) {
        return false;
      }
    }
  }
  return true;
};

export const SORT_BYS = [
  {
    id: 0,
    field: 'employee',
    label: 'Name (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 1,
    field: 'employee',
    label: 'Name (desc)',
    sortOrder: 'decSort',
  },
  {
    id: 2,
    field: 'department',
    label: 'Department (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 3,
    field: 'department',
    label: 'Department (desc)',
    sortOrder: 'decSort',
  },
];

export const assertIsString = str => {
  if (typeof str !== 'string') {
    db(
      `Expected string, found type: ${typeof str}. Converting to empty string`,
    );
    str = '';
  }
  return str;
};

export const makeTextFilter = textFilter => {
  let textSearchFilter = assertIsString(textFilter).toLowerCase();

  const func = timecard => {
    if (!textSearchFilter) return true;

    let { employee = '', employeeSSN = '', occupationName = '' } = timecard;

    employee = assertIsString(employee);
    employeeSSN = assertIsString(employeeSSN);
    occupationName = assertIsString(occupationName);

    const lastSsnDigits = employeeSSN?.replace(/[X-]/g, '') || '';

    return (
      employee.toLowerCase().indexOf(textSearchFilter) >= 0 ||
      lastSsnDigits.indexOf(textSearchFilter) >= 0 ||
      occupationName.toLowerCase().indexOf(textSearchFilter) >= 0
    );
  };
  return func;
};

export const makeTimecardsFilter = activeFilters => {
  const { department, status } = activeFilters;

  const func = timecard => {
    const { departmentId: deptId, status: timecardStatus } = timecard;

    const hasDept = !department.length || department.includes(deptId);

    const hasStatus = !status.length || status.includes(timecardStatus);

    return hasDept && hasStatus;
  };
  return func;
};

export const validateTimecard = (timecard, drawerTimecards) => {
  const makeError = field => ({
    completed: false,
    created: Date.now(),
    field,
    level: 'Error',
    text: `This timecard does not have a valid ${field}. Contact support`,
    user: 'wtcValidation',
  });

  if (Array.isArray(timecard.errors) === false) {
    console.warn('Timecard.errors not array as expected.');
    timecard.errors = [];
  }

  if (!timecard.batch || !timecard.batch.id) {
    timecard.errors.push(makeError('Batch'));
  }
  if (!timecard.dealMemo || !timecard.dealMemo.id) {
    timecard.errors.push(makeError('Deal memo'));
  }
  if (!timecard.weekEndingDate) {
    timecard.errors.push(makeError('Week ending'));
  }

  const drawerTimecard = drawerTimecards.find(
    tc => tc.timecardEntryHeaderId === timecard.timecardEntryHeaderId,
  );

  if (!drawerTimecard || !drawerTimecard.department) {
    timecard.errors.push(makeError('Department'));
  }

  if (!timecard.employee || !timecard.employee.code) {
    const field = isRegionCanada(localStorage.getItem('region'))
      ? 'SIN'
      : 'SSN';
    timecard.errors.push(makeError(field));
  }
};
const getFieldName = str => {
  return str.split('.')[1];
};
const getFocusDay = str => {
  const matches = str.match(/\[(.*)\]/);
  return Number(matches[1]);
};

export const checkParent = (parent, child) => {
  if (parent === null) return false;
  return parent !== child && parent.contains(child);
};

export const downHandler = (e, count, str, mstRow) => {
  let day, fieldName, x, nuName, nextname;
  e.preventDefault();
  e.stopPropagation();

  if (e.target.type === 'checkbox') {
    if (checkParent(mstRow, e.target)) {
      if (e.shiftKey) {
        return;
      }
      nextname = `${str}[0].${e.target.name}`;
      x = document.getElementsByName(nextname);
      x[0].select();
    } else {
      day = getFocusDay(e.target.name);
      fieldName = getFieldName(e.target.name);
      if (e.shiftKey) {
        if (day === 0) {
          if (!mstRow) {
            return;
          }
          x = document.getElementsByName(fieldName);
          x[0].select();
        } else if (day > 0) {
          nuName = `${str}[${day - 1}].${fieldName}`;
          x = document.getElementsByName(nuName);
          x[0].select();
        }
      } else {
        if (day + 1 === count) {
          return;
        }
        nuName = `${str}[${day + 1}].${fieldName}`;
        x = document.getElementsByName(nuName);
        x[0].select();
      }
    }
  } else if (e.target.type === 'text') {
    if (e.target.name === '') {
      if (checkParent(mstRow, e.target)) {
        if (e.shiftKey) {
          return;
        }
        fieldName = e.target.id.split('auto-complete-for-')[1];
        nuName = `auto-complete-for-${str}[0].${fieldName}`;
        x = document.getElementById(nuName);
        x.select();
      } else {
        day = getFocusDay(e.target.id);
        if (e.shiftKey) {
          if (day === 0) {
            if (!mstRow) {
              return;
            }
            fieldName = e.target.id.split('].')[1];
            nuName = `auto-complete-for-${fieldName}`;
            x = document.getElementById(nuName);
            x.select();
          } else if (day > 0) {
            fieldName = e.target.id.split('].')[1];
            nuName = `auto-complete-for-${str}[${day - 1}].${fieldName}`;
            x = document.getElementById(nuName);
            x.select();
          }
        } else {
          if (day + 1 === count) {
            return;
          }
          fieldName = e.target.id.split('].')[1];
          nuName = `auto-complete-for-${str}[${day + 1}].${fieldName}`;
          x = document.getElementById(nuName);
          x.select();
        }
      }
    } else {
      if (checkParent(mstRow, e.target)) {
        if (e.shiftKey) {
          return;
        }
        nextname = `${str}[0].${e.target.name}`;
        x = document.getElementsByName(nextname);
        x[0].select();
      } else {
        day = getFocusDay(e.target.name);
        fieldName = getFieldName(e.target.name);
        if (e.shiftKey) {
          if (day === 0) {
            if (!mstRow) {
              return;
            }
            x = document.getElementsByName(fieldName);
            x[0].select();
          } else if (day > 0) {
            nuName = `${str}[${day - 1}].${fieldName}`;
            x = document.getElementsByName(nuName);
            x[0].select();
          }
        } else {
          if (day + 1 === count) {
            return;
          }
          nuName = `${str}[${day + 1}].${fieldName}`;
          x = document.getElementsByName(nuName);
          x[0].select();
        }
      }
    }
  }
};

export const scanForWorkTimeChanges = (prevTimecard, nextTimecard) => {
  const changedTimes = {};

  const prevDates = prevTimecard.details
    .filter(d => d.unusedDay !== true && d.dealMemo !== null)
    .map(d => moment(d.effectiveDate).format());

  const nextDates = nextTimecard.details
    .filter(d => d.unusedDay !== true && d.dealMemo !== null)
    .map(d => moment(d.effectiveDate).format());

  prevDates.forEach(date => {
    if (nextDates.includes(date) === false) {
      changedTimes[date] = 'removed';
    }
  });

  nextDates.forEach(date => {
    if (prevDates.includes(date) === false) {
      changedTimes[date] = 'added';
    } else {
      getTimeDiffs(changedTimes, date, prevTimecard, nextTimecard);
    }
  });
  return changedTimes;
};

const getTimeDiffs = (changedTimes, date, prevTimecard, nextTimecard) => {
  const prev = prevTimecard.details.find(
    d => moment(d.effectiveDate).format() === date,
  );

  const next = nextTimecard.details.find(
    d => moment(d.effectiveDate).format() === date,
  );

  WORK_TIME_FIELDS.forEach(fieldName => {
    const prevVal = prev[fieldName];
    const nextVal = next[fieldName];
    if (prevVal !== nextVal) {
      if (!changedTimes[date]) changedTimes[date] = {};
      if (!changedTimes[date][fieldName]) changedTimes[date][fieldName] = {};

      changedTimes[date][fieldName] = { prevVal, nextVal };
    }
  });
};

export const generateComment = (changedTimes, tableFields) => {
  const comments = [];
  const dates = Object.keys(changedTimes).sort((a, b) => {
    if (a < b) return -1;
    return 1;
  });

  dates.forEach(date => {
    const changedEntry = changedTimes[date];
    const dayOfWeek = moment(date).format('dddd');
    if (typeof changedEntry === 'string') {
      let str = `${dayOfWeek} ${changedEntry}`;
      comments.push(str);
    } else {
      for (const fieldName in changedEntry) {
        if (Object.hasOwnProperty.call(changedEntry, fieldName)) {
          const changes = changedEntry[fieldName];
          const fieldLabel = tableFields[fieldName]?.label || fieldName;
          let str = `${dayOfWeek} ${fieldLabel} `;

          const { prevVal, nextVal } = changes;

          if ((prevVal || prevVal === 0) && (nextVal || nextVal === 0)) {
            str += `changed from ${prevVal.toFixed(1)} to ${nextVal.toFixed(
              1,
            )}.`;
          } else if (!prevVal && prevVal !== 0) {
            str += `added as ${nextVal.toFixed(1)}.`;
          } else if (!nextVal && nextVal !== 0) {
            str += 'removed.';
          }
          comments.push(str);
        }
      }
    }
  });

  let comment = '';
  comments.forEach(line => (comment += `${line}\n`));

  return comment;
};

export const getFieldType = (type, key) => {
  //different - types form HTG resp
  // ['text', 'checkbox', 'auto-complete', 'float', 'time', 'label', 'mask']
  switch (type) {
    case 'checkbox':
      return 'checkbox';
    case 'auto-complete':
      return 'auto-complete';
    case 'text':
    case 'time':
    case 'float':
    case 'mask':
    case 'label':
      if (key === 'combineCheck') return 'auto-complete';
      return 'text';
    default:
  }
};

export const sortWTCFieldsByPosition = obj => {
  const res = {};
  const order = Object.keys(obj).sort(
    (a, b) => obj[a].position - obj[b].position,
  );
  order.forEach(key => {
    res[key] = obj[key];
  });
  return res;
};
