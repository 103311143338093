import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Field, getFormValues, change as formChange } from 'redux-form';

import AutoV2 from './AutoV2';

//redux
import * as sel from '../../selectors';
import {
  getCitiesV1,
  getCountriesV1,
  getCountiesV1,
  getLoading,
  getSubdivisionsV1,
} from 'selectors/location';
import {
  fetchCountriesV1,
  fetchStatesV1,
  fetchCountiesV1,
  fetchCitiesV1,
  storeStatesV1,
  storeCitiesV1,
  fetchSubdivisionsV1,
} from 'actions/location';
import * as actions from '../../actions';

import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';

const mapState = state => ({
  dayTypes: sel.getDayTypes(state),
  countries: getCountriesV1(state),
  states: sel.getStateOptions(state),
  counties: getCountiesV1(state),
  cities: getCitiesV1(state),
  timecard: getFormValues(FORM_NAME)(state) || {},
  loading: getLoading(state),
  episodes: sel.getEpisodes(state),
  occCodes: sel.getOccCodes(state),
  occCodeLoading: sel.getLoading(state, 'fetchOccCodes'),
  workLocations: sel.getWorkLocations(state),
  subdivisions: getSubdivisionsV1(state),
});
const mapDispatch = dispatch => ({
  dispatch,
  onFetchCountries: () => dispatch(fetchCountriesV1()),
  onFetchStates: args => dispatch(fetchStatesV1(args)),
  onFetchCities: args => dispatch(fetchCitiesV1(args)),
  onFetchCounties: args => dispatch(fetchCountiesV1(args)),
  onFetchSubdivision: args => dispatch(fetchSubdivisionsV1(args)),
  onFetchOccCodes: args => dispatch(actions.fetchOccCodes(args)),
  onResetLoc: variant => {
    switch (variant) {
      case 'state':
        dispatch(storeStatesV1({ states: [] }));
        break;
      case 'city':
        dispatch(storeCitiesV1({ cities: [] }));
        break;
      default:
        break;
    }
  },
  onChangeDayType: (index, newVal, oldVal) => {
    dispatch(actions.changeDayType({ index, newVal, oldVal }));
  },
});

const SelectCell = props => {
  const {
    column: { accessor },
    name,
    dayTypes,
    onFetchOccCodes,
    onFetchCountries,
    onFetchStates,
    onFetchCounties,
    onFetchCities,
    onFetchSubdivision,
    onResetLoc,
    countries,
    states,
    counties,
    cities,
    subdivisions,
    timecard,
    member,
    dispatch,
    loading,
    isDayActive,
    occCodes,
    occCodeLoading,
    episodes,
    workLocations,
    isTcEditable,
    index,
    isPartialDealMemo,
    onChangeDayType,
  } = props;

  const change = React.useCallback(
    (field, value) => {
      dispatch(formChange(FORM_NAME, field, value));
    },
    [dispatch],
  );

  const changeDayType = React.useCallback(
    (e, newVal, oldVal) => {
      onChangeDayType(index, newVal, oldVal);
    },
    [onChangeDayType, index],
  );

  const changeCountry = React.useCallback(
    newValue => {
      change(`${member}.htgCountryId`, newValue && newValue.id);
      change(`${member}.htgStateId`, null);
      change(`${member}.htgCityId`, null);
      change(`${member}.htgCountyId`, null);
      change(`${member}.htgSubdivisionId`, null);
      change(`${member}.workSubdivision`, null);
      change(`${member}.state`, null);
      change(`${member}.city`, null);
      change(`${member}.county`, null);
    },
    [change, member],
  );

  const changeState = React.useCallback(
    newValue => {
      change(`${member}.htgStateId`, newValue && newValue.id);
      change(`${member}.htgCityId`, null);
      change(`${member}.htgCountyId`, null);
      change(`${member}.htgSubdivisionId`, null);
      change(`${member}.workSubdivision`, null);
      change(`${member}.city`, null);
      change(`${member}.county`, null);
    },
    [change, member],
  );

  const changeCity = React.useCallback(
    newValue => {
      change(`${member}.htgCityId`, newValue && newValue.id);
    },
    [change, member],
  );

  const changeCounty = React.useCallback(
    newValue => {
      change(`${member}.htgCountyId`, newValue && newValue.id);
    },
    [change, member],
  );

  const changeSubdivision = React.useCallback(
    newValue => {
      change(`${member}.htgSubdivisionId`, newValue && newValue.id);
    },
    [change, member],
  );

  const changeEpisode = React.useCallback(
    episode => {
      if (episode?.series) {
        change(`${member}.series`, episode?.series);
      }
      if (episode?.location) {
        change(`${member}.location`, episode?.location);
      }
    },
    [change, member],
  );

  const changeWorkZone = React.useCallback(
    newValue => {
      if (!_.isEmpty(newValue)) {
        change(`${member}.locationType`, newValue);
        change(`${member}.htgLocationTypeId`, newValue.id);
      } else {
        change(`${member}.locationType`, null);
        change(`${member}.htgLocationTypeId`, null);
      }
    },
    [change, member],
  );
  const selectProps = {
    name,
    component: AutoV2,
    isDayActive,
    options: [],
    isTcEditable,
    disabled: isPartialDealMemo,
  };

  let stateId;
  switch (accessor) {
    case 'dayType':
      selectProps.options = dayTypes;
      selectProps.isDayActive = true;
      selectProps.onChange = changeDayType;
      break;
    case 'country':
      selectProps.options = countries;
      selectProps.onOpen = onFetchCountries;
      selectProps.onChange = changeCountry;
      selectProps.loading = loading['country'] || false;

      break;
    case 'state':
      selectProps.options = states;
      //TODO - figure out how to clear options, but still allow
      // clicking from an open dropdown to another one
      // currently the onClose on the initial is getting fired AFTER the onOpen on the next
      // resulting in an empty list
      selectProps.onClose = () => onResetLoc('state');
      const country = _.get(timecard, `${member}.country`);
      const countryId = country?.id || '';
      selectProps.onOpen = () => {
        onFetchStates({ countryId });
      };
      selectProps.onChange = changeState;
      selectProps.loading = loading['states'] || false;

      break;
    case 'county':
      selectProps.options = counties;
      stateId = _.get(timecard, `${member}.state.id`, '');
      selectProps.onOpen = () => onFetchCounties({ stateId });
      selectProps.onClose = () => onResetLoc('county');
      selectProps.disabled = !stateId;
      selectProps.onChange = changeCounty;
      break;
    case 'city':
      //TODO on mobile, new options are pushing the dropdown off screen on initial load after 'loading'
      // only seems to happen when there a 2-3 city options like north dakota
      selectProps.options = cities;
      stateId = _.get(timecard, `${member}.state.id`, '');
      selectProps.onOpen = () => {
        onFetchCities({ stateId });
      };
      selectProps.onClose = () => onResetLoc('city');
      selectProps.disabled = !stateId;
      selectProps.onChange = changeCity;
      selectProps.loading = loading['cities'] || false;
      break;
    case 'workSubdivision':
      selectProps.options = subdivisions;
      stateId = _.get(timecard, `${member}.state.id`, '');
      selectProps.disabled = !stateId;
      selectProps.onChange = changeSubdivision;
      selectProps.loading = loading['subdivisions'] || false;
      selectProps.async = true;
      selectProps.loadOptions = search => onFetchSubdivision({ search });
      selectProps.onOpen = () => onFetchSubdivision({ search: '' });
      break;
    case 'occupationCode':
      selectProps.options = occCodes;
      selectProps.loading = occCodeLoading;
      selectProps.async = true;
      selectProps.loadOptions = search => onFetchOccCodes({ search });
      selectProps.onOpen = () => onFetchOccCodes({ search: '' });
      break;
    case 'locationType':
      selectProps.options = workLocations;
      selectProps.onChange = changeWorkZone;
      break;
    case 'episode':
      selectProps.options = episodes;
      selectProps.onChange = episode => {
        changeEpisode(episode);
      };
      break;
    default:
      break;
  }
  return <Field {...selectProps} />;
};

SelectCell.propTypes = {
  column: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  dayTypes: PropTypes.array.isRequired,
  onFetchOccCodes: PropTypes.func.isRequired,
  onFetchCountries: PropTypes.func.isRequired,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCounties: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchSubdivision: PropTypes.func.isRequired,
  onResetLoc: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  counties: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  timecard: PropTypes.object.isRequired,
  member: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  isDayActive: PropTypes.bool.isRequired,
  occCodes: PropTypes.array.isRequired,
  occCodeLoading: PropTypes.bool.isRequired,
  episodes: PropTypes.array.isRequired,
  workLocations: PropTypes.array.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isPartialDealMemo: PropTypes.bool.isRequired,
  onChangeDayType: PropTypes.func.isRequired,
  subdivisions: PropTypes.array.isRequired,
};

export default compose(connect(mapState, mapDispatch))(SelectCell);
