import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Typography,
  TableRow,
  TableCell,
  LinearProgress,
} from '@mui/material';
import {
  FaRegClock as ClockIcon,
  FaTimes as RemoveIcon,
  FaPencilAlt as EditIcon,
} from 'react-icons/fa';
//components
import {
  Card,
  CardHeaderPlain as CardHeader,
  CardContent,
} from 'components/Shared/Card';
import { withStyleSheet } from 'shared/theme';
import { NavButton } from 'components/Shared/Nav';
import { TableList } from 'components/Shared/TableList';
import { DATE_FORMAT_HOUR_MIN } from 'components/Shared/constants';
// selectors
import { getReportName, getClientName } from 'selectors/reports';
//utils
import { formatDate } from 'utils';

export const style = ({ palette }) => ({
  root: {
    fontFamily: 'inherit',
    width: '100%',
  },
  content: {
    padding: 0,
    fontFamily: 'inherit',
    '&:last-child': { paddingBottom: 0 },
  },
  tableBody: {
    padding: 0,
    fontFamily: 'inherit',
  },
  blankSlate: {
    fontSize: '2.14285714em',
    background: palette.background.paper,
    color: palette.text.disabled,
    fontWeight: '700',
    fontStyle: 'italic',
    marginTop: '2px',
    padding: '50px',
    textAlign: 'center',
  },
});
class Schedule extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reports: PropTypes.array.isRequired,
    onDeleteScheduledReport: PropTypes.func.isRequired,
    onAddScheduledReport: PropTypes.func,
    onEditScheduledReport: PropTypes.func,
  };

  static defaultProps = {
    onDeleteScheduledReport: undefined,
  };

  renderScheduledReports() {
    const { classes, reports, onDeleteScheduledReport, onEditScheduledReport } =
      this.props;
    let scheduledReportsRows = null;

    if (reports && reports.length <= 0) {
      scheduledReportsRows = (
        <TableRow>
          <TableCell colSpan={7} className={classes.blankSlate}>
            {'There are currently no reports scheduled'}
          </TableCell>
        </TableRow>
      );
    } else {
      scheduledReportsRows = reports.map(report => (
        <TableRow key={report.id} className={classes.tableRow}>
          <TableCell>{getReportName(report.reportType)}</TableCell>
          <TableCell>{getClientName(report)}</TableCell>
          <TableCell>{report.last_status || 'N/A'}</TableCell>
          <TableCell>
            {formatDate(report.lastExecuted_on, DATE_FORMAT_HOUR_MIN) || 'N/A'}
          </TableCell>
          <TableCell>
            {formatDate(report.nextExecution, DATE_FORMAT_HOUR_MIN) || 'N/A'}
          </TableCell>
          <TableCell>
            {formatDate(report.expiresOn, DATE_FORMAT_HOUR_MIN) || 'N/A'}
          </TableCell>
          <TableCell>
            <IconButton
              className={classes.iconButton}
              onClick={() => onEditScheduledReport(report.id)}
              size="large"
            >
              <EditIcon size={18} />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              onClick={() => onDeleteScheduledReport(report.id)}
              size="large"
            >
              <RemoveIcon size={18} />
            </IconButton>
          </TableCell>
        </TableRow>
      ));
    }

    const scheduledReportsHeader = [
      { columnId: 'report=Name', label: 'Report Name', compact: true },
      { columnId: 'clientName', label: 'Client/Project Name', compact: true },
      {
        columnId: 'lastExecStatus',
        label: 'Last Execution Status',
        compact: true,
      },
      { columnId: 'lastExecOn', label: 'Last Executed On', compact: true },
      { columnId: 'nextExecOn', label: 'Next Execution On', compact: true },
      { columnId: 'expiresOn', label: 'Expires On', compact: true },
      { columnId: 'actions', label: 'Action', compact: true },
    ];

    return (
      <TableList className={classes.tableBody} headers={scheduledReportsHeader}>
        {scheduledReportsRows}
      </TableList>
    );
  }

  render() {
    const { classes, loading, onAddScheduledReport } = this.props;

    const addScheduleReport = (
      <NavButton
        key="add_department"
        label="Schedule Reports"
        icon={ClockIcon}
        onClick={() => onAddScheduledReport()}
        color="primary"
        variant="contained"
      />
    );
    return (
      <Card className={classes.root}>
        <CardHeader title buttons={addScheduleReport}>
          <Typography variant="h6" align="left">
            Scheduled Reports
          </Typography>
        </CardHeader>
        <CardContent>
          {loading && <LinearProgress />}
          {this.renderScheduledReports()}
        </CardContent>
      </Card>
    );
  }
}

export default withStyleSheet('Schedule', style)(Schedule);
