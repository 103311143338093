import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { default as LeftIcon } from '@mui/icons-material/KeyboardArrowLeft';
import { default as RightIcon } from '@mui/icons-material/KeyboardArrowRight';
import { useLocation } from 'react-router-dom';

//selectors
import { getProjectDetails, getViewingYear } from 'selectors/project';
import { currentUser as activeUser } from 'selectors/session';
import * as sel from 'feature/EmployeeTimecard/selectors';

//actions
import { setViewingYear, switchYear } from 'actions/project';
import * as actions from 'feature/EmployeeTimecard/actions';

//utils
import { getAltYearLandingPage } from 'utils/helperFunctions';
import { isDirty, reset } from 'redux-form';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  YearPicker: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginLeft: 10,
  },
  yearArrows: {
    cursor: 'pointer',
    color: palette.primary.dark,
    fontSize: 20,
  },
  hasNextArrows: {
    color: palette.error.contrastText,
  },
  yearText: {
    [breakpoints.down('sm')]: {
      padding: '0px 15px',
    },
  },
  yearAlert: {
    backgroundColor: palette.error.main,
    color: palette.error.contrastText,
    padding: '5px',
    borderRadius: '5px',
  },
}));

const mapStateToProps = state => ({
  projectDetails: getProjectDetails(state),
  viewingYear: getViewingYear(state),
  currentUser: activeUser(state),
  isDirty: isDirty(sel.FORM_NAME)(state),
  saving: sel.getLoading(state, 'saving'),
  submittingEmp: sel.getLoading(state, 'submittingEmp'),
  deleting: sel.getLoading(state, 'delete'),
  comment: sel.getNewComment(state),
});

const mapDispatchToProps = dispatch => ({
  onSetYear: viewingYear => dispatch(setViewingYear({ viewingYear })),
  onSwitchYear: location => dispatch(switchYear({ location })),
  onResetEmpTimecard: () => dispatch(reset(sel.FORM_NAME)),
  onClearComment: () => dispatch(actions.setNewComment({ comment: '' })),
});

export const YearPicker = props => {
  const classes = useStyles();
  const location = useLocation();

  const {
    className,
    viewingYear,
    projectDetails: {
      previousYearProjectId,
      canAccessNextYearProject,
      canAccessPreviousYearProject,
      nextYearProjectId,
    },
    onSetYear,
    currentUser: { role },
    hide,
    onSwitchYear,
    isDirty,
    onResetEmpTimecard,
    comment,
    saving,
    submittingEmp,
    deleting,
    onClearComment,
  } = props;

  const prevYearRef = useRef(viewingYear);
  const locationRef = useRef(location);

  useEffect(() => {
    //don't trigger switch when initial year is set (project sagas).
    if (prevYearRef.current && prevYearRef.current !== viewingYear) {
      onSwitchYear(locationRef.current);
    }
    prevYearRef.current = viewingYear;
  }, [viewingYear, onSwitchYear]);

  //keep location upto date without triggering the year switch
  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  const hasPrev = viewingYear === 2020 ? false : canAccessPreviousYearProject;

  const hasNext = canAccessNextYearProject;

  if (hide || isNaN(viewingYear) || viewingYear === 0) {
    return null;
  }

  const setYear = () => {
    if (viewingYear === 2021) {
      const url = getAltYearLandingPage(location, previousYearProjectId, role);
      window.location.href = url;
    } else if (viewingYear === 2020) {
      //this shouldn't be shown for 2020 projects
      console.error(
        'Expected: 2020 project should not have a prev year option',
      );
    } else {
      onSetYear(viewingYear - 1);
    }
  };
  const handlePrevClick = () => {
    if ((isDirty || !!comment) && !saving && !submittingEmp && !deleting) {
      const isOk = window.confirm(
        'You have unsaved changes. All unsaved changes will be lost when you leave. Are you sure you want to navigate away?',
      );
      if (isOk) {
        onResetEmpTimecard(); //resetting empTimecard form to avoid prompt alert
        onClearComment();
        setYear();
      }
    } else {
      setYear();
    }
  };

  const handleNextClick = () => {
    if (viewingYear === 2020) {
      const url = getAltYearLandingPage(location, nextYearProjectId, role);
      window.location.href = url + '?year=2021';
    } else {
      onSetYear(viewingYear + 1);
    }
  };

  return (
    <Tooltip title={hasNext ? 'Viewing previous year' : ''}>
      <div
        className={clsx(classes.YearPicker, className, {
          [classes.yearAlert]: hasNext,
        })}
      >
        {hasPrev && (
          <LeftIcon
            className={clsx(classes.yearArrows, {
              [classes.hasNextArrows]: hasNext,
            })}
            onClick={handlePrevClick}
          />
        )}
        <span className={classes.yearText}>{viewingYear}</span>
        {hasNext && (
          <RightIcon
            className={clsx(classes.yearArrows, classes.hasNextArrows)}
            onClick={handleNextClick}
          />
        )}
      </div>
    </Tooltip>
  );
};

YearPicker.propTypes = {
  className: PropTypes.string,
  projectDetails: PropTypes.object.isRequired,
  viewingYear: PropTypes.number,
  onSetYear: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  hide: PropTypes.bool,
  onSwitchYear: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(YearPicker);
