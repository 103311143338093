import React from 'react';
import PropTypes from 'prop-types';
import { Box, styled, Chip, Tooltip, IconButton } from '@mui/material';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { getFormSyncErrors } from 'redux-form';
import moment from 'moment';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CommentIconFilled from 'components/images/CommentIconFilled';
import { show as showWeekDayModal } from 'actions/modalDialog';

import { storeSelectedDay } from '../actions';

import * as sel from '../selectors';

import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';
import { MOBILE_DAY_MODAL } from 'feature/EmployeeTimecard/Modals/modalNames';
import {
  TAB_NAV_MAP,
  TAB_NAV_MAP_REVERSE,
} from 'feature/EmployeeTimecard/empTimecardUtils';

const mapState = (state, ownProps) => ({
  hours: sel.getDayHours(state),
  formErrors: getFormSyncErrors(FORM_NAME)(state),
  activeDeal: sel.getActiveDealMemo(state),
  comments: sel.getComments(state),
});
const mapDispatch = dispatch => ({
  dispatch,
  onShowWeekDayModal: (member, index) => {
    dispatch(storeSelectedDay({ member, index }));
    dispatch(showWeekDayModal({ dialog: MOBILE_DAY_MODAL }));
  },
  onChangeNav: url => dispatch(push(url)),
});

const DayPadding = 5;

const DayBorder = styled(Box)(({ theme }) => ({
  margin: '10px',
  padding: `${DayPadding}px`,
  borderRadius: '10px',
}));

const DayCard = styled(Box)(({ theme }) => ({
  margin: '10px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: '5px',
}));

const Day = props => {
  const {
    fields,
    index,
    member,
    onShowWeekDayModal,
    hours,
    formErrors,
    activeDeal,
    comments,
    onChangeNav,
  } = props;

  const errorDays = formErrors?.days || [];

  const currentDay = fields.get(index);
  const hoursWorked = hours[index];
  const errors = errorDays[index] || {};
  const hasErrors = Object.keys(errors).length > 0;
  const date = currentDay.date;
  const dateMoment = moment(date);
  const day = moment(date).format('dddd');
  const formattedDate = moment(date).format('MMM DD');
  const labelText =
    currentDay.dayType?.name?.toLowerCase() === 'sick'
      ? currentDay.dayType?.name
      : '';

  const location = useLocation();

  const navToCommentsTab = e => {
    e.stopPropagation();
    const tabNum = TAB_NAV_MAP.comments;
    const tabName = TAB_NAV_MAP_REVERSE[tabNum];
    const url = `${location.pathname}?${tabName}`;
    onChangeNav(url);
  };
  const getStyledChipContent = (color, label) => (
    <StyledChip color={color} label={label} />
  );

  const dayLevelComments = comments?.filter(c => c.type === 'day');
  const isCommentExists = dayLevelComments?.some(d =>
    moment(d.referenceDate, 'YYYY-MM-DD').isSame(date, 'YYYY-MM-DD'),
  );

  let sideContent = [];

  if (hasErrors) {
    sideContent.push(getStyledChipContent('error', 'Incomplete'));
  } else if (labelText) {
    sideContent.push(getStyledChipContent('primary', labelText));
  } else if (hoursWorked) {
    sideContent.push(
      getStyledChipContent('primary', `Total Hours: ${hoursWorked}`),
    );
  }
  if (isCommentExists) {
    sideContent.push(
      <IconButton onClick={navToCommentsTab} sx={{ p: '0px' }}>
        <CommentIconFilled />
      </IconButton>,
    );
  }
  const onShowModal = () => {
    onShowWeekDayModal(member, index);
  };

  const isDateBefore = moment(date).isBefore(activeDeal.start);
  const isDateAfter = moment(date).isAfter(activeDeal.end);
  let tooltipText = '';
  if (isDateBefore) {
    tooltipText = activeDeal.start
      ? `Deal Memo’s start date is ${moment(activeDeal.start).format('MMM Do')}`
      : 'No valid deals exist for the selected work week';
  } else if (isDateAfter) {
    tooltipText = activeDeal.end
      ? `Deal Memo’s end date is ${moment(activeDeal.end).format('MMM Do')}`
      : 'No valid deals exist for the selected work week';
  }
  let isToday = false;
  const today = moment();
  isToday = today.isSame(dateMoment, 'day');

  return (
    <DayBorder
      sx={{ backgroundColor: tooltipText ? 'gray.+8' : 'primary.contrastText' }}
    >
      {isToday && (
        <Box sx={{ position: 'relative' }}>
          <Tooltip title="Today">
            <Box
              sx={{
                height: '87px',
                width: '12px',
                backgroundColor: 'primary.+6',
                position: 'absolute',
                left: `-${DayPadding}px`,
                top: `-${DayPadding}px`,
                borderRadius: '10px 0 0 10px',
              }}
            />
          </Tooltip>
        </Box>
      )}
      <DayCard onClick={() => !tooltipText && onShowModal()}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <h3 style={{ margin: '0px', marginBottom: '5px' }}>{day}</h3>
            <Box>{formattedDate}</Box>
          </Box>
          <Box>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <InfoOutlinedIcon
                  sx={{ color: 'gray.primary', fontSize: '20px', ml: '5px' }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}
        >
          {sideContent}
        </Box>
      </DayCard>
    </DayBorder>
  );
};

Day.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  member: PropTypes.string.isRequired,
  onShowWeekDayModal: PropTypes.func.isRequired,
  hours: PropTypes.array.isRequired,
  formErrors: PropTypes.object.isRequired,
  activeDeal: PropTypes.object.isRequired,
};

export default compose(connect(mapState, mapDispatch))(Day);
