import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';

import { onNavToBatchInWTC } from 'actions/moveTimecards';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid white',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  messageContent: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  contentColumn: {
    flexDirection: 'column',
    '& > *': {
      margin: 0,
      paddingBottom: 5,
    },
  },
  wtcLink: {
    textDecoration: 'underline',
    paddingLeft: 10,
    color: theme.palette.misc.link,
    cursor: 'pointer',
  },
}));

function SnackbarContentWrapper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { className, message, onClose, variant, action, ...other } = props;

  const handleClick = () => {
    dispatch(onNavToBatchInWTC({ batchWorksightId: action.batchWorksightId }));
  };
  const batchLink = (
    <span className={classes.wtcLink} onClick={handleClick}>
      Go to batch
    </span>
  );

  const Icon = variantIcon[variant];
  let messageContent = null;
  if (_.isArray(message)) {
    messageContent = _.map(message, (content, index) => {
      return (
        <ul key={`message-id-${index}`} className={classes.message}>
          {content}
        </ul>
      );
    });
    messageContent = (
      <div id="client-snackbar" className={classes.messageContent}>
        <Icon className={clsx(classes.icon, classes.iconVariant)} />
        <div className={clsx(classes.messageContent, classes.contentColumn)}>
          {messageContent}
        </div>
      </div>
    );
  } else {
    messageContent = (
      <span id="client-snackbar" className={classes.message}>
        <Icon className={clsx(classes.icon, classes.iconVariant)} />
        {message}
        {action && action.link ? batchLink : ''}
      </span>
    );
  }

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={messageContent}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => onClose()}
          size="large"
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.any,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default function MessageSnack({
  open,
  handleClose,
  message,
  variant = 'warning',
  action,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={variant === 'error' ? null : action ? 5000 : 2000}
      ClickAwayListenerProps={{ onClickAway: () => null }} //disable clickAway to close
    >
      <SnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        message={message ? message : 'Oops, Something went wrong.'}
        action={action}
      />
    </Snackbar>
  );
}
