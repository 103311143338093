import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';

export const style = ({ palette }) => {
  return {
    root: {
      background: palette.error.light,
      color: palette.error.dark,
      lineHeight: '1em',
      margin: 0,
      overflow: 'hidden',
      padding: 0,
    },
  };
};

class ErrorMessage extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    className: undefined,
  };

  render() {
    const { children, classes, className: classNameProp } = this.props;

    const className = clsx(classes.root, classNameProp);

    return <Paper className={className}>{children}</Paper>;
  }
}

export default withStyleSheet('ErrorMessage', style)(ErrorMessage);
