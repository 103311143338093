import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyleSheet } from 'shared/theme';
import clsx from 'clsx';

export const style = ({ palette }) => ({
  root: {
    lineHeight: '1em',
    margin: 0,
    fontWeight: 500,
    padding: 0,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  secondary: {
    color: palette.primary.main,
  },
  warning: {
    color: palette.warning.main,
  },
  primary: {
    color: palette.secondary.light,
  },
  error: {
    color: palette.error.main,
  },
  errorSecondary: {
    color: palette.error.main,
    fontWeight: 400,
  },
});

class Message extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    warning: PropTypes.bool,
    error: PropTypes.bool,
    uppercase: PropTypes.bool,
  };

  static defaultProps = {
    primary: false,
    uppercase: false,
    status: '',
  };

  render() {
    const {
      classes,
      children,
      primary,
      secondary,
      status,
      warning,
      error,
      errorSecondary,
      uppercase,
    } = this.props;

    const className = clsx(classes.root, {
      [classes.primary]: primary || status === 'primary',
      [classes.uppercase]: uppercase,
      [classes.secondary]: secondary || status === 'secondary',
      [classes.warning]: warning || status === 'warning',
      [classes.error]: error || status === 'error',
      [classes.errorSecondary]: errorSecondary,
    });
    const isArray = Array.isArray(children);
    if (!isArray) {
      return <div className={className}>{children}</div>;
    } else {
      return (
        <div>
          {_.map(children, (item, index) => (
            <div key={`message-${index}`} className={className}>
              {item}
            </div>
          ))}
        </div>
      );
    }
  }
}

export default withStyleSheet('Message', style)(Message);
