import { createSelector } from 'reselect';
import { getFormValues } from './formValues';
import _ from 'lodash';
export const getProfileValues = createSelector(
  [getFormValues('ProfileDetails')],
  details => ({
    ...details,
    alternate_id: details.alternateIdMask || '',
    ssn: details.ssnMask || '',
  }),
);

export const getNotificationsLoading = state =>
  _.get(state, 'profile.notificationsLoading', false);

export const getPhoneNumberLoading = state =>
  _.get(state, 'profile.phoneNumberLoading', false);

export const getAllNotifications = state =>
  _.get(state, 'profile.preference.notifications.list', []);

export const getNotificationSelectedFlag = state =>
  _.get(state, 'profile.preference.notifications.selectedAll', '');

export const getAllPhoneNumbers = state =>
  _.get(state, 'profile.phoneNumber', {});

export const getPhoneNumberVerifiedStatus = state =>
  _.get(state, 'profile.phoneNumber.verified', false);

export const getUpdateStatus = state =>
  _.get(state, 'profile.notification.status', '');

export const getNotificationsFormValues = createSelector(
  [getFormValues('Notifications')],
  preferences => {
    return preferences;
  },
);

export const getdefaultAllowancesFormValues = createSelector(
  [getFormValues('AllowanceTypes')],
  values => {
    const lstDefaultAllowances = values;
    return {
      ...lstDefaultAllowances,
    };
  },
);

export const getAllowanceTypes = state =>
  _.get(state, 'profile.allowanceTypes', []);

export const getDefaultAllowances = state =>
  _.get(state, 'profile.defaultAllowances', []);

export const getAllDefaultAllowances = createSelector(
  [getDefaultAllowances, getAllowanceTypes],
  (defaultAllowances, allowanceTypes) => {
    let rows = _.map(defaultAllowances, defaultAllowance => {
      const allowance =
        _.find(
          allowanceTypes,
          a => a.id === defaultAllowance.htgAllowanceTypeId,
        ) || {};
      return {
        ...defaultAllowance,
        canEdit: false,
        htgAllowanceTypeName: allowance.description,
      };
    });
    rows.push({
      amount: '',
      description: '',
      employeeMandatory: null,
      employeeVisible: null,
      enabled: '',
      filename: '',
      htgAllowanceTypeId: '',
      htgAllowanceTypeName: '',
      isDefaultAllowance: '',
      s3Path: '',
      token: '',
      type: '',
      canEdit: true,
    });
    return rows;
  },
);

export const getAllowanceTypesLoading = state =>
  _.get(state, 'profile.allowanceTypesLoading', false);

export const getDefaultAllowancesLoading = state =>
  _.get(state, 'profile.defaultAllowancesLoading', false);

export const getEditingDefaultAllowance = state =>
  _.get(state, 'profile.defaultAllowanceId', 0);

export const downloadingSupportingDocument = state =>
  _.get(state, 'profile.downloadingSupportingDocument', false);
