import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';
import SearchBar from 'components/Shared/SearchBar';
// import AppBar from './AppBar';
import FadeInScroll from 'components/Shared/FadeInScroll';

export const style = ({ palette, breakpoints }) => ({
  root: {
    flexGrow: 1,
    width: '100vw',
    color: palette.getDefaultColor(),
    boxShadow: '2px 2px 2px gray',
  },
  navSection: {
    textAlign: 'right',
    [breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  toolBar: {
    minHeight: 48,
    width: '100vw',
    backgroundColor: palette.background.default,
  },
  searchBar: {
    flexGrow: 1,
    borderBottom: `2px solid ${palette.gray['+6']}`,
    width: '80%',
  },
  searchBarBorder: {
    borderBottom: `2px solid ${palette.info.main}`,
  },

  [breakpoints.down('md')]: {
    root: {
      '&:first-child': {
        paddingTop: 8,
      },
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  [breakpoints.down('sm')]: {
    root: {
      background: palette.background.default,
    },
    toolBar: {
      paddingBottom: '24px',
    },
    searchBar: {
      background: palette.background.default,
      marginTop: '12px',
    },
  },
});

class StickyHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    showSearch: PropTypes.bool,
    onFocus: PropTypes.bool,
    onApplyFiltering: PropTypes.func,
    searchBarPlaceHolder: PropTypes.string,
    navItems: PropTypes.arrayOf(PropTypes.node),
  };

  static defaultProps = {
    navItems: [],
    onApplyFiltering: undefined,
    showSearch: false,
    searchBarPlaceHolder: 'Search...',
  };

  state = {
    focus: false,
  };

  onFilter(event) {
    this.props.onApplyFiltering(event.target.value || '');
  }

  onFieldBlur(event) {
    this.setState({ focus: false });
  }
  onFieldFocus(event) {
    this.setState({ focus: true });
  }

  render() {
    const { classes, searchBarPlaceHolder } = this.props;

    return (
      <FadeInScroll customThreshold={260}>
        <Toolbar className={classes.toolBar}>
          <Hidden smUp>
            <Grid
              container
              className={classes.root}
              alignItems="flex-start"
              direction="column"
              justifyContent="space-between"
            >
              <Grid
                item
                xs={12}
                sm={12}
                className={clsx(classes.searchBar, {
                  [classes.searchBarBorder]: this.state.focus,
                })}
              >
                <SearchBar
                  onFilter={this.onFilter.bind(this)}
                  placeholder={searchBarPlaceHolder}
                  onFieldBlur={this.onFieldBlur.bind(this)}
                  onFieldFocus={this.onFieldFocus.bind(this)}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </FadeInScroll>
    );
  }
}

export default withStyleSheet('StickyHeader', style)(StickyHeader);
