import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Switch } from 'react-router-dom';
import withAuthProtection from 'decorators/withAuthProtection';
import Routes from './Routes';
import AppReady from 'containers/RoutingSupport/AppReady';
import { MyCnCHeader as HeaderV2 } from '@castandcrew/global-navbar';
import apiServer from 'constants/config/apiServer';

import { getUseNewNavBar } from 'selectors/flags';
import { logout } from 'actions/authenticate';

const mapState = state => ({
  //TODO HOUR-11661
  useNewNavBar: getUseNewNavBar(state),
});

const mapDispatch = dispatch => ({
  onLogout: () => {
    dispatch(logout());
  },
});

/*
 * Load Routes Helper
 */
function loadRoutes(routes) {
  let routeComponents = [];
  Object.keys(routes).forEach(key => {
    if (typeof routes[key] === 'function') {
      routeComponents.push(routes[key]());
    } else {
      routeComponents = routeComponents.concat(loadRoutes(routes[key]));
    }
  });

  return routeComponents;
}

/*
 * Routes
 */
const routes = <Switch>{loadRoutes(Routes)}</Switch>;

/*
 * Router declaration
 */
const App = ({ history, onLogout, useNewNavBar }) => (
  <AppReady>
    {useNewNavBar && (
      <HeaderV2 configs={apiServer.dashboardConfig} onLogout={onLogout} />
    )}
    <ConnectedRouter history={history}>{routes}</ConnectedRouter>
  </AppReady>
);

App.propTypes = {
  history: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  useNewNavBar: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withAuthProtection(),
)(App);
