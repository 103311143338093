import React, { useRef, useEffect } from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';

import {
  Button,
  ButtonGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';

import { ChatBubbleOutlined, Schedule } from '@mui/icons-material';
import AccountCodesBtn from '@mui/icons-material/FormatListNumbered';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';

import { AutoComplete } from 'components/Shared/AutoComplete';
import {
  formatDate,
  calculateDayHours,
  doesDayAllowTimes,
  TIME_FIELD_ORDER,
} from 'utils/weekUtils';
import TimeValidator from 'utils/TimeValidator';

import { NON_TIME_DAY_TYPES } from 'components/Shared/constants/index';

import makeStyles from '@mui/styles/makeStyles';
import { TimeCombo } from 'components/Shared/TimeCombo';
import clsx from 'clsx';
import AccountCodes from '../AccountCodes';
import Note from '../Note';
import { isFieldEnabled } from 'utils/weekUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    overflow: 'visible',
  },
  tableCell: {
    paddingRight: 5,
    verticalAlign: 'top',
    marginBottom: 15,
  },
  firstCell: {
    verticalAlign: 'top',
  },
  panel: {
    margin: 0,
    boxShadow: 'none',
    borderBottom: `1px solid ${palette.gray['+6']}`,
  },
  hoursCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
  },
  noPad: {
    padding: 0,
  },
  noBorder: {
    border: 0,
  },
  evenRow: {
    backgroundColor: palette.primary.background,
  },
  icon: {
    fontSize: 16,
    marginRight: 5,
  },
  optionalText: {
    color: palette.text.disabled,
    fontSize: 12,
  },
}));

export default function WeekDay(props) {
  const {
    day,
    dayTypes: incomingDayTypes = [],
    editableFields,
    even,
    dayError,
    firstDay,
    meals,
    member,
    onCopyToAll,
    showAccountCodes = false,
    stateOptions,
    cityOptions,
    onFetchStates,
    onFetchCities,
    onResetLoc,
    timecard,
    viewOnly = false,
    onSaveNote,
    loadDayTypes,
    change,
    workLocations = [],
    setNoteEmpty,
    showLastMan1In,
    isUserDeleted,
    stateLabel,
    isCityVisible,
    caRegion,
    showNdm,
    showNdb,
    index,
    project,
  } = props;

  const dayTypes = incomingDayTypes.slice();

  const classes = useStyles();

  const editableTimes = isFieldEnabled(editableFields, 'times');
  const editableLocation = isFieldEnabled(editableFields, 'location');
  const editableAccountCodes = isFieldEnabled(editableFields, 'account-codes');

  const dayTypeCode = day?.dayType?.code;
  const allowsTimes = doesDayAllowTimes(dayTypeCode);
  const isPaid = timecard?.status === 'processed';

  const timeValidator = new TimeValidator(timecard?.dealMemo?.roundTo);

  const disableTimes =
    viewOnly || !editableTimes || !allowsTimes || !day.htgDayTypeId;

  const disableAccountCodes = !editableAccountCodes;
  const mealRows = [];

  //populate on dayType selection
  const prevDayTypeCode = useRef(dayTypeCode);
  useEffect(() => {
    if (!!dayTypeCode && !prevDayTypeCode.current) {
      if (!day.htgStateId && !day.htgCityId) {
        if (timecard.htgStateId && timecard.state) {
          change(`${member}.htgStateId`, timecard.htgStateId);
          change(`${member}.state`, timecard.state);
          change(`${member}.htgCityId`, timecard.htgCityId);
          change(`${member}.city`, timecard.city);
          change(`${member}.htgCountyId`, timecard.htgCountyId);
          change(`${member}.workCounty`, timecard.county);
          change(`${member}.htgSubdivisionId`, timecard.htgSubdivisionId);
          change(`${member}.workSubdivision`, timecard.subdivision);
        }
      }
    }

    prevDayTypeCode.current = dayTypeCode;
  }, [change, day, dayTypeCode, member, timecard]);

  const showAccountCodeSection = showAccountCodes;

  const rowClass = clsx(classes.root, { [classes.evenRow]: even });
  const firstRowCell = clsx(classes.tableCell, {
    [classes.noBorder]: showAccountCodeSection,
  });
  const hoursCellClx = clsx(classes.hoursCell, {
    [classes.noBorder]: showAccountCodeSection,
  });

  if (dayTypes.length === 0) dayTypes.push({});

  const getErrorText = field => {
    return dayError && dayError[field] ? _.startCase(dayError[field]) : null;
  };

  // convertTimes(day);

  function handleChangeState(newValue) {
    change(`${member}.htgStateId`, newValue && newValue.id);
    change(`${member}.htgCityId`, null);
    change(`${member}.city`, null);
    change(`${member}.htgCountyId`, null);
    change(`${member}.workCounty`, null);
    change(`${member}.htgSubdivisionId`, null);
    change(`${member}.workSubdivision`, null);
  }

  function handleChangeCity(newValue) {
    change(`${member}.htgCityId`, newValue && newValue.id);
  }

  function loadStatesOnOpen() {
    const countryId = day?.htgCountryId;
    onFetchStates({ countryId });
  }

  function loadCitiesOnOpen() {
    const stateId = day?.htgStateId;
    if (stateId) onFetchCities({ stateId });
  }

  if (showNdb) {
    const inKey = 'ndbIn';
    const inLabel = caRegion ? 'General Call' : 'NDB In';

    //ndb
    mealRows.push(
      <Grid item xs={12} key={inKey} className={firstRowCell}>
        <TimeCombo
          name={`${member}.${inKey}`}
          placeholder={`${inLabel}`}
          disabled={disableTimes}
          value={day[`${inKey}`]}
          viewOnly={viewOnly}
          helperText={getErrorText(inKey)}
          error={getErrorText(inKey) !== null}
          change={change}
          timeValidator={timeValidator}
        />
      </Grid>,
    );
  }

  _.times(meals || 1, index => {
    const outKey = `meal${index + 1}Out`;
    const outLabel = `Meal${index + 1} Out`;
    const inKey = `meal${index + 1}In`;
    const inLabel = `Meal${index + 1} In`;

    mealRows.push(
      <Grid item xs={12} key={outKey} className={firstRowCell}>
        <TimeCombo
          name={`${member}.${outKey}`}
          placeholder={`${outLabel}`}
          disabled={disableTimes}
          value={day[`${outKey}`]}
          viewOnly={viewOnly}
          helperText={getErrorText(outKey)}
          error={getErrorText(outKey) !== null}
          change={change}
          timeValidator={timeValidator}
        />
      </Grid>,
    );

    mealRows.push(
      <Grid item xs={12} key={inKey} className={firstRowCell}>
        <TimeCombo
          name={`${member}.${inKey}`}
          placeholder={`${inLabel}`}
          disabled={disableTimes}
          value={day[`${inKey}`]}
          viewOnly={viewOnly}
          helperText={getErrorText(inKey)}
          error={getErrorText(inKey) !== null}
          change={change}
          timeValidator={timeValidator}
        />
      </Grid>,
    );

    if (showLastMan1In && index === 0) {
      mealRows.push(
        <Grid item xs={12} key={'lastMan1In'} className={firstRowCell}>
          <TimeCombo
            name={`${member}.lastMan1In`}
            placeholder={`Last Man In`}
            disabled={disableTimes}
            value={day[`lastMan1In`]}
            viewOnly={viewOnly}
            helperText={getErrorText('lastMan1In')}
            error={getErrorText('lastMan1In') !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </Grid>,
      );
    }

    if (showNdm && index === 0) {
      const outKey = `ndmOut`;
      const outLabel = `NDM Out`;
      const inKey = `ndmIn`;
      const inLabel = `NDM In`;

      mealRows.push(
        <Grid item xs={12} key={outKey} className={firstRowCell}>
          <TimeCombo
            name={`${member}.${outKey}`}
            placeholder={`${outLabel}`}
            disabled={disableTimes}
            value={day[`${outKey}`]}
            viewOnly={viewOnly}
            helperText={getErrorText(outKey)}
            error={getErrorText(outKey) !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </Grid>,
      );

      mealRows.push(
        <Grid item xs={12} key={inKey} className={firstRowCell}>
          <TimeCombo
            name={`${member}.${inKey}`}
            placeholder={`${inLabel}`}
            disabled={disableTimes}
            value={day[`${inKey}`]}
            viewOnly={viewOnly}
            helperText={getErrorText(inKey)}
            error={getErrorText(inKey) !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </Grid>,
      );
    }
  });

  // calculate total day Hours with rounded feature
  let totalDayHours = calculateDayHours(day);
  if (totalDayHours) {
    totalDayHours = +parseFloat(totalDayHours).toFixed(2);
  } else if (totalDayHours !== 0) {
    totalDayHours = '--';
  }

  function handleChangeDayTypes(id) {
    const result = dayTypes.find(d => d.id === id);

    change(`${member}.dayType.id`, !result ? '' : result.id);
    change(`${member}.dayType.code`, !result ? '' : result.code);
    change(`${member}.dayType.name`, !result ? '' : result.name);

    const isNoTimeDayType = NON_TIME_DAY_TYPES.find(d => d.id === id);
    if (!!isNoTimeDayType) {
      for (let i = 0; i <= TIME_FIELD_ORDER.length; i++) {
        change(`${member}.${TIME_FIELD_ORDER[i]}`, null);
      }
    }
  }

  function handleChangeWorkLocation(id) {
    const result = workLocations.find(d => d.id === id);
    change(`${member}.locationType.id`, !result ? null : result.id);
    change(`${member}.locationType.code`, !result ? null : result.code);
    change(`${member}.locationType.name`, !result ? null : result.name);
  }

  //Add dayType to options if it not there already:
  if (day && day.dayType) {
    const result = _.find(dayTypes, dt => dt.id === day.dayType.id);
    if (!result) {
      dayTypes.push(day.dayType);
    }
  }

  return (
    <React.Fragment>
      <Accordion
        square
        className={`PENDO_timecard_day_${index} ${classes.panel}`}
        key={`${day.date}-1`}
      >
        <AccordionSummary
          className={classes.firstCell}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container spacing={3}>
            <Grid item xs={5} className={firstRowCell}>
              <Field
                component="input"
                name={`${member}.id`}
                type="hidden"
                value={day.id}
              />
              <Typography variant="subtitle2" color="inherit">
                <b>{formatDate(day.date, 'D MMMM')}</b>
              </Typography>
              <Typography variant="subtitle2">
                {formatDate(day.date, 'dddd')}
              </Typography>
            </Grid>
            <Grid item xs={6} className={hoursCellClx}>
              <Schedule className={classes.icon} />
              <span>{totalDayHours}</span>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container spacing={3}>
            {!caRegion && (
              <Grid item xs={12} className={firstRowCell}>
                {viewOnly && (
                  <Typography>
                    {(day.locationType && day.locationType.name) || ''}
                  </Typography>
                )}
                {!viewOnly && (
                  <AutoComplete
                    list={props.workLocations}
                    name={`${member}.htgLocationTypeId`}
                    placeholder="Work Location (Optional)"
                    fieldLabel="name"
                    fieldValue="id"
                    helperText={getErrorText('htgLocationTypeId')}
                    error={getErrorText('htgLocationTypeId') !== null}
                    disabled={!editableLocation}
                    required
                    onChange={handleChangeWorkLocation}
                  />
                )}
                <Typography className={classes.optionalText}>
                  Your Payroll Accountant can select this option for you.
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} className={firstRowCell}>
              {viewOnly && (
                <Typography>
                  {(day.dayType && day.dayType.name) || ''}
                </Typography>
              )}
              {!viewOnly && (
                <AutoComplete
                  list={dayTypes}
                  name={`${member}.htgDayTypeId`}
                  placeholder="Day Type (Required)"
                  fieldLabel="name"
                  fieldValue="id"
                  helperText={getErrorText('htgDayTypeId')}
                  error={getErrorText('htgDayTypeId') !== null}
                  disabled={viewOnly || !editableTimes}
                  required
                  async
                  loadOptions={loadDayTypes}
                  onChange={handleChangeDayTypes}
                />
              )}
            </Grid>
            <Grid item xs={12} className={firstRowCell}>
              {viewOnly && (
                <Typography>{(day.state && day.state.name) || ''}</Typography>
              )}
              {!viewOnly && (
                <AutoComplete
                  list={stateOptions}
                  name={`${member}.state`}
                  placeholder={stateLabel}
                  fieldLabel="name"
                  fieldValue="id"
                  disabled={disableTimes}
                  helperText={getErrorText('htgStateId')}
                  error={getErrorText('htgStateId') !== null}
                  required
                  defaultSort
                  objectSelect
                  onChange={handleChangeState}
                  onMenuOpen={loadStatesOnOpen}
                  onMenuClose={() => onResetLoc('workState')}
                  showLoadingMsg
                />
              )}
            </Grid>
            {isCityVisible && (
              <Grid item xs={12} className={firstRowCell}>
                {viewOnly && (
                  <Typography>{(day.city && day.city.name) || ''}</Typography>
                )}
                {!viewOnly && (
                  <AutoComplete
                    list={cityOptions}
                    name={`${member}.city`}
                    placeholder="Work City"
                    fieldLabel="name"
                    fieldValue="id"
                    disabled={disableTimes}
                    helperText={getErrorText('htgCityId')}
                    error={getErrorText('htgCityId') !== null}
                    required
                    defaultSort
                    objectSelect
                    onMenuOpen={loadCitiesOnOpen}
                    onChange={handleChangeCity}
                    onMenuClose={() => onResetLoc('workCity')}
                    showLoadingMsg
                  />
                )}
              </Grid>
            )}
            <Grid item xs={12} className={firstRowCell}>
              <TimeCombo
                name={`${member}.call`}
                placeholder="Call"
                disabled={disableTimes}
                value={day.call}
                viewOnly={viewOnly}
                helperText={getErrorText('call')}
                error={getErrorText('call') !== null}
                change={change}
                timeValidator={timeValidator}
              />
            </Grid>
            {mealRows}
            <Grid item xs={12} className={firstRowCell}>
              <TimeCombo
                name={`${member}.wrap`}
                placeholder="Wrap"
                disabled={disableTimes}
                value={day.wrap}
                viewOnly={viewOnly}
                helperText={getErrorText('wrap')}
                error={getErrorText('wrap') !== null}
                change={change}
                timeValidator={timeValidator}
              />
            </Grid>
            <Grid item xs={12} className={hoursCellClx}>
              {firstDay && !viewOnly && (
                <ButtonGroup
                  fullWidth
                  color="primary"
                  aria-label="full width  utlined primary button group"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => onCopyToAll(day.date)}
                  >
                    <CopyIcon className={classes.icon} /> Copy to all days
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
            {!isUserDeleted && !isPaid && (
              <Grid item xs={12} className={firstRowCell}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Show Comments"
                  >
                    <ChatBubbleOutlined /> Comments
                  </AccordionSummary>
                  <AccordionDetails>
                    <Note
                      label='Enter Day Note. For Example: "I had an NDB at 6am"'
                      name={`note-${day.date}`}
                      onSave={val =>
                        onSaveNote(val, 'day', timecard.id, day.date)
                      }
                      setNoteEmpty={setNoteEmpty}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

            <Grid item xs={12} className={firstRowCell}>
              <Accordion defaultExpanded={showAccountCodeSection}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Show Account Codes"
                >
                  <AccountCodesBtn /> Account Codes
                </AccordionSummary>
                <AccordionDetails>
                  <AccountCodes
                    member={member}
                    timecard={timecard}
                    rowClass={rowClass}
                    disabled={disableTimes}
                    viewOnly={viewOnly}
                    disableAccountCodes={disableAccountCodes}
                    dayError={dayError}
                    project={project}
                    {...props}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
