import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button } from '@mui/material';

// components
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import InviteCrewRow from './InviteCrewRow';

// decorators
import withModalDialog from 'decorators/withModalDialog';
import { withStyleSheet } from 'shared/theme';

// actions
import { hide as hideModal } from 'actions/modalDialog';
import { sendInvites } from '../actions';

// utilities
import {
  formatMessage,
  NAME_INVALID,
  REQUIRED,
  INVALID_EMAIL,
  NOT_UNIQUE,
} from 'utils/messages';
import validator from 'utils/validator';
import { emailExist, emailUnique } from '../CrewListUtils';

// selector
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { currentUser } from 'selectors/session';

import { getModalParams } from 'selectors/modalDialog';
import { getUsers, getSortedDepartments } from '../selectors';

const style = ({ palette }) => ({
  content: {
    display: 'flex',
  },
});

const modalDialog = 'NonCrewInvite';
const mapState = state => {
  const modalParams = getModalParams(state, modalDialog);
  const hasSelected = modalParams?.selected?.length > 0;

  return {
    formValues: getFormValues(modalDialog)(state),
    users: getFormValues(modalDialog)(state),
    initialValues: {
      users: getUsers(state),
      invites: hasSelected ? modalParams.selected : [{}],
    },
    hasSelected: !!hasSelected,
    departments: getSortedDepartments(state),
    currentUser: currentUser(state),
    errors: getFormSyncErrors(modalDialog)(state),
  };
};

const onSubmit = (values, dispatch) => {
  dispatch(sendInvites());
};

const validate = values => {
  const errors = { valid: true };
  const inviteErrors = [];
  let inviteLength = values?.invites?.length || [].length;

  (values.invites || []).forEach((invite, index) => {
    const inviteError = { valid: true };
    if (_.isEmpty(invite) && index + 1 === inviteLength) {
      return null;
    }
    // email
    if (!invite.email) {
      inviteError.email = formatMessage(REQUIRED);
      inviteError.valid = false;
    } else if (!validator.isEmail(invite.email)) {
      inviteError.valid = false;
      inviteError.email = formatMessage(INVALID_EMAIL);
    } else if (emailExist(values.users, invite)) {
      inviteError.valid = false;
      inviteError.email = formatMessage(NOT_UNIQUE);
    } else if (!emailUnique(values.invites, invite)) {
      inviteError.valid = false;
      inviteError.email = formatMessage(NOT_UNIQUE);
    }
    // name
    if (
      'name' in invite &&
      !('firstName' in invite) &&
      !('lastName' in invite)
    ) {
      if (!invite.name) {
        inviteError.valid = false;
        inviteError.name = formatMessage(REQUIRED);
      } else if (!validator.isAlphaSpaceOnly(invite.name)) {
        inviteError.valid = false;
        inviteError.name = formatMessage(NAME_INVALID);
      }
    } else {
      if (!invite.firstName) {
        inviteError.valid = false;
        inviteError.firstName = formatMessage(REQUIRED);
      } else if (!validator.isAlphaSpaceOnly(invite.firstName)) {
        inviteError.valid = false;
        inviteError.firstName = formatMessage(NAME_INVALID);
      }

      if (!invite.lastName) {
        inviteError.valid = false;
        inviteError.lastName = formatMessage(REQUIRED);
      } else if (!validator.isAlphaSpaceOnly(invite.lastName)) {
        inviteError.valid = false;
        inviteError.lastName = formatMessage(NAME_INVALID);
      }
    }
    if (!inviteError.valid) {
      inviteErrors[index] = inviteError;
    }
  });

  if (inviteErrors.length) {
    errors.valid = false;
    errors.invites = inviteErrors;
  }
  return errors;
};
const renderRows = ({
  fields,
  errors = {},
  change,
  formValues,
  hasSelected,
  ...others
}) => {
  if (fields.length === 0) {
    fields.push({});
  }
  const disableRemove = (fields && fields.length <= 1) || false;
  return fields.map((member, index) => {
    const invitesError = (errors && errors.invites) || [];
    const inviteError = invitesError[index];
    return (
      <InviteCrewRow
        // eslint-disable-next-line react/no-array-index-key
        key={index} //HOUR-8905
        index={index}
        invite={member}
        addNext={() => fields.push({})}
        rowValues={_.get(formValues, member)}
        fields={fields}
        removeUser={() => fields.remove(index)}
        disableRemove={disableRemove}
        canAddNext={!hasSelected && (fields.length === index + 1 || false)}
        canDelete={fields.length !== 1}
        inviteError={inviteError}
        change={change}
        hasSelected={hasSelected}
        {...others}
      />
    );
  });
};

const InviteCrew = props => {
  const {
    errors,
    handleSubmit,
    formValues,
    change,
    valid,
    dirty,
    hasSelected,
    departments,
    submitting,
    // ...others
  } = props;
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (hasSelected) {
      if (valid) setSubmitDisabled(false);
      else setSubmitDisabled(true);
    } else if (!hasSelected) {
      if (valid && dirty) setSubmitDisabled(false);
      if ((!valid && dirty) || (valid && !dirty) || (!valid && !dirty)) {
        setSubmitDisabled(true);
      }
    }
  }, [valid, dirty, setSubmitDisabled, hasSelected]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ModalTitle>
          {hasSelected ? 'Invite new user' : 'Invite new non-employees'}
        </ModalTitle>
        <ModalContent>
          <FieldArray
            name="invites"
            component={renderRows}
            errors={errors}
            change={change}
            hasSelected={hasSelected}
            formValues={formValues}
            departments={departments}
          />
        </ModalContent>
        <ModalFooter>
          <Button
            variant="outlined"
            color="primary"
            name="cancel"
            onClick={() => {
              dispatch(hideModal({ dialog: modalDialog }));
            }}
            disabled={submitting}
          >
            {'Cancel'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => {}}
            disabled={submitDisabled || submitting}
          >
            {'Send Invite'}
          </Button>
        </ModalFooter>
      </form>
    </div>
  );
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: modalDialog, maxWidth: 'lg' }),
  connect(mapState),
  reduxForm({
    form: modalDialog,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit,
    validate,
    touchOnChange: true,
    touchOnBlur: false,
  }),
)(InviteCrew);
