import produce from 'immer';
import * as actions from 'actions/projectAllowances';
import _ from 'lodash';

const initialState = {
  list: [],
  loading: false,
  dayTypes: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;
      case `${actions.store}`:
        draft.list = _.sortBy(
          action.projectAllowances,
          item => item.description && item.description.toLowerCase(),
        );
        break;
      case `${actions.edit}`:
        let employeeOptions = draft.list.find(item => item.id === action.id);
        if (action.field === 'employeeVisible') {
          if (action.value === false) {
            employeeOptions.employeeVisible = action.value;
            employeeOptions.employeeMandatory = action.value;
          } else {
            employeeOptions.employeeVisible = action.value;
          }
        } else if (action.field === 'employeeMandatory') {
          employeeOptions.employeeMandatory = action.value;
        } else if (action.field === 'dayTypes' && action.name) {
          const checked = action.value;
          const dypeTypeId = action.name;
          const dayType = draft.dayTypes.find(item => item.id === dypeTypeId);
          if (!employeeOptions['dayTypes']) {
            employeeOptions['dayTypes'] = [];
          }
          if (checked) {
            employeeOptions['dayTypes'].push(dayType);
          } else {
            employeeOptions['dayTypes'] = employeeOptions['dayTypes'].filter(
              item => item.id !== dypeTypeId,
            );
          }
        }
        break;
      case `${actions.sort}`:
        let projectAllowances = draft.list;
        if (action.sortOrder === 'asc') {
          projectAllowances = !action.sortBoolean
            ? _.sortBy(
                projectAllowances,
                item =>
                  item[action.sortKey] && item[action.sortKey].toLowerCase(),
              )
            : projectAllowances.sort(
                (prev, next) => next[action.sortKey] - prev[action.sortKey],
              );
          draft.list = projectAllowances;
        }
        if (action.sortOrder === 'desc') {
          projectAllowances = !action.sortBoolean
            ? _.sortBy(
                projectAllowances,
                item =>
                  item[action.sortKey] && item[action.sortKey].toLowerCase(),
              ).reverse()
            : projectAllowances.sort(
                (prev, next) => prev[action.sortKey] - next[action.sortKey],
              );
          draft.list = projectAllowances;
        }
        break;
      case `${actions.reset}`:
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;

      case `${actions.storeDayTypes}`:
        draft.dayTypes = action.dayTypes;
        break;
      default:
    }
  });
