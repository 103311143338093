import produce from 'immer';
import * as actions from 'actions/userInfo';

const initialState = {
  loading: false,
  activeUser: {},
  permissions: {},
  worksightId: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.store}`:
        draft.activeUser[action.projectId] = action.activeUser;
        break;

      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.storePermissions}`:
        draft.permissions[action.projectId] = action.permissions;
        break;
      case `${actions.storeWorksightId}`:
        draft.worksightId = action.worksightId;
        break;
      default:
    }
  });
