import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  reduxForm,
  isDirty,
  reset,
  getFormSyncErrors,
  getFormSyncWarnings,
} from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Button, LinearProgress, Paper, Typography } from '@mui/material';
//actions
import * as actions from 'actions/timecards';
import { loadApprovalFlow, reviewApprovalFlow } from 'actions/reviews';
import {
  fetchCountriesV1,
  fetchStatesV1,
  fetchCitiesV1,
  fetchCountiesV1,
  fetchSubdivisionsV1,
  storeStatesV1,
  storeCitiesV1,
  storeCountiesV1,
} from 'actions/location';
import { showAlert } from 'actions/alert';
import { fetchPayrollSettings } from 'actions/settings';
import { show as showModal } from 'actions/modalDialog';
import { fetch as fetchProjectAllowances } from 'actions/projectAllowances';

// selectors
import { currentUser } from 'selectors/session';
import * as timecardCommon from 'selectors/timecard/common';
import {
  getTimecard,
  getInitialValues,
  getTimecardAllowances,
  getAllowanceInput,
} from 'selectors/timecard/form';
import { getProject } from 'selectors/routeParams';
import { getProjectDetails } from 'selectors/project';
import { getStateOptions, getCityOptions } from 'selectors/wtc';
import { getSettings, getProjectPayrollSettings } from 'selectors/settings';

// utils
import moment from 'utils/moment';
import {
  timecardValidate as validate,
  warnForDHReject as warn,
} from 'utils/weekUtils';

// components
import { departmentHeadUserProps } from 'components/props/profiles';
import Timecard from 'components/Employees/Timecards/Timecard';
import { projectProps } from 'components/props/projects';
import { timecardProps } from 'components/props/timecard';
import ConfirmTakeMeBack from 'containers/Employees/Timecards/Modals/ConfirmTakeMeBack';
import { DH } from 'components/props/profiles';
import DeleteTimecardAllowance from 'containers/Employees/Timecards/Modals/DeleteTimecardAllowance';
import RejectTimecard from './Reviewer/RejectTimecard';

const formName = 'timecard';

const onSubmit = (values, dispatch) => {
  dispatch(actions.setCurrentTimecard({ timecardId: values.id }));
  dispatch(actions.onSave({ timecard: values }));
};

const mapState = (state, { location }) => {
  const fromURL = location.pathname || '';
  const isViewOnly = fromURL.indexOf('/review') >= 0;
  const searchString = location.search ? location.search : '';
  const fromHistory = searchString.indexOf('fromHistory=true') >= 0;
  const timecardId = timecardCommon.getCurrentTimecard(state);
  const initialValues = getInitialValues(state, timecardId);

  return {
    activeUser: currentUser(state),
    allowances: getTimecardAllowances(state, timecardId, false),
    allowanceInput: getAllowanceInput(state),
    dayTypes: timecardCommon.getDayTypes(state),
    enableESignature: timecardCommon.getESignatureStatus(state),
    episodes: timecardCommon.getEpisodes(state),
    fromHistory: fromHistory,
    fromURI: timecardCommon.getFromURI(state),
    initialValues,
    isAllowanceProcessing: timecardCommon.isAllowanceProcessing(state),
    isNotesProcessing: timecardCommon.isNotesProcessing(state),
    isViewOnly: isViewOnly,
    loading: timecardCommon.getLoadingDetails(state),
    payrollSettings: getProjectPayrollSettings(state),
    project: getProjectDetails(state),
    projectId: getProject(state),
    projectSettings: getSettings(state),
    savingTimecard: timecardCommon.isSavingTimecard(state),
    cityOptions: getCityOptions(state),
    stateOptions: getStateOptions(state),
    timecard: getTimecard(state, timecardId, formName),
    timecardAllowances: timecardCommon.parsedAllowancePayCodes(state),
    timecardId,
    workLocations: timecardCommon.getWorkLocations(state),
    isTimecardDirty: isDirty(formName)(state),
    formSyncErrors: getFormSyncErrors(formName)(state),
    formSyncWarnings: getFormSyncWarnings(formName)(state),
  };
};

const mapDispatch = (dispatch, { match }) => {
  const approvalFlowId = match.params.approvalFlowId;

  return {
    onFetchData: () => {
      dispatch(actions.initTimecard());
      dispatch(actions.loadingDetails({ loading: true }));
      dispatch(loadApprovalFlow({ approvalFlowId }));
      dispatch(fetchProjectAllowances());
      dispatch(fetchPayrollSettings());
    },
    onSaveNote: (note, type, timecardId, referenceDate) => {
      const value = { note, type, timecardId, referenceDate };
      dispatch(actions.saveTimeCardNotes({ value }));
    },
    onShowDirtyAlert: () =>
      dispatch(showModal({ dialog: 'TakeMeBack', maxWidth: 'md' })),

    onTakeMeBackAction: () => dispatch(actions.onTakeMeBack()),

    onRejectTimecard: () => {
      dispatch(showModal({ dialog: 'RejectTimecard', maxWidth: 'md' }));
    },

    onApproveTimecard: (
      timecardId,
      timecardEntryHeaderId,
      isTimecardDirty = false,
    ) => {
      dispatch(actions.setCurrentTimecard({ timecardId }));

      dispatch(
        reviewApprovalFlow({
          status: 'approved',
          comment: '',
          formName,
          timecardEntryHeaderId,
          isTimecardDirty,
        }),
      );
    },
    onDownloadDocument: (file, token) => {
      dispatch(actions.downloadSupportingDocument({ token, file }));
    },
    onSaveAllowance: (index, allowance, timecardId) => {
      dispatch(actions.saveTimecardAllowance({ index, allowance, timecardId }));
    },
    onSelectAllowanceType: ({ index, timecardId, allowanceType }) => {
      dispatch(
        actions.selectAllowanceType({ index, timecardId, allowanceType }),
      );
    },
    onSelectAllowanceAmount: ({ index, timecardId, amount }) => {
      dispatch(actions.selectAllowanceAmount({ index, timecardId, amount }));
    },
    onSelectAllowanceRate: ({ index, timecardId, rate }) => {
      dispatch(actions.selectAllowanceRate({ index, timecardId, rate }));
    },
    onSelectAllowanceUnits: ({ index, timecardId, units }) => {
      dispatch(actions.selectAllowanceUnits({ index, timecardId, units }));
    },
    onSelectAllowanceCombineCheckCode: ({
      index,
      timecardId,
      combineCheckCode,
    }) => {
      dispatch(
        actions.selectAllowanceCombineCheckCode({
          index,
          timecardId,
          combineCheckCode,
        }),
      );
    },
    onUploadDocument: ({ index, timecardId, document }) => {
      dispatch(
        actions.uploadAllowanceDocument({ index, timecardId, document }),
      );
    },
    onResetAllowance: ({ index, timecardId }) => {
      dispatch(
        actions.resetAllowanceInput({
          index,
          timecardId,
        }),
      );
    },
    onDeleteTimecardAllowance: () => {
      dispatch(showModal({ dialog: 'DeleteTimecardAllowance' }));
    },
    onClearCurrentTC: () => {
      dispatch(actions.clearCurrentTimecard());
    },
    onFetchCountries: () => dispatch(fetchCountriesV1()),
    onFetchStates: args => dispatch(fetchStatesV1(args)),
    onFetchCities: args => dispatch(fetchCitiesV1(args)),
    onFetchCounties: args => dispatch(fetchCountiesV1(args)),
    onFetchSubdivisions: args => dispatch(fetchSubdivisionsV1(args)),
    onShowAlert: args => {
      dispatch(showAlert(args));
    },
    onResetLoc: variant => {
      switch (variant) {
        case 'workState':
          dispatch(storeStatesV1({ states: [] }));
          break;
        case 'workCity':
          dispatch(storeCitiesV1({ cities: [] }));
          break;
        case 'workCounty':
          dispatch(storeCountiesV1({ counties: [] }));
          break;

        default:
          break;
      }
    },
  };
};

/**
 * Timecard page for timecards that are in status `ready_for_dh_review` only.
 * Only expecting DH to use this component
 */
class ReviewTimecard extends Component {
  static propTypes = {
    activeUser: departmentHeadUserProps,
    editableFields: PropTypes.object,
    location: PropTypes.object.isRequired,
    project: projectProps.isRequired,
    projectId: PropTypes.string.isRequired,
    timecard: timecardProps.isRequired,
    timecardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onFetchData: PropTypes.func.isRequired,
    onShowDirtyAlert: PropTypes.func.isRequired,
    onTakeMeBackAction: PropTypes.func.isRequired,
    onRejectTimecard: PropTypes.func.isRequired,
    onApproveTimecard: PropTypes.func.isRequired,
    onDeleteTimecardAllowance: PropTypes.func.isRequired,
    onClearCurrentTC: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.object,
    formSyncErrors: PropTypes.object.isRequired,
    formSyncWarnings: PropTypes.object.isRequired,
    isTimecardDirty: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    savingTimecard: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editReview: false,
      allowanceId: null,
      allowanceInProgress: false,
      areNotesEmpty: true,
      notesEmpty: {},
    };
    this.setNoteEmpty = this.setNoteEmpty.bind(this);
    this.setAllowanceInProgress = this.setAllowanceInProgress.bind(this);

    this.onToggleEdit.bind(this);
  }

  componentDidMount() {
    this.props.onFetchData();
  }

  componentWillUnmount() {
    this.props.onClearCurrentTC();
  }

  onDeleteAllowance(id) {
    this.setState({ allowanceId: id });
    setTimeout(() => this.props.onDeleteTimecardAllowance(), 100);
  }

  onCopyToAll(referenceDate) {
    const { timecard, change } = this.props;
    const days = timecard.days;
    const referenceDay = _.find(days, day =>
      moment(day.date).isSame(referenceDate),
    );
    _.map(days, (day, index) => {
      if (!day.isActive) return day;

      const newDay = _.cloneDeepWith(referenceDay, () => {
        return {
          ...referenceDay,
          date: day.date,
          id: day.id,
          splits: null,
          isActive: true,
        };
      });

      change(`days[${index}]`, newDay);
      return {
        ...newDay,
      };
    });
  }

  onTakeMeBack() {
    const { pristine, onShowDirtyAlert, onTakeMeBackAction } = this.props;
    if (!pristine) {
      onShowDirtyAlert();
    } else {
      onTakeMeBackAction();
    }
  }

  setNoteEmpty(noteId, isEmpty) {
    const { notesEmpty } = this.state;
    notesEmpty[noteId] = isEmpty;

    let areNotesEmpty = true;
    for (const note in notesEmpty) {
      if (notesEmpty.hasOwnProperty(note)) {
        const setNoteEmpty = notesEmpty[note];
        areNotesEmpty = areNotesEmpty && setNoteEmpty;
      }
    }
    this.setState({ areNotesEmpty, notesEmpty });
  }

  setAllowanceInProgress(val) {
    this.setState({ allowanceInProgress: val });
  }

  onToggleEdit() {
    this.setState({
      editReview: !this.state.editReview,
    });
  }

  actionButtons() {
    const buttons = [];
    const {
      activeUser,
      submitting,
      dispatch,
      timecard,
      error,
      formSyncErrors,
      formSyncWarnings,
      isTimecardDirty,
      onApproveTimecard,
      timecardId,
      onRejectTimecard,
    } = this.props;

    const btnErrors = _.merge(error, formSyncErrors);

    btnErrors.days?.forEach(day => {
      if (_.isEmpty(day) === false) btnErrors.valid = false;
    });

    const isValid = btnErrors.valid;

    const { editReview, areNotesEmpty, allowanceInProgress } = this.state;

    const disableSaveApprove = formSyncWarnings.needsToReject;
    /**
     * @submitting
     * a built-in flag from redux-form
     * turns on and off by
     * startSubmit/ stopSubmit actions calls
     */
    const disabled = submitting || !areNotesEmpty || allowanceInProgress;

    if (!editReview) {
      if (activeUser.role === DH) {
        buttons.push(
          <Button
            variant="text"
            color="primary"
            key="edit"
            disabled={disabled}
            onClick={() => this.onToggleEdit()}
          >
            {'Edit'}
          </Button>,
        );
      }
      buttons.push(
        <Button
          variant="outlined"
          color="primary"
          key="reject"
          disabled={disabled}
          onClick={onRejectTimecard}
        >
          {'Reject'}
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="submit"
          disabled={disabled}
          onClick={() => onApproveTimecard(timecardId, timecard.entryHeaderId)}
        >
          {'Approve'}
        </Button>,
      );
    } else {
      buttons.push(
        <Button
          variant="text"
          key="cancel"
          onClick={() => {
            this.onToggleEdit();
            dispatch(reset(formName));
          }}
          disabled={disabled}
        >
          {'Cancel'}
        </Button>,
        <Button
          variant="outlined"
          color="primary"
          key="submit"
          disabled={disabled || disableSaveApprove}
          onClick={() =>
            onApproveTimecard(
              timecardId,
              timecard.entryHeaderId,
              isTimecardDirty,
            )
          }
        >
          {'Save and Approve'}
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="reject"
          disabled={disabled || !isValid}
          onClick={onRejectTimecard}
        >
          {'Save and Reject'}
        </Button>,
      );
    }

    return buttons;
  }

  render() {
    const {
      timecard,
      handleSubmit,
      isTimecardDirty,
      loading,
      submitting,
      savingTimecard,
      onTakeMeBackAction,
      timecardId,
    } = this.props;

    const { editReview, allowanceId } = this.state;

    if (loading) {
      return <LinearProgress />;
    }

    if (!timecard.id) {
      return (
        <Paper style={{ padding: 25, textAlign: 'center' }}>
          <Typography variant="h6">{'Timecard Not Found'}</Typography>
        </Paper>
      );
    }

    return (
      <div>
        {(submitting || savingTimecard) && <LinearProgress />}
        <form onSubmit={handleSubmit}>
          <Timecard
            {...this.props}
            isViewOnly={!editReview}
            editReview={editReview}
            onCopyToAll={this.onCopyToAll.bind(this)}
            buttons={this.actionButtons()}
            onTakeMeBack={this.onTakeMeBack.bind(this)}
            onDeleteAllowance={this.onDeleteAllowance.bind(this)}
            setNoteEmpty={this.setNoteEmpty}
            setAllowanceInProgress={this.setAllowanceInProgress}
            isReviewing
            allowanceEditable
          />
        </form>
        <ConfirmTakeMeBack hasSubmitAction onSubmit={onTakeMeBackAction} />
        <RejectTimecard
          isTimecardDirty={isTimecardDirty}
          timecardEntryHeaderId={timecard.entryHeaderId}
        />
        <DeleteTimecardAllowance
          worksightId={allowanceId}
          timecardId={timecardId}
        />
      </div>
    );
  }
}

export default compose(
  connect(mapState, mapDispatch),

  reduxForm({
    form: formName,
    enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    warn,
    onSubmit,
  }),
)(ReviewTimecard);
