import React from 'react';
import { EnhancedRoute } from 'containers/RoutingSupport';
import AllowanceDocument from 'containers/Employees/AllowanceDocument';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    key="/allowances/:token"
    path="/allowances/:token"
    authenticated
    component={AllowanceDocument}
  />
);
