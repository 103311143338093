import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import clsx from 'clsx';
import {
  FormGroup,
  FormControlLabel,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { FaPlus as AddIcon, FaTimes as DeleteIcon } from 'react-icons/fa';
import { AutoComplete } from 'components/Shared/AutoComplete';
import Checkbox from 'components/Shared/redux/Checkbox';
// import { PA, DH, ROLE_LABELS } from 'schemas/profiles';

export const style = ({ palette }) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: 10,
    marginRight: 5,
  },
  fieldContainer: {
    width: '85%',
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
  field: {
    width: '100%',
  },
  errorField: {
    '& > label': {
      color: palette.error.main,
    },
    '& > p': {
      color: palette.error.main,
    },
  },
  emergencyCheck: {
    margin: '5px 0px 0px 0px',
  },
  noPadding: { padding: 0 },
  iconButton: {
    padding: 8,
    fontSize: '1.2em',
    display: 'inline',
    verticalAlign: 'bottom',
  },
  actionContainer: {
    display: 'inline-flex',
    padding: 0,
  },
  actionColumn: {
    width: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    verticalAlign: 'middle',
  },
  autoCompleteSettings: {
    '& > *': {
      marginBottom: 0,
    },
  },
});

/* This is used in Department & Approvers section. */
class UserSelect extends Component {
  static propTypes = {
    addNext: PropTypes.func.isRequired,
    canAddNext: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    disableRemove: PropTypes.bool,
    label: PropTypes.string,
    noPadding: PropTypes.bool,
    removeUser: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
    showEmergencyCheck: PropTypes.bool,
    userOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  static defaultProps = {
    canAddNext: true,
    disableRemove: true,
    user: undefined,
    label: '',
    name: 'userSelect',
    noPadding: false,
    showEmergencyCheck: false,
  };
  render() {
    const {
      addNext,
      canAddNext = false,
      classes,
      disableRemove,
      noPadding,
      label,
      removeUser,
      user,
      showEmergencyCheck,
      userOptions,
    } = this.props;

    const rowClass = clsx(classes.row, {
      [classes.noPadding]: noPadding,
    });

    return (
      <Paper elevation={0}>
        <div className={rowClass}>
          <div className={classes.fieldContainer}>
            <AutoComplete
              list={userOptions}
              name={`${user}.id`}
              label={label}
              fieldLabel="fullName"
              fieldValue="id"
              placeholder="Select User"
              required
            />
          </div>
          <div className={classes.actionColumn}>
            <Tooltip title="Remove">
              <span>
                <IconButton
                  className={classes.iconButton}
                  disabled={disableRemove}
                  onClick={removeUser}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            {canAddNext && (
              <Tooltip title="Add">
                <IconButton
                  className={classes.iconButton}
                  onClick={addNext}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {showEmergencyCheck && (
          <FormGroup className={classes.emergencyCheck}>
            <FormControlLabel
              control={
                <Field
                  component={Checkbox}
                  name={`${user}.canSubmitEmergencyTimecard`}
                  label={label}
                  placeholder={label}
                  color="primary"
                />
              }
              label="Can Submit Emergency Timecard"
            />
          </FormGroup>
        )}
      </Paper>
    );
  }
}

export default withStyleSheet('UserSelect', style)(UserSelect);
