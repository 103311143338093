import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Typography } from '@mui/material';

import { compose } from 'utils/helperFunctions';
import {
  TitleBox,
  ContentBox,
  FooterBox,
  HeaderText,
  FooterButton,
} from '../Shared/styledComponents';
import { push } from 'connected-react-router';
import { getIsMobile } from '../selectors';
import * as sel from '../selectors';
import { getModalParams } from 'selectors/modalDialog';

import withModalDialog from 'decorators/withModalDialog';
//actions
import { hide as hideModal, show as showModal } from 'actions/modalDialog';
// import { onSubmit } from 'sagas/timecards';
import { SIGNATURE_MODAL, SUBMIT_REMINDER_MODAL } from './modalNames';

const mapState = state => ({
  isMobile: getIsMobile(state),
  disableBtns: sel.getDisabledBtns(state),
  modalParams: getModalParams(state, SUBMIT_REMINDER_MODAL),
});
const mapDispatch = dispatch => ({
  onClose: url => {
    dispatch(hideModal({ dialog: SUBMIT_REMINDER_MODAL }));
    dispatch(push(url));
  },
  onSubmit: () => {
    dispatch(hideModal({ dialog: SUBMIT_REMINDER_MODAL }));
    dispatch(showModal({ dialog: SIGNATURE_MODAL }));
  },
});

const SubmitReminderModal = props => {
  const { onClose, onSubmit, isMobile, disableBtns, modalParams } = props;
  const { url } = modalParams;
  const { disableSubmit } = disableBtns;

  const ButtonsGroup = useCallback(() => {
    const defaultButtons = [
      <FooterButton
        key={'keepMaking'}
        variant="outlined"
        onClick={() => onClose(url)}
      >
        Keep making changes
      </FooterButton>,
      !disableSubmit && (
        <FooterButton key={'Submit'} onClick={onSubmit}>
          Submit
        </FooterButton>
      ),
    ];

    return defaultButtons;
  }, [disableSubmit, onSubmit, onClose, url]);

  return (
    <Paper>
      <TitleBox>
        <HeaderText>Submit Reminder</HeaderText>
      </TitleBox>
      <ContentBox>
        <Typography>
          {`Timecard saved successfully. ${
            !disableSubmit
              ? 'Do you want to proceed to submit this timecard?'
              : 'Please remember to submit your timecard later.'
          }`}
        </Typography>
      </ContentBox>
      <FooterBox
        sx={{
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? 3 : '',
        }}
      >
        {ButtonsGroup()}
      </FooterBox>
    </Paper>
  );
};

SubmitReminderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  disableBtns: PropTypes.object.isRequired,
  modalParams: PropTypes.object,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: SUBMIT_REMINDER_MODAL,
    roundedCorners: true,
    maxWidth: 'sm',
  }),
)(SubmitReminderModal);
