import React from 'react';

import { Input, InputAdornment, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';

//selectors
import { getAccountMask, getProjectDetails } from 'selectors/project';

//utils
import { makeMaskFunc, accountsFieldMaxLength } from 'utils/weekUtils';
import { NUMERIC_CELLS } from 'feature/DTS/dtsUtils';

const mapStateToProps = state => ({
  accountMask: getAccountMask(state),
  project: getProjectDetails(state),
});

// Default editable cell renderer
const TextCell = props => {
  const {
    column: { id: columnId },
    disabled,
    baseOnChange,
    baseOnBlur,
    baseClasses,
    localVal,
    toggleCTA,
    error,
    id,
    project,
  } = props;

  const isAccountCode = columnId === 'accountCode';
  const isNumCell = NUMERIC_CELLS.includes(columnId);

  const { errorMsg } = error;

  const onChange = e => {
    let value = e.target.value;

    //normalize for accountCode
    if (isAccountCode) {
      const { accountMask } = props;
      const normalize = makeMaskFunc(accountMask);
      value = normalize(value);
    }

    baseOnChange(value);
  };

  const numberClass = isNumCell ? baseClasses.numberCell : '';

  return (
    <div>
      <Input
        id={id}
        classes={{
          root: `${baseClasses.input} ${numberClass}`,
          disabled: baseClasses.disabled,
          focused: baseClasses.focusColor,
        }}
        type="text"
        disabled={disabled}
        value={localVal || ''}
        error={!!errorMsg}
        variant="outlined"
        inputProps={{
          autoComplete: 'off',
          maxLength: accountsFieldMaxLength(project, columnId),
        }}
        endAdornment={
          !!errorMsg ? (
            <InputAdornment position="end">
              <Tooltip arrow title={errorMsg}>
                <ErrorIcon className={baseClasses.errorIcon} />
              </Tooltip>
            </InputAdornment>
          ) : (
            ''
          )
        }
        onChange={onChange} //normalize handled here
        onBlur={() => {
          toggleCTA(false);
          baseOnBlur();
        }}
        onFocus={() => toggleCTA(true)}
      />
    </div>
  );
};

export default compose(connect(mapStateToProps, null))(TextCell);
