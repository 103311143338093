import _ from 'lodash';
import { createSelector } from 'reselect';
// utils
import { formatDate } from 'utils';
import { getFilteredNotes, isSigned, getDays, getEmptyDay } from './utils';
import { getEditableFields, currentWorkWeek } from 'utils/weekUtils';

// constants
import * as constants from 'components/Shared/constants';
// helper selectors
import { getFormValues } from 'selectors/formValues';
import { currentUser } from 'selectors/session';
import { getPayrollSettings } from 'selectors/settings';
import { getProjectDetails } from 'selectors/project';
import { getProjectAllowances } from 'selectors/projectAllowances';
import * as timecardCommon from './common';

export const EMPTY_MAP = {};
export const EMPTY_ARRAY = [];

// Timecard Page
export const getNotes = (state, id) => {
  const notes = _.get(state, `timecards.notes.${id}`, {});
  const result = getFilteredNotes(notes);
  return _.map(result, note => {
    const status = constants.TIMECARD_NOTE_STATUS_TO_BADGE_MAP[note.type];
    const label =
      status && note.type === 'day'
        ? formatDate(note.referenceDate, 'dddd')
        : constants.TIMECARD_NOTE_STATUS_TO_LABEL_MAP[note.type];
    return {
      ...note,
      status,
      label,
    };
  });
};
export const getHistoryStatusAndLabel = (history, id = null) =>
  _.map(history, item => {
    let status = constants.HISTORY_ACTION_TO_STATUS[item.action];
    let label = constants.HISTORY_ACTION_TO_LABEL_MAP[item.action];
    let downloadUrl = null,
      icon = null;
    if (
      item.originalUser &&
      item.originalUser.lastName === 'Payroll_Coordinator'
    ) {
      item.originalUser.fullName = item.originalUser.fullName.replace(
        'Payroll_Coordinator',
        'Payroll Coordinator',
      );
    } else if (item.actor && item.actor.lastName === 'Payroll_Coordinator') {
      item.actor.fullName = item.actor.fullName.replace(
        'Payroll_Coordinator',
        'Payroll Coordinator',
      );
    }
    if (item && isSigned(item)) {
      status = 'primary';
      label = 'Signed';
      icon = 'download';
      downloadUrl =
        id !== null
          ? [
              `/api/projects/${id}`,
              `/timecards/${item.subjectId}`,
              '/print',
            ].join('')
          : [];

      const approvalId = item && item.actionable && item.actionable.id;

      if (approvalId) downloadUrl += `?approvalId=${approvalId}`;
    }

    return { ...item, label, status, downloadUrl, icon };
  });

export const getHistory = (state, id) => {
  const history = _.get(state, `timecards.history.${id}`, {});
  return getHistoryStatusAndLabel(history, id);
};

export const getCopyPreviousFlag = state =>
  _.get(state, 'timecards.isCopyPrevious', false);

export const getAllowanceInput = state =>
  _.get(state, 'timecards.allowanceInput', {});

// Initial Values (Timecard & Days only)
export const getInitialValues = createSelector(
  [
    state => getProjectDetails(state),
    (state, id) => timecardCommon.getDetails(state, id),
    state => getPayrollSettings(state),
    state => currentUser(state),
    (state, id, role) => role,
  ],
  (project, timecard, payrollSettings, activeUser, role) => {
    if (!_.isEmpty(timecard) && timecard.id) {
      const returnTimecard = _.cloneDeep(timecard);
      const userRole = role || activeUser.role || 'employee';

      const editableFields = getEditableFields(timecard.status, userRole);
      const workWeek = currentWorkWeek(project, timecard.startsOn);
      const days = getDays(timecard.days || [], payrollSettings, workWeek);

      const newTimecard = {
        ...returnTimecard,
        days,
        editableFields,
      };

      return newTimecard;
    }

    return EMPTY_MAP;
  },
);

// Entire TC Object.
// for reference only, no modifications should be done in this selector
export const getTimecard = createSelector(
  [
    (state, id, formName, role) => getInitialValues(state, id, role),
    (state, id, formName) => getFormValues(formName)(state),
    (state, id) => getNotes(state, id),
    (state, id) => getHistory(state, id),
  ],
  (initial, updates, notes, history) => {
    const timecard = updates || initial;

    const timecardCopy = _.cloneDeep(timecard);

    timecardCopy.notes = notes;
    timecardCopy.history = history;

    return timecardCopy;
  },
);

export const getTimecardFormValues = createSelector(
  [
    (state, id, formName) => getFormValues(formName)(state),
    (state, id) => getInitialValues(state, id),
  ],
  (updates, initial) => {
    const timecard = _.isEmpty(updates) ? initial : updates;

    const timecardCopy = _.cloneDeep(timecard);
    const days = _.map(timecard.days, day => {
      return day.htgDayTypeId && day.isActive ? day : getEmptyDay(day);
    });
    timecardCopy.days = days;

    return timecardCopy;
  },
);

export const getTimecardAllowances = createSelector(
  [
    state => getProjectAllowances(state),
    state => timecardCommon.getMyTimecardAllowances(state),
    (state, id) => timecardCommon.getTimecardAllowances(state, id),
    (state, id, useDefault = false) => useDefault,
  ],
  (projectAllowances, timecardAllowances, allowances, useDefault) => {
    let records = _.map(allowances, record => {
      const projectAllowance = _.find(
        projectAllowances,
        item => item.id === record.htgAllowanceTypeId,
      );
      let defaultAllowance = null;
      let description = projectAllowance && projectAllowance.description;
      if (record.isDefaultAllowance && useDefault) {
        defaultAllowance = _.find(
          timecardAllowances,
          item =>
            item.id === record.defaultAllowanceId && item.isDefaultAllowance,
        );
        if (defaultAllowance) {
          description = defaultAllowance.description;
        }
      }

      return {
        description,
        ...record,
      };
    });

    records = _.isEmpty(records) ? [] : records;

    return records;
  },
);

export const getEmployeeApprovalHistory = createSelector(
  [(state, id) => getHistory(state, id)],
  history => {
    const historyRec = _.chain(history)
      .filter(rec => rec.actionableType === 'Approval')
      .sortBy(rec => rec.id)
      .reverse()
      .first()
      .value();

    return historyRec;
  },
);

export const getDropdownAllowances = createSelector(
  [
    getProjectAllowances,
    timecardCommon.getMyTimecardAllowances,
    timecardCommon.getMyEmployeeAllowancePayCodes,
  ],
  (projectAllowances, timecardAllowances, employeeAllowancePaycodes) => {
    const projectAllowance = _.filter(
      projectAllowances,
      item => item.employeeVisible === true || item.employeeMandatory === true,
    );
    let employeeAllowance = _.filter(
      employeeAllowancePaycodes,
      employeeAllowancePaycode => {
        let allowance = _.find(
          projectAllowance,
          allowance => allowance.id === employeeAllowancePaycode.id,
        );
        return !!allowance;
      },
    );
    if (!_.isEmpty(employeeAllowance)) {
      var timecardAllowance = _.filter(timecardAllowances, item => {
        const tcallowance = _.find(
          employeeAllowance,
          allowance => allowance.id === item.htgAllowanceTypeId,
        );
        return !!tcallowance;
      });
    }
    return _.chain(timecardAllowance)
      .map(item => {
        return {
          htgAllowanceTypeId: item.htgAllowanceTypeId || item.id,
          ...item,
        };
      })
      .value();
  },
);

export const getTimecardHtgContractId = formName =>
  createSelector(getFormValues(formName), tc =>
    _.get(tc, 'dealMemo.htgContract.id', ''),
  );

export const getTimecardHtgUnionId = formName =>
  createSelector(getFormValues(formName), tc =>
    _.get(tc, 'dealMemo.htgUnion.id', ''),
  );
