import produce from 'immer';
import * as actions from 'actions/dts';
import _ from 'lodash';

//utils
import { setVisibleSelected } from 'components/Employees/Reviews/SearchTimecards/searchUtils';
import {
  SORT_TYPE_MAP,
  GROUP_OPTION_IDS,
  OPTIONAL_GROUP_OPTION_IDS,
  SOLO_OPTION_IDS,
} from 'feature/DTS/dtsUtils';
import { isRegionCanada } from 'utils/helperFunctions';
//editable cells
import BaseCell from 'feature/DTS/EditableCells/BaseCell';

//selectors
import { getFilteredDropdown } from 'selectors/dts';
import moment from 'moment';

const initialState = {
  rawData: {},
  loading: {
    data: true,
    template: true,
    filters: true,
    groupOptions: true,
    deleting: false,
    saving: false,
  },
  templateError: false,

  isDirty: false,
  effectiveDate: moment(),
  tableColumns: [],
  initialHiddenColumns: [],
  groupOptions: {},
  soloOptions: { all: [] },
  dealMemos: [],
  optionsLoading: {},
  /**
   * Each Filter obj will have
   * @param {int} index - index in the array (needed so checkbox knows which is which)
   * @param {string} label - Display label
   * @param {string} value - ID or val we're sending to API
   * @param {bool} selected - Is filter active (checked)?
   */
  filters: {},
  preSelectFlags: {},
  filtersPristine: false,
  searchFilters: {
    department: '',
    batch: '',
    employee: '',
    account: '',
    episode: '',
    set: '',
    union: '',
  },
  modalAction: { func: null, args: null },
  saveMetaData: { pending: [], success: [], error: [], timeout: [] },
  deleteJobId: {},
  postSaveAction: null,
  deletedEmployees: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    let filterName;
    switch (action.type) {
      case `${actions.loading}`:
        if (draft.loading[action.loadType] === undefined) {
          console.error('Invalid DTS loadType: ', action.loadType);
        }

        draft.loading[action.loadType] = action.loading;
        break;

      case `${actions.setDirty}`:
        draft.isDirty = action.isDirty;
        break;

      case `${actions.store}`:
        draft.rawData = action.data;
        draft.isDirty = false;
        break;

      case `${actions.setEffectiveDate}`:
        draft.effectiveDate = action.date;
        draft.rawData = initialState.rawData;
        break;

      case `${actions.storeTemplates}`:
        // currentTemplate has list of default visible columns
        // userColumns has all possible fields for this template
        try {
          const templateId = action.templateId;
          const currentTemplate = action.currentTemplate;

          if (!currentTemplate) {
            throw new Error(
              `Selected templateId:"${templateId}" not found in template options`,
            );
          }

          const userColumnsObj = action.userColumns;
          //Turn obj into array with key added as 'accessor' property
          const headerColumns = Object.keys(userColumnsObj);

          const fullColumnsArr = headerColumns
            .map(header => {
              const column = userColumnsObj[header];
              column.accessor = header;
              column.default = false;
              return column;
            })
            .filter(c => c.hide === 0); //remove any template hidden columns (distinct from additionalFields hidden)

          const sortedColumns = _.sortBy(fullColumnsArr, 'position');

          //flip default fields
          currentTemplate.templateFields.forEach(field => {
            const result = sortedColumns.find(c => {
              return c.rowId === field.id;
            });
            if (result) {
              result.default = true;
            } else {
              throw new Error(
                `Missing template field from user defined columns. ${field.name} not found`,
              );
            }
          });

          //Hide locationType && schedule if region is canada
          const caRegion = isRegionCanada(localStorage.getItem('region'));
          if (caRegion) {
            const index = sortedColumns?.findIndex(
              type => type.accessor === 'locationType',
            );
            sortedColumns.splice(index, 1);
            const scheduleIndex = sortedColumns?.findIndex(
              type => type.accessor === 'schedule',
            );
            sortedColumns.splice(scheduleIndex, 1);
          }

          //create tableColumns Array
          const tableColumns = sortedColumns.map(column => {
            const Header = column.display ? column.display : column.label;

            const sortType = SORT_TYPE_MAP[column.type]
              ? SORT_TYPE_MAP[column.type]
              : 'alpha';

            if (
              column.type === 'mask' &&
              column.accessor !== 'accountCode' &&
              column.accessor !== 'combineCheck'
            ) {
              throw new Error(
                `Type 'mask' only expected for accountCode and combineCheck. Found for: ${column.accessor}`,
              );
            }

            const devShowColumns = ['dealMemo', 'rate'];

            return {
              accessor: column.accessor,
              id: column.accessor,
              Header,
              // //dev help: show columns by default:
              default: devShowColumns.includes(column.accessor)
                ? true
                : column.default,
              // default: column.default, //show by default, other wise put in additional fields
              type: column.type,
              width: column.width,
              position: column.position,
              sortType,
              Cell: BaseCell,
            };
          });

          const STATUS_COLUMN = {
            accessor: 'userFriendlyTimecardStatus',
            id: 'userFriendlyTimecardStatus',
            Header: 'Status',
            default: false,
            width: 100,
            position: Number.MAX_SAFE_INTEGER,
            sortType: 'alpha',
            Cell: BaseCell,
          };

          tableColumns.push(STATUS_COLUMN);

          //generate initial hidden columns
          const hiddenColumns = [];

          // scan and set additional hidden columns
          // AND
          // scan for columns that need dropdown options
          // does not matter if field is visible or not, if its in the template it will be loaded
          tableColumns.forEach(column => {
            if (!column.default) hiddenColumns.push(column.accessor);

            if (OPTIONAL_GROUP_OPTION_IDS.includes(column.id)) {
              draft.groupOptions[column.id] = [];
            }
          });

          draft.tableColumns = tableColumns;
          draft.initialHiddenColumns = hiddenColumns;
        } catch (error) {
          console.error('Error parsing template:', error);
          draft.templateError = true;
        }

        break;
      case `${actions.setTemplateError}`:
        draft.templateError = true;
        break;

      case `${actions.storeDdOptions}`:
        const { data, columnId } = action;

        if (GROUP_OPTION_IDS.includes(columnId)) {
          draft.groupOptions[columnId] = data.slice();
        } else if (SOLO_OPTION_IDS.includes(columnId)) {
          //store per employee
          draft.soloOptions[columnId] = data.slice();
        }

        break;

      case `${actions.storeDealMemos}`:
        draft.dealMemos = action.data;
        break;

      case `${actions.optionsLoading}`:
        draft.optionsLoading[action.columnId] = action.loading;
        break;
      case `${actions.clearSoloOptions}`:
        draft.soloOptions[action.columnId] = [];
        break;
      case `${actions.storeSort}`:
        draft.storedSort = action.storedSort;
        break;

      case `${actions.fetchData}`:
        draft.filtersPristine = true;
        break;

      case `${actions.resetFilters}`:
        draft.filtersPristine = false;
        const currFilters = draft.filters;
        for (const filterName in currFilters) {
          if (Object.hasOwnProperty.call(currFilters, filterName)) {
            const filterOptions = currFilters[filterName];
            filterOptions.forEach(option => (option.selected = true));
          }
        }
        for (const searchFilter in draft.searchFilters) {
          if (Object.hasOwnProperty.call(draft.searchFilters, searchFilter)) {
            draft.searchFilters[searchFilter] = '';
          }
        }
        break;

      case `${actions.storeFilterOptions}`:
        filterName = action.filterName;
        const options = state.filters[filterName] || [];
        const newOptions = action.data;

        const isPreSelect = !!action.preSelect;
        //This will be true the first load after a preSelect
        //defaults new filter options for this filter to false
        const isLoadAfterPreSelect = draft.preSelectFlags[filterName];

        //add in showBlanks option if it was in prev options
        if (options.length && options[0].label === 'Show blanks') {
          newOptions.unshift({
            id: '',
            value: 'Show blanks',
          });
        }

        const mappedFilters = newOptions.map((option, index) => ({
          label: option.value || '',
          id: option.id,
          selected: isLoadAfterPreSelect ? false : true,
          index,
        }));

        //preserve previous selection, default tow true if undefined
        options.forEach(option => {
          const result = mappedFilters.find(o => o.id === option.id);
          if (result) {
            result.selected = option.selected === false ? false : true;
          }
        });

        //if no filters are selected, select all of them
        const hasSelected = mappedFilters.some(o => o.selected);
        if (!hasSelected) mappedFilters.forEach(o => (o.selected = true));

        //turn on flag to preserve selection on the next load
        if (isPreSelect) {
          draft.preSelectFlags[filterName] = true;
        } else if (isPreSelect === false && isLoadAfterPreSelect) {
          //turn off preSelectFlagsFlag after initial load
          draft.preSelectFlags[filterName] = false;
        }

        draft.filters[filterName] = mappedFilters;
        break;
      case `${actions.addShowBlanksOption}`:
        filterName = action.filterName;
        const list = draft.filters[filterName];
        if (!Array.isArray(list)) break;
        list.unshift({
          label: 'Show blanks',
          id: '',
          selected: true,
          index: -1,
        });
        list.forEach(o => o.index++);
        draft.filters[filterName] = list;
        break;

      case `${actions.onSelect}`:
        const selected =
          draft.filters[action.filterName][action.index].selected;
        draft.filters[action.filterName][action.index].selected = !selected;
        draft.filtersPristine = false;
        break;

      case `${actions.onSelectAll}`:
        const fullList = draft.filters[action.filterName];

        const mockState = { dts: state };
        const visibleList = getFilteredDropdown(mockState, action.filterName);
        const visibleSelectedCount = visibleList.filter(
          f => f.selected === true,
        ).length;

        let newValue;
        if (visibleSelectedCount === visibleList.length) {
          //unselect all visible
          newValue = false;
        } else {
          //select all visible
          newValue = true;
        }

        setVisibleSelected({ fullList, visibleList, newValue });
        draft.filters[action.filterName] = fullList;
        draft.filtersPristine = false;
        break;

      case `${actions.setSearchFilter}`:
        draft.searchFilters[action.filterName] = action.value;
        break;

      case `${actions.setModalAction}`:
        draft.modalAction = action.modalAction;
        break;

      case `${actions.clearModalAction}`:
        draft.modalAction = initialState.modalAction;
        break;

      case `${actions.setPostSaveAction}`:
        draft.postSaveAction = action.func;
        break;
      case `${actions.cleanup}`:
        draft.rawData = initialState.rawData;
        draft.templateError = initialState.templateError;
        draft.loading = initialState.loading;
        draft.saveMetaData = initialState.saveMetaData;
        draft.deleteJobId = initialState.deleteJobId;
        break;

      case `${actions.setSaveMetaData}`:
        if (action.pending) {
          draft.saveMetaData.pending = action.pending;
          draft.saveMetaData.success = [];
          draft.saveMetaData.error = [];
          draft.saveMetaData.timeout = [];
        } else {
          if (action.success) {
            const success = draft.saveMetaData.success.slice();
            const pending = draft.saveMetaData.pending.slice();
            action.success.forEach(tcId => {
              const index = pending.findIndex(id => id === tcId);
              if (index > -1) {
                pending.splice(index, 1);
                success.push(tcId);
              } else {
                console.error(`Success TC not found in Pending list:${tcId}}`);
              }
            });
            draft.saveMetaData.success = success;
            draft.saveMetaData.pending = pending;
          }
          if (action.error) {
            const error = draft.saveMetaData.error.slice();
            const pending = draft.saveMetaData.pending.slice();
            action.error.forEach(tcId => {
              const index = pending.findIndex(id => id === tcId);
              if (index > -1) {
                pending.splice(index, 1);
                error.push(tcId);
              } else {
                console.error(`Errored TC not found in Pending list:${tcId}}`);
              }
            });
            draft.saveMetaData.error = error;
            draft.saveMetaData.pending = pending;
          }
          if (action.timeout) {
            const timeout = draft.saveMetaData.timeout.slice();
            const pending = draft.saveMetaData.pending.slice();
            action.timeout.forEach(tcId => {
              const index = pending.findIndex(id => id === tcId);
              if (index > -1) {
                pending.splice(index, 1);
                timeout.push(tcId);
              } else {
                console.error(
                  `Timed out TC not found in Pending list:${tcId}}`,
                );
              }
            });
            draft.saveMetaData.timeout = timeout;
            draft.saveMetaData.pending = pending;
          }
        }
        break;
      case `${actions.setRounding}`:
        draft.rounding = action.rounding;
        break;
      case `${actions.reset}`:
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      case `${actions.setDeleteJobId}`:
        draft.deleteJobId = action.deleteJobId;
        break;
      case `${actions.setDeletedEmployees}`:
        draft.deletedEmployees = action.deletedEmployees;
        break;
      default:
    }
  });
