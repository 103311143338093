import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { NavBar } from 'components/Shared/Nav';
import NavItem from 'containers/Shared/NavItem';

export const style = () => ({
  root: {
    width: '100%',
    height: 64,
    lineHeight: '62px',
    textAlign: 'right',
  },
});

class ProfileNav extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool,
  };

  static defaultProps = {
    isAdminUser: false,
  };

  render() {
    const { classes, isAdminUser } = this.props;
    const tabItems = [];

    if (!isAdminUser) {
      tabItems.push(
        <NavItem
          secondary
          showIndicator
          key={`/me/profile`}
          linkTo={`/me/profile`}
          secondaryLinks={[`/me/profile`]}
          navLabel="Profile"
        />,
        <NavItem
          secondary
          showIndicator
          key={`/me/preferences`}
          linkTo={`/me/preferences`}
          secondaryLinks={[`/me/preferences`]}
          navLabel="Preferences"
        />,
      );
    }

    return (
      <div className={classes.root}>
        <NavBar>{tabItems}</NavBar>
      </div>
    );
  }
}

export default withStyleSheet('ProfileNav', style)(ProfileNav);
