import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Typography,
  Paper,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import EditIcon from '@mui/icons-material/Edit';
import { withStyleSheet } from 'shared/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import { departmentsProps } from 'components/props/departments';
import {
  Card,
  CardHeaderPlain as CardHeader,
  CardContent,
} from 'components/Shared/Card';
import { TableList } from 'components/Shared/TableList';

export const style = ({ palette }) => ({
  root: {
    fontFamily: 'inherit',
  },
  header: {
    fontFamily: 'inherit',
  },
  content: {
    padding: 0,
    fontFamily: 'inherit',
    '&:last-child': { paddingBottom: 0 },
  },
  tableRow: {
    '& > *': {
      padding: {
        top: 5,
        bottom: 5,
        right: 0,
        left: 5,
      },
      fontFamily: 'inherit',
    },
  },
  iconButton: {
    padding: 8,
    fontSize: '1.2em',
    display: 'inline',
    verticalAlign: 'bottom',
  },
  actionColumn: { width: '17%', align: 'right' },
  noBatchError: { color: palette.error.main },
});

class Departments extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    departments: departmentsProps.isRequired,
    onAddDepartment: PropTypes.func,
    onEditDepartment: PropTypes.func,
    onDeleteDepartment: PropTypes.func,
  };

  static defaultProps = {
    onAddDepartment: undefined,
    onEditDepartment: undefined,
    onDeleteDepartment: undefined,
  };

  renderDepartments() {
    const { classes, departments, onDeleteDepartment, onEditDepartment } =
      this.props;
    let departmentRows = null;

    if (departments && departments.length <= 0) {
      departmentRows = (
        <TableRow>
          <TableCell colSpan={3} className={classes.blnakSlate}>
            {'No Departments'}
          </TableCell>
        </TableRow>
      );
    } else {
      departmentRows = departments.map(department => (
        <TableRow key={department.id} className={classes.tableRow}>
          <TableCell>{department.name}</TableCell>
          <TableCell style={{ width: '24%' }}>{department.headNames}</TableCell>
          <TableCell style={{ width: '20%' }}>
            {department.batchTemplateName ? (
              department.batchTemplateName
            ) : (
              <div className={classes.noBatchError}>{'Not Assigned'}</div>
            )}
          </TableCell>
          <TableCell>
            <Tooltip title={department?.wtcLayoutName || 'Project Default'}>
              <span>{department?.wtcLayoutName || 'Project Default'}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={classes.actionColumn}>
            <IconButton
              className={classes.iconButton}
              onClick={() => onEditDepartment(department.id, department.heads)}
              size="large"
            >
              <EditIcon color="primary" size={18} />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              onClick={() => onDeleteDepartment(department.id)}
              size="large"
            >
              <DeleteIcon color="primary" size={18} />
            </IconButton>
          </TableCell>
        </TableRow>
      ));
    }
    const headers = [
      {
        columnId: 'department.name',
        label: 'Name',
        align: 'left',
        compact: true,
      },
      {
        columnId: 'department.headNames',
        label: 'Department Head',
        align: 'left',
        compact: true,
      },
      {
        columnId: 'department.batchTemplateName',
        label: 'Batch Name',
        compact: true,
        align: 'left',
      },
      {
        columnId: 'department.wtcLayoutName',
        label: 'WTC Layout',
        compact: true,
        align: 'left',
      },
      { columnId: 'actions', label: ' ', align: 'left', compact: true },
    ];
    return (
      <Paper className={classes.root}>
        <TableList headers={headers}>{departmentRows}</TableList>
      </Paper>
    );
  }

  render() {
    const { classes, onAddDepartment } = this.props;

    const addDepartment = (
      <IconButton onClick={onAddDepartment}>
        <AddIcon sx={{ fontSize: '32px' }} color="primary" />
      </IconButton>
    );

    return (
      <Card className={classes.root}>
        <CardHeader title buttons={addDepartment}>
          <Typography variant="h6">{'Departments'}</Typography>
        </CardHeader>
        <CardContent className={classes.content}>
          {this.renderDepartments()}
        </CardContent>
      </Card>
    );
  }
}

export default withStyleSheet('Departments', style)(Departments);
