import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import withModalDialog from 'decorators/withModalDialog';
import { ConfirmModal } from 'components/Shared/Modals';
import TextField from 'components/Shared/redux/TextField';
import { addComment } from 'actions/wtc';
import { formatMessages } from 'utils/messages';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

export const style = ({ palette }) => ({
  textField: {
    width: '100%',
    marginBottom: 32,
  },
});

const formName = 'AddWTCComment';
const onSubmit = (values, dispatch) => {
  dispatch(addComment({ ...values, formName }));
};
class AddComment extends Component {
  static propTypes = {
    error: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: undefined,
  };

  render() {
    const { classes, error, handleSubmit, submitting, ...others } = this.props;

    const title = (
      <Typography variant="h6" color="inherit">
        {'Add Comment'}
      </Typography>
    );

    const content = (
      <div>
        <Field
          className={
            error && error.email ? classes.errorField : classes.textField
          }
          hinttext={
            error && error.comment ? formatMessages(error.comment) : null
          }
          component={TextField}
          name="comment"
          variant="outlined"
          multiline
          rows="8"
          inputProps={{ maxLength: commentsLimit }}
          required
        />
      </div>
    );
    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Add Comment"
          buttonType="secondary"
          submitDisabled={submitting}
          raisedButton
          {...others}
        />
      </form>
    );
  }
}
export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'AddWTCComment', maxWidth: 'md' }),
  reduxForm({
    form: formName,
    onSubmit,
  }),
)(AddComment);
