import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

export const style = ({ palette }) => ({
  root: {},
  container: {
    '& > :last-child': {
      width: '90%',
      paddingLeft: 20,
    },
  },
  icon: {
    fontSize: '2em',
  },
  info: {
    color: palette.secondary.light,
    borderColor: palette.secondary.light,
    border: '1px solid',
    padding: 15,
    boxShadow: 'inset 4 0 0 1000px rgba(0,0,0,0.1)',
  },
  warning: {
    borderColor: palette.warning.main,
    border: '1px solid',
    padding: 15,
    boxShadow: 'inset 4 0 0 1000px rgba(0,0,0,0.1)',
  },
  error: {
    borderColor: palette.error.main,
    border: '1px solid',
    padding: 15,
    boxShadow: 'inset 4 0 0 1000px rgba(0,0,0,0.1)',
  },
  warningIcon: {
    color: palette.warning.main,
  },
  errorIcon: {
    color: palette.error.main,
  },
});

const TYPES = ['info', 'warning', 'error'];
const ICONS = {
  info: InfoIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const Notice = ({ classes, type, customIcon, className, children }) => {
  const Icon = customIcon || ICONS[type];
  const noticeClassName = clsx(classes[type], className);

  const iconClassName = clsx(classes[`${type}Icon`], classes.icon);

  return (
    <div className={noticeClassName}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Icon className={iconClassName} />
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </div>
  );
};

Notice.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf(TYPES).isRequired,
  className: PropTypes.string,
  customIcon: PropTypes.func,
  children: PropTypes.node,
};

Notice.defaultProps = {
  customIcon: null,
  children: undefined,
  className: '',
};

export default withStyleSheet('Notice', style)(Notice);
