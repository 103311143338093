//re-usable code that doesn't go with any particular part of the site
import _ from 'lodash';
import moment from 'moment';
import debug from 'debug';
import { delay, select } from 'redux-saga/effects';
import {
  LANDING_PAGE_BY_ROLE,
  US_CURRENCY_TEXT,
  CANADIAN_CURRENCY_TEXT,
  ACCOUNT_FIELDS_MAP,
  NAVS,
  REVIEW_TABS_BY_ROLE,
} from 'components/Shared/constants';
import { getEmptyDay } from 'selectors/timecard/utils';

import {
  hasReviewerRole,
  hasCrewTimeCardRoles,
  roleIsPA,
  roleIsDH,
  roleIsUPM,
  hasIARole,
} from 'selectors/session';

import { PillBadge } from 'components/Shared/Text';

export const db = debug('hPlus');

export function memoByValue(prev, next) {
  const prevJson = JSON.stringify(prev);
  const nextJson = JSON.stringify(next);

  const isEqual = prevJson === nextJson;
  return isEqual;
}

export function onHitEnterKey(event, callback, ...args) {
  if (event.keyCode === 13) {
    callback(...args);
  }
}

export function findCommonsInMultipleArray(workLocationsLists = []) {
  const teamWorkLocations = [];
  const magicNumber = workLocationsLists.length;
  const idMap = {};

  workLocationsLists.forEach(list => {
    for (let i = 0; i < list.length; i++) {
      const workLocation = list[i];
      const id = workLocation.id;
      if (!idMap[id]) {
        idMap[id] = 0;
        idMap[id]++;
      } else {
        idMap[id]++;
      }

      if (idMap[id] === magicNumber) {
        teamWorkLocations.push(workLocation);
      }
    }
  });
  return teamWorkLocations;
}

export function getParamFromURL(param) {
  const path = window.location.pathname.split('/');
  const labelIdx = path.indexOf(param);

  if (labelIdx === -1) return null;

  if (path.length >= labelIdx + 2) {
    return path[labelIdx + 1];
  }

  return null;
}

//HTG can't handle the lastMan1In field so it has to be LastMan1In
//HOUR-2635 - Remove this if HTG ever fixes their odd capitalization.
export function lowerCaseLastManIn(days) {
  if (Array.isArray(days)) {
    days.forEach(day => {
      if (day && day.LastMan1In) {
        day.lastMan1In = day.LastMan1In;
        delete day.LastMan1In;
      }
    });
  }
}

export function hasTimeFieldValue(timecard, arg) {
  const days =
    timecard && timecard.details
      ? timecard.details
      : timecard && timecard.days
      ? timecard.days
      : [];

  for (let i = 0; i < days.length; i++) {
    const day = days[i];
    if (day && day[arg]) return true;
  }
  return false;
}

/**
 * remove all non-numeric characters from a string and ensure only one decimal point
 */
const formatStrNum = str => {
  //strip non-numeric characters
  str = str.replace(/[^0-9.]/, '');
  //remove all but the first decimal point
  str = str.split('').reduce((acc, val, idx, arr) => {
    if (val === '.' && arr.indexOf('.') !== idx) {
      //current . isn't the first one in the string, do not add it to result
    } else {
      acc += val;
    }
    return acc;
  }, '');
  return str;
};

/**
 * Returns a string with only numbers and one decimal point and decimal places to a given precision(default 2)
 * @param {*} str string
 * @param {*} options
 * @returns
 */
export function formatStrNumber(str, options) {
  const defaultOptions = {
    limitDecimal: true,
    decimalPrecision: 2,
  };
  options = { ...defaultOptions, ...options };
  if (str === undefined) return str;
  if (typeof str !== 'string') str = `${str}`;

  str = formatStrNum(str);

  const { limitDecimal, decimalPrecision } = options;

  if (limitDecimal) {
    if (decimalPrecision < 0 || isNaN(decimalPrecision)) {
      throw new Error('decimalPrecision must be 0 or greater number');
    }

    const regex = new RegExp(`(\\d*\\.\\d{${decimalPrecision}})\\d`);
    const result = str.match(regex);
    if (result) {
      str = result[1];
    }
  }

  return str;
}

export function onBlurStrNumber(str, options) {
  const defaultOptions = {
    minDecimal: 2,
  };

  options = { ...defaultOptions, ...options };
  if (str === undefined) return str;
  if (typeof str !== 'string') str = `${str}`;

  //remove non-num characters
  str = formatStrNum(str);

  const { minDecimal } = options;

  //add leading 0 if needed
  const num = parseFloat(str);
  str = isNaN(num) ? '' : `${num}`;

  //count decimals
  const decimals = str.split('.')[1];
  const decimalCount = !decimals ? 0 : decimals.length;

  //add decimals if needed
  if (str && minDecimal > 0 && decimalCount < minDecimal) {
    str = parseFloat(str).toFixed(minDecimal);
  }

  return str;
}

export function formatAllowancesAmount(amount) {
  let tempAmount = parseFloat(amount);
  const dec = `${tempAmount}`.split('.')[1];
  if (Number(dec) === 0 || isNaN(Number(dec)) || dec.length >= 1) {
    return Number(amount).toFixed(2);
  } else {
    return parseFloat(amount);
  }
}

export const getProjectFromURL = url => {
  const regex = /projects\/(\d+)\//;

  const result = url.match(regex);
  let projectId = parseInt(result[1], 10);

  return projectId;
};

// throw a file in this bad boy and it will replace the name's
// special characters with '_'
export const fileNameChange = file =>
  file
    ? new File(
        [file],
        // eslint-disable-next-line
        `${file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '_')}`,
        { type: file.type, lastModified: file.lastModified },
      )
    : null;

// take pay## db code and return number 4 digit year.
// this will fail for Y3k
export const getYearFromDbCode = dbCode => {
  if (!dbCode) {
    return undefined;
  }
  const regex = /pay(\d\d)/i;
  const result = dbCode.match(regex);
  const year = result && result[1] && `20${result[1]}`;

  return parseInt(year, 10);
};

/**
 * For 20 <--> 21 navigation
 * Ensure user stays on reviews or invoice page if currently theres
 * Otherwise redirect to default landing page (my timecards for emp, searchTimecards for all others)
 * @param {object} location
 * @param {number} newProjectId
 * @param {string} role
 * @returns
 */
export const getAltYearLandingPage = (location, newProjectId, role) => {
  const currUrl = location.pathname;
  let newUrl;
  if (
    currUrl.match(/projects\/[0-9]+\/me\/review\//) || // review page
    currUrl.match(/projects\/[0-9]+\/search-invoice/) // search invoices
  ) {
    newUrl = currUrl.replace(/projects\/[0-9]+/, `projects/${newProjectId}`);
  } else {
    newUrl = LANDING_PAGE_BY_ROLE(role, newProjectId);
  }

  return newUrl;
};

// Given array of directories & a directoryId, returns the path to the directory
// or null if the directory is not found.  Source => start-plus-web
export const getDirectoryPath = (directories, directoryId, path = '') => {
  if (directoryId === '' || directoryId === null) {
    return null;
  }
  // This is a bit clunky
  let p = null;
  if (_.isEmpty(directories[0])) {
    return p;
  }
  directories.find(({ id, childDirectories, name }) => {
    const thisPath = `${path}/${name}`;
    if (id === directoryId) {
      p = thisPath;
      return true;
    }
    if (!(childDirectories && childDirectories.length)) return false;
    p = getDirectoryPath(childDirectories, directoryId, thisPath);
    return !!p;
  });
  return p;
};

// Sort a list of timecards
export const sortTimecards = (timecards, sortOrder, orderBy) => {
  let order = 'timecard.user.fullName';
  switch (orderBy) {
    case 'department':
      order = 'departmentName';
      break;
    case 'status':
      order = 'userFriendlyTimecardStatus';
      break;
    default:
      break;
  }
  if (sortOrder === 'default') {
    order = 'timecard.user.fullName';
  }
  let sortedTcs = [];
  if (
    sortOrder === 'ascSort' ||
    sortOrder === 'default' ||
    sortOrder === undefined
  ) {
    sortedTcs = _.chain(timecards).sortBy(order).value();
  } else if (sortOrder === 'decSort') {
    sortedTcs = _.chain(timecards).sortBy(order).reverse().value();
  }
  return sortedTcs;
};

export const propRequiredIf = (name, propType = undefined) => {
  return (props, propName, componentName) => {
    if (propType && props[propName] && typeof props[propName] !== propType) {
      return new Error(
        `Invalid prop '${propName} of type '${typeof props[
          propName
        ]}' supplied to '${componentName}', expected type ${propType}`,
      );
    }
    if (!(props[name] || props[name] === 0) && props[propName]) {
      return new Error(`${name} is required if ${propName} is included`);
    }
  };
};

export const statusClass = status => {
  let cssClass;

  switch (status) {
    case 'draft':
    case 'Draft':
    case 'incomplete':
    case 'Incomplete':
    case 'rejected':
    case 'Rejected':
      cssClass = 'redBtn';
      break;
    case 'ready_for_dh':
    case 'Ready for dept head':
    case 'ready_for_pa':
    case 'Ready for payroll acct':
    case 'ready_for_approver1':
    case 'Ready for approver 1':
    case 'ready_for_approver2':
    case 'Ready for approver 2':
    case 'ready_for_approver3':
    case 'Ready for approver 3':
    case 'ready_for_employee':
    case 'Ready for employee':
      cssClass = 'orangeBtn';
      break;
    case 'processing':
    case 'Processing':
    case 'submitted_to_c_and_c':
    case 'Submitted to C&C':
      cssClass = 'yellowBtn';
      break;
    case 'ready_for_me':
    case 'Ready for me':
      cssClass = 'greenBtn';
      break;
    case 'paid':
    case 'Paid':
      cssClass = 'grayBtn';
      break;
    default:
      cssClass = 'greenBtn';
  }
  return cssClass;
};

export const moveReadyForMe = (options, level, name = false) => {
  let arr = [...options];
  let r4mIndex = name
    ? options.findIndex(e => e.name === 'ready_for_me')
    : options.findIndex(e => e.value === 'ready_for_me');
  let r4m = arr.splice(r4mIndex, 1)[0];
  arr.splice(r4mIndex + level, 0, r4m);
  return arr;
};

export const pageFromPath = pathname => {
  if (pathname.match(/search-timecards/)) return 'timecards';
  if (pathname.match(/search-invoices/)) return 'invoice';
  if (pathname.match(/digital-edits/)) return 'digitalEdits';
  if (pathname.match(/me\/timecards$/)) return 'myTimecards';
  if (pathname.match(/[0-9]+\/reports/)) return 'reports';

  return 'default';
};

export const getYearFromQueryString = query => {
  const result = /year=(\d{4})/.exec(query);

  if (result === null) return null;

  return parseInt(result[1], 10);
};

export function* delayOnValue(selector, options = {}) {
  const { args = [], timeout = 3000 } = options;
  if (Array.isArray(args) === false) {
    throw new Error('delayOnValue error: args must be array');
  }
  let value = yield select(selector, ...args);
  let count = 0;
  const delayAmount = 50;

  while (!value && value !== 0) {
    yield delay(delayAmount);
    value = yield select(selector, ...args);
    if (count > timeout) {
      console.warn('Warning: Value timeout for selector', selector?.name);
      break;
    }
    count += delayAmount;
  }
  return value;
}

//delay until selector returns a non-empty object or array
export function* delayOnValueObj(selector, options = {}) {
  const { args = [], timeout = 3000 } = options;
  if (Array.isArray(args) === false) {
    throw new Error('delayOnValueObj error: args must be array');
  }
  let value = yield select(selector, ...args);
  let count = 0;
  const delayAmount = 50;

  while (_.isEmpty(value)) {
    yield delay(delayAmount);
    value = yield select(selector);
    if (count > timeout) {
      console.warn('Warning: Value timeout for selector:', selector?.name);
      break;
    }
    count += delayAmount;
  }
  return value;
}

/**
 * compose func from recompose: https://www.npmjs.com/package/recompose
 * This was the only bit we used and the lib has a security risk in its dependency, so moving it in-house
 * Used to combine HOCs cleanly
 */
export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    arg => arg,
  );

/**
 * Removing creating teamTimecard on server before submitting.
 * This takes the same parameters as the previous and returns a TC obj as close to what the BE was returning previously. *
 */
export const composeTeamTimecard = params => {
  const {
    projectId,
    departmentId,
    weekEndingdate,
    crewUsers,
    selectedUsers,
    batchTemplate = {},
    roundTo,
    wtcLayoutName,
  } = params;

  const days = [];
  let dateObj = moment(weekEndingdate, 'YYYY-MM-DDT00:00:00');
  const endsOn = dateObj.format('YYYY-MM-DDT00:00:00');
  let startsOn;
  for (let i = 0; i < 7; i++) {
    const date = dateObj.format('YYYY-MM-DDT00:00:00');
    dateObj = dateObj.subtract(1, 'day');
    const day = {
      date,
      call: null,
      ndbOut: null,
      ndbIn: null,
      meal1Out: null,
      meal1In: null,
      LastMan1In: null,
      ndmOut: null,
      ndmIn: null,
      meal2Out: null,
      meal2In: null,
      meal3Out: null,
      meal3In: null,
      wrap: null,
    };
    days.unshift(day);
    if (i === 6) startsOn = date;
  }

  const { state, city, htgStateId, htgCityId } = batchTemplate;

  const users = composeTTUsers(selectedUsers, crewUsers);

  const newTimecard = {
    status: 'incomplete',
    departmentId: Number(departmentId),
    projectId: Number(projectId),
    startsOn,
    endsOn,
    days,
    users,
    htgStateId,
    htgCityId,
    city: city ? makeMiniObj(city) : undefined,
    state: state ? makeMiniObj(state) : undefined,
    teamDealMemo: { roundTo },
    wtcLayoutName,
  };

  return newTimecard;
};

export const parseSelectedTTUser = selectedUser => {
  const split = selectedUser.split('_');
  const userId = split[0];
  const dealMemoId = split[1];

  return { userId, dealMemoId };
};

const composeTTUsers = (selectedUsers, crewUsers) => {
  const users = selectedUsers.map(sel => {
    const { userId, dealMemoId } = parseSelectedTTUser(sel);

    // eslint-disable-next-line eqeqeq
    const data = crewUsers.find(u => u?.user?.id == userId);
    const deal = data.dealMemos.find(dm => {
      return dm.id === dealMemoId;
    });

    const {
      id,
      occupationId,
      productionCompanyId,
      code,
      name,
      startsOn,
      endsOn,
      unionId,
      isValid,
      isExempt,
      jobDescription,
      occupationCode,
      htgUnion,
      htgContract,
    } = deal;

    const user = {
      userId: Number(userId),
      teamTimecardId: 'fresh-0',
      htgDealmemoId: deal.id,
      dealMemo: {
        id,
        occupationId,
        productionCompanyId,
        code,
        name,
        startsOn,
        endsOn,
        unionId,
        isValid,
        isExempt,
        jobDescription,
        occupationCode,
        htgUnion,
        htgContract,
      },
    };
    return user;
  });
  return users;
};

export function setupPendoInfo() {
  try {
    const project = JSON.parse(sessionStorage.getItem('pendo_project'));
    const uid = JSON.parse(
      window.atob(
        JSON.parse(
          sessionStorage?.getItem('okta-token-storage'),
        )?.accessToken?.accessToken?.split('.')?.[1] || null,
      ),
    )?.uid;
    let clientObj = {
      clientId: project?.clientCode || '',
      clientName: project?.clientName || '',
      dbCode: project?.dbCode || '',
      producerCode: project?.producerCode || '',
    };
    const userDetails = JSON.parse(sessionStorage.getItem('pendo_profile'));
    const adminObj = JSON.parse(sessionStorage.getItem('pendo_impersonate'));
    // check if admin
    if (adminObj?.isImpersonated || adminObj?.isCurrentUserAdmin) {
      userDetails.role = 'admin';
    }
    const parentIdObject = `${clientObj.dbCode}|${clientObj.clientId}|`;
    const accountIdObject = parentIdObject
      ? `${clientObj.producerCode}`
      : `${clientObj.dbCode}|${clientObj.clientId}|${clientObj.producerCode}`;
    const pendoObj = {
      visitor: {
        id: uid,
        role: userDetails.role,
        email: userDetails.email,
        current_parent_ID: `${clientObj.dbCode}|${clientObj.clientId}`,
        current_account_ID: `${clientObj.dbCode}|${clientObj.clientId}|${clientObj.producerCode}`,
        current_parent_name: clientObj.clientName,
        current_account_name: `${clientObj.clientName}|${project?.producerName}`,
      },
      account: {
        id: accountIdObject,
        name: `${clientObj.clientName}|${project?.producerName}`,
      },
      parentAccount: {
        id: parentIdObject,
        name: clientObj.clientName,
      },
    };

    window.pendo && window.pendo.updateOptions(pendoObj);
  } catch (error) {
    console.error('Pendo Error:', error);
  }
}

/** Copy state object without cities or counties */
export const copyState = state => {
  return _.cloneDeep({
    id: state.id,
    name: state.name,
    code: state.code,
    specialOptions: state.specialOptions,
  });
};

export const makeMiniObj = obj => ({
  id: obj.id,
  code: obj.code,
  name: obj.name,
});

export const makeMiniDeal = ({ dealMemo, includeGuar = false }) => {
  const newDeal = makeMiniObj(dealMemo);

  const objsToSave = [
    'htgUnion',
    'workSchedule',
    'htgContract',
    'occupationCode',
    'pensionUnion',
    'contract',
    'payrollCompany',
  ];
  objsToSave.forEach(fieldName => {
    if (dealMemo[fieldName]) {
      newDeal[fieldName] = makeMiniObj(dealMemo[fieldName]);
    }
  });

  const fieldsToSave = ACCOUNT_FIELDS_MAP.map(f => f.dealName);
  fieldsToSave.forEach(fieldName => {
    if (Object.hasOwnProperty.call(dealMemo, fieldName)) {
      newDeal[fieldName] = dealMemo[fieldName];
    }
  });

  if (dealMemo.guarantees && dealMemo.guarantees.length > 0) {
    newDeal.guarantees = _.clone(dealMemo.guarantees);
  }

  if (dealMemo.dealMemoAllowances && dealMemo.dealMemoAllowances.length > 0) {
    newDeal.dealMemoAllowances = _.cloneDeep(dealMemo.dealMemoAllowances);
  }
  return newDeal;
};

export const formatAllowedChars = value => {
  return value
    .trimStart()
    .replace(/[^A-Z0-9_&().,_/ -]/gi, '')
    .replace(/\s+$/g, ' ');
};

export const removeNoValueFromTimecard = (timecard, dayLabel = 'days') => {
  for (let i = 0; i < timecard[dayLabel]?.length; i++) {
    const day = timecard[dayLabel][i];
    removeNoValueObj(day);
  }
  removeNoValueObj(timecard);
};

//change episode to just ID string instead of object
export const removeEpiObj = timecard => {
  if (Array.isArray(timecard?.days)) {
    timecard.days.forEach(day => {
      if (day?.episode) {
        day.episode = day.episode.id;
      }
    });
  }
};

export const removeInvalidDealDays = timecard => {
  if (Array.isArray(timecard?.days)) {
    const dealMemo = timecard.dealMemo;
    const dealStart = moment(dealMemo.start);
    const dealEnd = moment(dealMemo.end);
    timecard.days.forEach(day => {
      const dayDate = moment(day.date);
      if (dayDate.isBefore(dealStart) || dayDate.isAfter(dealEnd)) {
        day.htgDayTypeId = null;
        day.dayType = null;
      }
    });
  }
};

export const removeInactiveDays = timecard => {
  if (Array.isArray(timecard?.days)) {
    let removedDays = false;
    for (let i = 0; i < timecard.days.length; i++) {
      let day = timecard.days[i];
      if (day.dayType?.id) {
        day.htgDayTypeId = day.dayType.id;
      } else {
        day = getEmptyDay(day);
        removedDays = true;
      }
      timecard.days[i] = day;
    }

    if (removedDays) {
      console.error(
        'Day removed from timecard due to invalid deal memo.  This should not happen, likely the deal was changed when the timecard was inflight',
      );
    }
  }
};

export const addEpisodeObj = (timecard, episodes) => {
  //episode comes in as an id, need to insert the episode object
  //expect to have timecard loaded already at this point

  timecard?.days?.forEach(day => {
    if (day.episode) {
      const episode = episodes.find(e => e.id === day.episode);
      if (episode) {
        day.episode = episode;
      }
    }
  });
};

/*
 * Removes empty object and empty strings from payloads
 * null or empty string id objects are considered empty objects
 */
export const removeNoValueObj = obj => {
  for (const field in obj) {
    if (Object.hasOwnProperty.call(obj, field)) {
      const value = obj[field];
      if (
        (value &&
          typeof value === 'object' &&
          !Array.isArray(value) &&
          (_.isEmpty(value) || value?.id === null || value?.id === '')) ||
        value === ''
      ) {
        delete obj[field];
      }
    }
  } //rof
};

export const getCurrency = ({ region, dbCode }) => {
  switch (region) {
    case 'US':
      switch (dbCode) {
        case 'PAY/PAYPA':
          return US_CURRENCY_TEXT;
        default:
          return US_CURRENCY_TEXT;
      }
    case 'Canada':
      switch (dbCode) {
        case 'PAYTORCA':
          return CANADIAN_CURRENCY_TEXT;
        case 'PAYTORUS':
          return US_CURRENCY_TEXT;
        case 'PAYVANCA':
          return CANADIAN_CURRENCY_TEXT;
        case 'PAYVANUS':
          return US_CURRENCY_TEXT;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

export const isRegionCanada = (region = localStorage.getItem('region')) =>
  region === 'Canada';

export const isArrayItemEmptyOrNull = array => {
  let isEmptyOrNull = false;
  for (const item of array) {
    if (_.isEmpty(item) || null) {
      isEmptyOrNull = true;
    } else {
      isEmptyOrNull = false;
    }
  }
  return isEmptyOrNull;
};

const CANADA_LABEL_MAP = {
  'Work State': 'Work Prov',
  'Live Check': 'Live Cheque',
  State: 'Prov',
  CombineCheck: 'Combine Cheque',
  'Combine Check': 'Combine Cheque',
  check: 'cheque',
  'Hire State': 'Hire Province',
  labor: 'labour',
};

export const canadianLabel = label => {
  const isCanada = isRegionCanada();

  if (isCanada && CANADA_LABEL_MAP[label]) {
    const canadaLabel = CANADA_LABEL_MAP[label];
    return canadaLabel;
  }
  return label;
};

export const isValidGuid = guid => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  return regex.test(guid);
};

//List utility functions
export function getStatusBadge(timecardStatus, isPA, isUPM, userLevel) {
  let statusText = timecardStatus;
  const STATUS_MAP = {
    'Ready for approver 1': 'ready',
    'Ready for approver 2': 'ready',
    'Ready for approver 3': 'ready',
    'Submitted to C&C': 'submitted',
    Processed: 'processing',
    Paid: 'paid',
    Draft: 'incomplete',
    Incomplete: 'incomplete',
    'Ready for employee': 'incomplete',
    Rejected: 'incomplete',
    'Ready for dept head': 'ready',
    'Ready for payroll acct': 'ready',
    'Ready for me': 'primary',
    Processing: 'processing',
  };

  const status = STATUS_MAP[timecardStatus];

  if (isPA && timecardStatus === 'Ready for Payroll Acct') {
    statusText = 'Ready for me';
  }
  if (isUPM && timecardStatus.includes('Ready for Approver')) {
    if (timecardStatus.includes(`${userLevel}`)) statusText = 'Ready for me';
  }

  return <PillBadge status={status}>{statusText}</PillBadge>;
}

// Used for setting default workLocation as Studio for Canada Projects
export function setWorkLocationForCanada(timecard, workLocations) {
  const days = timecard.days.map(day => {
    return {
      ...day,
      locationType:
        workLocations.find(workLoc => workLoc.name === 'Studio') || null,
    };
  });
  const updatedTc = { ...timecard, days };
  return updatedTc;
}
export const getEmployeeSIN = value => {
  let isSin = true;
  let securityNumber = value.ssnMask.split('-');
  if (securityNumber[2] && securityNumber[2].length === 4) {
    isSin = false;
  }
  return isSin;
};

export const getWorkflowStatus = project => {
  let workflowStatus;
  if (project && project.isTimePlusEnabled === 'N') {
    workflowStatus = 'Disabled';
  } else if (project && !project.isDraft && project.isTimePlusEnabled === 'Y') {
    workflowStatus = 'Enabled';
  } else {
    workflowStatus = 'Draft';
  }
  return workflowStatus;
};

export const getPayrollStatus = project => {
  let status;
  switch (project?.status) {
    case 'C':
      status = 'Complete';
      break;
    case 'A':
      status = 'Active';
      break;
    case 'Z':
      status = 'Post';
      break;
    case 'P':
      status = 'Pending';
      break;
    default:
      break;
  }
  return status;
};

/**
 *
 * @param {*} num
 * @returns number with either 2 decimal places or none if it would be .00.
 *          If input is NOT a number, returns original value
 */
export const formatNum2DecOrNone = num => {
  if (typeof num !== 'number') return num;
  const strVal = num.toFixed(2);

  const splits = strVal.split('.');
  if (splits[1] === '00') {
    return Number(num.toFixed(0));
  } else {
    return Number(strVal);
  }
};

//useful for comparing objects regardless of order of keys
export function deepSortObject(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    obj.sort((a, b) => {
      if (a.id) {
        return a.id < b.id ? 1 : -1;
      }
      if (a.effectiveDate) {
        return a.effectiveDate < b.effectiveDate ? 1 : -1;
      }
      return a < b;
    });
    return obj.map(deepSortObject);
  }

  const sortedObject = {};
  const keys = Object.keys(obj).sort();

  for (const key of keys) {
    sortedObject[key] = deepSortObject(obj[key]);
  }

  return sortedObject;
}

export function truncateStringToFitLength(length, str = '') {
  if (str.length <= length) {
    return str;
  }

  const ellipsis = '...';
  const totalLength = length - ellipsis.length;
  const prefixLength = Math.ceil(totalLength / 2);
  const suffixLength = Math.floor(totalLength / 2);

  const prefix = str.substring(0, prefixLength);
  const suffix = str.substring(str.length - suffixLength);

  return prefix + ellipsis + suffix;
}

export const getNavItems = ({ currentUser, project, isLoggedIn }) => {
  const {
    isProjectArchived,
    timePlusEnabled,
    dtsSettingEnabled,
    id: projectId,
  } = project;
  const { role, projectRoles, worksightId, workSightId, isAdmin } = currentUser;
  let isAdminUser = false;
  if (isLoggedIn) {
    isAdminUser = !!isAdmin;
  }
  const isPA = roleIsPA(role);
  const isUPM = roleIsUPM(role);
  const isDH = roleIsDH(role);
  const isIA = hasIARole(projectRoles);
  const isCrewTimecardRole = hasCrewTimeCardRoles(role);
  const isReviewer = hasReviewerRole(role);
  const hasWorksight = !!worksightId || !!workSightId;

  const navTitles = [];

  if (isAdminUser) {
    navTitles.push('Project Settings', 'Crew List', 'Audit Log');

    if (timePlusEnabled) {
      navTitles.push('Departments & Approvers', 'Templates');
    }

    if (isIA) {
      //TODO can this ever happen? Admin IA ??
      navTitles.push('Invoices');
    }
  } else {
    if (hasWorksight) {
      navTitles.push('My Timecards');
    }
    if (isReviewer) {
      navTitles.push('Timecards', 'Reports');
      if (!isProjectArchived) {
        navTitles.push('Crew List', 'Reviews');
        if (!isUPM) navTitles.push('Crew Timecards');
        if (isPA) {
          navTitles.push('Bulk Edit');
        }
        if (dtsSettingEnabled && isCrewTimecardRole) {
          navTitles.push('Daily Timesheets');
        }
      }
      if (!isDH || (isDH && isIA)) {
        navTitles.push('Invoices');
      }
    }
  }
  if (isIA && !navTitles.includes('Invoices')) {
    navTitles.push('Invoices');
  }

  const navItems = navTitles.map(title => {
    let index = NAVS.findIndex(n => n.label === title);

    if (title === 'Crew List' && !isAdminUser) {
      index = NAVS.findLastIndex(n => n.label === title);
    }
    let to = NAVS[index].path.replace(':projectId', projectId);
    const label = NAVS[index].label;

    if (label === 'Reviews') {
      const tabs = REVIEW_TABS_BY_ROLE(role, projectId);
      const activeReviewTab =
        tabs?.find(tab => tab.defaultActive === true)?.linkTo || null;
      to = activeReviewTab ? activeReviewTab : to;
    }
    return {
      label,
      path: NAVS[index].path,
      to,
      index,
    };
  });

  navItems.sort((a, b) => a.index - b.index);

  return navItems;
};

export const empDealSort = (a, b) => {
  const aDate = moment(a.effectiveDate);
  const bDate = moment(b.effectiveDate);

  if (aDate.isBefore(bDate)) {
    return 1;
  } else if (aDate.isAfter(bDate)) {
    return -1;
  } else {
    const aCode = Number(a.code);
    const bCode = Number(b.code);
    return bCode - aCode;
  }
};
