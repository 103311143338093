import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { withStyleSheet } from 'shared/theme';

export const style = ({ palette }) => ({
  root: {},
  icon: {
    marginRight: 5,
    fontSize: '1.2em',
    verticalAlign: 'middle',
  },
});

class NavButton extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), //MUI icons are obj while fa icons are functions
  };

  static defaultProps = {
    icon: null,
  };

  render() {
    const { classes, label, icon: Icon, ...other } = this.props;

    return (
      <Button {...other}>
        {Icon && <Icon className={classes.icon} />}
        {label}
      </Button>
    );
  }
}

export default withStyleSheet('NavButton', style)(NavButton);
