import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { Typography, LinearProgress } from '@mui/material';
import withModalDialog from 'decorators/withModalDialog';
import { ConfirmModal } from 'components/Shared/Modals';
import TextField from 'components/Shared/redux/TextField';
import { hide as hideModal } from 'actions/modalDialog';
import { setEditComment } from 'actions/timecards';
import { withStyleSheet } from 'shared/theme';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

export const style = ({ palette }) => ({
  dialogBox: {
    padding: 10,
    width: 600,
    height: 600,
  },
  header: {
    fontSize: 28,
    color: palette.text.secondary,
  },
  textField: {
    width: '100%',
    marginBottom: 32,
  },
  cancelButton: {
    width: 144,
    height: 40,
  },
  submitButton: {
    width: 128,
    height: 40,
    marginLeft: 24,
  },
  warningIcon: {
    color: palette.warning.main,
    verticalAlign: 'middle',
  },
  warningMessage: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const formName = 'EditTimecard';

const onSubmit = (values, dispatch, ownProps) => {
  dispatch(
    setEditComment({
      comment: {
        comments: values.comment,
        timecardEntryHeaderIds: [values.timecardEntryHeaderId],
        status: 'rejected',
        formName: formName,
        editReview: true,
      },
    }),
  );
  ownProps.timecardEnable({ editReview: true });
  dispatch(hideModal({ dialog: 'EditTimecard' }));
};
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      timecardEntryHeaderId: ownProps.timecardEntryHeaderId,
    },
  };
};
/**
 * Used when employee edits a timecard that has been submitted to them, usually for missing times.
 */
class EditTimecard extends Component {
  render() {
    const {
      classes,
      error,
      handleSubmit,
      timecard,
      submitting,
      timecardEntryHeaderId,
      editReview,
      enable,
      ...others
    } = this.props;

    const title = (
      <Typography variant="h6" color="inherit">
        {'Edit Timecard'}
      </Typography>
    );

    const content = (
      <div>
        <Typography color="inherit">
          {'Please add edit comment below'}
        </Typography>
        <br />
        <Field
          className={classes.textField}
          component={TextField}
          name="comment"
          label="Comment"
          inputProps={{ maxLength: commentsLimit }}
          placeholder="Please add edit comment below"
          required
          multiline
          autoFocus
        />
        <Field
          component="input"
          name="timecardEntryHeaderId"
          type="hidden"
          value={timecardEntryHeaderId}
        />
      </div>
    );
    return (
      <form onSubmit={handleSubmit}>
        {submitting && <LinearProgress />}
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Submit"
          buttonType="secondary"
          raisedButton
          cancelText="Cancel"
          submitDisabled={submitting}
          {...others}
        />
      </form>
    );
  }
}
export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'EditTimecard', maxWidth: 'md' }),
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    onSubmit,
  }),
)(EditTimecard);
