import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { change as changeAction } from 'redux-form';
import {
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  Button,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
//actions
import { addAllDaysOfWeek } from 'actions/wtc';

//selectors
import {
  getCanEditWorkTimes,
  getIsDraft,
  getIsEmergencyTC,
} from 'selectors/wtc';
import { isCurrentUserPA } from 'selectors/wtc';
import { getSettings } from 'selectors/settings';

//utils
import { WTC_FORM_NAME } from 'utils/wtcWeekUtils';
import { DEL_BTN_PAD_SIZE } from 'components/Employees/Reviews/WTC/WeekDay';
const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 1,
  },
  dealMemoHeader: {
    cursor: 'pointer',
    '& > *': { borderBottom: '1px dotted' },
  },
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    '& > *': {
      height: 50,
      fontWeight: 700,
      color: palette.getDefaultColor(),
    },
  },
  tableRow: {
    height: 31,
  },
  tableCell: {
    fontWeight: 500,
    color: palette.getDefaultColor(),
    padding: '7px 10px 9px 10px',
    lineHeight: '1rem',
  },
  timeCell: {
    whiteSpace: 'pre',
    backgroundColor: palette.misc.tableHeader,
    fontWeight: 500,
    color: palette.getDefaultColor(),
    padding: '7px 10px 9px 10px',
    lineHeight: '1rem',
  },
  calcCell: {
    color: '#ff9500', //hardCodedColor
    backgroundColor: 'rgba(255, 149, 0, 0.28)', //hardCodedColor
    fontWeight: 500,
    padding: '7px 15px 9px 16px',
    lineHeight: '1rem',
  },
  codeCell: {
    whiteSpace: 'pre',
    color: '#cb3fee', //hardCodedColor
    backgroundColor: 'rgba(203, 63, 238, 0.19)', //hardCodedColor
    fontWeight: 500,
    padding: '7px 15px 9px 16px',
    lineHeight: '1rem',
  },
  addLeftBorder: {
    borderLeft: `solid 5px ${palette.background.paper}`,
  },
  compactCell: {
    padding: 5,
    fontWeight: 700,
    color: palette.getDefaultColor(),
  },
  stickyCell: {
    zIndex: 1,
    position: 'sticky',
    left: 0,
    backgroundColor: palette.misc.tableHeader,
  },
  delBtnPad: {
    left: DEL_BTN_PAD_SIZE,
  },
}));

const mapState = state => ({
  canEditWorkTimes: getCanEditWorkTimes(state),
  isPA: isCurrentUserPA(state),
  isEmergencyTimecard: getIsEmergencyTC(state),
  isDraft: getIsDraft(state),
  projectSettings: getSettings(state),
});

const mapDispatch = dispatch => ({
  onChangeCanEditWorkTimes: () => {
    dispatch(changeAction(WTC_FORM_NAME, 'canEditWorkTimes', true));
  },
  onAddAllDaysOfWeek: () => dispatch(addAllDaysOfWeek()),
});

const TableHeadWTC = props => {
  const {
    columns,
    onShowMemoModal,
    dealMemoId,
    wtcDisabled,
    breakdown = false,
    //state
    canEditWorkTimes,
    isPA,
    isEmergencyTimecard,
    isDraft,
    projectSettings,
    //dispatch
    onChangeCanEditWorkTimes,
    onAddAllDaysOfWeek,
  } = props;
  const classes = useStyles();
  let needToAddCalcSpace = true;
  let needToAddCodeSpace = true;

  const editTimesSettingEnabled = projectSettings.editOptions === 'canEdit';

  const showEditDayTimesBtn =
    isPA &&
    !wtcDisabled &&
    !canEditWorkTimes &&
    (isEmergencyTimecard || editTimesSettingEnabled || isDraft);

  const handleShowEditDayTimesBtn = useCallback(() => {
    onChangeCanEditWorkTimes();
    onAddAllDaysOfWeek();
  }, [onAddAllDaysOfWeek, onChangeCanEditWorkTimes]);

  let tooltipContent = '';
  if (isDraft) {
    tooltipContent = 'Show all days for the week';
  } else if (isEmergencyTimecard) {
    tooltipContent = (
      <div>
        This feature allows you to change the call, meal, and wrap times, as
        well as add and remove days. <br />
        <br />
        These changes will be recorded in a comment on the timecard.
      </div>
    );
  } else {
    tooltipContent = (
      <div>
        You’ll be able to change the call, meal, and wrap times, as well as add
        and remove days. <br />
        <br />
        The employee will be required to sign off on these changes before the
        timecard can be approved further.
      </div>
    );
  }

  return (
    <MuiTableHead className={classes.tableHead}>
      <TableRow className={classes.tableRow}>
        {!breakdown && canEditWorkTimes && (
          <TableCell
            sx={{
              position: 'sticky',
              left: 0,
              backgroundColor: 'misc.tableHeader',
            }}
          />
        )}
        {columns.map((header, index) => {
          if (header.hidden) return null;
          if (!header.visible) return null;
          if (header.columnId === 'dayInfo') {
            return null;
          }
          let align = header.align || 'left';
          if (header.alignRight) align = 'right';

          // build cell classes
          // Make sure you have a leading space on new class strings
          let cellClasses = '';
          if (header.group === 'time') {
            cellClasses += ` ${classes.timeCell}`;
          } else if (header.group === 'calc') {
            const leftBorder = needToAddCalcSpace ? classes.addLeftBorder : '';
            needToAddCalcSpace = false;
            cellClasses += ` ${classes.calcCell} ${leftBorder}`;

            switch (header.label) {
              case 'Units':
                header.label = 'MPV AM';
                break;
              case 'Amount':
                header.label = 'MPV Amt AM';
                break;
              case 'UnitsPM':
                header.label = 'MPV PM';
                break;
              case 'AmountPM':
                header.label = 'MPV Amt PM';
                break;
              default:
              //leave header label as is
            }
          } else if (header.group === 'meta-codes') {
            const leftBorder = needToAddCodeSpace ? classes.addLeftBorder : '';
            needToAddCodeSpace = false;
            cellClasses += ` ${classes.codeCell} ${leftBorder}`;
          } else {
            cellClasses += ` ${classes.tableCell}`;
            if (header.columnId === 'splitButton') {
              cellClasses += ` ${classes.stickyCell}`;
              if (canEditWorkTimes) cellClasses += ` ${classes.delBtnPad}`;
            }
          }

          if (header.columnId === 'dealMemo') {
            cellClasses += ` ${classes.dealMemoHeader}`;
          }

          let value = header.label;
          let colSpan = 1;
          if (header.columnId === 'splitButton') {
            colSpan = 2;

            if (showEditDayTimesBtn) {
              value = (
                <Tooltip title={tooltipContent}>
                  <Button
                    sx={{ fontSize: 12, padding: '3px 8px' }}
                    onClick={handleShowEditDayTimesBtn}
                  >
                    {isDraft ? 'Edit Days' : 'Edit Days/Times'}
                  </Button>
                </Tooltip>
              );
            }
          }

          return (
            <TableCell
              className={cellClasses}
              size={header.compact ? 'small' : 'medium'}
              key={header.columnId}
              align={align}
              colSpan={colSpan}
              onClick={
                header.columnId !== 'dealMemo'
                  ? () => {}
                  : () => {
                      onShowMemoModal({
                        source: 'week',
                        currentDeal: dealMemoId,
                      });
                    }
              }
            >
              <span>{value}</span>
            </TableCell>
          );
        })}
      </TableRow>
    </MuiTableHead>
  );
};

export const columnHeaderProps = PropTypes.arrayOf(
  PropTypes.shape({
    columnId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    compact: PropTypes.bool,
    alignRight: PropTypes.bool,
    align: PropTypes.string,
    isCheck: PropTypes.bool,
  }),
);

TableHeadWTC.propTypes = {
  columns: columnHeaderProps.isRequired,
  onShowMemoModal: PropTypes.func,
  dealMemoId: PropTypes.string,
  wtcDisabled: PropTypes.bool,
  breakdown: PropTypes.bool,
  //redux props
  canEditWorkTimes: PropTypes.bool,
  isPA: PropTypes.bool,
  isEmergencyTimecard: PropTypes.bool,
  isDraft: PropTypes.bool,
  projectSettings: PropTypes.object,
  //dispatch
  onChangeCanEditWorkTimes: PropTypes.func,
  onAddAllDaysOfWeek: PropTypes.func,
};

export default compose(connect(mapState, mapDispatch))(TableHeadWTC);
