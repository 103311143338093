import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { showAlert, hideAlert } from 'actions/alert';
import SnackBar from 'components/Shared/Snackbar';

export const getAlert = state => get(state, `alert`, {});

/**
 * Show snack bar alert on top of page.
 * Use this decorator some where above the component you want to show alert from.
 * Usage: Dispatch showAlert({variant,message}) action
 *
 * @param {string} message (optional) - Message to be displayed
 * default: Oops, Something went wrong"
 *
 * @param {string} variant (optional)- Style of message to be shown
 * @variant (default) 'warning' - orange
 * @variant 'error' - red
 * @variant 'info' - blue
 * @variant 'success' - green
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const mapStateToProps = state => ({
    // snack: getAlert(state),
  });

  const mapDispatchToProps = dispatch => ({
    onShowAlert: message => dispatch(showAlert(message)),
    handleClose: () => dispatch(hideAlert()),
  });

  //TODO - remove unused snackbar instances, snackbars handled by root level component now, decorator isn't needed

  return BaseComponent => {
    class ComponentWithMenu extends Component {
      static displayName = `withSnack(${BaseComponent.displayName})`;

      render() {
        return (
          <React.Fragment>
            <BaseComponent {...this.props} />
            <SnackBar
              handleClose={this.props.handleClose}
              {...this.props.snack}
            />
          </React.Fragment>
        );
      }
    }

    return connect(mapStateToProps, mapDispatchToProps)(ComponentWithMenu);
  };
};
