import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import moment from 'moment';
import _ from 'lodash';
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { compose } from 'utils/helperFunctions';
import { DatePicker } from '@mui/x-date-pickers-pro';

import EmailChange from './EmailChange';

export const style = () => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  switch: {
    paddingLeft: 0,
  },
  introText: {
    marginTop: 0,
    marginBottom: 0,
  },
  space: {
    marginTop: 5,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  errorMessage: {
    color: 'red',
    align: 'center',
    padding: 16,
    margin: [16, 0],
    boxShadow: 'none',
  },
  saveMessage: {
    color: 'green',
    align: 'center',
    padding: 16,
    margin: [16, 0],
    boxShadow: 'none',
  },
  tablestyle: {
    width: '100%',
  },
  tableFirstTd: {
    width: '20%',
  },
  tableSecondTd: {
    width: '30%',
  },
  tableThirdTd: {
    width: '50%',
  },
  textInput: {
    width: '98%',
  },
  selectBox: {
    width: '100%',
  },
  inputButton: {
    margin: '0px',
  },
  rejectedTableFirstTd: {
    width: '20%',
  },
  rejectedTableSecondTd: {
    width: '50%',
  },
  rejectedTableThirdTd: {
    width: '30%',
  },
  datePickerBox: {
    width: '48%',
  },
  panelTop: {
    marginTop: 30,
  },
  labelSpace: {
    paddingLeft: '4%',
  },
  childRoot: {
    width: '100%',
    marginTop: 20,
  },
  emailChangeRoot: {
    width: '100%',
    marginTop: 20,
  },
  mergeButton: {
    textAlign: 'right',
  },
});

class SuperAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayvalidation: '',
      buttonflag: false,
      reports: '',
      expand: false,
      toDate: null,
      fromDate: null,
      idEndDisabled: true,
      tcExpand: false,
      templateExpand: false,
      templateButtonFlag: false,
      templateReportDetails: [],
      templetateProducersList: [],
      templateCompanyList: [],
      templateProjectsList: [],
      templateClientId: 0,
      templetateProducerId: 0,
      templateProductCompanyId: 0,
      templateProjectId: 0,
      templateToDate: moment(),
      emailChangeExpand: false,
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.rejectedHandleExpand = this.rejectedHandleExpand.bind(this);
    this.templateHandleExpand = this.templateHandleExpand.bind(this);
    this.setFromDate = this.setFromDate.bind(this);
    this.setToDate = this.setToDate.bind(this);
    this.setTemplateCreatedDate = this.setTemplateCreatedDate.bind(this);
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  handleExpand = e => {
    this.setState({
      expand: e ? false : true,
    });
  };

  rejectedHandleExpand = e => {
    this.setState({ tcExpand: e ? false : true });
  };
  templateHandleExpand = e => {
    this.setState({ templateExpand: e ? false : true });
  };

  emailChangeHandleExpand = e => {
    this.setState({ emailChangeExpand: e ? false : true });
  };
  handletemplateClientChange = () => {
    let msg = '';
    let buttonstatus = true;
    if (!document.getElementById('templateClientId')?.value) {
      buttonstatus = false;
    }
    let templateClientId = 0;
    if (
      document.getElementById('templateClientId').value !== '' &&
      document.getElementById('templateClientId').value !== 'undefined'
    ) {
      templateClientId = document.getElementById('templateClientId').value;
      this.props.onFetchUserAccessReportDetails({
        useraccessClientId: templateClientId,
        type: 'templeteReport',
      });
    }
    if (!document.getElementById('templateStartDate')?.value) {
      buttonstatus = false;
    }
    this.setState({
      displayvalidation: msg,
      templateButtonFlag: buttonstatus,
      templateReportDetails: [],
      templetateProducersList: [],
      templateCompanyList: [],
      templateProjectsList: [],
      templetateProducerId: 0,
      templateProductCompanyId: 0,
      templateProjectId: 0,
      templateToDate: null,
      templateClientId: templateClientId,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      JSON.stringify(prevState.clientDetailsById) !==
      JSON.stringify(nextProps.clientDetailsById)
    ) {
      let templetateProducersList = [];
      let templateReportDetails = nextProps.clientDetailsById;
      if (
        templateReportDetails.producer &&
        templateReportDetails.producer.length > 0
      ) {
        templetateProducersList = templateReportDetails.producer;
      }
      return {
        templateReportDetails: templateReportDetails,
        templetateProducersList: templetateProducersList,
      };
    } else return null;
  }

  handletemplateProducersChange = e => {
    let templateCompanyList = [];
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const producerId = el.getAttribute('id');
    let tempTemplateCompanyList =
      producerId === '0'
        ? this.state.templateReportDetails.productionCompany
        : this.state.templateReportDetails.productionCompany.filter(
            pd => pd.masterid === producerId,
          );
    if (tempTemplateCompanyList && tempTemplateCompanyList.length > 0) {
      templateCompanyList = tempTemplateCompanyList;
    }
    this.setState({
      templetateProducerId: producerId,
      templateProductCompanyId: 0,
      templateProjectId: 0,
      templateCompanyList: templateCompanyList,
      templateProjectsList: [],
    });
  };

  handletemplateProductCompanyChange = e => {
    let templateProjectsList = [];
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const productCompanyId = el.getAttribute('id');
    let tempTemplateProjectsList =
      productCompanyId === '0'
        ? this.state.templateReportDetails.projects
        : this.state.templateReportDetails.projects.filter(
            p => p.masterid === productCompanyId,
          );

    if (tempTemplateProjectsList && tempTemplateProjectsList.length > 0) {
      templateProjectsList = tempTemplateProjectsList;
    }
    this.setState({
      templateProductCompanyId: productCompanyId,
      templateProjectId: 0,
      templateProjectsList: templateProjectsList,
    });
  };

  handletemplateProjectsChange = e => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const projectId = el.getAttribute('id');
    this.setState({
      templateProjectId: projectId,
    });
  };

  setTemplateCreatedDate = date => {
    this.setState({
      templateToDate: date,
      templateButtonFlag: !(date === null),
    });
  };
  setFromDate = date => {
    this.setState({
      fromDate: date,
      idEndDisabled:
        date === null ||
        this.state.toDate === null ||
        this.state.toDate.isBefore(date),
    });
  };
  setToDate = date => {
    this.setState({
      toDate: date,
      idEndDisabled: this.state.fromDate === null || date === null,
    });
  };

  render() {
    const {
      report,
      classes,
      loading,
      rejectedLoading,
      onRunRejectedTimecardCommentsReport,
      onRunTemplateReport,
      clientsDetails,
    } = this.props;

    let errors = {};

    function RunTemplateReport(templateToDate) {
      const createdDate = templateToDate?.format('MM/DD/YYYY') || null;
      onRunTemplateReport({
        ...report,
        loading,
        ClientId: document.getElementById('templateClientId').value,
        producerId: document.getElementById('templateProducer').value,
        productCompanyId: document.getElementById('templateProductcompany')
          .value,
        projectId: document.getElementById('templateProject').value,
        createdDate,
        classes: classes,
        errors,
      });
    }
    function RunRejectedTimecardCommentsReport(fromDate, toDate) {
      onRunRejectedTimecardCommentsReport({
        ...report,
        rejectedLoading,
        fromDate: fromDate?.format('MM/DD/YYYY') || null,
        toDate: toDate?.format('MM/DD/YYYY') || null,
        classes: classes,
        errors,
      });
    }

    function SelectControlOptions({ options }) {
      if (options && options.length > 0) {
        return (
          <>
            <option id={0} value={0}>
              All
            </option>
            {_.chain(options)
              .sortBy('name')
              .value()
              .map(option => (
                <option
                  key={option.id}
                  id={option.id}
                  value={option.id}
                  name={option.name}
                >
                  {option.name}
                </option>
              ))}
          </>
        );
      } else {
        return <option></option>;
      }
    }

    function SelectCodeOptions({ options }) {
      if (options && options.length > 0) {
        return (
          <>
            <option id={0} value={0}>
              All
            </option>
            {_.chain(options)
              .sortBy('nameCode')
              .value()
              .map(option => (
                <option
                  key={option.id}
                  id={option.id}
                  value={option.code}
                  name={option.name}
                >
                  {option.nameCode}
                </option>
              ))}
          </>
        );
      } else {
        return <option></option>;
      }
    }
    return (
      <div className={classes.panelTop} onLoad={this.loaddata}>
        <Accordion expanded={this.state.tcExpand} className={classes.root}>
          <AccordionSummary
            id="rejectorTCExpand"
            className={classes.panelTop}
            expandIcon={<ExpandMoreIcon />}
            onClick={() => this.rejectedHandleExpand(this.state.tcExpand)}
          >
            <Typography className={classes.heading}>
              Rejected Timecard Report
            </Typography>
          </AccordionSummary>
          {rejectedLoading && <LinearProgress />}
          <AccordionDetails>
            <div className={classes.childRoot}>
              <table className={classes.tablestyle}>
                <tbody>
                  <tr>
                    <td className={classes.rejectedTableFirstTd}>
                      <Typography color="inherit">
                        Rejected Timecard{' '}
                      </Typography>
                    </td>
                    <td className={classes.rejectedTableSecondTd}>
                      <DatePicker
                        className={classes.datePickerBox}
                        id="startDate"
                        label="Start"
                        clearable
                        format="MM/DD/YYYY"
                        value={this.state.fromDate}
                        onChange={date => {
                          this.setFromDate(date);
                        }}
                      />
                      <label className={classes.labelSpace}></label>
                      <DatePicker
                        className={classes.datePickerBox}
                        id="endDate"
                        label="End"
                        clearable
                        format="MM/DD/YYYY"
                        value={this.state.toDate}
                        onChange={this.setToDate}
                        minDate={
                          this.state.fromDate
                            ? this.state.fromDate
                            : new moment('1900-01-01')
                        }
                      />
                    </td>
                    <td className={classes.rejectedTableThirdTd}>
                      <Button
                        disabled={this.state.idEndDisabled}
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          RunRejectedTimecardCommentsReport(
                            this.state.fromDate,
                            this.state.toDate,
                          )
                        }
                      >
                        Run
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.templateExpand}
          className={classes.root}
        >
          <AccordionSummary
            id="templatesTCExpand"
            className={classes.panelTop}
            expandIcon={<ExpandMoreIcon />}
            onClick={() => this.templateHandleExpand(this.state.templateExpand)}
          >
            <Typography className={classes.heading}>
              Templates and Departments
            </Typography>
          </AccordionSummary>
          {loading && <LinearProgress />}
          <AccordionDetails>
            <div className={classes.childRoot}>
              <table className={classes.tablestyle}>
                <tbody>
                  <tr>
                    <td className={classes.tableFirstTd}>
                      {' '}
                      <Typography>Client</Typography>
                    </td>
                    <td className={classes.tableSecondTd}>
                      <div>
                        <select
                          className={classes.selectBox}
                          value={this.state.templateClientId}
                          onChange={this.handletemplateClientChange}
                          id="templateClientId"
                        >
                          <SelectCodeOptions options={clientsDetails || []} />
                        </select>
                      </div>
                    </td>
                    <td className={classes.tableThirdTd}>
                      <span className={classes.errorMessage}>
                        {this.state.displayvalidation}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableFirstTd}>
                      {' '}
                      <Typography>Producer</Typography>
                    </td>
                    <td className={classes.tableSecondTd}>
                      <div>
                        <select
                          className={classes.selectBox}
                          value={this.state.templetateProducerId}
                          onChange={this.handletemplateProducersChange}
                          id="templateProducer"
                        >
                          <SelectControlOptions
                            options={this.state.templetateProducersList}
                          />
                        </select>
                      </div>
                    </td>
                    <td className={classes.tableThirdTd}></td>
                  </tr>
                  <tr>
                    <td className={classes.tableFirstTd}>
                      {' '}
                      <Typography>Prod Co.</Typography>
                    </td>
                    <td className={classes.tableSecondTd}>
                      <div>
                        <select
                          className={classes.selectBox}
                          value={this.state.templateProductCompanyId}
                          onChange={this.handletemplateProductCompanyChange}
                          id="templateProductcompany"
                        >
                          <SelectControlOptions
                            options={this.state.templateCompanyList}
                          />
                        </select>
                      </div>
                    </td>
                    <td className={classes.tableThirdTd}></td>
                  </tr>
                  <tr>
                    <td className={classes.tableFirstTd}>
                      {' '}
                      <Typography>Projects</Typography>
                    </td>
                    <td className={classes.tableSecondTd}>
                      <div>
                        <select
                          className={classes.selectBox}
                          value={this.state.templateProjectId}
                          onChange={this.handletemplateProjectsChange}
                          id="templateProject"
                        >
                          <SelectControlOptions
                            options={this.state.templateProjectsList}
                          />
                        </select>
                      </div>
                    </td>
                    <td className={classes.tableThirdTd}></td>
                  </tr>
                  <tr>
                    <td className={classes.tableFirstTd}>
                      {' '}
                      <Typography color="inherit">
                        Project Create Date
                      </Typography>
                    </td>
                    <td className={classes.tableSecondTd}>
                      <DatePicker
                        className={classes.datePickerBox}
                        id="templateStartDate"
                        label="Start"
                        clearable
                        format="MM/DD/YYYY"
                        value={this.state.templateToDate}
                        onChange={this.setTemplateCreatedDate}
                      />
                    </td>
                    <td className={classes.tableThirdTd}></td>
                  </tr>
                  <tr>
                    <td className={classes.tableFirstTd}></td>
                    <td className={classes.tableSecondTd}>
                      <Button
                        className={classes.inputButton}
                        id="btnTemplateReport"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          RunTemplateReport(this.state.templateToDate)
                        }
                        disabled={loading || !this.state.templateButtonFlag}
                      >
                        Run
                      </Button>
                    </td>
                    <td className={classes.tableThirdTd}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.emailChangeExpand}
          className={classes.root}
        >
          <AccordionSummary
            id="emailChangeExpand"
            className={classes.panelTop}
            expandIcon={<ExpandMoreIcon />}
            onClick={() =>
              this.emailChangeHandleExpand(this.state.emailChangeExpand)
            }
          >
            <Typography className={classes.heading}>Email Change</Typography>
          </AccordionSummary>
          {loading && <LinearProgress />}
          <AccordionDetails>
            <div className={classes.emailChangeRoot}>
              <EmailChange />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default compose(withStyleSheet('SuperAdmin', style))(SuperAdmin);
