import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { removeStepUser } from 'actions/reviewFlows';
// selector

const onSubmit = (_, dispatch) => {
  dispatch(removeStepUser());
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class RemoveReviewer extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { classes, handleSubmit } = this.props;
    const content = (
      <div className={classes.content}>
        <Typography color="inherit">
          {'Please be careful, this action can not be undone. ' +
            'Any timecards awaiting review on this level will be passed onto the next level. ' +
            'If this is the final reviewer level all timecards on this level will be submitted to C&C.'}
        </Typography>
      </div>
    );
    const title = (
      <Typography variant="h6" color="inherit">
        {'Remove Reviewer Level'}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Remove"
          buttonType="secondary"
          raisedButton={true}
          {...this.props}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'removeReviewer', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'RemoveReviewer',
    onSubmit,
  }),
)(RemoveReviewer);
