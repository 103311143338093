import produce from 'immer';
import * as actions from 'actions/invitations';

const initialState = {
  profile: {},
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loadingInvitations}`:
        draft.loading = action.loading;
        break;
      case `${actions.storeInvitations}`:
        draft.profile = action.invitation.profile;
        break;

      default:
    }
  });
