import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';
import { CardMedia as MuiCardHeader } from '@mui/material';

export const style = theme => ({
  root: {
    minHeight: '1em',
  },

  titleBar: {
    padding: 16,
    color: theme.palette.getDefaultColor(),
    borderBottom: [1, 'solid', theme.palette.gray['+8']],
  },

  brand: {
    backgroundImage: theme.palette.brand,
    color: 'white',
    borderBottom: 0,
  },
});

const CardHeader = ({
  classes,
  children,
  className: classNameProp,
  plain,
  ...props
}) => {
  const className = clsx(classes.root, classNameProp);
  const titleBarClass = clsx(classes.titleBar, {
    [classes.brand]: !plain,
  });

  return (
    <MuiCardHeader className={className} {...props}>
      <div className={titleBarClass}>{children}</div>
    </MuiCardHeader>
  );
};

CardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  plain: PropTypes.bool,
  src: PropTypes.string,
  image: PropTypes.string,
};

CardHeader.defaultProps = {
  children: undefined,
  className: undefined,
  plain: false,
  image: ' ',
  src: ' ',
};

export default withStyleSheet('CardHeader', style)(CardHeader);
