import React from 'react';
import { Button, Tooltip } from '@mui/material';

const ApproveRejectButtons = props => {
  const {
    showCheckboxesHandler,
    processingBulkApproval,
    enableBulkApproveReject,
    isPA,
    timecards,
    pristine,
  } = props;

  //if batch contains different statuses timecards ( ex: incomplete, draft, pending_pa_review, pending_upm_review, etc..)
  const filteredTCs = timecards?.filter(
    tc => tc.workflowStatus === 'pending_pa_review',
  );
  const isAllTCResubmitToEmployees = filteredTCs?.every(
    tc => tc.workflowAction === 'resubmitToEmployee',
  );
  const isTCReadyForApproval = filteredTCs?.some(
    tc => !tc.hasTimecardErrors && !tc.workflowAction,
  );

  const isTCReadyForRejection = filteredTCs?.some(
    tc => !tc.emergencyType && !tc.workflowAction,
  );
  const isRejectDisabled = isPA && !isTCReadyForRejection;
  const isApproveDisabled = isPA && !isTCReadyForApproval;
  const isButtonsDisabled = isPA && isAllTCResubmitToEmployees;

  const disabled =
    !enableBulkApproveReject ||
    processingBulkApproval ||
    !pristine ||
    isButtonsDisabled;
  let title = '';
  if (!enableBulkApproveReject) {
    title = 'No timecards can be approved or rejected';
  }

  const sx = isPA
    ? { justifyContent: 'space-evenly', marginBottom: '10px' }
    : {};
  const role = isPA ? 'PA' : 'UPM';
  return (
    <Tooltip title={title}>
      <div style={{ display: 'flex', ...sx }}>
        <Button
          className={`PENDO_wtc_bulk_approve_${role}`}
          variant="contained"
          onClick={() => showCheckboxesHandler('approve')}
          disabled={disabled || isApproveDisabled}
        >
          Approve
        </Button>
        <Button
          className={`PENDO_wtc_bulk_reject_${role}`}
          variant="contained"
          color="error"
          onClick={() => showCheckboxesHandler('reject')}
          disabled={disabled || isRejectDisabled}
        >
          Reject
        </Button>
      </div>
    </Tooltip>
  );
};
export default ApproveRejectButtons;
