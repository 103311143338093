import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router/immutable';
import appReducer from 'reducers';
import createRootSaga from 'sagas';
import createAction from 'actions/createAction';

export const RESET_APP = createAction('reset/app');
export const RESET_APP_ALL = createAction('reset/app/all');

if (!localStorage.debug) {
  localStorage.debug = 'axios,sagas';
}

export default function configureStore({ initialState, api, cookie, history }) {
  const sagaMiddleware = createSagaMiddleware();
  const rootSaga = createRootSaga({ api, cookie });
  // if env is development, check for REDUX_DEVTOOLS_EXTENSION (browser extension)
  const composeEnhancer =
    process.env.NODE_ENV !== 'development'
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancer = composeEnhancer(
    applyMiddleware(routerMiddleware(history), sagaMiddleware),
  );
  // const persistConfig = {
  //   key: 'root',
  //   storage,
  // };

  // const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const rootReducer = history => (state, action) => {
    if (action.type === 'reset/app') {
      const { projects, session } = state;
      state = { projects, session };
      localStorage.removeItem('fromURI');
    }
    if (action.type === 'reset/app/all') {
      state = {};
      localStorage.removeItem('fromURI');
    }

    return appReducer(history)(state, action);
  };
  const store = createStore(rootReducer(history), initialState, enhancer);
  // const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return store;
}
