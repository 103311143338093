import InvoiceApproverIcon from '@mui/icons-material/AssignmentTurnedIn';
import {
  Button,
  LinearProgress,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Label } from 'components/Shared/Text';
import { IA, ROLE_LABELS } from 'components/props/profiles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { withStyleSheet } from 'shared/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TableList } from 'components/Shared/TableList';

import { readableUsers } from 'feature/CrewList/CrewListUtils';
import { compose } from 'utils/helperFunctions';
const cubic = 'cubic-bezier(.64,.09,.08,1);';

export const style = () => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'auto',
  },
  empty: {
    textAlign: 'center',
  },
  row: {
    height: '64px',
  },
  visible: {
    overflow: 'visible',
  },
  actionColumn: {
    overflow: 'visible',
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionContainer: {
    display: 'inline-flex',
    padding: 0,
  },
  iconButton: {
    padding: 8,
    fontSize: '1.2em',
    display: 'inline',
    verticalAlign: 'bottom',
  },
  tooltip: {
    position: 'relative',
    '&:after': {
      opacity: 0,
      visibility: 'hidden',
      position: 'absolute',
      content: 'attr(data-tooltip)',
      padding: '6px 10px',
      top: '3.5em',
      left: '50%',
      fontSize: '0.7em',
      transform: 'translateX(-50%) translateY(-2px)',
      background: 'gray',
      color: 'white',
      whiteSpace: 'nowrap',
      zIndex: 999,
      borderRadius: '2px',
      transition: `opacity 0.2s ${cubic}, transform 0.2s ${cubic}`,
    },
    '&:hover': {
      '&:after': {
        display: 'block',
        opacity: 1,
        visibility: 'visible',
        transform: 'translateX(-50%) translateY(0)',
      },
    },
  },
  tableStyle: {
    width: '100%',
    marginTop: 10,
  },
  tableFirstTd: {
    width: '25%',
  },
  tableSecondTd: {
    width: '45%',
  },
  tableThirdTd: {
    width: '5%',
  },
  tableFourthTd: {
    width: '25%',
  },
  inputText: {
    marginTop: '10px',
    height: '32px',
    width: '100%',
  },
  inputButton: {
    marginTop: '10px',
  },
  invoiceIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  accordion: {
    marginTop: '20px',
  },
  heading: {
    fontSize: '1.1em',
  },
});

class UsersAllProjects extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      initialEmpty: true,
      expand: false,
    };

    this.disableInitialEmpty = this.disableInitialEmpty.bind(this);
  }

  disableInitialEmpty() {
    this.setState({ initialEmpty: false });
  }

  fetchUsersAction() {
    if (
      document.getElementById('userSearch').value !== '' &&
      document.getElementById('userSearch').value !== 'undefined'
    ) {
      this.props.onFetchUsers(document.getElementById('userSearch').value);
      this.disableInitialEmpty();
    } else {
      this.props.onFetchUsers('');
    }
  }

  //constants
  displayName = 'UsersAllProjectsList';

  headers = [
    {
      columnId: 'projectId',
      label: 'Project Id',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'projectName',
      label: 'Project Name',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'productionCompanyName',
      label: 'Production Company Name',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'profileName',
      label: 'User',
      sortable: true,
      compact: true,
    },
    { columnId: 'role', label: 'User Role', sortable: true, compact: true },
    {
      columnId: 'deptName',
      label: 'Department',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'status',
      label: 'Status',
      sortable: true,
      compact: false,
    },
    { columnId: 'isDraft', label: 'Draft?', sortable: true, compact: true },
    { columnId: 'isHidden', label: 'Hide?', sortable: true, compact: true },
    {
      columnId: 'isArchived',
      label: 'Archived?',
      sortable: true,
      compact: true,
    },
  ];

  render() {
    const { classes, allUsers, loading, onFetchUsers, ...others } = this.props;
    const readableUsersList = readableUsers(
      allUsers.map(item => ({ ...item, worksightId: item.userWorksightid })),
    );

    let contentRows = null;
    let enablePagination = true;
    if (readableUsersList && readableUsersList.length <= 0) {
      contentRows = (
        <TableRow>
          <TableCell colSpan={10} rowSpan={3} className={classes.empty}>
            <Typography variant="h6">
              {loading
                ? 'Loading Users...'
                : this.state.initialEmpty
                ? 'Enter email or name to search for users across projects'
                : 'No users found'}
            </Typography>
          </TableCell>
        </TableRow>
      );
      enablePagination = false;
    } else {
      contentRows = readableUsersList.map(user => (
        <TableRow
          hover
          key={`${user.projectId}-${user.profileEmail}`}
          className={classes.row}
        >
          <TableCell>{user.projectId}</TableCell>
          <TableCell>{user.projectName}</TableCell>
          <TableCell>{user.productionCompanyName}</TableCell>
          <TableCell>
            <Typography>{user.profileName}</Typography>
            <Typography>{user.profileEmail}</Typography>
          </TableCell>

          <TableCell>
            <div className={classes.invoiceIcon}>
              <Typography> {user.readableRole} </Typography>
              <Typography>
                {' '}
                {user?.roles?.includes(IA) && user?.hasSystemLocationAccess && (
                  <Tooltip title={ROLE_LABELS[IA]}>
                    <InvoiceApproverIcon sx={{ ml: 1 }} />
                  </Tooltip>
                )}
              </Typography>
            </div>
          </TableCell>

          <TableCell>{user.deptName}</TableCell>
          <TableCell>
            <Label uppercase primary={user.status === 'accepted'}>
              {user.status}
            </Label>
          </TableCell>
          <TableCell size="small">{user.isDraft ? 'Yes' : 'No'}</TableCell>
          <TableCell size="small">{user.isHidden ? 'Yes' : 'No'}</TableCell>
          <TableCell size="small">{user.isArchived ? 'Yes' : 'No'}</TableCell>
        </TableRow>
      ));
    }

    return (
      <Fragment>
        <Accordion expanded={this.state.expand}>
          <AccordionSummary
            className={classes.accordion}
            expandIcon={<ExpandMoreIcon />}
            onClick={() => this.setState({ expand: !this.state.expand })}
          >
            <Typography className={classes.heading}>User Search</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table className={classes.tableStyle}>
              <tbody>
                <tr>
                  <td className={classes.tableFirstTd}></td>
                  <td className={classes.tableSecondTd}>
                    <div>
                      <input
                        className={classes.inputText}
                        id="userSearch"
                        placeholder="Search Users By Email or Name"
                        autoFocus
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            this.fetchUsersAction();
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className={classes.tableThirdTd}>
                    <Button
                      className={classes.inputButton}
                      variant="contained"
                      color="primary"
                      onClick={this.fetchUsersAction.bind(this)}
                    >
                      Search
                    </Button>
                  </td>
                  <td className={classes.tableFourthTd}></td>
                </tr>
              </tbody>
            </table>
            <Paper className={classes.root}>
              {loading && <LinearProgress />}
              <TableList
                enablePagination={enablePagination}
                headers={this.headers}
                {...others}
              >
                {contentRows}
              </TableList>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }
}

export default compose(withStyleSheet('UserList', style))(UsersAllProjects);
