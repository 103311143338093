import { takeEvery, call, put, select, all, spawn } from 'redux-saga/effects';
import camelCase from 'camelcase-keys';
import snakeCase from 'snakecase-keys';
// actions
import { showAlert } from 'actions/alert';
import * as actions from 'actions/projectAllowances';
import { fetchDetails } from 'actions/project';

import {
  // toggleRejectOnAllowanceChange,
  store as storeSettings,
} from 'actions/settings';
// selector
import { getSettings, getInitialSettings } from 'selectors/settings';
import { getProject as currentProject } from 'selectors/routeParams';
import { getProjectAllowances } from 'selectors/projectAllowances';
import _ from 'lodash';

export function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));

    const projectId = yield select(currentProject);
    const data = yield call(api.projects.allowances, { projectId });
    yield spawn(fetchDayTypes, api, debug);
    const projectAllowances = camelCase(data, { deep: true });
    yield put(actions.store({ projectAllowances }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ projectAllowances: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

function* fetchDayTypes(api, debug) {
  try {
    const type = 'dayType';
    const params = {
      page: 1,
      pageSize: -1,
    };

    const res = yield call(api.timecards.searchByTypes, {
      type,
      params,
    });

    const dayTypes = res.map(item => {
      return {
        code: item.code,
        id: item.id,
        name: item.name,
        label: `${item.code} - ${item.name}`,
      };
    });

    yield put(actions.storeDayTypes({ dayTypes }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* update(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(currentProject);
    const projectAllowances = yield select(getProjectAllowances);
    const settings = yield select(getSettings);
    const initialSettings = yield select(getInitialSettings);
    let updateSettingsOrNot = false;
    let settingsObject = {};

    if (
      settings.myTimecardAutoAllowances !==
      initialSettings.myTimecardAutoAllowances
    ) {
      settingsObject.myTimecardAutoAllowances =
        settings.myTimecardAutoAllowances;
      updateSettingsOrNot = true;
    }

    if (
      settings.crewTimecardAutoAllowances !==
      initialSettings.crewTimecardAutoAllowances
    ) {
      settingsObject.crewTimecardAutoAllowances =
        settings.crewTimecardAutoAllowances;
      updateSettingsOrNot = true;
    }

    if (updateSettingsOrNot) {
      yield call(api.projects.updateSettings, {
        projectId,
        settings: settingsObject,
      });
      const data = yield call(api.projects.settings, { projectId });
      const settings = camelCase(data, { deep: true });
      yield put(storeSettings({ projectId, settings }));

      yield put(fetchDetails());
      updateSettingsOrNot = false;
    }

    const allowanceLst = snakeCase(projectAllowances, { deep: true });
    let allowanceObj = _.map(allowanceLst, allowance => {
      return {
        employee_mandatory: allowance.employee_mandatory,
        employee_visible: allowance.employee_visible,
        htg_allowance_type_id: allowance.id,
      };
    });
    let data = { allowances: Object.values(allowanceObj) };
    yield call(api.projects.updateProjectAllowances, { projectId, data });
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    // ** keep these, may need later **//
    // const projectId = yield select(currentProject);
    // if (e.request.responseURL.indexOf('settings') > 0) {
    //   yield put(toggleRejectOnAllowanceChange({ projectId }));
    // }
    debug(e);
    yield put(actions.loading({ loading: false }));
  }
}

export default function* projectAllowances({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.update}`, update, api, debug),
  ]);
}
