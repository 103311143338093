import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, startSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';

//components
import withModalDialog from 'decorators/withModalDialog';
import { CommentModal } from 'components/Shared/Modals';
import TextField from 'components/Shared/redux/TextField';

//actions
import { addModalComment, clearLocalComments } from 'actions/reviews';
import { clearCurrentTimecard } from 'actions/timecards';

//utils
import { formatMessages } from 'utils/messages';

//selectors
import { getComments } from 'selectors/reviews';
import { getModalParams } from 'selectors/modalDialog';
import { getCurrentTimecard } from 'selectors/timecard/common';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

export const style = ({ palette }) => ({
  textField: {
    width: '100%',
    marginBottom: 32,
  },
});

const formName = 'AddComment';
const mapStateToProps = state => ({
  timecardHeaderId: getCurrentTimecard(state),
  comments: getComments(state),
  modalParams: getModalParams(state, 'AddComment'),
});

const mapDispatchToProps = dispatch => ({
  clearComments: () => {
    dispatch(clearLocalComments());
  },
  onClearCurrentTimecard: () => {
    dispatch(clearCurrentTimecard());
  },
});

const onSubmit = (values, dispatch, ownProps) => {
  dispatch(startSubmit(formName));
  dispatch(addModalComment({ ...values, formName }));
};

// Requires currentTimecard to be set to the timecardHeaderId in question
// use this action to set:  dispatch(setCurrentTimecard({ timecardId: entryHeaderId }));

class AddModalComment extends Component {
  static propTypes = {
    error: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: undefined,
  };

  componentWillUnmount() {
    this.props.onClearCurrentTimecard();
    this.props.clearComments();
  }
  render() {
    const {
      classes,
      error,
      handleSubmit,
      comments,
      submitting,
      timecardHeaderId,
      modalParams,
      ...others
    } = this.props;

    const status = (modalParams?.status || '').toLowerCase();
    const cannotAddComment = status === 'paid' || status === 'processed';

    const content = cannotAddComment ? null : (
      <div>
        <Field
          className={
            error && error.email ? classes.errorField : classes.textField
          }
          hinttext={
            error && error.comment ? formatMessages(error.comment) : null
          }
          component={TextField}
          name="comment"
          variant="outlined"
          multiline
          rows="8"
          inputProps={{ maxLength: commentsLimit }}
          required
        />
      </div>
    );

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <CommentModal
            content={content}
            title={cannotAddComment ? '' : 'Add Comments'}
            comments={comments}
            buttonText="Add Comment"
            cancelText={cannotAddComment ? 'Close' : 'Cancel'}
            buttonType="secondary"
            submitting={submitting}
            raisedButton
            buttonHide={cannotAddComment}
            {...others}
          />
        </div>
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'AddComment', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    onSubmit,
  }),
)(AddModalComment);
