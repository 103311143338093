import React, { Component } from 'react';
import Draggable from 'react-draggable';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { Dialog } from '@mui/material';

import { getOnScreenHeight, getWidth } from 'utils';

export const style = () => ({
  root: {
    '& > *': {
      overflowY: 'auto',
      fontFamily: 'inherit',
      opacity: '1 !important',
      visibility: 'visible !important', //prevent closing on hot-reload
    },
  },
  rounded: {
    '& .MuiDialog-paper': { borderRadius: '20px' },
  },
});

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool,
    isRendered: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onRequestClose: PropTypes.func.isRequired,
    noEscClose: PropTypes.bool,
    clickAwayClose: PropTypes.bool,
  };

  static defaultProps = {
    fullScreen: false,
    isRendered: false,
    maxWidth: 'sm',
    draggable: false,
    noEscClose: false,
    clickAwayClose: false,
  };

  render() {
    const {
      children,
      classes,
      fullScreen,
      isRendered,
      onRequestClose,
      maxWidth,
      draggable,
      noEscClose,
      clickAwayClose,
      roundedCorners = false,
      sx = {},
    } = this.props;

    //Setup Draggable Modal options
    const onStart = () => draggable;
    //Can't easily get rid of backdrop, but it needs to cover whole screen, even if modal is dragged to extreme corners.
    const backDropProps = draggable
      ? {
          style: {
            width: '250%',
            height: '250%',
            position: 'absolute',
            top: '-80%',
            left: '-80%',
            opacity: 0,
            overflowY: 'none',
          },
        }
      : {};

    const horBound = Math.floor(getWidth() / 1.5);
    const vertBound = Math.floor(getOnScreenHeight() / 2);
    //End Draggable setup
    return (
      <Draggable
        bounds={{
          top: -vertBound,
          left: -horBound,
          right: horBound,
          bottom: vertBound,
        }}
        onStart={onStart}
      >
        <Dialog
          className={clsx(classes.root, { [classes.rounded]: roundedCorners })}
          sx={{ '.MuiDialog-paper': sx }}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          open={isRendered}
          onClose={(e, reason) => {
            if (
              (reason === 'escapeKeyDown' && noEscClose === false) ||
              (reason === 'backdropClick' && clickAwayClose === true)
            ) {
              onRequestClose();
            }
          }}
          BackdropProps={backDropProps}
        >
          {children}
        </Dialog>
      </Draggable>
    );
  }
}

export default withStyleSheet('Modal', style)(Modal);
