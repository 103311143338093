import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback,
} from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { get, map, startCase } from 'lodash';
import { TableRow, Button, IconButton, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { WTCTableCell as TableCell } from 'components/Shared/Table/';
import { Field } from 'redux-form';
import { TextField } from 'components/Shared/redux';
import WTCAutoV2 from './WTCAutoV2';
import { Checkbox } from 'components/Shared/redux';
import { OutlinedIconBadge } from 'components/Shared/Text';
import {
  preventBlanks,
  accountsFieldMaxLength,
  isAutoAllowanceCheck,
} from 'utils/weekUtils';
import {
  combineCheckOptions,
  handleChangeDealmemosWTCAllowance,
  getPayCodeFromDealMemoAllowance,
  fieldHasValue,
} from 'utils/wtcWeekUtils';
import {
  formatStrNumber,
  fileNameChange,
  onBlurStrNumber,
} from 'utils/helperFunctions';
// import { fileEncryptionCheckHandler } from 'utils/allowancesUtils';

// actions
import {
  fetchAllowancePayCode,
  toggleAllowanceField,
  fetchPayCodeById,
  // toggleTableFields,
} from 'actions/wtc';

//icons
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//components
import AlloFlagBadge from 'components/Shared/Text/AlloFlagBadge';

//constants
import {
  ALLOWANCE_DOC_MAX_SIZE,
  PERMITTED_FILE_UPLOAD_TYPE,
  ACCOUNT_FIELDS_MAP,
} from 'components/Shared/constants/';
import _ from 'lodash';
const style = ({ palette }) => ({
  docError: {
    color: palette.error.main,
    fontSize: 10,
    padding: 5,
  },
  disabledColor: { color: palette.action.disabled },
  input: {
    display: 'none',
  },
  inputProp: {
    width: 56,
    padding: '8px 4px',
    fontSize: 13,
  },
  inputError: {
    backgroundColor: palette.button.yellow.background,
    borderRadius: 5,
  },
  uploadFileContainer: {
    padding: '0px 5px',
  },
  supportingDocActions: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      //target icons
      height: 18,
    },
  },
  supportingDocContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px dashed',
    paddingLeft: 5,
    paddingRight: 5,
    width: 215,
    margin: 1,
  },
  supportingDocBtn: {
    marginLeft: '-8px',
  },
  supportingDocText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 104,
    marginLeft: 4,
  },
  uploadStretch: {
    width: 200,
  },
  deleteIcon: {
    color: palette.primary.main,
  },
  copyIcon: {
    color: palette.primary.main,
  },
  allowanceBtns: {
    display: 'flex',
  },
});

const AllowancesBodyRow = ({
  classes,
  index,
  removeRow,
  allowance,
  allowanceData,
  hasFactorNot1,
  occupationCodes = [],
  loadOccupationCodes,
  workLocations,
  dealMemos = [],
  dealMemoId,
  episodes,
  loadEpisodes,
  payCodes,
  supportDocument,
  allowancesOrder,
  change,
  blur,
  allowanceError,
  masterRowData,
  allowanceDisabled,
  upmEnabled,
  maskingFunc,
  accountCodeMask,
  dateOptions,
  handleCopyRow,
  isUPM,
  //
  currentStateId,
  currentCountryId,
  countries,
  stateOptions,
  countyOptions,
  cityOptions,
  onShowAlert,
  subdivisions = [],
  locationFuncs: {
    onFetchCountries,
    onFetchStates,
    onFetchCities,
    onFetchCounties,
    onFetchSubdivisions,
    onResetLoc,
  },
  caRegion,
  project,
}) => {
  const isAutoAllow = isAutoAllowanceCheck(allowanceData?.allowanceTypeFlag);
  const didMountRef = useRef(false);
  const [docError, setDocError] = useState({ type: '', hasError: false });

  const [disabledPayCode, setDisabledPayCode] = useState(isAutoAllow);
  const [payCodeOptions, setPayCodeOptions] = useState(payCodes);

  React.useEffect(() => {
    if (!isAutoAllow) {
      setPayCodeOptions(payCodes);
      return;
    }

    const dealMemo = dealMemos.find(dm => dm.id === dealMemoId) || {};

    const dealAllo = dealMemo?.dealMemoAllowances?.find(
      dmA => dmA.sequenceNumber === allowanceData.sequenceNumber,
    );

    if (!dealAllo) return;

    const newOptions = [];

    const option1 = payCodes.find(
      payCode => payCode.id === dealAllo?.payCode1?.id,
    );

    if (option1) newOptions.push(option1);

    const option2 = payCodes.find(
      payCode => payCode.id === dealAllo?.payCode2?.id,
    );

    if (option2) newOptions.push(option2);

    const compBoxCodes = ['EQP', 'EQR', 'COMPTAX', 'COMPNON'];

    if (
      compBoxCodes.includes(option1?.code) ||
      compBoxCodes.includes(option2?.code)
    ) {
      compBoxCodes.forEach(code => {
        const exists = newOptions.find(option => option.code === code);
        if (exists) return;

        const payCode = payCodes.find(payCode => payCode.code === code);
        if (payCode) newOptions.push(payCode);
      });
    }

    if (newOptions.length < 2) return;

    setPayCodeOptions(newOptions);
    setDisabledPayCode(false);
  }, [allowanceData, dealMemoId, dealMemos, isAutoAllow, payCodes]);

  useEffect(() => {
    if (
      !allowanceData.comment &&
      !allowanceData.document &&
      !allowanceData.filename
    ) {
      setDocError(err => {
        if (
          err.hasError &&
          allowanceData.comment === '' &&
          allowanceData.document === null &&
          allowanceData.filename === ''
        ) {
          return err;
        } else return { type: '', hasError: false };
      });
    } else {
      setDocError({ type: '', hasError: false });
    }
  }, [allowanceData]);
  const dispatch = useDispatch();

  let globalInputId = '';

  // initialize [] effect
  useEffect(() => {
    //Initialize Empty Allowance Row
    if (didMountRef.current) return;
    didMountRef.current = true;

    if (allowanceData.workState) {
      const state = allowanceData.workState;
      if (state?.specialOptions?.includes('C')) {
        dispatch(
          toggleAllowanceField({ columnId: 'workCounty', visible: true }),
        );
      }

      if (state?.specialOptions?.includes('U')) {
        dispatch(
          toggleAllowanceField({ columnId: 'workSubdivision', visible: true }),
        );
      }
    }

    if (fieldHasValue(allowanceData.workSubdivision)) {
      dispatch(
        toggleAllowanceField({ columnId: 'workSubdivision', visible: true }),
      );
    }
  }, [allowance, allowanceData, dispatch]);

  //aka Units
  function onBlurHours(e) {
    let { rate, amount, factor } = allowanceData;
    let hours = e.target.value;

    rate = parseFloat(rate);
    hours = parseFloat(hours);
    factor = parseFloat(factor || 1);
    if (isNaN(hours)) hours = 1;

    if (rate) {
      amount = factor * rate * hours;
      if (!isNaN(amount)) {
        change(`${allowance}.amount`, amount.toFixed(2));
      }
    } else {
      change(`${allowance}.amount`, 0);
    }
  }

  function handleChangePayCode(newValue) {
    if (newValue?.id && allowanceData && allowanceData.dealMemo) {
      const dealMemo = dealMemos.find(dm => dm.id === dealMemoId) || {};

      const dealPayCode = getPayCodeFromDealMemoAllowance(
        newValue.id,
        dealMemo,
      );
      dispatch(
        fetchPayCodeById({
          params: newValue?.id,
          member: allowance,
          rate: dealPayCode?.rate || 0,
          allowanceData: allowanceData,
        }),
      );
      if (dealPayCode) {
        ACCOUNT_FIELDS_MAP.forEach(field => {
          const { name, dealName } = field;
          if (dealPayCode[dealName]) {
            change(`${allowance}.${name}`, dealPayCode[dealName]);
          }
        });

        //dealMemoAllowances use yet another name for accountCode/wageAccount
        if (dealPayCode?.account) {
          change(`${allowance}.accountCode`, dealPayCode.account);
        }

        if (dealPayCode?.rate) {
          change(`${allowance}.rate`, dealPayCode.rate);
        }
      }
    }
  }

  function onBlurRate(e) {
    e.preventDefault(); //needed to change the field value with blur()
    let { hours, amount, factor } = allowanceData;
    let rate = e.target.value;

    blur(`${allowance}.rate`, onBlurStrNumber(rate));

    rate = parseFloat(rate);
    hours = parseFloat(hours);
    factor = parseFloat(factor || 1);

    if (isNaN(hours)) hours = 1;

    if (rate) {
      amount = factor * rate * hours;
      if (!isNaN(amount)) {
        change(`${allowance}.amount`, amount.toFixed(2));
      }
    } else {
      change(`${allowance}.amount`, 0);
    }
  }

  function handleChangeEpisodes(newValue) {
    change(`${allowance}.series`, newValue?.series || '');
    change(`${allowance}.location`, newValue?.location || '');
  }

  function handleChangeDealmemos(newValue) {
    const newDealId = newValue?.id;
    handleChangeDealmemosWTCAllowance(newDealId, {
      dealMemos,
      allowance,
      change,
      allowanceData,
    });
  }

  function handleChangeCountry() {
    change(`${allowance}.workState`, null);
    change(`${allowance}.workCity`, null);
    change(`${allowance}.workCounty`, null);
  }

  function handleChangeState(newValue) {
    change(`${allowance}.workCity`, null);
    change(`${allowance}.workCounty`, null);
    if (
      newValue?.specialOptions?.includes('C') &&
      !allowancesOrder.workCounty.visible
    ) {
      dispatch(toggleAllowanceField({ columnId: 'workCounty', visible: true }));
    }

    if (
      newValue?.specialOptions &&
      !newValue.specialOptions.includes('U')
      // U is the special option that denotes if that state can use workSubdivision
    ) {
      change(`${allowance}.workSubdivision`, null);
    }
  }

  const loadStatesOnOpen = useCallback(() => {
    const countryId = currentCountryId || '';
    onFetchStates({ countryId });
  }, [currentCountryId, onFetchStates]);

  const loadCountiesOnOpen = useCallback(() => {
    const stateId = currentStateId;
    if (stateId) onFetchCounties({ stateId });
  }, [currentStateId, onFetchCounties]);

  const loadCitiesOnOpen = useCallback(() => {
    const stateId = currentStateId;
    if (stateId) onFetchCities({ stateId });
  }, [currentStateId, onFetchCities]);

  const loadSubdivisions = useCallback(
    arg => {
      if (typeof arg === 'string') {
        onFetchSubdivisions({ search: arg });
      } else {
        //onOpen calls with an event obj
        onFetchSubdivisions();
      }
    },
    [onFetchSubdivisions],
  );

  function onRemoveRow(index) {
    removeRow(index);
  }

  const getErrorText = field => {
    return allowanceError && allowanceError[field]
      ? startCase(allowanceError[field])
      : null;
  };

  // loadOptions
  const loadPayCodes = inputText => {
    if (inputText && inputText.trim()) {
      dispatch(fetchAllowancePayCode({ search: inputText }));
    }
  };

  const loadWithDealMemo = callback => {
    // parentValue need to be dbCode ID
    const dbCodeId =
      get(masterRowData, 'project.dbCode.id', '') ||
      get(allowanceData, 'dealMemo.id', '');

    const htgContractId =
      get(allowanceData, 'dealMemo.htgContract.id', '') ||
      get(masterRowData, 'dealMemo.htgContract.id', '');

    const pensionUnionId =
      get(allowanceData, 'dealMemo.pensionUnion.id', '') ||
      get(masterRowData, 'dealMemo.pensionUnion.id', '');

    return searchText => {
      const params = {
        search: searchText,
        htgContractId,
        pensionUnionId,
        dbCodeId,
      };
      return callback(params);
    };
  };
  const handleClearUpload = useCallback(() => {
    change(`${allowance}.filename`, '');
    change(`${allowance}.comment`, '');
    change(`${allowance}.document`, null);
  }, [allowance, change]);

  const handleChangeUpload = async event => {
    const file = event.target.files && event.target.files[0];
    const fileType = file?.type;
    const newFile = fileNameChange(file);
    const hasUpload = allowanceData.document || allowanceData.comment;

    // HOUR-8062 - removing file encryption check for now
    // const isFileEncrypted = await fileEncryptionCheckHandler(file);
    // if (isFileEncrypted) {
    //   setDocError({ type: 'encrypt', hasError: true });
    //   if (hasUpload) handleClearUpload();
    //   event.target.value = '';
    //   onShowAlert({
    //     variant: 'warning',
    //     message:
    //       'The uploaded document cannot be attached, due to an e-signature on the document.',
    //   });
    // } else
    if (!PERMITTED_FILE_UPLOAD_TYPE.includes(fileType)) {
      setDocError({ type: 'fileType', hasError: true });
      if (hasUpload) handleClearUpload();
      event.target.value = '';
    } else if (file && file.size > ALLOWANCE_DOC_MAX_SIZE) {
      setDocError({ type: 'fileSize', hasError: true });
      if (hasUpload) handleClearUpload();
      event.target.value = '';
    } else if (file && file.name) {
      setDocError({ type: '', hasError: false });
      change(`${allowance}.filename`, newFile.name);
      change(`${allowance}.comment`, newFile.name);
      change(`${allowance}.document`, newFile);
    }
  };

  const handleDeleteFile = useCallback(() => {
    if (allowanceDisabled) return;
    handleClearUpload();
    setDocError({ type: '', hasError: false });
    const inputField = document.getElementById(globalInputId);
    if (inputField) inputField.value = '';
  }, [allowanceDisabled, handleClearUpload, setDocError, globalInputId]);

  const checkUPM = path => {
    const allowedFields = [
      'accountCode',
      'episode',
      'series',
      'location',
      'set',
      'insurance',
      'freeField1',
      'freeField2',
      'freeField3',
      'freeField4',
      'combineCheckCode',
    ];
    if (!isUPM) return allowanceDisabled; // PA can update allowances for draft/signedOff
    const isUPMfield = allowedFields.indexOf(path) !== -1;
    return isUPMfield ? upmEnabled : allowanceDisabled;
  };
  const pendoAllowanceFields = [
    'locationType',
    'reason',
    'combineCheck',
    'episode',
  ];
  const tableCells = map(allowancesOrder, (tableCell, i) => {
    if (tableCell.type === 'auto-complete') {
      const iscaRegionLocationType =
        caRegion && tableCell.columnId === 'locationType';
      return (
        <Fragment key={`${tableCell.label}_row${index}_${i}`}>
          <TableCell
            autoComplete
            bodyCell
            display={iscaRegionLocationType ? false : tableCell.visible}
          >
            <Field
              name={`${allowance}.${tableCell.path}`}
              pendoClass={
                pendoAllowanceFields.includes(tableCell.path)
                  ? `PENDO_wtc_allowance_${tableCell.path}`
                  : ''
              }
              component={WTCAutoV2}
              async
              loadOptions={(() => {
                switch (tableCell.path) {
                  case 'reason':
                    return loadPayCodes;
                  case 'occupationCode':
                    return loadWithDealMemo(loadOccupationCodes);
                  case 'workSubdivision':
                    return loadSubdivisions;
                  case 'episode':
                    return loadEpisodes;

                  default:
                    return () => {};
                }
              })()}
              stringValue={(() => {
                switch (tableCell.path) {
                  case 'combineCheck':
                  case 'date':
                    return true;
                  default:
                    return false;
                }
              })()}
              isDate={tableCell.path === 'date'}
              options={
                (() => {
                  switch (tableCell.path) {
                    case 'workCountry':
                      return countries;
                    case 'workState':
                      return stateOptions;
                    case 'workCounty':
                      return countyOptions;
                    case 'workCity':
                      return cityOptions;
                    case 'workSubdivision':
                      return subdivisions;
                    case 'occupationCode':
                      return occupationCodes;
                    case 'dealMemo':
                      return dealMemos;
                    case 'episode':
                      return episodes;
                    case 'reason':
                      return payCodeOptions;
                    case 'date':
                      return dateOptions;
                    case 'combineCheck':
                      return combineCheckOptions;
                    default:
                      return workLocations;
                  }
                })() || []
              }
              onChange={(() => {
                switch (tableCell.path) {
                  case 'workCountry':
                    return handleChangeCountry;
                  case 'workState':
                    return handleChangeState;
                  // case 'workSubdivision':
                  //   return handleChangeWorkSubdivision;
                  // case 'locationType':
                  //   return handleChangeWorkLocations;
                  // case 'occupationCode':
                  //   return handleChangeOccupationCode;
                  case 'dealMemo':
                    return handleChangeDealmemos;
                  case 'episode':
                    return handleChangeEpisodes;
                  case 'reason':
                    return handleChangePayCode;
                  // case 'combineCheck':
                  //   return handleChangeCCK;
                  default:
                    return () => {};
                }
              })()}
              onOpen={(() => {
                switch (tableCell.path) {
                  case 'workCountry':
                    return onFetchCountries;
                  case 'workState':
                    return loadStatesOnOpen;
                  case 'workCounty':
                    return loadCountiesOnOpen;
                  case 'workCity':
                    return loadCitiesOnOpen;
                  case 'workSubdivision':
                    return loadSubdivisions;
                  default:
                    return undefined;
                }
              })()}
              onClose={(() => {
                switch (tableCell.path) {
                  case 'workCountry':
                  case 'workState':
                  case 'workCounty':
                  case 'workCity':
                    return () => onResetLoc(tableCell.path);
                  default:
                    return undefined;
                }
              })()}
              error={getErrorText(tableCell.path) !== null}
              disabled={
                checkUPM(tableCell.path) ||
                (tableCell.path === 'reason' && disabledPayCode)
              }
            />
          </TableCell>
        </Fragment>
      );
    }
    if (tableCell.type === 'badge') {
      return (
        <Fragment key={`${tableCell.label}_row${index}_${i}`}>
          <TableCell bodyCell display={tableCell.visible}>
            <OutlinedIconBadge primary>Placeholder</OutlinedIconBadge>
          </TableCell>
        </Fragment>
      );
    }

    if (tableCell.type === 'checkbox') {
      return (
        <Fragment key={`${tableCell.label}_row${index}_${i}`}>
          <TableCell bodyCell display={tableCell.visible}>
            <Field
              inputProps={{
                className: `PENDO_wtc_allowance_${tableCell.path}`,
              }}
              name={`${allowance}.${tableCell.path}`}
              component={Checkbox}
              disabled={checkUPM(tableCell.path)}
              color="primary"
            />
          </TableCell>
        </Fragment>
      );
    }

    if (tableCell.columnId === 'comment') {
      globalInputId = `${tableCell.label}_row${index}_${i}`;
      var fileURLCheck =
        supportDocument.indexOf('https://') >= 0 ? false : true;
      return (
        <TableCell
          bodyCell
          display={tableCell.visible}
          key={`${tableCell.label}_row${index}_${i}`}
        >
          <div>
            <input
              id={globalInputId}
              type="file"
              accept="image/png, image/jpeg, .pdf"
              className={classes.input}
              multiple
              onChange={handleChangeUpload}
              disabled={allowanceDisabled}
            />
            {supportDocument.length || docError.hasError ? (
              <div
                className={clsx(classes.supportingDocContainer, {
                  [classes.docError]: docError.hasError,
                })}
              >
                {!fileURLCheck ? (
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.supportingDocBtn}
                    target="_blank"
                    href={supportDocument}
                  >
                    <CloudDownloadIcon />
                    <span className={classes.supportingDocText}>
                      {allowanceData.filename
                        ? allowanceData.filename
                        : 'Download File'}
                    </span>
                  </Button>
                ) : docError.hasError ? (
                  docError.type === 'fileType' ? (
                    <div>
                      <span>Error: File type invalid</span> <br />
                      <span>Please select different file type</span>
                    </div>
                  ) : docError.type === 'encrypt' ? (
                    <div>
                      <span>Error: File is esigned/passowd protected</span>{' '}
                      <br />
                      <span>Please select different file type</span>
                    </div>
                  ) : (
                    <div>
                      <span>Error: This file is too large</span> <br />
                      <span>File size should not exceed 10 MB</span>
                    </div>
                  )
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.supportingDocBtn}
                    target="_blank"
                    href={supportDocument}
                    disabled={fileURLCheck}
                  >
                    <CloudDownloadIcon />
                    <span className={classes.supportingDocText}>
                      {allowanceData.filename
                        ? allowanceData.filename
                        : 'Download File'}
                    </span>
                  </Button>
                )}
                <div
                  className={clsx(classes.supportingDocActions, {
                    [classes.disabledColor]: allowanceDisabled,
                  })}
                >
                  <label htmlFor={globalInputId}>
                    <EditIcon />
                  </label>
                  <HighlightOffIcon onClick={handleDeleteFile} />
                </div>
              </div>
            ) : (
              <div className={classes.uploadFileContainer}>
                <label htmlFor={globalInputId}>
                  <Button
                    variant="text"
                    color="primary"
                    component="span"
                    className={classes.supportingDocBtn}
                    disabled={allowanceDisabled}
                  >
                    <CloudUploadIcon />
                    <div className={classes.uploadStretch}>
                      <span className={classes.supportingDocText}>
                        Upload File
                      </span>
                    </div>
                  </Button>
                </label>
              </div>
            )}
          </div>
        </TableCell>
      );
    }

    if (tableCell.columnId === 'flagBadge') {
      const flagType =
        allowanceData?.allowanceTypeFlag === 'A'
          ? allowanceData?.isEdited
            ? 'modified'
            : 'deal'
          : 'manual';

      return (
        <TableCell
          bodyCell
          display={tableCell.visible}
          key={`${tableCell.label}_row${index}_${i}`}
        >
          <AlloFlagBadge flagType={flagType} />
        </TableCell>
      );
    }

    const textFieldInputProps = {
      classes: {
        input: classes.inputProp,
        error: classes.inputError,
      },
      inputProps: {
        style: {
          width: tableCell.path === 'accountCode' ? 68 : 56,
        },
        maxLength: accountsFieldMaxLength(project, tableCell.path),
        placeholder:
          tableCell.path === 'accountCode' ? accountCodeMask || '' : '',
      },
    };

    if (tableCell.path === 'combineCheck') {
      textFieldInputProps.inputProps = { maxLength: '1' };
    }

    return (
      <Fragment key={`${tableCell.label}_row${index}_${i}`}>
        <TableCell
          bodyCell
          display={
            tableCell.path === 'factor' ? !hasFactorNot1 : tableCell.visible
          }
        >
          <Field
            component={TextField}
            name={`${allowance}.${tableCell.path}`}
            type="text"
            InputProps={textFieldInputProps}
            variant="outlined"
            margin="none"
            error={getErrorText(tableCell.path) !== null}
            hasHelperText={false}
            disabled={
              tableCell.path === 'amount' || tableCell.path === 'factor'
                ? true
                : checkUPM(tableCell.path)
            }
            onBlur={
              tableCell.path === 'hours'
                ? onBlurHours
                : tableCell.path === 'rate'
                ? onBlurRate
                : () => {}
            }
            normalize={
              tableCell.path === 'rate'
                ? value =>
                    formatStrNumber(value, {
                      decimalPrecision: 4,
                    })
                : tableCell.path === 'hours'
                ? value =>
                    formatStrNumber(value, {
                      decimalPrecision: 2,
                    })
                : tableCell.path === 'accountCode'
                ? maskingFunc
                : tableCell.path === 'series' ||
                  tableCell.path === 'location' ||
                  tableCell.path === 'set' ||
                  tableCell.path === 'insurance' ||
                  tableCell.path === 'freeField1' ||
                  tableCell.path === 'freeField2' ||
                  tableCell.path === 'freeField3' ||
                  tableCell.path === 'freeField4'
                ? value => preventBlanks(value)
                : undefined
            }
          />
        </TableCell>
      </Fragment>
    );
  });
  const copyButtonHandler = e => {
    e.preventDefault();
    var allowanceDataDeepCopy = _.cloneDeep(allowanceData);
    if (allowanceDataDeepCopy.allowanceTypeFlag === 'A') {
      allowanceDataDeepCopy.allowanceTypeFlag = 'M';
    }
    if (!allowanceDataDeepCopy.comment) delete allowanceDataDeepCopy.comment;
    if (!allowanceDataDeepCopy.document) delete allowanceDataDeepCopy.document;
    if (!allowanceDataDeepCopy.filename) delete allowanceDataDeepCopy.filename;
    handleCopyRow(index, allowanceDataDeepCopy);
  };
  const onKeyDownHandler = e => {
    // metaKey for mac
    if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.key.toLowerCase() === 'c') {
      copyButtonHandler(e);
    }
  };
  const tooltipTitle = (
    <div>
      Windows: “CTRL + Shift + C” <br />
      Mac: “CMD + Shift + C”
    </div>
  );
  const allowanceBtnsBg = index % 2 === 0 ? 'even' : 'odd';
  return (
    <TableRow onKeyDown={onKeyDownHandler}>
      {tableCells}
      <TableCell
        bodyCell
        display={true}
        sticky={true}
        allowanceBtnsBg={allowanceBtnsBg}
      >
        <div className={classes.allowanceBtns}>
          <Tooltip title={tooltipTitle} placement="bottom">
            <span>
              <IconButton
                size="small"
                className="PENDO_wtcCopyAllowance"
                onClick={copyButtonHandler}
                disabled={allowanceDisabled}
              >
                <CopyIcon
                  fontSize="small"
                  className={clsx({
                    [classes.copyIcon]: !allowanceDisabled,
                  })}
                />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            size="small"
            className="PENDO_wtcDeleteAllowance"
            onClick={onRemoveRow}
            disabled={allowanceDisabled}
          >
            <DeleteIcon
              fontSize="small"
              className={clsx({
                [classes.deleteIcon]: !allowanceDisabled,
              })}
            />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(style)(AllowancesBodyRow);
