import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, LinearProgress, Button } from '@mui/material';
import pluralize from 'pluralize';
//sahred
import { withStyleSheet } from 'shared/theme';
// components
import { timecardsProps } from 'components/props/timecard';

import { getApproverList } from '../Reviews/ApproverList';

import {
  currentApprovers,
  getDeleteIcon,
  EmergencyIcon,
  ForceResubmitIcon,
  getStatusBadgeV2,
} from 'containers/Employees/Reviews/Shared/timecardUtils';

import CreateTimecard from '../../../containers/Nav/Pages/CreateTimecard';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

export const style = ({ palette }) => ({
  appBar: {
    justifyContent: 'flex-end',
    top: 'auto',
    bottom: 0,
    backgroundColor: palette.primary.background,
  },
  paper: {
    width: '100%',
    marginTop: 30,
    marginBottom: 50,
  },
  grow: {
    flexGrow: 1,
  },
  gridItem: {
    color: palette.text.secondary,
  },
  tableEmpty: {
    textAlign: 'center',
    height: 70,
    padding: 25,
    backgroundColor: palette.background.default,
  },
  title: {
    marginLeft: 20,
  },

  card: {
    fontSize: 14,
    padding: '24px 14px',
    lineHeight: '24px',
    marginBottom: 2,
  },

  bold: {
    fontWeight: 500,
  },
  cardContainer: {
    '& > div:nth-child(odd)': {
      backgroundColor: palette.primary.table,
    },
    '& > div:nth-child(even)': {
      backgroundColor: palette.background.paper,
    },
    overflowX: 'hidden',
  },
});
let currPlace = 0;
const keyCombo = ['t', 'c', 'v', '1'];
class CardList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    timecards: timecardsProps.isRequired,
    activeUser: PropTypes.object.isRequired,
    finish: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.state = {
      showV1: false,
    };

    this.listenForCombo = this.listenForCombo.bind(this);
  }

  handleClick(timecard) {
    if (this.props.onClick) {
      this.props.onClick(timecard);
    }
  }

  handleDelete(e, timecardId) {
    e.stopPropagation();
    if (this.props.onDelete) {
      this.props.onDelete(timecardId);
    }
  }

  listenForCombo = e => {
    const key = e.key.toLowerCase();
    if (key === keyCombo[currPlace]) {
      currPlace++;
    } else {
      currPlace = 0;
    }
    if (currPlace === keyCombo.length) {
      this.setState({ showV1: true });
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.listenForCombo);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listenForCombo);
  }

  render() {
    const {
      classes,
      timecards = [],
      finish,
      loading,
      isUserDeleted,
    } = this.props;
    const self = this;
    let contentRows = null;
    if (timecards && timecards.length <= 0) {
      contentRows = (
        <Grid container>
          <Grid item xs={12} className={classes.tableEmpty}>
            {loading ? 'Loading Timecards...' : 'No Timecards Found'}
          </Grid>
        </Grid>
      );
    } else {
      contentRows = timecards.map(timecard => (
        <Grid
          container
          className={classes.card}
          key={timecard.timecardId}
          onClick={() => self.handleClick(timecard)}
        >
          <Grid container>
            <Grid item xs={5} className={classes.gridItem} width={'3%'}></Grid>
            <Grid item xs={7} className={classes.bold}>
              {timecard.emergencyType ? (
                <EmergencyIcon />
              ) : timecard.workflowAction === 'resubmitToEmployee' ? (
                <ForceResubmitIcon />
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={classes.gridItem}>
              Week Ending:
            </Grid>
            <Grid item xs={7} className={classes.bold}>
              {timecard.endsOn.slice(0, 10)}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={classes.gridItem}>
              Occupation code:
            </Grid>
            <Grid item xs={7} className={classes.bold}>
              {' '}
              {timecard.occupationCode || 'Pending'}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={classes.gridItem}>
              Current Approver:
            </Grid>
            <Grid item xs={7} className={classes.bold}>
              {getApproverList(currentApprovers(timecard.currentApproversStr))}
            </Grid>
          </Grid>
          <Grid container sx={{ mt: '5px' }}>
            <Grid item xs={5} className={classes.gridItem}>
              Status:
            </Grid>
            <Grid item xs={7} className={classes.bold}>
              {getStatusBadgeV2(timecard)}
            </Grid>
          </Grid>
          <Grid container>
            {/* TODO - remove after my tc redesign is ready */}
            <Grid item xs={5} className={classes.gridItem}></Grid>
            <Grid item xs={7} className={classes.bold}>
              {this.state.showV1 && (
                <span>
                  <Button
                    onClick={e => {
                      this.props.onClickV1(timecard);
                      e.stopPropagation();
                    }}
                  >
                    V1
                  </Button>
                </span>
              )}
            </Grid>
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {!isUserDeleted &&
              getDeleteIcon(timecard, self.handleDelete, 'Delete')}
          </Grid>
        </Grid>
      ));
    }

    return (
      <Fragment>
        <Paper className={classes.paper}>
          {loading && timecards.length <= 0 && <LinearProgress />}
          {finish && (
            <h4 className={classes.title}>
              {`You have
               ${pluralize('timecard', timecards.length, true)} to Finish`}
            </h4>
          )}
          <div className={classes.cardContainer}>{contentRows}</div>
        </Paper>

        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className={classes.grow} />
            <CreateTimecard isUserDeleted={isUserDeleted} />
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyleSheet('CardList', style)(CardList);
