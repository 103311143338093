import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { push } from 'connected-react-router';
// decorators
import withDataGrid from 'decorators/withDataGrid';

// components
import { List as CrewTimecardList } from 'components/Employees/CrewTimecard';
// actions
import { fetchDepartments } from 'actions/project';
import { fetchReviewLevels } from 'actions/reviewFlows';
import { fetchWeekendingStats, storeWeekendingStats } from 'actions/timecards';
// selectors
import { getSortedDepartments } from 'selectors/project';
import { getLoading, getFilteredCrewTimecards } from 'selectors/timecards';
import { sortData } from 'selectors/sorting';
import { getReviewFlowLevels } from 'selectors/reviewFlows';

const grid = 'crew-timecard';

const mapState = (state, ownProps) => {
  const { match, pagination } = ownProps;
  const tempweekendingStats = getFilteredCrewTimecards(state);
  const gridPagination = {
    ...pagination,
    totalCount:
      pagination && pagination.totalCount !== tempweekendingStats.length
        ? tempweekendingStats.length
        : pagination.totalCount,
    activePage:
      tempweekendingStats.length > pagination.activePage * pagination.pageSize
        ? pagination.activePage
        : 0,
  };

  return {
    pagination: gridPagination,
    projectId: match.params.projectId,
    loading: getLoading(state),
    departments: getSortedDepartments(state),
    weekendingStats: sortData(
      getFilteredCrewTimecards,
      grid,
      gridPagination,
    )(state),
    reviewLevels: getReviewFlowLevels(state),
  };
};

const mapDispatch = dispatch => ({
  onFetchData: () => {
    dispatch(fetchDepartments());
    dispatch(fetchReviewLevels());
  },
  onFetchWeekendingStats: departmentId => {
    dispatch(fetchWeekendingStats({ departmentId }));
  },
  onTimecardSelect: (projectId, date, departmentId) => {
    const toURL = `/projects/${projectId}/timecards/week-ending/${date}/department/${departmentId}`;
    dispatch(push(toURL));
  },
  onClearWeekendingStats: () =>
    dispatch(storeWeekendingStats({ weekendingStats: [] })),
});

class CrewTimecard extends Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  componentWillUnmount() {
    this.props.onClearWeekendingStats();
  }

  render() {
    return <CrewTimecardList {...this.props} />;
  }
}

export default compose(
  withDataGrid(grid),
  connect(mapState, mapDispatch),
)(CrewTimecard);
