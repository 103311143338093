import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@mui/material';

const CheckboxCell = props => {
  const { disabled, baseOnChange, baseOnBlur, localVal, toggleCTA, id } = props;

  return (
    <Checkbox
      id={id}
      sx={{ margin: '0 0 0 35%', p: 0 }}
      disabled={disabled}
      color={'primary'}
      checked={localVal || false}
      onChange={e => {
        baseOnChange(e.target.checked);
        if (window.navigator.userAgent.includes('Safari')) {
          e?.target?.focus();
        }
      }}
      onFocus={() => toggleCTA(true)}
      onBlur={() => {
        toggleCTA(false);
        baseOnBlur();
      }}
    />
  );
};

CheckboxCell.propTypes = {
  disabled: PropTypes.bool,
  baseOnChange: PropTypes.func,
  baseOnBlur: PropTypes.func,
  localVal: PropTypes.bool,
  toggleCTA: PropTypes.func,
  id: PropTypes.string,
};

export default CheckboxCell;
