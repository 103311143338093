import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { withStyleSheet } from 'shared/theme';

export const style = theme => {
  const palette = theme.palette;
  const activeColor = '#f8e71c'; //hardCoded Colors
  const noColor = 'transparent';
  const root = {
    display: 'inline-block',
    minWidth: 145,
    height: 'inherit',
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: palette.gray['+9'],
    '& > *': {
      color: 'inherit',
    },
  };

  const navLink = {
    ...root,
    display: 'inline-block',
    height: '100%',
    position: 'relative',
  };

  return {
    root,

    noTransform: {
      paddingBottom: 0,
      verticalAlign: 'super',
    },

    navButton: {
      ...root,
      height: '100%',
      padding: 0,
      paddingLeft: 10,
      paddingRight: 10,
      verticalAlign: 'middle',
    },

    navLink,

    navLabel: {
      verticalAlign: 'middle',
      fontSize: 14,
    },

    navLabelPadded: {
      verticalAlign: 'middle',
      paddingLeft: 5,
    },

    navLabelLarge: {
      verticalAlign: 'middle',
      fontSize: 16,
    },

    navIndicator: {
      position: 'relative',
      display: 'block',
      height: 4,
      marginTop: -4,
      transition: theme.transitions.create(),
      willChange: 'left, width',
      backgroundColor: 'transparent',
    },

    activeNavSide: {
      ...navLink,
      boxShadow: `4px 0 0 ${activeColor} inset`,
    },

    activeNav: {
      ...navLink,
      backgroundColor: 'rgba(0,0,0,0.1)',
      '& > span:last-child': {
        backgroundColor: activeColor,
      },
    },

    noBackground: {
      backgroundColor: noColor,
    },

    secondaryNav: {
      minWidth: 'auto',
      fontWeight: 500,
      paddingRight: 4,
      paddingLeft: 4,
    },

    alternateNav: {
      color: palette.text.primary,
      minWidth: 'auto',
      fontWeight: 500,
      paddingRight: 4,
      paddingLeft: 4,
    },

    navIcon: {
      fontSize: '1rem',
      position: 'absolute',
      right: '10%',
      top: 21,
    },

    alternateActiveNav: {
      '& > span:last-child': {
        backgroundColor: palette.secondary.light,
      },
    },
  };
};

// require either or for url and linkTo
const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.linkTo && !props.url) {
    return new Error(
      `One of 'linkTo' or 'url' is required by '${componentName}', 
      but the values are: linkTo:'${props.linkTo}' url:'${props.url}'`,
    );
  }

  if (props[propName] && typeof props[propName] !== 'string') {
    let msg = '';
    msg += `Invalid prop '${propName}' of type '${typeof props[propName]}'`;
    msg += ` supplied to '${componentName}', expected 'string'`;
    return new Error(msg);
  }
};

class NavItem extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    disablePadding: PropTypes.bool,
    linkTo: requiredPropsCheck,
    location: PropTypes.object.isRequired,
    navIcon: PropTypes.node,
    navLabel: PropTypes.string.isRequired,
    showSideActive: PropTypes.bool,
    showIndicator: PropTypes.bool,
    noTransform: PropTypes.bool,
    active: PropTypes.bool,
    secondary: PropTypes.bool,
    secondaryLinks: PropTypes.arrayOf(PropTypes.string),
    alternate: PropTypes.bool,
    url: requiredPropsCheck,
  };

  static defaultProps = {
    disablePadding: false,
    linkTo: undefined,
    navIcon: undefined,
    showSideActive: false,
    showIndicator: false,
    secondary: false,
    noTransform: false,
    alternate: false,
    secondaryLink: [''],
  };

  constructor(props) {
    super(props);

    this.isActiveLink = this.isActiveLink.bind(this);
  }

  isActiveLink(match, location) {
    const { secondaryLinks, active } = this.props;

    if (active || match) return true;

    return _.includes(secondaryLinks, location.pathname);
  }

  render() {
    const {
      classes,
      disablePadding,
      linkTo,
      url,
      location,
      navIcon,
      navLabel,
      showSideActive,
      showIndicator,
      noTransform,
      secondary,
      alternate,
      mobileNav,
    } = this.props;

    const activeClass = clsx({
      [classes.activeNav]: true,
      [classes.activeNavSide]: showSideActive,
      [classes.noBackground]: showIndicator,
      [classes.alternateActiveNav]: alternate,
    });

    const navClass = clsx({
      [classes.navLink]: true,
      [classes.secondaryNav]: secondary,
      [classes.alternateNav]: alternate,
      [classes.noTransform]: noTransform,
    });

    const navButtonClass = clsx({
      [classes.navButton]: true,
      [classes.secondaryNav]: secondary,
      [classes.alternateNav]: alternate,
    });

    const navLabelClass = clsx({
      [classes.navLabel]: !noTransform && disablePadding,
      [classes.navLabelPadded]: !noTransform && !disablePadding,
      [classes.navLabelLarge]: noTransform,
    });

    if (linkTo) {
      return (
        <NavLink
          to={linkTo}
          activeClassName={activeClass}
          className={navClass}
          location={location}
          isActive={this.isActiveLink}
        >
          <div className={navButtonClass}>
            {navIcon}
            <span className={navLabelClass}>{navLabel}</span>
          </div>
          {mobileNav && <ArrowForwardIosIcon className={classes.navIcon} />}
          {showIndicator && <span className={classes.navIndicator} />}
        </NavLink>
      );
    }

    if (url) {
      return (
        <a
          // activeClassName={activeClass}
          className={navClass}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={navButtonClass}>
            {navIcon}
            <span className={navLabelClass}>{navLabel}</span>
          </div>
          {mobileNav && <ArrowForwardIosIcon className={classes.navIcon} />}
        </a>
      );
    }
  }
}

export default withStyleSheet('NavItem', style)(NavItem);
